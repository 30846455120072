export class OvrseaDataError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "OvrseaDataError";
    Object.setPrototypeOf(this, OvrseaDataError.prototype);
  }
}

export type GenericCallback<A, B extends any[]> = (...arg: B) => A;

export const secureAgainstLegacyError =
  <A, B extends any[]>(callback: GenericCallback<A, B>, errorInfo?: object) =>
  (...args: B) => {
    try {
      return callback(...args);
    } catch (err) {
      if (err instanceof OvrseaDataError) {
        console.log(`Error info: ${errorInfo}`);
        console.log(err);

        return null;
      }
      throw err;
    }
  };
