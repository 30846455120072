import React from "react";
import { isNumber } from "lodash-es";
import type { Variants } from "framer-motion";
import { motion } from "framer-motion";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";

const enterDelay = (delay?: { enter?: string } | number) => ({
  delay: isNumber(delay) ? delay : delay?.["enter"],
  duration: 0.2,
  ease: [0, 0, 0.2, 1],
});

const exitDelay = (delay?: { exit?: string } | number) => ({
  delay: isNumber(delay) ? delay : delay?.["exit"],
  duration: 0.1,
  ease: [0.4, 0, 1, 1],
});

const variants: Variants = {
  enter: ({ delay, transition, transitionEnd }) => ({
    opacity: 1,
    // scale: 1,
    transition: transition?.enter ?? enterDelay(delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ delay, transition, transitionEnd }) => ({
    opacity: 0,
    // scale: initialScale,
    transition: transition?.exit ?? exitDelay(delay),
    transitionEnd: transitionEnd?.exit,
  }),
};

const reverseScale = {
  initialScale: 0.95,
  reverse: true,
};

const ScaleMotion = polymorphic<"div">(
  (
    {
      onAnimationEnd,
      onAnimationStart,
      onDrag,
      onDragEnd,
      onDragStart,
      ...rest
    },
    ref,
  ) => (
    <styled.section
      animate="enter"
      as={motion.section}
      custom={reverseScale}
      exit="exit"
      initial="exit"
      ref={ref}
      variants={variants}
      {...rest}
    />
  ),
);

ScaleMotion.displayName = "ScaleMotion";

export { ScaleMotion };
