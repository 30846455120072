import { keyframes } from "../system";

export const animateEnter = keyframes({
  "0%": {
    opacity: "var(--enter-opacity, 1)",
    transform:
      "translate3d(var(--enter-translate-x, 0), var(--enter-translate-y, 0), 0) scale3d(var(--enter-scale, 1), var(--enter-scale, 1), var(--enter-scale, 1))",
  },
});

export const animateExit = keyframes({
  to: {
    opacity: "var(--exit-opacity, 1)",
    transform:
      "translate3d(var(--exit-translate-x, 0), var(--exit-translate-y, 0), 0) scale3d(var(--exit-scale, 1), var(--exit-scale, 1), var(--exit-scale, 1))",
  },
});
