import React from "react";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { AlertErrorIcon } from "../../Feedback/Alert/AlertComponents";
import { Spinner } from "../../Feedback/Spinner";
import type { BodyProps } from "../../Typography/Body";
import { Body } from "../../Typography/Body";
import type { HeadingProps } from "../../Typography/Heading";
import { Heading } from "../../Typography/Heading";
import type { CenterProps } from "../Center";
import { Center } from "../Center";
import type { FlexProps } from "../Flex";
import { Flex } from "../Flex";
import { useFetchStateContext } from "./FetchStateContext";

const StyledContent = styled<"div", { isEmpty?: boolean; isLoading?: boolean }>(
  "div",
  {
    base: ({ isLoading, theme }) => [
      {
        transitionDuration: theme.transition.duration.fast,
        transitionProperty: "opacity",
        transitionTimingFunction: theme.transition.easing.color,
      },
      isLoading && {
        cursor: "default",
        opacity: 0.4,
        pointerEvents: "none",
      },
    ],
  },
);

const StateContent = polymorphic<"div", FlexProps>((props, ref) => {
  const { isDisabledWhileRefetching, isEmpty, isErrored, isLoading } =
    useFetchStateContext();

  const shouldShowSpinner = isLoading && isDisabledWhileRefetching;

  if (isEmpty || isErrored) {
    return null;
  }

  return <StyledContent isLoading={shouldShowSpinner} ref={ref} {...props} />;
});

StateContent.displayName = "StateContent";

const SpinnerContainer = styled(Center, {
  base: {
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
});

const shouldShowSpinner = ({
  isDisabledWhileRefetching,
  isEmpty,
  isLoading,
}: {
  isDisabledWhileRefetching: boolean | undefined;
  isEmpty: boolean | undefined;
  isLoading: boolean | undefined;
}) => {
  const isRefetching = isLoading && !isEmpty;

  if (isRefetching && isDisabledWhileRefetching) {
    return false;
  }

  return isLoading;
};

const LoadingState = polymorphic<"div", CenterProps>(
  ({ children, ...rest }, ref) => {
    const { isDisabledWhileRefetching, isEmpty, isLoading } =
      useFetchStateContext();

    return (
      <>
        {shouldShowSpinner({ isDisabledWhileRefetching, isEmpty, isLoading }) &&
          (children ?? (
            <SpinnerContainer ref={ref} {...rest}>
              <Spinner isActive />
            </SpinnerContainer>
          ))}
      </>
    );
  },
);

LoadingState.displayName = "LoadingState";

const StyledEmptyState = styled(Center, {
  base: {
    margin: "3rem 0",
  },
});

const EmptyState = polymorphic<"div", CenterProps>((props, ref) => {
  const { isEmpty, isErrored, isLoading } = useFetchStateContext();

  if (!isEmpty || isLoading || isErrored) {
    return null;
  }

  return <StyledEmptyState flexDirection="column" ref={ref} {...props} />;
});

EmptyState.displayName = "EmptyState";

const EmptyStateTitle = polymorphic<"h5", HeadingProps>((props, ref) => (
  <Heading size="xs" {...props} ref={ref} />
));

EmptyStateTitle.displayName = "EmptyStateTitle";

const StyledDescription = styled(Body, {
  base: ({ theme }) => ({
    marginBottom: theme.spacing[24],
    marginTop: theme.spacing[16],
    maxWidth: 360,
    textAlign: "center",
  }),
});

const EmptyStateDescription = polymorphic<"p", BodyProps>((props, ref) => (
  <StyledDescription isSecondary ref={ref} {...props} />
));

EmptyStateDescription.displayName = "EmptyStateDescription";

type _ErrorStateProps = {
  /** @internal */
  force?: boolean;
} & FlexProps;

const Relative = styled(Flex, { base: { position: "relative" } });

const ErrorContainer = styled(Center, { base: { margin: "3rem 0" } });

const ErrorState = polymorphic<"div", _ErrorStateProps>(
  ({ children, force, ...rest }, ref) => {
    const { isErrored } = useFetchStateContext();

    if (!isErrored && !force) {
      return null;
    }

    return (
      <ErrorContainer data-testid="ErrorState" ref={ref} {...rest}>
        <Relative flexDirection="column" justifyContent="flex-start">
          {children}
        </Relative>
      </ErrorContainer>
    );
  },
);

ErrorState.displayName = "ErrorState";

const ErrorStateIcon = styled(AlertErrorIcon, {
  base: ({ theme }) => ({
    color: theme.colors.alert.danger,
    left: "-1.75rem",
    position: "absolute",
    top: "0.5rem",
  }),
});

ErrorStateIcon.displayName = "ErrorStateIcon";

const ErrorStateTitle = polymorphic<typeof Heading, HeadingProps>(
  (props, ref) => <Heading size="xs" {...props} ref={ref} />,
);

ErrorStateTitle.displayName = "ErrorStateTitle";

const StyledErrorDescription = styled(Body, {
  base: ({ theme }) => ({
    marginBottom: theme.spacing[24],
    marginTop: theme.spacing[8],
    maxWidth: 360,
  }),
});

const ErrorStateDescription = polymorphic<"div", BodyProps>((props, ref) => (
  <StyledErrorDescription isSecondary {...props} ref={ref} />
));

ErrorStateDescription.displayName = "ErrorStateDescription";

export {
  EmptyState,
  EmptyStateDescription,
  EmptyStateTitle,
  ErrorState,
  ErrorStateDescription,
  ErrorStateIcon,
  ErrorStateTitle,
  LoadingState,
  StateContent,
};
