import type { PropsWithChildren } from "react";
import React from "react";
import { createPortal } from "react-dom";
import { usePortalRef } from "./Portal/usePortalRef";

export type PortalProps = PropsWithChildren<object>;

export const Portal = ({ children }: PortalProps): JSX.Element => {
  const { portalRef, temporaryRef } = usePortalRef();

  if (!portalRef.current) {
    return <span ref={temporaryRef} />;
  }

  return createPortal(children, portalRef.current);
};
