export const convertKgToTons = (valueInKg: number) => {
  return valueInKg / 1000;
};

export const convertKgToLbs = (valueInKg: number) => {
  return valueInKg * 2.20462;
};

export const convertLbsToKg = (valueInLbs: number) => {
  return valueInLbs / 2.20462;
};

export const convertCmToIn = (valueInCm: number) => {
  return valueInCm * 0.3937;
};

export const convertInToCm = (valueInIn: number) => {
  return valueInIn / 0.3937;
};

export const convertCbmToCbf = (valueInCbm: number) => {
  return valueInCbm * 35.314667;
};
export const convertCbmToCcm = (valueInCbm: number) => valueInCbm * 1000000;

export const convertCbmToCbi = (valueInCbm: number) => valueInCbm * 61023.74;

export const convertCbiToCbf = (valueInCbi: number) => {
  return valueInCbi / 1728;
};

export const convertCbiToCcm = (valueInCbi: number) => {
  return valueInCbi * 16.38706;
};

export const convertCbiToCbm = (valueInCbi: number) => {
  return valueInCbi / 61023.74;
};

export const convertCbfToCbm = (valueInCbf: number) => {
  return valueInCbf / 35.314667;
};

export const convertCbfToCbi = (valueInCbf: number) => {
  return valueInCbf * 1728;
};

export const convertCbfToCcm = (valueInCbf: number) => {
  return valueInCbf * 28316.85;
};

export const convertCcmToCbm = (valueInCcm: number) => valueInCcm / 1000000;

export const convertCcmToCbi = (valueInCcm: number) => valueInCcm / 16.38706;

export const convertCcmToCbf = (valueInCcm: number) => valueInCcm / 28316.85;
