import { isNotDefined } from "@ovrsea/ovrutils";
import type { CSSObject } from "../utils/types/css";
import type { SpacingScale } from "../utils/system/system";
import type { Theme } from "./theme";

export const mapFocusRing = (theme: Theme) => ({
  boxShadow: `${theme.colors.focus.ring} 0 0 0 3px`,
});

/** removes outline (that comes from browser user-agent stylesheet),
 * and put a focus ring box-shadow (on focus) */
export const mapPseudoFocusRing = (theme: Theme) => ({
  "&:focus-visible": mapFocusRing(theme),
  outline: "none",
});

type SpacingDirection = "bottom" | "left" | "right" | "top";

const directionPropertyMap = {
  bottom: "marginBottom",
  left: "marginLeft",
  right: "marginRight",
  top: "marginTop",
} as const;

/** generates spacing with the lobotomized-owl selector
 * (see https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/)
 * Prefer the `gap` global prop in flex containers
 * */
export const generateSpacing =
  (theme: Theme) =>
  (spacing: SpacingScale | undefined, direction: SpacingDirection = "left") => {
    if (isNotDefined(spacing)) {
      return undefined;
    }

    return {
      "& > * + *": {
        [directionPropertyMap[direction]]: theme.spacing[spacing],
      },
    };
  };

const commonStyles = {
  hidden: {
    border: 0,
    clip: "rect(0px, 0px, 0px, 0px)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    whiteSpace: "nowrap",
    width: "1px",
  },
  truncated: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
} as const;

export const styles: Record<keyof typeof commonStyles, CSSObject> =
  commonStyles;
