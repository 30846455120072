import React from "react";
import { injectIndex } from "../../../utils/children";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";

const StyledTabList = styled("div", {
  base: ({ theme }) => ({
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderColor: "inherit",
    display: "flex",
    gap: theme.spacing[16],
  }),
});

export const TabList = polymorphic(({ children, ...rest }, ref) => (
  <StyledTabList
    aria-orientation="horizontal"
    ref={ref}
    role="tablist"
    {...rest}
  >
    {injectIndex(children)}
  </StyledTabList>
));

TabList.displayName = "TabList";
