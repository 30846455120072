import type { Theme } from "../../theme/theme";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";

const backgroundColorMap = (theme: Theme) =>
  ({
    danger: {
      background: theme.colors.alert.dangerLight,
      text: theme.colors.alert.danger,
    },
    info: {
      background: theme.colors.alert.infoLight,
      text: theme.colors.alert.info,
    },
    neutral: {
      background: theme.colors.neutral.light,
      text: theme.colors.neutral.dark,
    },
    primary: {
      background: theme.colors.primary.base,
      text: theme.colors.primary.light,
    },
    success: {
      background: theme.colors.alert.successLight,
      text: theme.colors.alert.success,
    },
    warning: {
      background: theme.colors.alert.warningLight,
      text: theme.colors.alert.warning,
    },
    white: {
      background: theme.colors.background.light,
      text: undefined,
    },
  }) as const;

const borderColorMap = (theme: Theme) =>
  ({
    danger: theme.colors.alert.danger,
    info: theme.colors.alert.info,
    neutral: theme.colors.neutral[20],
    none: undefined,
    primary: theme.colors.primary.base,
    success: theme.colors.alert.success,
    warning: theme.colors.alert.warning,
  }) as const;

type ColorType = keyof ReturnType<typeof backgroundColorMap>;
export type BorderColorType = "none" | keyof ReturnType<typeof borderColorMap>;

type Props = {
  borderColor?: BorderColorType;
  color?: ColorType;
  hasRadius?: boolean;
};

export type CardProps = Props & SystemProps<"div">;

const computeBorderWidth = ({
  borderColor,
  color,
}: {
  borderColor: BorderColorType;
  color?: ColorType;
}) => {
  if (borderColor === "none") {
    return 0;
  }

  return color === "white" && borderColor !== "neutral" ? "2px" : "1px";
};

export const Card = styled<"div", Props>("div", {
  base: ({
    color = "white",
    // eslint-disable-next-line perfectionist/sort-objects
    borderColor: borderColorProp = color === "white" ? "neutral" : "none",
    hasRadius = true,
    theme,
  }) => {
    const { background, text: textColor } = backgroundColorMap(theme)[color];
    const borderColor = borderColorMap(theme)[borderColorProp];
    const borderWidth = computeBorderWidth({
      borderColor: borderColorProp,
      color,
    });

    return [
      {
        background,
        borderRadius: hasRadius ? theme.radius.xl : "none",
        boxShadow: borderWidth
          ? `0 0 0 ${borderWidth} ${borderColor}`
          : undefined,
        inset: "1px",
        padding: `${theme.spacing[12]} ${theme.spacing[16]}`,
        position: "relative",
      },
      Boolean(textColor) && {
        // propagate color to links
        "& a": {
          color: textColor,
        },
        color: textColor,
      },
    ];
  },
});

Card.displayName = "Card";
