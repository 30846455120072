import type { MouseEventHandler, SyntheticEvent } from "react";
import { useCallback, useEffect } from "react";
import type { SelectOptionType } from "../Shared/types";

type Params = {
  activeIndex: number;
  filteredOptions: SelectOptionType[];
  handleFocusInput: () => void;
  isDisabled?: boolean;
  isOpened: boolean;
  onChange: (value: string) => void;
  onSearchChange: (value: string) => void;
  scrollToSelectedItem: () => void;
  setActiveIndex: (id: number) => void;
  setClosed: () => void;
  shouldReturnFocusOnSelect: boolean;
  value?: string;
};

export const useSelectInteractions = ({
  activeIndex,
  filteredOptions,
  handleFocusInput,
  isDisabled,
  isOpened,
  onChange,
  onSearchChange,
  scrollToSelectedItem,
  setActiveIndex,
  setClosed,
  shouldReturnFocusOnSelect,
  value,
}: Params) => {
  const handleInputBlur = () => onSearchChange("");

  useEffect(() => {
    if (isOpened) {
      scrollToSelectedItem();
    }
  }, [isOpened]);

  const handleSelect = useCallback(
    (value: string) => {
      onChange(value);
      onSearchChange("");
      setActiveIndex(-1);
      setClosed();

      if (shouldReturnFocusOnSelect) {
        setTimeout(handleFocusInput, 0);
      }
    },
    [value, filteredOptions, shouldReturnFocusOnSelect],
  );

  const handleListClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const activeOption = filteredOptions[activeIndex];

    const isTargetingListbox =
      (event.target as HTMLDivElement).role === "listbox";

    if (isTargetingListbox && activeOption) {
      handleSelect(activeOption.value);
    }
  };

  const handleInputClick = (event: SyntheticEvent) => {
    if (isDisabled) {
      return event.stopPropagation();
    }
    if (!isOpened) {
      setTimeout(handleFocusInput, 0);
    }
  };

  return {
    handleInputBlur,
    handleInputClick,
    handleListClick,
    handleSelect,
  };
};
