import type { DbPricingSteps } from "../../sharedTypes";

export const priceCategoriesFormatted: { [key in DbPricingSteps]: string } = {
  arrival_customs: "Arrival Customs",
  arrival_fees: "Arrival Fees",
  arrival_logistics: "Arrival Logistics",
  arrival_truck_freight: "On-Carriage",
  carbon_offset: "Carbon Offset",
  customs: "Customs",
  departure_customs: "Departure Customs",
  departure_fees: "Departure Fees",
  departure_logistics: "Departure Logistics",
  departure_truck_freight: "Pre-Carriage",
  freight: "Freight",
  insurance: "Insurance",
  other: "Other",
  other_services: "Other services",
};
