export const SHIPOWNER_IDS = {
  CMA_CGM: "1",
  COTRANS: "597",
  DEPAEUW: "2267",
  HINTERLAND: "2012",
  ROAD_TRANS_SHIPOWNER_ID: "2388",
} as const;

export const OVRSEA_LOGISTIC_AGENT_ID = "f6f1b7d2-707b-4a6c-85f3-d22728b09386";
export const isOvrseaLogisticAgent = ({ id }: { id?: null | string }) =>
  id === OVRSEA_LOGISTIC_AGENT_ID;
