type UsStatesListType = {
  abbreviation: string;
  name: string;
};

const usStatesList: UsStatesListType[] = [
  {
    abbreviation: "al",
    name: "Alabama",
  },
  {
    abbreviation: "ak",
    name: "Alaska",
  },
  {
    abbreviation: "as",
    name: "American Samoa",
  },
  {
    abbreviation: "az",
    name: "Arizona",
  },
  {
    abbreviation: "ar",
    name: "Arkansas",
  },
  {
    abbreviation: "ca",
    name: "California",
  },
  {
    abbreviation: "co",
    name: "Colorado",
  },
  {
    abbreviation: "ct",
    name: "Connecticut",
  },
  {
    abbreviation: "de",
    name: "Delaware",
  },
  {
    abbreviation: "dc",
    name: "District Of Columbia",
  },
  {
    abbreviation: "fm",
    name: "Federated States Of Micronesia",
  },
  {
    abbreviation: "fl",
    name: "Florida",
  },
  {
    abbreviation: "ga",
    name: "Georgia",
  },
  {
    abbreviation: "gu",
    name: "Guam",
  },
  {
    abbreviation: "hi",
    name: "Hawaii",
  },
  {
    abbreviation: "id",
    name: "Idaho",
  },
  {
    abbreviation: "il",
    name: "Illinois",
  },
  {
    abbreviation: "in",
    name: "Indiana",
  },
  {
    abbreviation: "ia",
    name: "Iowa",
  },
  {
    abbreviation: "ks",
    name: "Kansas",
  },
  {
    abbreviation: "ky",
    name: "Kentucky",
  },
  {
    abbreviation: "la",
    name: "Louisiana",
  },
  {
    abbreviation: "me",
    name: "Maine",
  },
  {
    abbreviation: "mh",
    name: "Marshall Islands",
  },
  {
    abbreviation: "md",
    name: "Maryland",
  },
  {
    abbreviation: "ma",
    name: "Massachusetts",
  },
  {
    abbreviation: "mi",
    name: "Michigan",
  },
  {
    abbreviation: "mn",
    name: "Minnesota",
  },
  {
    abbreviation: "ms",
    name: "Mississippi",
  },
  {
    abbreviation: "mo",
    name: "Missouri",
  },
  {
    abbreviation: "mt",
    name: "Montana",
  },
  {
    abbreviation: "ne",
    name: "Nebraska",
  },
  {
    abbreviation: "nv",
    name: "Nevada",
  },
  {
    abbreviation: "nh",
    name: "New Hampshire",
  },
  {
    abbreviation: "nj",
    name: "New Jersey",
  },
  {
    abbreviation: "nm",
    name: "New Mexico",
  },
  {
    abbreviation: "ny",
    name: "New York",
  },
  {
    abbreviation: "nc",
    name: "North Carolina",
  },
  {
    abbreviation: "nd",
    name: "North Dakota",
  },
  {
    abbreviation: "mp",
    name: "Northern Mariana Islands",
  },
  {
    abbreviation: "oh",
    name: "Ohio",
  },
  {
    abbreviation: "ok",
    name: "Oklahoma",
  },
  {
    abbreviation: "or",
    name: "Oregon",
  },
  {
    abbreviation: "pw",
    name: "Palau",
  },
  {
    abbreviation: "pa",
    name: "Pennsylvania",
  },
  {
    abbreviation: "pr",
    name: "Puerto Rico",
  },
  {
    abbreviation: "ri",
    name: "Rhode Island",
  },
  {
    abbreviation: "sc",
    name: "South Carolina",
  },
  {
    abbreviation: "sd",
    name: "South Dakota",
  },
  {
    abbreviation: "tn",
    name: "Tennessee",
  },
  {
    abbreviation: "tx",
    name: "Texas",
  },
  {
    abbreviation: "ut",
    name: "Utah",
  },
  {
    abbreviation: "vt",
    name: "Vermont",
  },
  {
    abbreviation: "vi",
    name: "Virgin Islands",
  },
  {
    abbreviation: "va",
    name: "Virginia",
  },
  {
    abbreviation: "wa",
    name: "Washington",
  },
  {
    abbreviation: "wv",
    name: "West Virginia",
  },
  {
    abbreviation: "wi",
    name: "Wisconsin",
  },
  {
    abbreviation: "wy",
    name: "Wyoming",
  },
];

export default usStatesList;
