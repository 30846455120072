import type { ErrorInfo, PropsWithChildren, ReactNode } from "react";
import { Component } from "react";

type State = {
  error?: Error;
  isErrored: boolean;
};

export type SilentErrorBoundaryProps = PropsWithChildren<{
  fallback?: (error?: Error) => ReactNode;
  onError?: (error: Error, info: ErrorInfo) => void;
}>;

export class SilentErrorBoundary extends Component<
  SilentErrorBoundaryProps,
  State
> {
  state = { error: undefined, isErrored: false };

  static getDerivedStateFromError(error: Error) {
    return { error, isErrored: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.onError?.(error, info);
  }

  render() {
    if (this.state.isErrored) {
      return this.props.fallback?.(this.state.error) ?? null;
    }

    return this.props.children;
  }
}
