import React from "react";
import { css as emotionCss, Global as EmotionGlobal } from "@emotion/react";
import type { Theme } from "../theme";
import { useOvrseaTheme } from "../useTheme";
import { callIfFunction } from "../../utils/objects";
import { reset } from "./CSSReset";

export type EmotionStyles = Parameters<typeof emotionCss>[number];

type GlobalStylesProps = {
  styles: ((theme: Theme) => EmotionStyles) | EmotionStyles;
};

const Global = ({ styles }: GlobalStylesProps) => {
  const theme = useOvrseaTheme();

  return <EmotionGlobal styles={emotionCss(callIfFunction(styles)(theme))} />;
};

const CSSVariables = () => (
  <Global styles={(theme: any) => ({ [":root"]: theme.__cssVariables })} />
);

const CSSReset = () => <Global styles={reset} />;

/** Injection of theme css variables and applying a css reset.
 * Should only be used once to prevent re-declaring css variables and to avoid performance issues */
export const Preflight = () => (
  <>
    <CSSVariables />
    <CSSReset />
  </>
);
