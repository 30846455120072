import type { ReactNode } from "react";
import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { styled } from "../../utils/system/factory";
import { polymorphic } from "../../utils/ref";
import { Body } from "../Typography/Body";
import type { SystemProps } from "../../utils/types/system";
import { useFormFieldContext } from "./FormField/FormFieldProvider";

type Props = {
  isChecked?: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  onChange: (isChecked: boolean) => void;
};

export type ToggleProps = Props & SystemProps<"button">;

const StyledContainer = styled<"button", Pick<Props, "isChecked">>("button", {
  base: () => ({
    alignItems: "center",
    background: "none",
    display: "inline-flex",
  }),
});

const StyledTrack = styled<"div", Pick<Props, "isChecked" | "isDisabled">>(
  "div",
  {
    base: ({ isChecked, isDisabled, theme }) => [
      {
        alignItems: "center",
        background: isChecked
          ? theme.colors.primary.base
          : theme.colors.neutral["50"],
        borderRadius: theme.radius.round,
        display: "inline-flex",
        height: theme.spacing["24"],
        opacity: isDisabled ? "0.3" : "1",
        padding: 2,
        transition: "box-shadow, background, transform",
        transitionDuration: theme.transition.duration.slow,
        transitionTimingFunction: theme.transition.easing.translateX,
        width: theme.spacing["48"],
      },
      !isDisabled && {
        "&:hover, &:focus-visible": {
          boxShadow: `0 0 0 2px ${theme.colors.primary.light}`,
          outline: "none",
        },
      },
    ],
  },
);

const StyledThumb = styled<"span", Pick<Props, "isChecked">>("span", {
  base: ({ isChecked, theme }) => ({
    background: theme.colors.background.light,
    borderRadius: theme.radius.round,
    height: 20,
    transform: isChecked ? "translateX(24px)" : "translateX(0px)",
    transition: "transform",
    transitionDuration: theme.transition.duration.slow,
    transitionTimingFunction: theme.transition.easing.translateX,
    width: 20,
  }),
});

export const Toggle = polymorphic<"button", Props>(
  (
    { isChecked, isDisabled: isDisabledProp, label, onChange, ...rest },
    ref,
  ) => {
    const context = useFormFieldContext();

    const isDisabled = isDisabledProp || context?.isDisabled;

    return (
      <StyledContainer
        aria-checked={isChecked}
        checked={isChecked}
        disabled={isDisabled}
        onClick={() => onChange(!isChecked)}
        ref={ref}
        role="switch"
        tabIndex={0}
        type="button"
        {...rest}
      >
        <StyledTrack
          checked={isChecked}
          isChecked={isChecked}
          isDisabled={isDisabled}
        >
          <StyledThumb aria-hidden="true" isChecked={isChecked} />
        </StyledTrack>
        {isDefined(label) && <Body marginLeft={8}>{label}</Body>}
      </StyledContainer>
    );
  },
);
