export const isDefined = <Type>(
  arg: Type,
): arg is Exclude<Type, null | undefined> => arg !== null && arg !== undefined;

export type PartiallyRequired<T, K extends keyof T> = {
  [k in K]-?: Exclude<T[k], null | undefined>;
} & {
  [k in keyof T]: Exclude<T[k], null | undefined>;
};

export const filterKeyDefined = <
  ObjectType extends object,
  Key extends keyof ObjectType,
>(
  list: ObjectType[],
  key: Key,
): PartiallyRequired<ObjectType, Key>[] =>
  //@ts-expect-error
  list.filter((element) => isDefined(element[key]));
