import { useRef } from "react";

const useImageLoad = () => {
  const imageRef = useRef<HTMLImageElement>(null);

  const onImageLoad = () => imageRef.current?.classList.add("loaded");

  return [imageRef, onImageLoad] as const;
};

export { useImageLoad };
