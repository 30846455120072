import { isDefined } from "@ovrsea/ovrutils";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { styles } from "../../theme/commons";
import type { CSSObject } from "../../utils/types/css";
import type { Theme } from "../../theme/theme";

export type BodySize = keyof Theme["body"];

const weights = (theme: Theme) => ({
  bold: theme.font.weight.bold,
  medium: theme.font.weight.medium,
  regular: theme.font.weight.base,
});

type BodyVariant = keyof ReturnType<typeof weights>;

type Props = {
  isInline?: boolean;
  isSecondary?: boolean;
  isTruncated?: boolean;
  linesCount?: number;
  size?: BodySize;
  variant?: BodyVariant;
};

export type BodyProps = Props & SystemProps<"p">;

const truncateLines = (linesCount: number): CSSObject => ({
  "@supports ((-webkit-box-orient: vertical) and (-webkit-line-clamp: 1))": {
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: linesCount,
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export const Body = styled<"p", Props>("p", {
  base: ({
    isInline,
    isSecondary,
    isTruncated,
    linesCount,
    size = "md",
    theme,
    variant,
  }) => {
    const { fontSize, fontWeight, lineHeight } = theme.body[size];
    const fontWeightProp = variant ? weights(theme)[variant] : "";

    return [
      {
        fontSize,
        fontWeight: fontWeightProp ?? fontWeight,
        lineHeight,
      },
      isInline && {
        display: "inline",
      },
      isSecondary && {
        color: theme.colors.text.secondary,
      },
      isTruncated && styles.truncated,
      isDefined(linesCount) && linesCount > 0 && truncateLines(linesCount),
    ];
  },
});

Body.displayName = "Body";
