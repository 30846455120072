import type { ReactNode } from "react";
import React from "react";
import { styled } from "../../utils/system/factory";
import type { StackProps } from "../Layout/Stack";
import { HStack } from "../Layout/Stack";
import { ariaAttribute, dataAttribute } from "../../utils/attributes";
import type { IconName, IconProps } from "../Typography/Icon";
import { Icon } from "../Typography/Icon";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { useFormFieldContext } from "./FormField/FormFieldProvider";
import { RadioCircle } from "./RadioGroup/RadioCircle";

type Props = {
  icon?: IconName;
  isChecked?: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  onChange: (isChecked: boolean) => void;
};

export type RadioLabelProps = Props & SystemProps<"button">;

const StyledLabel = styled("span", {
  base: ({ theme }) => theme.body.md,
});

const StyledIcon = styled<typeof Icon, IconProps>(Icon, {
  base: ({ theme }) => ({
    color: theme.colors.neutral["40"],
    marginRight: theme.spacing["4"],
  }),
});

const StyledContainer = styled<typeof HStack, StackProps>(HStack, {
  base: {
    "&[data-disabled]": {
      pointerEvents: "none",
    },
    appearance: "none",
    background: "unset",
  },
});

export const RadioLabel = polymorphic<"div", Props>(
  (
    {
      as = "button",
      icon,
      isChecked,
      isDisabled: isDisabledProp,
      label,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const context = useFormFieldContext();

    const isDisabled = isDisabledProp || context?.isDisabled;

    return (
      <StyledContainer
        alignItems="center"
        as={as}
        flexWrap="wrap"
        ref={ref}
        type="button"
        {...rest}
        disabled={isDisabled}
        onClick={() => onChange(!isChecked)}
      >
        <RadioCircle
          aria-checked={ariaAttribute(isChecked)}
          data-disabled={dataAttribute(isDisabled)}
          marginLeft={0}
        />
        <StyledLabel marginLeft={8}>
          {icon && <StyledIcon name={icon} />}
          {label}
        </StyledLabel>
      </StyledContainer>
    );
  },
);
