const clientIdConstants = {
  ABYSSE_CORP: "2620",
  AIRIS_FRANCE: "2912",
  AIRNOV_FRANCE: "2496",
  ARIZONA_APPAREL: "5348",
  BABYMOOV: "2149",
  BAKERLY: "4507",
  BIOLOGIQUE_RECHERCHE_USA: "374",
  BOLLORE_TW: "2707",
  BOTANIC: "3035",
  DELSEY: "2642",
  DEVIALET_USA: "603",
  DIXNEUF_ATELIERS: "2865",
  EOVOLT: "1856",
  GONG_GALAXY: "2752",
  GROUPE_HAMELIN: "3312",
  IZIPIZI: "328",
  KSGB_EUROPE: "902",
  LBD: "4243",
  LBD_HOLDING: "2187",
  LBD_MAISON: "4244",
  LEXIBOOK_SA: "129",
  LIGHTSCIENTISTS: "2685",
  LITED: "2928",
  "LOG'S": "928",
  MEDIPROTEC: "2899",
  MOBIWIRE: "2905",
  O2FEEL_BIKES: "651",
  PASQUIER: "5698",
  RAMONDIN: "3300",
  RAZOR_GROUP: "2486",
  RHYTHM_HEALTHCARE: "5086",
  SEQENS_NOVACAP: "1356",
  SEZANE: "2687",
  TAHE_OUTDOORS: "2844",
  THE_KOOPLES: "2523",
  UPERGY: "2919",
  ZADIG_VOLTAIRE: "2673",
};

const mustValidateBookingRequestManually = (clientId: string): boolean => {
  return clientId === clientIdConstants.KSGB_EUROPE;
};

const clientIdsInterestedInCo2Reduction = [
  "1104",
  "1116",
  "1152",
  "1161",
  "1173",
  "1222",
  "1278",
  "1291",
  "1348",
  "1403",
  "153",
  "1682",
  "173",
  "183",
  "1856",
  "1882",
  "1908",
  "1982",
  "2142",
  "2149",
  "2202",
  "2230",
  "2433",
  "2494",
  "2496",
  "2500",
  "2620",
  "2687",
  "2739",
  "2752",
  "2861",
  "2862",
  "2907",
  "2952",
  "2962",
  "3023",
  "328",
  "3498",
  "352",
  "365",
  "4044",
  "4078",
  "4172",
  "4175",
  "4315",
  "4393",
  "4537",
  "4691",
  "501",
  "5441",
  "591",
  "901",
  "902",
  "993",
];

const isClientInterestedInCo2Reduction = (clientId: string): boolean => {
  return clientIdsInterestedInCo2Reduction.includes(clientId);
};

export {
  clientIdConstants,
  isClientInterestedInCo2Reduction,
  mustValidateBookingRequestManually,
};
