import { isDefined } from "../../other/isDefined";

export type ShipownerWithParentGroup = {
  id: string;
  parentShipownerGroup: { id: string } | null;
};

const shipownersHaveSameParent = (
  shipowner1: ShipownerWithParentGroup,
  shipowner2: ShipownerWithParentGroup,
): boolean =>
  isDefined(shipowner1.parentShipownerGroup?.id) &&
  shipowner1.parentShipownerGroup?.id === shipowner2.parentShipownerGroup?.id;

export const shipownersBelongToTheSameFamily = (
  shipowner1: ShipownerWithParentGroup,
  shipowner2: ShipownerWithParentGroup,
): boolean =>
  shipowner1.id === shipowner2.id ||
  shipowner1.id === shipowner2.parentShipownerGroup?.id ||
  shipowner2.id === shipowner1.parentShipownerGroup?.id ||
  shipownersHaveSameParent(shipowner1, shipowner2);
