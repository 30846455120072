import { useCallback, useRef } from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { useBooleanState } from "./useBooleanState";
import { useUnmountEffect } from "./useForceUpdate";

type UseDisclosureParams = {
  closeDelay?: number;
  isDefaultOpened?: boolean;
  isDisabled?: boolean;
  isOpened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  openDelay?: number;
};

const useDisclosure = ({
  closeDelay = 0,
  isDefaultOpened,
  isDisabled,
  isOpened: isOpenedProp,
  onClose: onCloseProp,
  onOpen: onOpenProp,
  openDelay = 0,
}: UseDisclosureParams) => {
  const [isOpened, setOpened, setClosed] = useBooleanState(isDefaultOpened);

  const onOpen = useCallback(() => {
    if (isDisabled) {
      return;
    }
    if (!onOpenProp) {
      setOpened();
    }
    onOpenProp?.();
  }, [onOpenProp, isDisabled]);

  const onClose = useCallback(() => {
    if (!onCloseProp) {
      setClosed();
    }
    onCloseProp?.();
  }, [onCloseProp]);

  const openTimeoutRef = useRef<number>();
  const closeTimeoutRef = useRef<number>();

  const setDelayedOpen = useCallback(() => {
    openTimeoutRef.current = window.setTimeout(onOpen, openDelay);
  }, [onOpen, openDelay]);

  const setDelayedClose = useCallback(() => {
    if (isDefined(openTimeoutRef.current)) {
      window.clearTimeout(openTimeoutRef.current);
    }
    closeTimeoutRef.current = window.setTimeout(onClose, closeDelay);
  }, [onClose, closeDelay]);

  useUnmountEffect(() => {
    window.clearTimeout(openTimeoutRef.current);
    window.clearTimeout(closeTimeoutRef.current);
  });

  return {
    isOpened: isOpenedProp ?? isOpened,
    setClosed: setDelayedClose,
    setOpened: setDelayedOpen,
  };
};

export { useDisclosure };
