import type { ReactNode } from "react";
import React from "react";
import { Flex } from "../Layout/Flex";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import type { Theme } from "../../theme/theme";
import { VStack } from "../Layout/Stack";
import {
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "./Alert/AlertComponents";

const alertVariations = (theme: Theme) =>
  ({
    error: {
      background: theme.colors.alert.dangerLight,
      color: theme.colors.alert.danger,
    },
    info: {
      background: theme.colors.alert.infoLight,
      color: theme.colors.alert.info,
    },
    success: {
      background: theme.colors.alert.successLight,
      color: theme.colors.alert.success,
    },
    warning: {
      background: theme.colors.alert.warningLight,
      color: theme.colors.alert.warning,
    },
  }) as const;

export type AlertType = keyof ReturnType<typeof alertVariations>;

type Props = {
  description: ReactNode;
  title: ReactNode;
  type: AlertType;
};

export type AlertProps = Props & SystemProps<"div">;

const StyledAlert = styled<typeof Flex, { alertType: AlertType }>(Flex, {
  base: ({ alertType, theme }) => {
    const { background, color } = alertVariations(theme)[alertType];

    return {
      background,
      borderRadius: theme.radius.xl,
      color,
      textAlign: "left",
    };
  },
});

export const Alert = polymorphic<"div", Props>(
  ({ description, title, type = "info", ...rest }, ref) => (
    <StyledAlert padding={16} ref={ref} {...rest} alertType={type} role="alert">
      <AlertIcon type={type} />
      <VStack flex="1">
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </VStack>
    </StyledAlert>
  ),
);

Alert.displayName = "Alert";
