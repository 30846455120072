import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import type { Theme } from "../../theme/theme";
import { AvatarContent } from "./Avatar/AvatarContent";
import { AvatarGroup, useAvatarGroupContext } from "./Avatar/AvatarGroup";
import type { AvatarSize } from "./Avatar/sizes";
import { avatarSizes } from "./Avatar/sizes";

type _AvatarProps = {
  name?: null | string;
  size?: AvatarSize;
  src?: null | string;
};

export type AvatarProps = _AvatarProps & SystemProps<"div">;

const fontSizeMap = ({ body, caption }: Theme) => ({
  lg: body.lg,
  md: body.sm,
  sm: caption.xs,
});

const StyledAvatar = styled<"div", _AvatarProps>("div", {
  base: ({ size = "md", theme }) => {
    const dimensions = avatarSizes[size];
    const borderColor = theme.colors.neutral["20"];
    const { fontSize, fontWeight, lineHeight } = fontSizeMap(theme)[size];

    return {
      alignItems: "center",
      background: theme.colors.background.light,
      borderColor,
      borderRadius: theme.radius.round,
      borderStyle: "solid",
      borderWidth: "1px",
      display: "inline-flex",
      fontSize,
      fontWeight,
      height: `${dimensions}px`,
      justifyContent: "center",
      lineHeight,
      minHeight: `${dimensions}px`,
      minWidth: `${dimensions}px`,
      overflow: "hidden",
      textTransform: "uppercase",
      width: `${dimensions}px`,
    };
  },
});

type Compounds = {
  Group: typeof AvatarGroup;
};

export const Avatar = polymorphic<"div", _AvatarProps, Compounds>(
  ({ children, name, size: sizeProp, src, ...props }, ref) => {
    const context = useAvatarGroupContext();
    const size = sizeProp ?? context?.size;

    return (
      <StyledAvatar
        className="avatar"
        ref={ref}
        size={size}
        src={src}
        {...props}
      >
        <AvatarContent
          name={name}
          offset={size === "lg" ? "2px" : "1px"}
          src={src}
        />
        {children}
      </StyledAvatar>
    );
  },
);

Avatar.displayName = "Avatar";
Avatar.Group = AvatarGroup;
