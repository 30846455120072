/* eslint-disable perfectionist/sort-objects */
import React from "react";
import { polymorphic } from "../../utils/ref";
import { contextFactory } from "../../utils/contextFactory";
import type { SystemProps } from "../../utils/types/system";
import type { SpacingScale } from "../../utils/system/system";
import type { FlexProps } from "../Layout/Flex";
import { Flex } from "../Layout/Flex";
import type { ButtonColor, ButtonSize, ButtonType } from "./Button";

type Props = {
  color?: ButtonColor;
  isDisabled?: boolean;
  isLoading?: boolean;
  size?: ButtonSize;
  spacing?: SpacingScale;
  type?: ButtonType;
} & FlexProps;

export type ButtonGroupProps = Props & SystemProps<"div">;

type ButtonGroupContext = {
  color?: ButtonColor;
  isDisabled?: boolean;
  isLoading?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
};

const [ButtonGroupProvider, useButtonGroupContext] =
  contextFactory<ButtonGroupContext>({
    displayName: "ButtonGroupProvider",
    isOptional: true,
  });

const spacingMap = {
  xs: 8,
  sm: 8,
  md: 12,
} as const;

export const ButtonGroup = polymorphic<typeof Flex, Props>(
  (
    {
      children,
      isDisabled,
      isLoading,
      size,
      spacing = spacingMap[size ?? "md"],
      type,
      color,
      ...rest
    },
    ref,
  ) => (
    <ButtonGroupProvider
      value={{
        isDisabled,
        isLoading,
        size,
        type,
        color,
      }}
    >
      <Flex flexWrap="wrap" gap={spacing} ref={ref} role="group" {...rest}>
        {children}
      </Flex>
    </ButtonGroupProvider>
  ),
);

ButtonGroup.displayName = "ButtonGroup";

export { useButtonGroupContext };
