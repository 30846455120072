import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import type { Theme } from "../../theme/theme";
import type { IconName } from "../Typography/Icon";
import { Icon } from "../Typography/Icon";
import { keyframes } from "../../utils/system";
import { MarkerBadge } from "./Marker/MarkerBadge";

export type MarkerStatus = "current" | "done" | "error" | "pending";

type Props = {
  icon: IconName;
  primary?: boolean;
  status?: MarkerStatus;
};

export type MarkerProps = Props & SystemProps<"div">;

const statusToColorMap = (theme: Theme) =>
  ({
    current: {
      backgroundColor: theme.colors.alert.successLight,
      color: theme.colors.alert.success,
    },
    done: {
      backgroundColor: theme.colors.alert.successLight,
      color: theme.colors.alert.success,
    },
    error: {
      backgroundColor: theme.colors.alert.dangerLight,
      color: theme.colors.alert.danger,
    },
    pending: {
      backgroundColor: "white",
      color: theme.colors.neutral[40],
    },
  }) as const;

const currentAnimation = (shadowColor: string) =>
  keyframes({
    "0%": {
      boxShadow: `0 0 0 0 ${shadowColor}`,
    },
    "70%": {
      boxShadow: "0 0 0 10px transparent",
    },
    "100%": {
      boxShadow: "0 0 0 0 transparent",
    },
  });

const currentShadowAnimation = (shadowColor: string) => ({
  animation: `${currentAnimation(shadowColor)} 2s infinite`,
});

const StyledMarkerContainer = styled<
  "div",
  { primary?: boolean; status: MarkerStatus }
>("div", {
  base: ({ primary, status, theme }) => {
    const { backgroundColor, color } = statusToColorMap(theme)[status];

    return [
      {
        alignItems: "center",
        backgroundColor,
        border: `2px solid ${color}`,
        borderRadius: theme.radius.round,
        display: "flex",
        justifyContent: "center",
        minHeight: theme.spacing[32],
        minWidth: theme.spacing[32],
        position: "relative",
      },
      status === "current" && currentShadowAnimation(backgroundColor),
      primary && {
        backgroundColor: theme.colors.primary.light,
        border: `2px solid ${theme.colors.primary.base}`,
      },
    ];
  },
});

const StyledIcon = styled<
  typeof Icon,
  { primary?: boolean; status: MarkerStatus }
>(Icon, {
  base: ({ primary, status, theme }) => {
    const { color } = statusToColorMap(theme)[status];

    return {
      color: primary ? theme.colors.primary.base : color,
      height: theme.spacing[16],
      width: theme.spacing[16],
    };
  },
});

type Compounds = {
  Badge: typeof MarkerBadge;
};

export const Marker = polymorphic<"div", MarkerProps, Compounds>(
  ({ children, icon, primary, status = "pending", ...rest }, ref) => (
    <StyledMarkerContainer
      primary={primary}
      ref={ref}
      status={status}
      {...rest}
    >
      <StyledIcon name={icon} primary={primary} status={status} />
      {children}
    </StyledMarkerContainer>
  ),
);

Marker.displayName = "Marker";
Marker.Badge = MarkerBadge;
