/**
 * Returns:
 * - `0` if a or b falsy
 * - `1` if a >= b,
 * - `-1` otherwise
 */
const strcmp = (a?: null | string, b?: null | string) => {
  if (!a || !b) {
    return 0;
  }

  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export default strcmp;
