import type { PropsWithChildren, ReactNode, SyntheticEvent } from "react";
import React, { memo } from "react";
import { EmptyStateDescription } from "../../Layout/State/FetchStateComponents";
import { useDependenciesContext } from "../../../theme/dependencies";
import { styled } from "../../../utils/system/factory";
import { Body } from "../../Typography/Body";
import { DEFAULT_GROUP } from "../Shared/utils";
import { polymorphic } from "../../../utils/ref";
import { ariaAttribute, dataAttribute } from "../../../utils/attributes";
import type { Sx } from "../../../utils/types/system";
import { mapPseudoFocusRing } from "../../../theme/commons";
import type { IconName } from "../../Typography/Icon";
import { Icon } from "../../Typography/Icon";
import { Tooltip } from "../../Overlay/Tooltip";
import type { SetItemRef } from "../../../utils/hooks/useRefList";
import type { SelectOptionType } from "./types";

const EmptyState = ({ children }: PropsWithChildren<object>) => (
  <EmptyStateDescription marginY={16} sx={{ maxWidth: "none" }}>
    {children}
  </EmptyStateDescription>
);

type SelectEmptyStatesProps = {
  canCreate?: boolean;
  initialOptionCount: number;
  noOptionsMessage?: ReactNode;
  nothingFoundMessage?: ReactNode;
  optionCount: number;
};

const SelectEmptyStates = ({
  canCreate,
  initialOptionCount,
  noOptionsMessage,
  nothingFoundMessage,
  optionCount,
}: SelectEmptyStatesProps) => {
  const { messages } = useDependenciesContext();

  const isEmpty = !canCreate && !initialOptionCount;
  const hasNoResults =
    !canCreate && initialOptionCount > 0 && optionCount === 0;

  const noOptions = noOptionsMessage ?? messages.select.empty;
  const nothingFound = nothingFoundMessage ?? messages.select.nothingFound;

  return (
    <>
      {isEmpty && <EmptyState>{noOptions}</EmptyState>}
      {hasNoResults && <EmptyState>{nothingFound}</EmptyState>}
    </>
  );
};

const StyledGroup = styled<typeof Body>(Body, {
  base: ({ theme }) => ({
    alignItems: "center",
    background: theme.colors.neutral.light,
    borderBottom: `1px solid ${theme.colors.neutral["10"]}`,
    borderTop: `1px solid ${theme.colors.neutral["10"]}`,
    color: theme.colors.neutral["50"],
    display: "flex",
    height: theme.spacing[32],
    marginTop: -1,
  }),
});

type SelectGroupLabelProps = {
  group: string;
};

const SelectGroupLabel = ({ group }: SelectGroupLabelProps) => {
  if (group === DEFAULT_GROUP) {
    return null;
  }

  return (
    <StyledGroup paddingX={8} size="sm">
      {group}
    </StyledGroup>
  );
};

const StyledCreateOption = styled("div", {
  base: ({ theme }) => ({
    "&:hover": {
      background: theme.colors.neutral["10"],
    },
  }),
});

type CreateOptionProps = {
  isActive: boolean;
  label: ReactNode;
};

const SelectCreateOption = polymorphic<"div", CreateOptionProps>(
  ({ children, isActive, label: labelProp, ...rest }, ref) => {
    const { messages } = useDependenciesContext();
    const label = labelProp ?? messages.select.create;

    return (
      <StyledCreateOption
        margin={4}
        ref={ref}
        role="option"
        {...rest}
        data-active={dataAttribute(isActive)}
      >
        {"+ "}
        {label} {children}
      </StyledCreateOption>
    );
  },
);

SelectCreateOption.displayName = "CreateOption";

const iconSx: Sx = (theme) => ({
  background: "none",
  color: theme.colors.neutral["40"],
});

const focusIconSx: Sx = (theme) => mapPseudoFocusRing(theme);

const buttonSx: Sx = (theme) => ({
  "&:disabled": {
    cursor: "not-allowed",
  },
  background: "none",
  borderRadius: theme.radius.round,
  display: "flex",
  outline: "none",
});

const SelectIconButton = polymorphic<"button", { name: IconName; sx?: Sx }>(
  ({ name, sx, ...rest }, ref) => (
    <styled.button sx={[buttonSx, sx]} type="button" {...rest} ref={ref}>
      <Icon name={name} sx={iconSx} />
    </styled.button>
  ),
);

type SelectIconProps = {
  hasValue: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  onClear: (event: SyntheticEvent) => void;
  onOpen: () => void;
};

const SelectIcon = ({
  hasValue,
  isClearable,
  isDisabled,
  onClear,
  onOpen,
}: SelectIconProps) => {
  if (isDisabled || !isClearable || !hasValue) {
    return (
      <SelectIconButton
        disabled={isDisabled}
        name="arrow-drop-down"
        onClick={onOpen}
        tabIndex={-1}
      />
    );
  }

  if (isClearable) {
    return (
      <Tooltip label="Clear">
        <SelectIconButton
          data-testid="select-clear"
          disabled={isDisabled}
          name="cross-circle"
          onPointerDown={(event) => {
            event.stopPropagation();
            onClear(event);
          }}
          sx={focusIconSx}
        />
      </Tooltip>
    );
  }

  return null;
};

const SelectAction = styled("div", {
  base: ({ theme }) => ({
    background: theme.colors.background.light,
    borderTop: `1px solid ${theme.colors.neutral["10"]}`,
    bottom: 0,
    padding: `${theme.spacing[8]} ${theme.spacing[8]}`,
    width: "100%",
  }),
});

type OptionProps = {
  handleSelect: (value: string) => void;
  isActive: boolean;
  isSelected?: boolean;
  optionValue: string;
  selectedIndex: number;
  setActiveIndex: (index: number) => void;
  setItemRef: SetItemRef<HTMLDivElement>;
} & Partial<SelectOptionType>;

const SelectOption = memo(
  ({
    description,
    handleSelect,
    isActive,
    isSelected,
    label,
    optionValue,
    selectedIndex,
    setActiveIndex,
    setItemRef,
    ...rest
  }: OptionProps) => {
    const onMouseDown = (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();
      handleSelect(optionValue);
    };

    const onMouseEnter = () => setActiveIndex(selectedIndex);

    return (
      <div
        {...rest}
        aria-selected={ariaAttribute(isSelected)}
        data-active={dataAttribute(isActive)}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        ref={setItemRef(optionValue)}
        role="option"
      >
        <div>
          {label}
          <div className="description">{description}</div>
        </div>
        {isSelected && <Icon name="check" />}
      </div>
    );
  },
);

SelectOption.displayName = "SelectOption";

export {
  SelectAction,
  SelectCreateOption,
  SelectEmptyStates,
  SelectGroupLabel,
  SelectIcon,
  SelectOption,
};
