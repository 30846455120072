import type { SyntheticEvent } from "react";
import { useState } from "react";
import { isDefined, normalizedSearch } from "@ovrsea/ovrutils";
import { compact } from "lodash-es";
import type { SelectOptionType } from "../Shared/types";

type Params = {
  handleFocusInput: () => void;
  isClearable?: boolean;
  onChange: (value: string[]) => void;
  options: SelectOptionType[];
  setClosed: () => void;
  value?: string[];
};

export const useMultiSelectFilter = ({
  handleFocusInput,
  isClearable,
  onChange,
  options,
  setClosed,
  value = [],
}: Params) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredOptions = options
    .filter((option) => isDefined(value) && !value.includes(option.value))
    .filter((option) => normalizedSearch(option.label, searchQuery));

  const selectedOptions = compact(
    value.map((singleValue) =>
      options.find((option) => option.value === singleValue),
    ),
  );

  const handleResetFilter = (value: string[]) => {
    const filtered = options.filter((option) => !value.includes(option.value));

    if (!filtered.length) {
      setClosed();
    }
  };

  const handleRemoveOption = (valueToRemove: string) => {
    const nextValue = value.filter((value) => value !== valueToRemove);

    onChange(nextValue);
  };

  const handleReset = (event: SyntheticEvent) => {
    event.stopPropagation();

    if (isClearable) {
      handleResetFilter(value);
      setSearchQuery("");

      onChange([]);
      setTimeout(handleFocusInput, 0);
      setClosed();
    }
  };

  return {
    filteredOptions,
    handleRemoveOption,
    handleReset,
    handleResetFilter,
    onSearchChange: setSearchQuery,
    searchQuery,
    selectedOptions,
    setSearchQuery,
  };
};
