export const officeIdsWithoutWorld = [
  "paris_fr",
  "nyc_usa",
  "barca_es",
  "milan_it",
] as const;
export const officeIds = [...officeIdsWithoutWorld, "master_world"] as const;
export type OfficeIdWithoutWorld = (typeof officeIdsWithoutWorld)[number];
export type OfficeId = (typeof officeIds)[number];
export type Country = "be" | "de" | "es" | "fr" | "it" | "nl" | "us";
export type DefaultOpsOwner = {
  email: string;
  id: string;
};
export type OvrseaOffice = {
  address: null | string;
  countryCode: null | string;
  defaultOpsOwner: DefaultOpsOwner | null;
  defaultPaymentDelayInDays: number;
  entityDescription: string;
  id: OfficeId;
  legalDescription: string;
  legalName: null | string;
  name: string;
  responsibilityArea: Country[];
  termsAndConditionsOfSaleLink: string;
  timezone: string;
};
