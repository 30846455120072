import React from "react";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { useTabs } from "./useTabs";

type Props = {
  dataIndex?: number;
  isSelected?: boolean;
};

const StyledTabPanel = styled("div", {
  base: {
    outline: "transparent solid 2px",
    outlineOffset: 2,
    padding: "1em",
  },
});

export const TabPanel = polymorphic<"div", Props>(
  ({ children, dataIndex, isSelected, ...rest }, ref) => {
    const { activeIndex, generateTabId, generateTabPanelId, isLazy } =
      useTabs();

    const selected = isSelected || activeIndex === dataIndex;

    if (dataIndex === undefined) {
      return null;
    }

    if (!selected && isLazy) {
      return null;
    }

    return (
      <StyledTabPanel
        aria-labelledby={generateTabId(dataIndex)}
        hidden={!selected}
        id={generateTabPanelId(dataIndex)}
        ref={ref}
        role="tabpanel"
        tabIndex={0}
        {...rest}
      >
        {children}
      </StyledTabPanel>
    );
  },
);

TabPanel.displayName = "TabPanel";
