import { last, sortBy } from "lodash-es";
import type { PropositionStatus } from "../sharedTypes";

export const extractLastPurchaseReadyDate = (
  propositions: {
    purchaseReadyDate: null | number;
    status: PropositionStatus;
  }[],
) => {
  const purchaseReadyPropositions = propositions.filter(
    (proposition) => proposition.status === "purchase_ready",
  );

  return last(
    sortBy(
      purchaseReadyPropositions,
      (propositions) => propositions.purchaseReadyDate,
    ),
  )?.purchaseReadyDate;
};
