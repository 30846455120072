import { difference, find, values } from "lodash-es";
import type { EnumOrLiteral } from "../typescriptHelpers/enumOrTypeLiteral";

type DocumentTaskSubtypes = "dgd" | "invoice" | "msds" | "packingList";

export type DocumentTypeMap = {
  correspondingTaskSubtype: EnumOrLiteral<DocumentTaskSubtypes> | null;
  matches: string[];
  name: string;
};

export const DOCUMENT_TAGS = {
  /**
   * Document that goes alongside the packing list, necessary for both import and export customs.
   * It indicates the commercial value of the goods transported, and allows the calculation of customs duties.
   * Be careful that the value of the goods is always NOT NULL, even for samples.
   */
  BILLING_INVOICE: "INVOICE",
  COMMERCIAL_INVOICE: "INVOICE",
  CUSTOMS_ENTRY_SUMMARY: "ENTRY SUMMARY",
  CUSTOMS_IMA: "IMA",
  CUSTOMS_T1: "T1",
  DGD: "DGD",
  EUR1: "EUR1",
  EXA: "EXA - exit ECS",
  HAWB: "HAWB",
  HBL: "HBL",
  MAWB: "MAWB",
  MBL: "MBL",
  MRN: "MRN",
  MSDS: "MSDS",
  PACKING_LIST: "PACKING LIST",
  POD: "POD/CMR",
  VGM: "VGM",
} as const;

export type DOCUMENT_TAGS_TYPE = keyof typeof DOCUMENT_TAGS;

export const ALL_DOCUMENTS_TAGS: string[] = values(DOCUMENT_TAGS);

//List of tags that can be applied by a user on a customs clearance file
export const USER_CUSTOMS_CLEARANCE_DOCUMENT_TAGS: string[] = difference(
  ALL_DOCUMENTS_TAGS,
  [DOCUMENT_TAGS.EXA, DOCUMENT_TAGS.MRN],
);

export const DOCUMENT_TYPES_MAP: Record<DOCUMENT_TAGS_TYPE, DocumentTypeMap> = {
  BILLING_INVOICE: {
    correspondingTaskSubtype: null,
    matches: [],
    name: DOCUMENT_TAGS.BILLING_INVOICE,
  },
  COMMERCIAL_INVOICE: {
    correspondingTaskSubtype: "invoice",
    matches: ["invoice", "facture", "cipl"],
    name: DOCUMENT_TAGS.COMMERCIAL_INVOICE,
  },

  CUSTOMS_ENTRY_SUMMARY: {
    correspondingTaskSubtype: null,
    matches: ["entry summary", "resume de l'entree"],
    name: DOCUMENT_TAGS.CUSTOMS_ENTRY_SUMMARY,
  },
  CUSTOMS_IMA: {
    correspondingTaskSubtype: null,
    matches: ["ima"],
    name: DOCUMENT_TAGS.CUSTOMS_IMA,
  },
  CUSTOMS_T1: {
    correspondingTaskSubtype: null,
    matches: ["t1"],
    name: DOCUMENT_TAGS.CUSTOMS_T1,
  },
  DGD: {
    correspondingTaskSubtype: "dgd",
    matches: ["dgd", "dangerous goods declaration"],
    name: DOCUMENT_TAGS.DGD,
  },
  EUR1: {
    correspondingTaskSubtype: null,
    matches: ["eur1"],
    name: DOCUMENT_TAGS.EUR1,
  },
  EXA: {
    correspondingTaskSubtype: null,
    matches: ["exa"],
    name: DOCUMENT_TAGS.EXA,
  },
  HAWB: {
    correspondingTaskSubtype: null,
    matches: ["hawb"],
    name: DOCUMENT_TAGS.HAWB,
  },
  HBL: {
    correspondingTaskSubtype: null,
    matches: ["hbl"],
    name: DOCUMENT_TAGS.HBL,
  },
  MAWB: {
    correspondingTaskSubtype: null,
    matches: ["mawb"],
    name: DOCUMENT_TAGS.MAWB,
  },
  MBL: {
    correspondingTaskSubtype: null,
    matches: ["mbl"],
    name: DOCUMENT_TAGS.MBL,
  },
  MRN: {
    correspondingTaskSubtype: null,
    matches: ["mrn"],
    name: DOCUMENT_TAGS.MRN,
  },
  MSDS: {
    correspondingTaskSubtype: "msds",
    matches: ["msds", "material safety data sheet"],
    name: DOCUMENT_TAGS.MSDS,
  },
  PACKING_LIST: {
    correspondingTaskSubtype: "packingList",
    matches: ["packing", "pl ", "packingList"],
    name: DOCUMENT_TAGS.PACKING_LIST,
  },
  POD: {
    correspondingTaskSubtype: null,
    matches: ["pod", "cmr"],
    name: DOCUMENT_TAGS.POD,
  },
  VGM: {
    correspondingTaskSubtype: null,
    matches: ["vgm"],
    name: DOCUMENT_TAGS.VGM,
  },
};

export const mapToTaskSubtype = (documentType?: null | string) =>
  find(DOCUMENT_TYPES_MAP, { matches: [documentType] })
    ?.correspondingTaskSubtype;
