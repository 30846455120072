// In atlas/src/components/MarkdownGenerator/templates/utils.ts
import { isDefined } from "../../other/isDefined";
import { shipownersBelongToTheSameFamily } from "./shipownersBelongToTheSameFamily";

type Shipowner = {
  id: string;
  parentShipownerGroup: { id: string } | null;
};

export const isPreCarriageCarrierHaulage = ({
  freightShipowner,
  preCarriageShipowner,
}: {
  freightShipowner: Shipowner | null;
  preCarriageShipowner: Shipowner | null;
}) => {
  return (
    isDefined(freightShipowner) &&
    isDefined(preCarriageShipowner) &&
    shipownersBelongToTheSameFamily(freightShipowner, preCarriageShipowner)
  );
};

export const isOnCarriageCarrierHaulage = ({
  freightShipowner,
  onCarriageShipowner,
}: {
  freightShipowner: Shipowner | null;
  onCarriageShipowner: Shipowner | null;
}) => {
  return (
    isDefined(freightShipowner) &&
    isDefined(onCarriageShipowner) &&
    shipownersBelongToTheSameFamily(freightShipowner, onCarriageShipowner)
  );
};

export const isPreCarriageMerchantHaulage = ({
  freightShipowner,
  preCarriageShipowner,
}: {
  freightShipowner: Shipowner | null;
  preCarriageShipowner: Shipowner | null;
}) => {
  return (
    isDefined(freightShipowner) &&
    isDefined(preCarriageShipowner) &&
    !isPreCarriageCarrierHaulage({
      freightShipowner,
      preCarriageShipowner,
    })
  );
};

export const isOnCarriageMerchantHaulage = ({
  freightShipowner,
  onCarriageShipowner,
}: {
  freightShipowner: Shipowner | null;
  onCarriageShipowner: Shipowner | null;
}) => {
  return (
    isDefined(freightShipowner) &&
    isDefined(onCarriageShipowner) &&
    !isOnCarriageCarrierHaulage({ freightShipowner, onCarriageShipowner })
  );
};
