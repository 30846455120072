import { contextFactory } from "../../../utils/contextFactory";
import type { useOverlay } from "./useOverlay";

const [OverlayProvider, useOverlayContext] = contextFactory<
  ReturnType<typeof useOverlay>
>({
  displayName: "OverlayProvider",
  errorMessage: "Overlay components should be wrapped in `OverlayProvider`.",
});

export { OverlayProvider, useOverlayContext };
