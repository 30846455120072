const frCountries: { [key: string]: string } = {
  ad: "Andorre",
  ae: "Émirats Arabes Unis",
  af: "Afghanistan",
  ag: "Antigua-Et-Barbuda",
  ai: "Anguilla",
  al: "Albanie",
  am: "Arménie",
  an: "Antilles Néerlandaises",
  ao: "Angola",
  ar: "Argentine",
  as: "Samoa américaines",
  at: "Autriche",
  au: "Australie",
  aw: "Aruba",
  ax: "Åland, Îles",
  az: "Azerbaïdjan",
  ba: "Bosnie-Herzégovine",
  bb: "Barbade",
  bd: "Bangladesh",
  be: "Belgique",
  bf: "Burkina Faso",
  bg: "Bulgarie",
  bh: "Bahreïn",
  bi: "Burundi",
  bj: "Bénin",
  bl: "Saint-Barthélemy",
  bm: "Bermudes",
  bn: "Brunei Darussalam",
  bo: "Bolivie",
  br: "Brésil",
  bs: "Bahamas",
  bt: "Bhoutan",
  bv: "Bouvet, Île",
  bw: "Botswana",
  by: "Bélarus",
  bz: "Belize",
  ca: "Canada",
  cc: "Cocos (Keeling), Îles",
  cd: "Congo, République démocratique du",
  cf: "République centrafricaine",
  cg: "Congo",
  ch: "Suisse",
  ci: "Côte d'Ivoire",
  ck: "Cook, Îles",
  cl: "Chili",
  cm: "Cameroun",
  cn: "Chine",
  co: "Colombie",
  cr: "Costa Rica",
  cs: "Serbie et Monténégro",
  cu: "Cuba",
  cv: "Cap-Vert",
  cx: "Christmas, Île",
  cy: "Chypre",
  cz: "République tchèque",
  de: "Allemagne",
  dj: "Djibouti",
  dk: "Danemark",
  dm: "Dominique",
  do: "République dominicaine",
  dz: "Algérie",
  ec: "Équateur",
  ee: "Estonie",
  eg: "Égypte",
  eh: "Sahara Occidental",
  er: "Érythrée",
  es: "Espagne",
  et: "Éthiopie",
  fi: "Finlande",
  fj: "Fidji",
  fk: "Malouines, Îles",
  fm: "Micronésie, États fédérés de",
  fo: "Féroé, Îles",
  fr: "France",
  ga: "Gabon",
  gb: "Royaume-Uni",
  gd: "Grenade",
  ge: "Géorgie",
  gf: "Guyane française",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Groenland",
  gm: "Gambie",
  gn: "Guinée",
  gp: "Guadeloupe",
  gq: "Guinée équatoriale",
  gr: "Grèce",
  gs: "Géorgie du Sud-et-les Îles Sandwich du Sud",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinée-Bissau",
  gy: "Guyana",
  hk: "Hong Kong",
  hm: "Heard-et-MacDonald, Îles",
  hn: "Honduras",
  hr: "Croatie",
  ht: "Haïti",
  hu: "Hongrie",
  id: "Indonésie",
  ie: "Irlande",
  il: "Israël",
  in: "Inde",
  io: "Territoire britannique de l'Océan Indien",
  iq: "Iraq",
  ir: "Iran",
  is: "Islande",
  it: "Italie",
  jm: "Jamaïque",
  jo: "Jordanie",
  jp: "Japon",
  ke: "Kenya",
  kg: "Kirghizistan",
  kh: "Cambodge",
  ki: "Kiribati",
  km: "Comores",
  kn: "Saint-Kitts-Et-Nevis",
  kp: "Corée du Nord",
  kr: "Corée du Sud",
  kw: "Koweït",
  ky: "Caïmans, Îles",
  kz: "Kazakhstan",
  la: "Lao, République Démocratique Populaire",
  lb: "Liban",
  lc: "Sainte-Lucie",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Libéria",
  ls: "Lesotho",
  lt: "Lituanie",
  lu: "Luxembourg",
  lv: "Lettonie",
  ly: "Libye",
  ma: "Maroc",
  mc: "Monaco",
  md: "Moldovie",
  me: "Monténégro",
  mg: "Madagascar",
  mh: "Marshall, Îles",
  mk: "Macédoine",
  ml: "Mali",
  mm: "Birmanie",
  mn: "Mongolie",
  mo: "Macao",
  mp: "Mariannes du Nord, Îles",
  mq: "Martinique",
  mr: "Mauritanie",
  ms: "Montserrat",
  mt: "Malte",
  mu: "Maurice",
  mv: "Maldives",
  mw: "Malawi",
  mx: "Mexique",
  my: "Malaisie",
  mz: "Mozambique",
  na: "Namibie",
  nc: "Nouvelle-Calédonie",
  ne: "Niger",
  nf: "Norfolk, Île",
  ng: "Nigéria",
  ni: "Nicaragua",
  nl: "Pays-Bas",
  no: "Norvège",
  np: "Népal",
  nr: "Nauru",
  nu: "Niué",
  nz: "Nouvelle-Zélande",
  om: "Oman",
  pa: "Panama",
  pe: "Pérou",
  pf: "Polynésie Française",
  pg: "Papouasie-Nouvelle-Guinée",
  ph: "Philippines",
  pk: "Pakistan",
  pl: "Pologne",
  pm: "Saint-Pierre-Et-Miquelon",
  pn: "Pitcairn",
  pr: "Porto Rico",
  ps: "Palestine, Etat de",
  pt: "Portugal",
  pw: "Palaos",
  py: "Paraguay",
  qa: "Qatar",
  re: "Réunion",
  ro: "Roumanie",
  rs: "Serbie",
  ru: "Russie",
  rw: "Rwanda",
  sa: "Arabie Saoudite",
  sb: "Salomon, Îles",
  sc: "Seychelles",
  sd: "Soudan",
  se: "Suède",
  sg: "Singapour",
  sh: "Sainte-Hélène",
  si: "Slovénie",
  sj: "Svalbard et Jan Mayen",
  sk: "Slovaquie",
  sl: "Sierra Leone",
  sm: "Saint-Marin",
  sn: "Sénégal",
  so: "Somalie",
  sr: "Suriname",
  st: "Sao Tomé-et-Principe",
  sv: "El Salvador",
  sy: "Syrie",
  sz: "Swaziland",
  tc: "Turks-et-Caïcos, Îles",
  td: "Tchad",
  tf: "Terres australes et antarctiques françaises",
  tg: "Togo",
  th: "Thaïlande",
  tj: "Tadjikistan",
  tk: "Tokelau",
  tl: "Timor Oriental",
  tm: "Turkménistan",
  tn: "Tunisie",
  to: "Tonga",
  tr: "Turquie",
  tt: "Trinité-et-Tobago",
  tv: "Tuvalu",
  tw: "Taïwan",
  tz: "Tanzanie",
  ua: "Ukraine",
  ug: "Ouganda",
  um: "Îles mineures éloignées des États-Unis",
  us: "États-Unis",
  uy: "Uruguay",
  uz: "Ouzbékistan",
  va: "Vatican, État de la Cité du",
  vc: "Saint-Vincent-Et-Les-Grenadiness",
  ve: "Venezuela",
  vg: "Îles Vierges britanniques",
  vi: "Îles Vierges des États-Unis",
  vn: "Viêt Nam",
  vu: "Vanuatu",
  wf: "Wallis et Futuna",
  ws: "Samoa",
  ye: "Yémen",
  yt: "Mayotte",
  za: "Afrique du Sud",
  zm: "Zambie",
  zw: "Zimbabwe",
};

const enCountries: { [key: string]: string } = {
  ad: "Andorra",
  ae: "United Arab Emirates",
  af: "Afghanistan",
  ag: "Antigua-and-Barbuda",
  ai: "Anguilla",
  al: "Albania",
  am: "Armenia",
  an: "Netherlands Antilles",
  ao: "Angola",
  ar: "Argentina",
  as: "American Samoa",
  at: "Austria",
  au: "Australia",
  aw: "Aruba",
  ax: "Åland, Islands",
  az: "Azerbaijan",
  ba: "Bosnia and Herzegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgium",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint-Barthelemy",
  bm: "Bermuda",
  bn: "Brunei Darussalam",
  bo: "Bolivia",
  br: "Brazil",
  bs: "The Bahamas",
  bt: "Bhutan",
  bv: "Bouvet Island",
  bw: "Botswana",
  by: "Belarus",
  bz: "Belize",
  ca: "Canada",
  cc: "Cocos (Keeling), Islands",
  cd: "Democratic Republic of the Congo",
  cf: "Central African Republic",
  cg: "Congo",
  ch: "Switzerland",
  ci: "Ivory Coast",
  ck: "Cook, Islands",
  cl: "Chile",
  cm: "Cameroon",
  cn: "China",
  co: "Colombia",
  cr: "Costa Rica",
  cs: "Serbia and Montenegro",
  cu: "Cuba",
  cv: "Cape Verde",
  cx: "Christmas Island",
  cy: "Cyprus",
  cz: "Czech Republic",
  de: "Germany",
  dj: "Djibouti",
  dk: "Denmark",
  dm: "Dominica",
  do: "Dominican Republic",
  dz: "Algeria",
  ec: "Ecuador",
  ee: "Estonia",
  eg: "Egypt",
  eh: "Western Sahara",
  er: "Eritrea",
  es: "Spain",
  et: "Ethiopia",
  fi: "Finland",
  fj: "Fiji",
  fk: "Falkland Islands",
  fm: "Federated States of Micronesia",
  fo: "Faroe Islands",
  fr: "France",
  ga: "Gabon",
  gb: "United Kingdom",
  gd: "Grenada",
  ge: "Georgia",
  gf: "French Guyana",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Greenland",
  gm: "The Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Equatorial Guinea",
  gr: "Greece",
  gs: "South Georgia and Sandwich Islands",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hong Kong",
  hm: "Heard Island and McDonald Islands",
  hn: "Honduras",
  hr: "Croatia",
  ht: "Haiti",
  hu: "Hungary",
  id: "Indonesia",
  ie: "Ireland",
  il: "Israel",
  in: "India",
  io: "British Indian Ocean Territory",
  iq: "Iraq",
  ir: "Iran",
  is: "Iceland",
  it: "Italy",
  jm: "Jamaica",
  jo: "Jordania",
  jp: "Japan",
  ke: "Kenya",
  kg: "Kyrgyzstan",
  kh: "Cambodia",
  ki: "Kiribati",
  km: "Comoros",
  kn: "Saint Kitts and Nevis",
  kp: "North Korea",
  kr: "South Korea",
  kw: "Kuwait",
  ky: "Cayman Islands",
  kz: "Kazakhstan",
  la: "Laos",
  lb: "Lebanon",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Lithuania",
  lu: "Luxembourg",
  lv: "Latvia",
  ly: "Libya",
  ma: "Morocco",
  mc: "Monaco",
  md: "Moldavia",
  me: "Montenegro",
  mg: "Madagascar",
  mh: "Marshall Islands",
  mk: "Republic of Macedonia",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongolia",
  mo: "Macau",
  mp: "Northern Mariana Islands",
  mq: "Martinique",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldives",
  mw: "Malawi",
  mx: "Mexico",
  my: "Malaysia",
  mz: "Mozambique",
  na: "Namibia",
  nc: "New Caledonia",
  ne: "Niger",
  nf: "Norfolk Island",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Netherlands",
  no: "Norway",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "New Zealand",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "French Polynesia",
  pg: "Papua New Guinea",
  ph: "Philippines",
  pk: "Pakistan",
  pl: "Poland",
  pm: "Saint Pierre and Miquelon",
  pn: "Pitcairn Islands",
  pr: "Puerto Rico",
  ps: "Palestine, State of",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Réunion",
  ro: "Romania",
  rs: "Serbia",
  ru: "Russia",
  rw: "Rwanda",
  sa: "Kingdom of Saudi Arabia",
  sb: "Solomon Islands",
  sc: "Seychelles",
  sd: "Sudan",
  se: "Sweden",
  sg: "Singapore",
  sh: "Saint Helena",
  si: "Slovenia",
  sj: "Svalbard and Jan Mayen",
  sk: "Slovakia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Suriname",
  st: "São Tomé and Príncipe",
  sv: "El Salvador",
  sy: "Syria",
  sz: "Swaziland",
  tc: "Turks and Caicos Islands",
  td: "Chad",
  tf: "French Southern and Antarctic Lands",
  tg: "Togo",
  th: "Thailand",
  tj: "Tajikistan",
  tk: "Tokelau",
  tl: "East Timor",
  tm: "Turkmenistan",
  tn: "Tunisia",
  to: "Tonga",
  tr: "Turkey",
  tt: "Trinidad and Tobago",
  tv: "Tuvalu",
  tw: "Taiwan",
  tz: "Tanzania",
  ua: "Ukraine",
  ug: "Uganda",
  um: "United States Minor Outlying Islands",
  us: "USA",
  uy: "Uruguay",
  uz: "Uzbekistan",
  va: "Vatican City",
  vc: "Saint Vincent and the Grenadines",
  ve: "Venezuela",
  vg: "British Virgin Islands",
  vi: "United States Virgin Islands",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis and Futuna",
  ws: "Samoa",
  ye: "Yemen",
  yt: "Mayotte",
  za: "South Africa",
  zm: "Zambia",
  zw: "Zimbabwe",
};

const esCountries: { [key: string]: string } = {
  ad: "Andorra",
  ae: "Emiratos Árabes Unidos",
  af: "Afganistán",
  ag: "Antigua y Barbuda",
  ai: "Anguilla",
  al: "Albania",
  am: "Armenia",
  an: "Netherlands Antilles",
  ao: "Angola",
  ar: "Argentina",
  as: "American Samoa",
  at: "Austria",
  au: "Australia",
  aw: "Aruba",
  ax: "Åland, Islands",
  az: "Azerbaiyán",
  ba: "Bosnia y Herzegovina",
  bb: "Barbados",
  bd: "Bangladés",
  be: "Bélgica",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Baréin",
  bi: "Burundi",
  bj: "Benín",
  bl: "Saint-Barthelemy",
  bm: "Bermuda",
  bn: "Brunéi",
  bo: "Bolivia",
  br: "Brasil",
  bs: "Bahamas",
  bt: "Bután",
  bv: "Bouvet Island",
  bw: "Botsuana",
  by: "Bielorrusia",
  bz: "Belice",
  ca: "Canadá",
  cc: "Cocos (Keeling), Islands",
  cd: "República Democrática del Congo",
  cf: "República Centroafricana",
  cg: "República del Congo",
  ch: "Suiza",
  ci: "Costa de Marfil",
  ck: "Cook, Islands",
  cl: "Chile",
  cm: "Camerún",
  cn: "China",
  co: "Colombia",
  cr: "Costa Rica",
  cs: "Serbia and Montenegro",
  cu: "Cuba",
  cv: "Cabo Verde",
  cx: "Christmas Island",
  cy: "Chipre",
  cz: "República Checa",
  de: "Alemania",
  dj: "Yibuti",
  dk: "Dinamarca",
  dm: "Dominica",
  do: "República Dominicana",
  dz: "Argelia",
  ec: "Ecuador",
  ee: "Estonia",
  eg: "Egipto",
  eh: "Western Sahara",
  er: "Eritrea",
  es: "España",
  et: "Etiopía",
  fi: "Finlandia",
  fj: "Fiyi",
  fk: "Falkland Islands",
  fm: "Micronesia",
  fo: "Faroe Islands",
  fr: "Francia",
  ga: "Gabón",
  gb: "Reino Unido",
  gd: "Granada",
  ge: "Georgia",
  gf: "French Guyana",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Greenland",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Guinea Ecuatorial",
  gr: "Grecia",
  gs: "South Georgia and Sandwich Islands",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bisáu",
  gy: "Guyana",
  hk: "Hong Kong",
  hm: "Heard Island and McDonald Islands",
  hn: "Honduras",
  hr: "Croacia",
  ht: "Haití",
  hu: "Hungría",
  id: "Indonesia",
  ie: "Irlanda",
  il: "Israel",
  in: "India",
  io: "British Indian Ocean Territory",
  iq: "Irak",
  ir: "Irán",
  is: "Islandia",
  it: "Italia",
  jm: "Jamaica",
  jo: "Jordania",
  jp: "Japón",
  ke: "Kenia",
  kg: "Kirguistán",
  kh: "Camboya",
  ki: "Kiribati",
  km: "Comoros",
  kn: "San Cristóbal y Nieves",
  kp: "Corea del Norte",
  kr: "Corea del Sur",
  kw: "Kuwait",
  ky: "Cayman Islands",
  kz: "Kazajistán",
  la: "Laos",
  lb: "Líbano",
  lc: "Santa Lucía",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesoto",
  lt: "Lituania",
  lu: "Luxemburgo",
  lv: "Letonia",
  ly: "Libia",
  ma: "Marruecos",
  mc: "Mónaco",
  md: "Moldavia",
  me: "Montenegro",
  mg: "Madagascar",
  mh: "Islas Marshall",
  mk: "Macedonia del Norte",
  ml: "Malí",
  mm: "Birmania",
  mn: "Mongolia",
  mo: "Macau",
  mp: "Northern Mariana Islands",
  mq: "Martinique",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauricio",
  mv: "Maldivas",
  mw: "Malaui",
  mx: "México",
  my: "Malasia",
  mz: "Mozambique",
  na: "Namibia",
  nc: "New Caledonia",
  ne: "Níger",
  nf: "Norfolk Island",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Países Bajos",
  no: "Noruega",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nueva Zelanda",
  om: "Omán",
  pa: "Panamá",
  pe: "Perú",
  pf: "French Polynesia",
  pg: "Papúa Nueva Guinea",
  ph: "Filipinas",
  pk: "Pakistán",
  pl: "Polonia",
  pm: "Saint Pierre and Miquelon",
  pn: "Pitcairn Islands",
  pr: "Puerto Rico",
  ps: "Palestine, State of",
  pt: "Portugal",
  pw: "Palaos",
  py: "Paraguay",
  qa: "Catar",
  re: "Réunion",
  ro: "Rumania",
  rs: "Serbia",
  ru: "Rusia",
  rw: "Ruanda",
  sa: "Arabia Saudita",
  sb: "Islas Salomón",
  sc: "Seychelles",
  sd: "Sudán",
  se: "Suecia",
  sg: "Singapur",
  sh: "Saint Helena",
  si: "Slovenia",
  sj: "Svalbard and Jan Mayen",
  sk: "Eslovaquia",
  sl: "Sierra Leona",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Surinam",
  st: "Santo Tomé y Príncipee",
  sv: "El Salvador",
  sy: "Siria",
  sz: "Suazilandia",
  tc: "Turks and Caicos Islands",
  td: "Chad",
  tf: "French Southern and Antarctic Lands",
  tg: "Togo",
  th: "Tailandia",
  tj: "Tayikistán",
  tk: "Tokelau",
  tl: "Timor Oriental",
  tm: "Turkmenistán",
  tn: "Túnez",
  to: "Tonga",
  tr: "Turquía",
  tt: "Trinidad y Tobago",
  tv: "Tuvalu",
  tw: "Taiwan",
  tz: "Tanzania",
  ua: "Ucrania",
  ug: "Uganda",
  um: "United States Minor Outlying Islands",
  us: "Estados Unidos",
  uy: "Uruguay",
  uz: "Uzbekistán",
  va: "Vatican City",
  vc: "San Vicente y las Granadinas",
  ve: "Venezuela",
  vg: "British Virgin Islands",
  vi: "United States Virgin Islands",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis and Futuna",
  ws: "Samoa",
  ye: "Yemen",
  yt: "Mayotte",
  za: "Sudáfrica",
  zm: "Zambia",
  zw: "Zimbabue",
};

const itCountries = {
  ad: "Andorra",
  ae: "Emirati Arabi Uniti",
  af: "Afghanistan",
  ag: "Antigua e Barbuda",
  ai: "Anguilla",
  al: "Albania",
  am: "Armenia",
  an: "",
  ao: "Angola",
  aq: "Antartide",
  ar: "Argentina",
  as: "Samoa Americane",
  at: "Austria",
  au: "Australia",
  aw: "Aruba",
  ax: "Isole Åland",
  az: "Azerbaigian",
  ba: "Bosnia ed Erzegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgio",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint-Barthélemy",
  bm: "Bermuda",
  bn: "Brunei",
  bo: "Bolivia",
  bq: "Caraibi olandesi",
  br: "Brasile",
  bs: "Bahamas",
  bt: "Bhutan",
  bv: "Isola Bouvet",
  bw: "Botswana",
  by: "Bielorussia",
  bz: "Belize",
  ca: "Canada",
  cc: "Isole Cocos (Keeling)",
  cd: "Repubblica Democratica del Congo",
  cf: "Repubblica Centrafricana",
  cg: "Congo-Brazzaville",
  ch: "Svizzera",
  ci: "Côte d’Ivoire",
  ck: "Isole Cook",
  cl: "Cile",
  cm: "Camerun",
  cn: "Cina",
  co: "Colombia",
  cr: "Costa Rica",
  cs: "Serbia and Montenegro",
  cu: "Cuba",
  cv: "Capo Verde",
  cw: "Curaçao",
  cx: "Isola Christmas",
  cy: "Cipro",
  cz: "Cechia",
  de: "Germania",
  dj: "Gibuti",
  dk: "Danimarca",
  dm: "Dominica",
  do: "Repubblica Dominicana",
  dz: "Algeria",
  ec: "Ecuador",
  ee: "Estonia",
  eg: "Egitto",
  eh: "Sahara Occidentale",
  er: "Eritrea",
  es: "Spagna",
  et: "Etiopia",
  fi: "Finlandia",
  fj: "Figi",
  fk: "Isole Falkland",
  fm: "Micronesia",
  fo: "Isole Faroe",
  fr: "Francia",
  ga: "Gabon",
  gb: "Regno Unito",
  gd: "Grenada",
  ge: "Georgia",
  gf: "Guyana francese",
  gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibilterra",
  gl: "Groenlandia",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadalupa",
  gq: "Guinea Equatoriale",
  gr: "Grecia",
  gs: "Georgia del Sud e Sandwich australi",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hong Kong",
  hm: "Isole Heard e McDonald",
  hn: "Honduras",
  hr: "Croazia",
  ht: "Haiti",
  hu: "Ungheria",
  id: "Indonesia",
  ie: "Irlanda",
  il: "Israele",
  im: "Isola di Man",
  in: "India",
  io: "Territorio Britannico dell’Oceano Indiano",
  iq: "Iraq",
  ir: "Iran",
  is: "Islanda",
  it: "Italia",
  je: "Jersey",
  jm: "Giamaica",
  jo: "Giordania",
  jp: "Giappone",
  ke: "Kenya",
  kg: "Kirghizistan",
  kh: "Cambogia",
  ki: "Kiribati",
  km: "Comore",
  kn: "Saint Kitts e Nevis",
  kp: "Corea del Nord",
  kr: "Corea del Sud",
  kw: "Kuwait",
  ky: "Isole Cayman",
  kz: "Kazakistan",
  la: "Laos",
  lb: "Libano",
  lc: "Santa Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Lituania",
  lu: "Lussemburgo",
  lv: "Lettonia",
  ly: "Libia",
  ma: "Marocco",
  mc: "Monaco",
  md: "Moldavia",
  me: "Montenegro",
  mf: "Saint Martin",
  mg: "Madagascar",
  mh: "Isole Marshall",
  mk: "Macedonia del Nord",
  ml: "Mali",
  mm: "Birmania",
  mn: "Mongolia",
  mo: "Macao",
  mp: "Isole Marianne settentrionali",
  mq: "Martinica",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldive",
  mw: "Malawi",
  mx: "Messico",
  my: "Malaysia",
  mz: "Mozambico",
  na: "Namibia",
  nc: "Nuova Caledonia",
  ne: "Niger",
  nf: "Isola Norfolk",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Paesi Bassi",
  no: "Norvegia",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nuova Zelanda",
  om: "Oman",
  pa: "Panamá",
  pe: "Perù",
  pf: "Polinesia francese",
  pg: "Papua Nuova Guinea",
  ph: "Filippine",
  pk: "Pakistan",
  pl: "Polonia",
  pm: "Saint-Pierre e Miquelon",
  pn: "Isole Pitcairn",
  pr: "Portorico",
  ps: "Palestina",
  pt: "Portogallo",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Riunione",
  ro: "Romania",
  rs: "Serbia",
  ru: "Russia",
  rw: "Ruanda",
  sa: "Arabia Saudita",
  sb: "Isole Salomone",
  sc: "Seychelles",
  sd: "Sudan",
  se: "Svezia",
  sg: "Singapore",
  sh: "Sant’Elena",
  si: "Slovenia",
  sj: "Svalbard e Jan Mayen",
  sk: "Slovacchia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Suriname",
  ss: "Sud Sudan",
  st: "São Tomé e Príncipe",
  sv: "El Salvador",
  sx: "Sint Maarten",
  sy: "Siria",
  sz: "Swaziland",
  tc: "Isole Turks e Caicos",
  td: "Ciad",
  tf: "Terre australi francesi",
  tg: "Togo",
  th: "Thailandia",
  tj: "Tagikistan",
  tk: "Tokelau",
  tl: "Timor Est",
  tm: "Turkmenistan",
  tn: "Tunisia",
  to: "Tonga",
  tr: "Turchia",
  tt: "Trinidad e Tobago",
  tv: "Tuvalu",
  tw: "Taiwan",
  tz: "Tanzania",
  ua: "Ucraina",
  ug: "Uganda",
  um: "Altre isole americane del Pacifico",
  us: "USA",
  uy: "Uruguay",
  uz: "Uzbekistan",
  va: "Vaticano",
  vc: "Saint Vincent e Grenadine",
  ve: "Venezuela",
  vg: "Isole Vergini Britanniche",
  vi: "Isole Vergini Americane",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis e Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Yemen",
  yt: "Mayotte",
  za: "Sudafrica",
  zm: "Zambia",
  zw: "Zimbabwe",
};

// when you need a new country: create an account on geonames
// then enable access for that account to the API on https://www.geonames.org/manageaccount

// then use your username to make a call like this:
// http://api.geonames.org/countryInfoCSV?lang=it&username=moaadovrsea

// see the script generateDictionaryFromGeoData.js next to this file

export { enCountries, esCountries, frCountries, itCountries };
