import { convertInToCm } from "../../conversions";
import { isDefined } from "../../other/isDefined";
import type { FreightMethod } from "../../propositions/isPropositionEligibleForAutomaticBooking/types";
import type { DbPricingSteps, KronosCategory } from "../../sharedTypes";
import type { VolumeUnit } from "../loadUtils";

type Load = {
  unitHeight: number;
  unitLength: number;
  unitWidth: number;
  volumeUnit: VolumeUnit;
};

type KronosFreightMode =
  | "air"
  | "fcl"
  | "lcl"
  | "rail_fcl"
  | "rail_lcl"
  | "truck";

const SIZE_RESTRICTIONS_BY_MODE_AND_CATEGORY: {
  [key in KronosFreightMode]?: {
    [key in DbPricingSteps]?: {
      maxHeight?: number;
      maxLength?: number;
      maxWidth?: number;
    };
  };
} = {
  air: {
    arrival_truck_freight: {
      maxLength: 250,
      maxWidth: 250,
    },
    departure_truck_freight: {
      maxLength: 250,
      maxWidth: 250,
    },
    freight: {
      maxHeight: 160,
      maxLength: 300,
      maxWidth: 300,
    },
  },
  lcl: {
    arrival_truck_freight: {
      maxLength: 250,
      maxWidth: 250,
    },
    departure_truck_freight: {
      maxLength: 250,
      maxWidth: 250,
    },
  },
};

const isLoadOversized =
  ({
    maxHeight,
    maxLength,
    maxWidth,
  }: {
    maxHeight?: number;
    maxLength?: number;
    maxWidth?: number;
  }) =>
  (load: Load) => {
    const convertToCm =
      load.volumeUnit === "in" ? convertInToCm : (x: number) => x;

    const unitHeight = convertToCm(load.unitHeight);
    const unitLength = convertToCm(load.unitLength);
    const unitWidth = convertToCm(load.unitWidth);

    return (
      (isDefined(maxLength) && unitLength > maxLength) ||
      (isDefined(maxWidth) && unitWidth > maxWidth) ||
      (isDefined(maxHeight) && unitHeight > maxHeight)
    );
  };

export const areLoadsOversized =
  ({
    category,
    freightMode,
  }: {
    category: KronosCategory;
    freightMode: KronosFreightMode;
  }) =>
  (loads: Load[]) => {
    const sizeRestrictions =
      SIZE_RESTRICTIONS_BY_MODE_AND_CATEGORY[freightMode]?.[category];

    if (!sizeRestrictions) {
      return false;
    }

    return loads.some(
      isLoadOversized({
        maxHeight: sizeRestrictions.maxHeight,
        maxLength: sizeRestrictions.maxLength,
        maxWidth: sizeRestrictions.maxWidth,
      }),
    );
  };

const FREIGHT_METHOD_TO_KRONOS_LCL_FREIGHT_MODE: Record<
  FreightMethod,
  KronosFreightMode
> = {
  air: "air",
  ocean: "lcl",
  rail: "rail_lcl",
  truck: "truck",
};

export const areLoadsInAnyCategoryOversized = ({
  freightMethod,
  loads,
}: {
  freightMethod: FreightMethod;
  loads: Load[];
}) => {
  const CATEGORIES_TO_CHECK_FOR_OVERSIZED_CARGO: KronosCategory[] = [
    "freight",
    "departure_truck_freight",
    "arrival_truck_freight",
  ];

  return CATEGORIES_TO_CHECK_FOR_OVERSIZED_CARGO.some((category) =>
    areLoadsOversized({
      category,
      freightMode: FREIGHT_METHOD_TO_KRONOS_LCL_FREIGHT_MODE[freightMethod],
    })(loads),
  );
};
