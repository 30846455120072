import { noop } from "lodash-es";
import type { ErrorInfo } from "react";
import { contextFactory } from "../utils/contextFactory";

const DEFAULT_MESSAGES = {
  button: {
    cancel: "Cancel",
    close: "Close",
    copy: "Copy",
    delete: "Delete",
    download: "Download",
    hasCopied: "Copied !",
    submit: "Submit",
  },
  calendar: {
    nextMonth: "",
    previousMonth: "",
  },
  ecoFreighScoreLabel: {
    A: "Best-of-class",
    B: "Low",
    C: "Moderate",
    D: "Still too high",
    E: "Too high",
  },
  select: {
    create: "Create",
    empty: "No options",
    nothingFound: "Nothing found",
  },
};

export type DependenciesContext = {
  logger: (error: Error, info: ErrorInfo) => void;
  messages: typeof DEFAULT_MESSAGES;
};

export const DEFAULT_DEPENDENCIES: DependenciesContext = {
  logger: noop,
  messages: DEFAULT_MESSAGES,
};

/** Injection of unwanted dependencies (logger, i18n, router, etc.) in ThemeProvider */
export const [DependenciesProvider, useDependenciesContext] =
  contextFactory<DependenciesContext>({
    displayName: "DependenciesProvider",
    errorMessage: "Dependencies should we wrapped in `DependenciesProvider`",
  });
