import type { ForwardRefRenderFunction } from "react";
import React from "react";
import { isFunction } from "lodash-es";
import type {
  As,
  JoinProps,
  PolymorphicComponent,
  PropsOf,
} from "./types/polymorphic";
import type { ThemingProps } from "./types/system";

/** Simple forwardRef but with type overloads for the `as` prop.
 * The component below on which props are spread and ref is forwarded
 * should be polymorphic (it should use the `styled` factory).

 * This should be used on the first level of all exported components.
 * */
const polymorphic = <
  Component extends As,
  Props extends object = object,
  Compounds extends object = object,
>(
  component: ForwardRefRenderFunction<
    any,
    {
      as?: As;
    } & JoinProps<PropsOf<Component>, Props>
  >,
) =>
  React.forwardRef(component) as unknown as Compounds &
    PolymorphicComponent<Component, Props & ThemingProps>;

export type ReactRef<T> =
  | React.MutableRefObject<T>
  | React.Ref<T>
  | React.RefObject<T>;

type MutableRefContent<T> = React.MutableRefObject<T>["current"];

const assignRefTo =
  <T>(value: T) =>
  (ref?: ReactRef<T>) => {
    if (!ref) {
      return;
    }

    if (isFunction(ref)) {
      return ref(value);
    }

    try {
      (ref.current as unknown as MutableRefContent<T>) = value;
    } catch (error) {
      throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
    }
  };

const mergeRefs =
  <T>(...refs: (ReactRef<T> | undefined)[]) =>
  (node: T | null) => {
    refs.forEach(assignRefTo(node));
  };

export { mergeRefs, polymorphic };
