import { contextFactory } from "../../../utils/contextFactory";

export type FetchStateContextType = {
  isDisabledWhileRefetching?: boolean;
  isEmpty?: boolean;
  isErrored?: boolean;
  isLoading?: boolean;
  name?: string;
  setIsEmpty: (isEmpty: boolean) => void;
  setIsErrored: (isErrored: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export const [FetchStateProvider, useFetchStateContext] =
  contextFactory<FetchStateContextType>({
    displayName: "FetchStateProvider",
    errorMessage: "FetchState components should be wrapped in `FetchState`.",
  });
