import type { OvrseaOffice } from "./types";

const worldOvrseaOffice: OvrseaOffice = {
  address: null,
  countryCode: null,
  defaultOpsOwner: null,
  defaultPaymentDelayInDays: 7,
  entityDescription: "OVRSEA France, 23 rue Nollet 75017 Paris (France)",
  id: "master_world",
  legalDescription:
    "FORESEA TECHNOLOGIES, société par actions simplifiée dont le siège social est situé 23 rue Nollet 75017 Paris (France), immatriculée au registre du commerce et des sociétés de Paris sous le numéro 832 541 189 - numéro de TVA : FR67832541189",
  legalName: null,
  name: "Ovrsea Master Office",
  responsibilityArea: [],
  termsAndConditionsOfSaleLink:
    "https://www.ovrsea.com/termes-et-conditions-generales-de-ventes",
  timezone: "Europe/Paris",
};

const spainOffice: OvrseaOffice = {
  address: "Paseo de Gracia, 101, 4-1 08008 Barcelona",
  countryCode: "es",
  defaultOpsOwner: {
    email: "solenne.michel@ovrsea.com",
    id: "303",
  },
  defaultPaymentDelayInDays: 30,
  entityDescription:
    "OVRSEA ESPAÑA S.L.U - PASEO DE GRACIA, 101, 4-1 08008 Barcelona",
  id: "barca_es",
  legalDescription:
    "OVRSEA ESPAÑA S.L.U - PASEO DE GRACIA, 101, 4-1 08008 Barcelona - Sociedad limitada unipersonal inscrita en el Registro Mercantil de Barcelona con el número Cif B09745290 - IVA : ESB09745290",
  legalName: "Ovrsea España S.L.U",
  name: "Ovrsea Spain",
  responsibilityArea: ["es"],
  termsAndConditionsOfSaleLink:
    "https://en.ovrsea.com/condiciones-generales-de-venta",
  timezone: "Europe/Paris",
};

const franceOffice: OvrseaOffice = {
  address: "23 rue Nollet, 75017 Paris",
  countryCode: "fr",
  defaultOpsOwner: {
    email: "lea.boubet@ovrsea.com",
    id: "301",
  },
  defaultPaymentDelayInDays: 7,
  entityDescription: "OVRSEA France, 23 rue Nollet 75017 Paris (France)",
  id: "paris_fr",
  legalDescription:
    "FORESEA TECHNOLOGIES, société par actions simplifiée dont le siège social est situé 23 rue Nollet, 75017 Paris (France), immatriculée au registre du commerce et des sociétés de Paris sous le numéro 832 541 189 - numéro de TVA : FR67832541189",
  legalName: "Foresea Technologies",
  name: "Ovrsea France",
  responsibilityArea: ["be", "fr", "nl", "de"],
  termsAndConditionsOfSaleLink:
    "https://www.ovrsea.com/termes-et-conditions-generales-de-ventes",
  timezone: "Europe/Paris",
};

const usaOffice: OvrseaOffice = {
  address: "1411 Broadway, 16th Floor, New York, NY 10018",
  countryCode: "us",
  defaultOpsOwner: {
    email: "valeria.carbajal@ovrsea.com",
    id: "1310",
  },
  defaultPaymentDelayInDays: 30,
  entityDescription:
    "OVRSEA Inc, 1411 Broadway, Fl 16 -  New York, NY 10018, USA",
  id: "nyc_usa",
  legalDescription:
    "OVRSEA Inc, a corporation company existing under the US law incorporated in Delaware, having its registered office at c/o Orbiss Inc. 1411 Broadway, Fl 16 - New York, NY 10018, USA, registered at the Register of Trade and Companies under the number 87-3569484",
  legalName: "Ovrsea Inc",
  name: "Ovrsea USA",
  responsibilityArea: ["us"],
  termsAndConditionsOfSaleLink:
    "https://en.ovrsea.com/terms-and-conditions-of-sales",
  timezone: "America/New_York",
};

const italyOffice: OvrseaOffice = {
  address: "Piazza Paolo Ferrari 8, 20121, Milano",
  countryCode: "it",
  defaultOpsOwner: {
    email: "solenne.michel@ovrsea.com",
    id: "303",
  },
  defaultPaymentDelayInDays: 30,
  entityDescription: "OVRSEA S.R.L. - Via Gustavo Fara 35, 20124, Milano (MI)",
  id: "milan_it",
  legalDescription:
    "OVRSEA S.R.L. - Via Gustavo Fara 35, 20124, Milano (MI) - Societa' a responsabilita' limitata iscritta al Registro del Commercio e delle Imprese di Milano con il numero unico MI - 2663851 - P.IVA : 12471780960",
  legalName: "OVRSEA S.R.L.",
  name: "Ovrsea Italy",
  responsibilityArea: ["it"],
  termsAndConditionsOfSaleLink: "https://en.ovrsea.com/termini-e-condizioni",
  timezone: "Europe/Paris",
};

export const physicalOvrseaOffices: OvrseaOffice[] = [
  franceOffice,
  spainOffice,
  usaOffice,
  italyOffice,
];

export const ovrseaOffices: OvrseaOffice[] = [
  ...physicalOvrseaOffices,
  worldOvrseaOffice,
];

export const officesMap = {
  france: franceOffice,
  italy: italyOffice,
  master: worldOvrseaOffice,
  spain: spainOffice,
  usa: usaOffice,
};
