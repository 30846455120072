import * as HeadlessTooltip from "@radix-ui/react-tooltip";

export { HeadlessTooltip };

export * as HeadlessMenu from "@radix-ui/react-dropdown-menu";
export * as HeadlessHoverCard from "@radix-ui/react-hover-card";
export * as HeadlessPopover from "@radix-ui/react-popover";

export type OverlayPosition =
  | "bottom"
  | "bottom-end"
  | "bottom-start"
  | "left"
  | "left-end"
  | "left-start"
  | "right"
  | "right-end"
  | "right-start"
  | "top"
  | "top-end"
  | "top-start";

export const getSideAndAlignFromPlacement = (placement: OverlayPosition) => {
  const [side = "top", align = "center"] = placement.split("-");

  return {
    align: align as HeadlessTooltip.TooltipContentProps["align"],
    side: side as HeadlessTooltip.TooltipContentProps["side"],
  };
};
