import type { ReactNode } from "react";
import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import type { Theme } from "../../theme/theme";

const colorMapper = ({ colors }: Theme) =>
  ({
    "danger-light": {
      background: colors.alert.dangerLight,
      text: colors.alert.danger,
    },
    "info-light": {
      background: colors.alert.infoLight,
      text: colors.alert.info,
    },
    "neutral-light": {
      background: colors.neutral["10"],
      text: colors.neutral["70"],
    },
    "primary-light": {
      background: colors.primary.light,
      text: colors.primary.base,
    },
    "success-light": {
      background: colors.alert.successLight,
      text: colors.alert.success,
    },
    "warning-light": {
      background: colors.alert.warningLight,
      text: colors.alert.warning,
    },
  }) as const;

export type PillColor = keyof ReturnType<typeof colorMapper>;

type Props = {
  color: PillColor;
  dataTestId?: string;
  label: ReactNode;
};

export type PillProps = Props & SystemProps<"div">;

const StyledPill = styled<"div", { pillColor: PillColor }>("div", {
  base: ({ pillColor, theme }) => {
    const { fontSize, fontWeight, letterSpacing, lineHeight } =
      theme.caption.xs;

    return {
      backgroundColor: colorMapper(theme)[pillColor].background,
      borderRadius: theme.radius.round,
      color: colorMapper(theme)[pillColor].text,
      display: "inline-block",
      fontSize,
      fontWeight,
      height: "min-content",
      letterSpacing,
      lineHeight,
      paddingBlock: theme.spacing[4],
      paddingInline: theme.spacing[8],
      whiteSpace: "nowrap",
    };
  },
});

const StyledCircle = styled<"div", { pillColor: PillColor }>("div", {
  base: ({ pillColor, theme }) => ({
    backgroundColor: colorMapper(theme)[pillColor].text,
    borderRadius: theme.radius.round,
    display: "inline-block",
    height: theme.spacing[8],
    marginRight: theme.spacing[4],
    verticalAlign: "baseline",
    width: theme.spacing[8],
  }),
});

export const Pill = polymorphic<"div", Props>(
  ({ color, dataTestId = "Pill", label, ...rest }, ref) => {
    return (
      <StyledPill
        data-testid={dataTestId}
        pillColor={color}
        ref={ref}
        {...rest}
      >
        <StyledCircle pillColor={color} />
        <span>{label}</span>
      </StyledPill>
    );
  },
);

Pill.displayName = "Pill";
