import type { FreightMethodOvrUtils } from "../orderUtils/orderFreightMethodSelectors";
import type { LoadOvrutilsWithUnits } from "../cargaisonUtils/loadUtils";
import {
  applyGenericHeightOnNonStackableLoads,
  calculateTotalQuantity,
  calculateTotalTaxableWeight,
  calculateTotalVolumeInM3,
  calculateTotalWeightInKg,
  calculateWM1,
  findLoadsTotalsChanges,
  palletizeLoads,
} from "../cargaisonUtils/loadUtils";
import { convertKgToLbs, convertKgToTons } from "../conversions";
import type { RateUnit } from "../finance/pricesUtils/rateUnitsUtils";
import type { PriceForAutofill } from "./types";

const chooseQuantityByQuantityUnit = ({
  quantity,
  quantityUnit,
  totalQuantityPalletized,
  wm1,
}: {
  quantity: number;
  quantityUnit: RateUnit | null;
  totalQuantityPalletized?: number;
  wm1?: null | number;
}) => {
  switch (quantityUnit) {
    case "wm":
      return wm1;
    case "t":
      return convertKgToTons(quantity);
    case "lbs":
      return convertKgToLbs(quantity);
    case "pallet":
      return totalQuantityPalletized;

    default:
      return quantity;
  }
};

export const determinePriceQuantityForNewLoads =
  <Loads extends LoadOvrutilsWithUnits>({
    freightMethod,
    newLoads,
    oldLoads,
  }: {
    freightMethod: FreightMethodOvrUtils;
    newLoads: Loads[];
    oldLoads: Loads[];
  }) =>
  <Price extends PriceForAutofill>(price: Price): null | number | undefined => {
    const loadsChanges = findLoadsTotalsChanges(newLoads, oldLoads);

    const totalQuantity = calculateTotalQuantity(newLoads);
    const taxableWeight = calculateTotalTaxableWeight(newLoads);
    const totalVolumeInCbm = calculateTotalVolumeInM3(newLoads);
    const totalWeightInKg = calculateTotalWeightInKg(newLoads);
    const totalWeightInTons = convertKgToTons(totalWeightInKg);

    const wm1 = calculateWM1(
      applyGenericHeightOnNonStackableLoads({ freightMethod, loads: newLoads }),
    );
    // Palletization involves arrivalCountryCode check as well as price category. See computeAllLoadsKronosPerStep.ts in kronos
    // The code below might lead to not-allowed palletization
    const totalQuantityPalletized = calculateTotalQuantity(
      palletizeLoads(newLoads),
    );

    const quantityTotalsByQuantityUnits = [
      {
        quantity: chooseQuantityByQuantityUnit({
          quantity: totalWeightInKg,
          quantityUnit: price.quantityUnit ?? null,
          wm1,
        }),
        quantityUnits: [
          "kg",
          "t",
          "lbs",
          totalWeightInTons > totalVolumeInCbm && "wm",
        ],
        type: "totalWeight",
      },
      {
        quantity: chooseQuantityByQuantityUnit({
          quantity: totalQuantity,
          quantityUnit: price.quantityUnit ?? null,
          totalQuantityPalletized,
        }),
        quantityUnits: ["pallet"],
        type: "totalQuantity",
      },
      {
        quantity: chooseQuantityByQuantityUnit({
          quantity: totalVolumeInCbm,
          quantityUnit: price.quantityUnit ?? null,
          wm1,
        }),
        quantityUnits: ["cbm", totalVolumeInCbm > totalWeightInTons && "wm"],
        type: "totalVolume",
      },
      {
        quantity: taxableWeight,
        quantityUnits: ["tw"],
        type: "taxableWeight",
      },
    ];

    const totalToApply = quantityTotalsByQuantityUnits.find(
      (total) =>
        price.quantityUnit && total.quantityUnits.includes(price.quantityUnit),
    );

    const totalHasChange = !!loadsChanges.find(
      (change) => change.type === totalToApply?.type,
    );

    const finalQuantity = totalHasChange
      ? totalToApply?.quantity
      : price.quantity;

    return finalQuantity ?? price.quantity;
  };
