import React from "react";
import { selectTabIndex } from "../../utils/mergeStyles";
import type { SystemProps } from "../../utils/types/system";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import { mapPseudoFocusRing } from "../../theme/commons";
import { Visibility } from "../Layout/Visibility";
import {
  DocumentActions,
  DocumentDate,
  DocumentExtension,
  DocumentGrid,
  DocumentName,
} from "./Document/DocumentComponents";
import { DocumentProvider } from "./Document/DocumentContext";
import type { CardProps } from "./Card";
import { Card } from "./Card";

type Props = {
  name: string;
  shouldShowActionsOnHover?: boolean;
};

export type DocumentProps = Props & SystemProps<"div">;

type Compounds = {
  Actions: typeof DocumentActions;
  Date: typeof DocumentDate;
  Extension: typeof DocumentExtension;
  Grid: typeof DocumentGrid;
  Name: typeof DocumentName;
};

const StyledVisibility = styled<typeof Visibility, { isInteractive: boolean }>(
  Visibility,
  {
    base: ({ isInteractive, theme }) => [
      {
        display: "flex",
        minWidth: 0,
        width: "unset",
      },
      isInteractive && {
        transitionDuration: theme.transition.duration.slow,
        transitionProperty: "all",
        transitionTimingFunction: theme.transition.easing.color,
        ...mapPseudoFocusRing(theme),
        "&:hover": {
          ".extension": {
            background: theme.colors.neutral["10"],
          },
          background: theme.colors.neutral.light,
        },
        cursor: "pointer",
      },
    ],
  },
);

const OutlinedCard = (props: CardProps) => (
  <Card {...props} variant="outlined" />
);

export const Document = polymorphic<"div", Props, Compounds>(
  (
    {
      children,
      name,
      onClick,
      shouldShowActionsOnHover = true,
      tabIndex,
      ...rest
    },
    ref,
  ) => (
    <DocumentProvider value={{ name, shouldShowActionsOnHover }}>
      <StyledVisibility
        as={OutlinedCard}
        isInteractive={Boolean(onClick)}
        ref={ref}
        variant="visibility"
        {...rest}
        onClick={onClick}
        tabIndex={tabIndex ?? selectTabIndex(onClick)}
      >
        {children}
      </StyledVisibility>
    </DocumentProvider>
  ),
);

Document.displayName = "Document";
Document.Actions = DocumentActions;
Document.Date = DocumentDate;
Document.Extension = DocumentExtension;
Document.Grid = DocumentGrid;
Document.Name = DocumentName;
