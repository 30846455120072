import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";

type Props = {
  progress: number;
};

export type ProgressProps = Props & SystemProps<"div">;

const StyledBar = styled<
  "div",
  {
    widthPercent?: number;
  }
>("div", {
  base: ({ theme, widthPercent }) => ({
    backgroundColor: theme.colors.primary.base,
    borderBottomRightRadius: theme.radius.xs,
    borderTopRightRadius: theme.radius.xs,
    height: "100%",
    transitionDuration: theme.transition.duration.slow,
    transitionProperty: "all",
    transitionTimingFunction: theme.transition.easing.base,
    width: `${widthPercent}%`,
    willChange: "width, background-color",
  }),
});

const StyledBarContainer = styled<"div", object>("div", {
  base: ({ theme }) => ({
    background: theme.colors.neutral[10],
    height: theme.spacing[8],
    overflow: "hidden",
    position: "relative",
    width: "100%",
  }),
});

export const Progress = polymorphic<"div", Props>(
  ({ progress, ...rest }, ref) => {
    return (
      <StyledBarContainer className="progress" ref={ref} {...rest}>
        <StyledBar
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={progress}
          className="bar"
          role="progressbar"
          widthPercent={Math.floor(progress)}
        />
      </StyledBarContainer>
    );
  },
);

Progress.displayName = "Progress";
