import React from "react";
import { I18nProvider } from "@react-aria/i18n";
import { polymorphic } from "../../utils/ref";
import { Flex } from "../Layout/Flex";
import { VStack } from "../Layout/Stack";
import { Box } from "../Meta/Box";
import { styled } from "../../utils/system/factory";
import type { CalendarTimezone } from "./Calendar/hooks/useCalendar";
import useCalendar from "./Calendar/hooks/useCalendar";
import { CalendarGrid } from "./Calendar/CalendarGrid";
import { CalendarHeader } from "./Calendar/CalendarHeader";
import { CalendarProvider } from "./Calendar/CalendarContext";

export type CalendarType = "double" | "single";

export type CalendarProps = {
  locale?: string;
  onChange: (date: number) => void;
  timeZone?: CalendarTimezone;
  type?: CalendarType;
  value?: number;
};

type CalendarContentProps = {
  dataTestId?: string;
  isDisabled?: boolean;
  locale: string;
  shouldDisableDate?: (date: number) => boolean;
  type: CalendarType;
} & Omit<CalendarProps, "locale">;

type StyledCalendarContentProps = {
  type: CalendarType;
};

export const calendarDimensions = {
  borderWidth: 1,
  cellWidth: 36,
  doubleGap: 24,
  weekNumberWidth: 36,
} as const;

const computeCalendarWidth = (type: CalendarType) => {
  const { borderWidth, cellWidth, doubleGap, weekNumberWidth } =
    calendarDimensions;

  if (type === "single") {
    return `calc(${cellWidth}px * 7 + 2 * ${borderWidth}px + ${weekNumberWidth}px)`;
  }

  return `calc(${cellWidth}px * 7 * 2 + ${doubleGap}px + 4 * ${borderWidth}px + ${weekNumberWidth}px * 2)`;
};

const StyledCalendarContent = styled<typeof VStack, StyledCalendarContentProps>(
  VStack,
  {
    base: ({ theme, type }) => ({
      color: theme.colors.text.primary,
      gap: 8,
      width: computeCalendarWidth(type),
    }),
  },
);

const CalendarContent = polymorphic<"div", CalendarContentProps>(
  (
    {
      dataTestId = "CalendarContent",
      locale,
      onChange,
      shouldDisableDate,
      timeZone,
      type,
      value,
      ...rest
    },
    ref,
  ) => {
    const {
      calendarProps,
      endMonthName,
      endOfMonth,
      getWeeksInMonth,
      isSameMonth,
      isToday,
      nextButtonProps,
      prevButtonProps,
      selectedTimezone,
      startMonthName,
      state,
      useCalendarCell,
      useCalendarGrid,
      useFocusForAccessibility,
    } = useCalendar({ locale, timeZone, type, value });

    return (
      <CalendarProvider
        value={{
          endMonthName,
          endOfMonth,
          getWeeksInMonth,
          isSameMonth,
          isToday,
          locale,
          selectedTimezone,
          startMonthName,
          state,
          useCalendarCell,
          useCalendarGrid,
          useFocusForAccessibility,
          value,
        }}
      >
        <StyledCalendarContent
          data-testid={dataTestId}
          type={type}
          {...calendarProps}
          {...rest}
          ref={ref}
        >
          <CalendarHeader
            calendarType={type}
            nextButtonProps={nextButtonProps}
            prevButtonProps={prevButtonProps}
          />
          <Flex>
            <CalendarGrid
              onChange={onChange}
              shouldDisableDate={shouldDisableDate}
            />
            {type === "double" && (
              <Box marginLeft={calendarDimensions.doubleGap}>
                <CalendarGrid
                  offset={{ months: 1 }}
                  onChange={onChange}
                  shouldDisableDate={shouldDisableDate}
                />
              </Box>
            )}
          </Flex>
        </StyledCalendarContent>
      </CalendarProvider>
    );
  },
);

export const Calendar = polymorphic<"div", CalendarProps>(
  ({ locale = "en-GB", type = "single", ...rest }, ref) => (
    <I18nProvider locale={locale}>
      <CalendarContent locale={locale} ref={ref} type={type} {...rest} />
    </I18nProvider>
  ),
);

Calendar.displayName = "Calendar";
