import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { clampElements, filterValidChildren } from "../../../utils/children";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { contextFactory } from "../../../utils/contextFactory";
import { Body } from "../../Typography/Body";
import type { AvatarSize } from "./sizes";
import { avatarSizes } from "./sizes";

type Props = {
  max?: number;
  size?: AvatarSize;
};

const Excess = styled<typeof Body, { avatarSize: AvatarSize }>(Body, {
  base: ({ avatarSize, theme }) => {
    const dimensions = avatarSizes[avatarSize];
    const isLarge = avatarSize === "lg";
    const { fontSize, fontWeight, lineHeight } = isLarge
      ? theme.body.md
      : theme.body.sm;

    return {
      alignItems: "center",
      background: theme.colors.neutral["10"],
      borderRadius: theme.radius.round,
      color: theme.colors.neutral.base,
      display: "flex",
      fontSize,
      fontWeight,
      height: `${dimensions}px`,
      justifyContent: "center",
      lineHeight,
      marginLeft: `-${avatarSize === "lg" ? 12 : 8}px`,
      minHeight: `${dimensions}px`,
      minWidth: `${dimensions}px`,
      width: `${dimensions}px`,
      zIndex: 1,
    };
  },
});

const StyledGroup = styled<"div", { size: AvatarSize }>("div", {
  base: ({ size }) => ({
    "& * + *": {
      marginLeft: `-${size === "lg" ? 12 : 8}px`,
    },
    display: "flex",
  }),
});

type AvatarGroupContext = {
  size?: AvatarSize;
};

const [AvatarGroupProvider, useAvatarGroupContext] =
  contextFactory<AvatarGroupContext>({
    displayName: "AvatarGroupProvider",
    isOptional: true,
  });

export const AvatarGroup = polymorphic<"div", Props>(
  ({ children, max, size = "md", ...rest }, ref) => {
    const validChildren = filterValidChildren(children);
    const clampedChildren = clampElements(validChildren, 0, max);

    const excess = isDefined(max) ? validChildren.length - max : 0;

    return (
      <AvatarGroupProvider value={{ size }}>
        <StyledGroup ref={ref} size={size} {...rest}>
          {clampedChildren.reverse()}
          {Boolean(isDefined(excess) && excess > 0) && (
            <Excess avatarSize={size} size={size === "lg" ? "md" : "sm"}>
              +{excess}
            </Excess>
          )}
        </StyledGroup>
      </AvatarGroupProvider>
    );
  },
);

AvatarGroup.displayName = "AvatarGroup";

export { useAvatarGroupContext };
