import { first, uniqBy } from "lodash-es";
import { OvrseaDataError } from "../../errors/OvrseaDataError";
import { convertKgToLbs, convertLbsToKg } from "../../conversions";
import roundValue from "../../roundValue";
import type { LoadType, WeightUnit, WeightUnitFullName } from "../loadUtils";
import { mapperWeightUnitShortForm } from "../loadUtils";
import type { EnumOrLiteral } from "../../typescriptHelpers/enumOrTypeLiteral";
import { isNotDefined } from "../../other/isNotDefined";

export type Load = {
  loadType: EnumOrLiteral<LoadType>;
  totalWeight?: null | number;
  unitNumber?: null | number;
  unitWeight?: null | number;
  weightUnit: WeightUnit;
};

export const sumWeights = (summedWeight: number, load: Load): number => {
  if (["full_truck", "vrac"].includes(load.loadType)) {
    return summedWeight + Number(load.totalWeight);
  }
  if (isNotDefined(load.unitNumber) || isNotDefined(load.unitWeight)) {
    throw new OvrseaDataError(
      `Standard load missing feature (unitNumber or unitWeight): ${JSON.stringify(
        load,
        null,
        2,
      )}`,
    );
  }

  return summedWeight + load.unitWeight * load.unitNumber;
};

export const checkWeightUnitUnicity = (loads: Load[]) => {
  if (uniqBy(loads, (e) => e.weightUnit).length !== 1) {
    throw new Error(
      `The loads must all have one unit and all the same, here loads have:${loads
        .map((load) => load.weightUnit)
        .join(",")}`,
    );
  }
};

export const convertFromBaseToTargetWeightUnit =
  ({
    base,
    target,
  }: {
    base: WeightUnitFullName;
    target: WeightUnitFullName;
  }) =>
  (weight: number) => {
    if (base === target) {
      return weight;
    }
    if (base === "kilogram") {
      return convertKgToLbs(weight);
    }

    return convertLbsToKg(weight);
  };

export const computeTotalWeight = ({
  loads,
  roundedValue,
  targetUnit,
}: {
  loads: Load[];
  roundedValue?: boolean;
  targetUnit: WeightUnitFullName;
}) => {
  const firstLoad = first(loads);

  if (!firstLoad) {
    return 0;
  }

  const currentUnit = mapperWeightUnitShortForm[firstLoad.weightUnit];

  checkWeightUnitUnicity(loads);
  const totalWeightInCurrentUnit = loads.reduce(sumWeights, 0);
  const convertedTotalWeight = convertFromBaseToTargetWeightUnit({
    base: currentUnit,
    target: targetUnit,
  })(totalWeightInCurrentUnit);

  return roundedValue
    ? roundValue(convertedTotalWeight, 3)
    : convertedTotalWeight;
};
