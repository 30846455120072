import React from "react";
import { styled } from "../../utils/system/factory";
import { polymorphic } from "../../utils/ref";
import { Box } from "../Meta/Box";
import type { Sx } from "../../utils/types/system";

const StyledBody = styled("div", {
  base: {
    filter: "blur(2px)",
    pointerEvents: "none",
    userSelect: "none",
  },
});

type Props = {
  centerElement?: React.ReactNode;
};

const centerElementSx: Sx = {
  left: "50%",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

export const Blur = polymorphic<"div", Props>(
  ({ centerElement, children, ...rest }, ref) => {
    return (
      <Box ref={ref} sx={{ position: "relative" }}>
        <StyledBody {...rest}>{children}</StyledBody>
        <Box sx={centerElementSx}>{centerElement}</Box>
      </Box>
    );
  },
);
