// USED FOR ONTRUCK API, ONLY ACCPETING EUROPEAN COUNTRIES
import strcmp from "../other/strcmp";

export const europeanCountryCodes = [
  "AL",
  "AD",
  "AM",
  "AT",
  "BY",
  "BE",
  "BA",
  "BG",
  "CH",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FO",
  "FI",
  "FR",
  "GB",
  "GE",
  "GI",
  "GR",
  "HU",
  "HR",
  "IE",
  "IS",
  "IT",
  "LT",
  "LU",
  "LV",
  "MC",
  "MK",
  "MT",
  "NO",
  "NL",
  "PO",
  "PT",
  "RO",
  "RU",
  "SE",
  "SI",
  "SK",
  "SM",
  "TR",
  "UA",
  "VA",
];

const isEuropeanCountryCode = (countryCode: string): boolean =>
  europeanCountryCodes.some(
    (europeanCountryCode) =>
      countryCode && strcmp(countryCode, europeanCountryCode) === 0,
  );

export default isEuropeanCountryCode;
