import type { CSSProperties, SyntheticEvent } from "react";
import React from "react";
import { isNotDefined, selectFileExtension } from "@ovrsea/ovrutils";
import { Body } from "../../Typography/Body";
import type { CenterProps } from "../../Layout/Center";
import { Center } from "../../Layout/Center";
import { polymorphic } from "../../../utils/ref";
import { Visibility } from "../../Layout/Visibility";
import type { BoxProps } from "../../Meta/Box";
import type { Sx } from "../../../../src/utils/types/system";
import { styled } from "../../../utils/system/factory";
import { useDocumentContext } from "./DocumentContext";

const StyledActions = styled("div", {
  base: {
    alignItems: "center",
    display: "inline-flex",
  },
});

const DocumentActions = polymorphic<"div", object>((props, ref) => {
  const { shouldShowActionsOnHover } = useDocumentContext();

  const Component = shouldShowActionsOnHover ? Visibility.MouseOn : "div";

  return (
    <StyledActions
      as={Component}
      gap={4}
      onClick={(event: SyntheticEvent) => event.stopPropagation()}
      ref={ref}
      {...props}
    />
  );
});

DocumentActions.displayName = "DocumentActions";

const StyledExtension = styled(Center, {
  base: ({ theme }) => ({
    background: theme.colors.neutral.light,
    borderRadius: theme.radius.lg,
    color: theme.colors.neutral["60"],
    flexShrink: 0,
    fontSize: theme.font.size.xs,
    fontWeight: theme.font.weight.medium,
    height: theme.spacing[48],
    marginRight: theme.spacing[8],
    textTransform: "uppercase",
    transitionDuration: theme.transition.duration.slow,
    transitionProperty: "all",
    transitionTimingFunction: theme.transition.easing.color,
    userSelect: "none",
    width: theme.spacing[48],
  }),
});

const DocumentExtensionCard = polymorphic<
  "div",
  { fileName: string } & CenterProps
>(({ children, fileName, ...rest }, ref) => {
  const extension = selectFileExtension(fileName);

  const trimmedExtension = extension?.toLowerCase().substr(0, 3);

  const content = children ?? trimmedExtension;

  if (isNotDefined(content)) {
    return null;
  }

  return (
    <StyledExtension className="extension" {...rest} ref={ref}>
      {content}
    </StyledExtension>
  );
});

const DocumentExtension = polymorphic<"div", CenterProps>(
  ({ children, ...rest }, ref) => {
    const { name } = useDocumentContext();

    return <DocumentExtensionCard {...rest} fileName={name} ref={ref} />;
  },
);

DocumentExtension.displayName = "DocumentExtension";

type DocumentNameProps = {
  textSx?: Sx;
} & BoxProps;

const DocumentName = polymorphic<"div", DocumentNameProps>(
  ({ children, textSx, ...rest }, ref) => {
    const { name } = useDocumentContext();

    return (
      <styled.div {...rest} ref={ref}>
        <Body isTruncated size="sm" sx={textSx} variant="medium">
          {name}
        </Body>
        {children}
      </styled.div>
    );
  },
);

DocumentName.displayName = "DocumentName";

const DocumentDate = styled("div", {
  base: ({ theme }) => ({
    color: theme.colors.text.secondary,
    fontSize: theme.font.size.sm,
  }),
});

DocumentDate.displayName = "DocumentDate";

type _DocumentGridProps = {
  templateColumns?: CSSProperties["gridTemplateColumns"];
};

const DocumentGrid = styled<"div", _DocumentGridProps>("div", {
  base: ({ templateColumns = "repeat(2, 1fr)", theme }) => ({
    display: "grid",
    gridGap: theme.spacing[16],
    gridTemplateColumns: templateColumns,
    width: "100%",
  }),
});

DocumentGrid.displayName = "DocumentGrid";

export {
  DocumentActions,
  DocumentDate,
  DocumentExtension,
  DocumentExtensionCard,
  DocumentGrid,
  DocumentName,
};
