/* eslint-disable perfectionist/sort-objects */
import { mapValues } from "lodash-es";
import { createMediaQuery } from "../utils/system/mediaQueries";
import { colors } from "./colors";

const screens = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

const below = mapValues(screens, (breakpoint) => createMediaQuery(breakpoint));
const above = mapValues(screens, (breakpoint) =>
  createMediaQuery(breakpoint, ">="),
);

export const theme = {
  below,
  above,
  screens: {
    sm: screens.sm,
    md: screens.md,
    lg: screens.lg,
    xl: screens.xl,
    "2xl": screens["2xl"],
    tablet: "var(--screens-sm)",
    laptop: "var(--screens-lg)",
    desktop: "var(--screens-xl)",
  },
  spacing: {
    0: 0,
    1: "0.0625rem",
    4: "0.25rem",
    8: "0.5rem",
    12: "0.75rem",
    16: "1rem",
    20: "1.25rem",
    24: "1.5rem",
    32: "2rem",
    40: "2.5rem",
    48: "3rem",
    56: "3.5rem",
    64: "4rem",
    72: "4.5rem",
    80: "5rem",
    96: "6rem",
  },

  zIndex: {
    hide: -1,
    base: 0,
    nav: 1000,
    header: 1100,
    modal: 1400,
    popover: 1500,
    tooltip: 1800,
  },

  transition: {
    easing: {
      base: "ease-in-out",
      opacity: "linear",
      color: "ease",
      translateX: "cubic-bezier(0.4, 0, 0.2, 1)",
    },
    duration: {
      fast: "100ms",
      base: "150ms",
      slow: "200ms",
      slower: "300ms",
    },
  },

  font: {
    weight: {
      base: 400,
      medium: 500,
      bold: 700,
    },

    size: {
      /** 10px */ xxs: "0.625rem",
      /** 12px */ xs: "0.75rem",
      /** 14px */ sm: "0.875rem",
      /** 16px */ md: "1rem",
      /** 18px */ lg: "1.125rem",
      /** 20px */ xl: "1.25rem",
      /** 24px */ "2xl": "1.5rem",
      /** 32px */ "3xl": "2rem",
      /** 40px */ "4xl": "2.5rem",
      /** 48px */ "5xl": "3rem",
    },

    family: {
      base: '"Inter","Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif',
    },
  },

  // component aliases
  heading: {
    xs: {
      fontSize: "var(--font-size-xl)",
      fontWeight: "var(--font-weight-medium)",
      letterSpacing: "var(--letterSpacing-base)",
      lineHeight: "var(--lineHeight-lg)",
    },
    sm: {
      fontSize: "var(--font-size-2xl)",
      fontWeight: "var(--font-weight-medium)",
      letterSpacing: "var(--letterSpacing-base)",
      lineHeight: "var(--lineHeight-lg)",
    },
    md: {
      fontSize: "var(--font-size-3xl)",
      fontWeight: "var(--font-weight-base)",
      letterSpacing: "var(--letterSpacing-base)",
      lineHeight: "var(--lineHeight-xl)",
    },
    lg: {
      fontSize: "var(--font-size-4xl)",
      fontWeight: "var(--font-weight-base)",
      letterSpacing: "var(--letterSpacing-wide)",
      lineHeight: "var(--lineHeight-2xl)",
    },
    xl: {
      fontSize: "var(--font-size-5xl)",
      fontWeight: "var(--font-weight-base)",
      letterSpacing: "var(--letterSpacing-wide)",
      lineHeight: "var(--lineHeight-3xl)",
    },
  },

  body: {
    lg: {
      fontSize: "var(--font-size-lg)",
      fontWeight: "var(--font-weight-base)",
      lineHeight: "var(--lineHeight-lg)",
    },
    md: {
      fontSize: "var(--font-size-md)",
      fontWeight: "var(--font-weight-base)",
      lineHeight: "var(--lineHeight-md)",
    },
    sm: {
      fontSize: "var(--font-size-sm)",
      fontWeight: "var(--font-weight-medium)",
      lineHeight: "var(--lineHeight-md)",
    },
  },

  subtitle: {
    md: {
      fontSize: "var(--font-size-md)",
      fontWeight: "var(--font-weight-bold)",
      letterSpacing: "var(--letterSpacing-wide)",
      lineHeight: "var(--lineHeight-md)",
    },
    lg: {
      fontSize: "var(--font-size-xl)",
      fontWeight: "var(--font-weight-bold)",
      letterSpacing: "var(--letterSpacing-wide)",
      lineHeight: "var(--lineHeight-md)",
    },
  },

  caption: {
    xs: {
      fontWeight: "var(--font-weight-medium)",
      letterSpacing: "var(--letterSpacing-wide)",
      lineHeight: "var(--lineHeight-sm)",
      fontSize: "var(--font-size-xs)",
    },
    xxs: {
      fontWeight: "var(--font-weight-medium)",
      letterSpacing: "var(--letterSpacing-wide)",
      lineHeight: "var(--lineHeight-sm)",
      fontSize: "var(--font-size-xxs)",
    },
  },

  button: {
    md: {
      fontSize: "var(--font-size-md)",
    },
    sm: {
      fontSize: "var(--font-size-md)",
    },
    xs: {
      fontSize: "var(--font-size-sm)",
    },
  },

  lineHeight: {
    sm: "16px",
    md: "24px",
    lg: "32px",
    xl: "40px",
    "2xl": "48px",
    "3xl": "56px",
  },

  letterSpacing: {
    base: 0,
    wide: "0.4px",
  },

  radius: {
    none: "0",
    xs: "2px",
    sm: "3px",
    base: "4px",
    md: "5px",
    lg: "6px",
    xl: "8px",
    round: "9999px",
  },

  shadows: {
    md: "0 4px 6px -1px var(--colors-shadow), 0 2px 4px -2px var(--colors-shadow)",
    lg: "0 10px 15px -3px var(--colors-shadow), 0 4px 6px -4px var(--colors-shadow)",
  },

  colors,
};

export type Theme = typeof theme;
