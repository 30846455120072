import React from "react";
import type { LinkProps } from "../Link";
import { Link } from "../Link";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { mapPseudoFocusRing } from "../../../theme/commons";

type InjectedProps = {
  isCurrentPage?: boolean;
};

type Props = InjectedProps & LinkProps;

const StyledPageLink = styled("span", {
  base: ({ theme }) => {
    const { fontSize, fontWeight, lineHeight } = theme.body.sm;

    return [
      {
        alignItems: "center",
        color: theme.colors.neutral["50"],
        display: "inline-flex",
        fontSize,
        fontWeight,
        lineHeight,
        transition: "all 0.1s ease-in-out",
      },
      {
        "&:hover, &[aria-current='page']": {
          color: theme.colors.neutral.base,
        },
      },
      {
        "&[aria-current='page']": {
          fontWeight: theme.font.weight.bold,
        },
      },
    ];
  },
});

const StyledLink = styled(StyledPageLink, {
  base: ({ theme }) => ({
    "&, &:hover": {
      textDecoration: "none",
    },
    borderRadius: theme.radius.sm,
    ...mapPseudoFocusRing(theme),
  }),
});

const BreadcrumbLink = polymorphic<typeof Link, Props>(
  ({ as = Link, children, isCurrentPage, ...props }, ref) => {
    if (isCurrentPage) {
      return (
        <StyledPageLink aria-current="page" ref={ref}>
          {children}
        </StyledPageLink>
      );
    }

    return (
      <StyledLink as={as} ref={ref} {...props}>
        {children}
      </StyledLink>
    );
  },
);

BreadcrumbLink.displayName = "BreadcrumbLink";

export { BreadcrumbLink };
