import type { ReactNode } from "react";
import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import type { TooltipProps } from "../../Overlay/Tooltip";
import { Tooltip } from "../../Overlay/Tooltip";
import type { SystemProps } from "../../../utils/types/system";
import { polymorphic } from "../../../utils/ref";
import { Body } from "../../Typography/Body";
import type { TimelineDirection, TimelineItemColor } from "../Timeline";
import { Progress } from "../../Feedback/Progress";
import { styled } from "../../../utils/system/factory";
import type { Theme } from "../../../theme/theme";
import { useOvrseaTheme } from "../../../theme/useTheme";
import { Center } from "../../Layout/Center";

const dotColors = (theme: Theme) =>
  ({
    green: {
      background: theme.colors.alert.successLight,
      color: theme.colors.alert.success,
    },
    grey: {
      background: theme.colors.neutral.light,
      color: theme.colors.neutral["40"],
    },
  }) as const;

export type DotColor = keyof ReturnType<typeof dotColors>;

type _TimelineItemProps = {
  dataTestId?: string;
  direction?: TimelineDirection;
  dotColor?: DotColor;
  dotLabel?: ReactNode;
  hideLine?: boolean;
  lineColor?: TimelineItemColor;
  progress?: number;
  showDot?: boolean;
};

const StyledTimelineItem = styled("ul", {
  base: {
    "&:last-child .line": {
      display: "none",
    },
    listStyle: "none",
    position: "relative",
  },
});

const StyledDotLabel = styled<"div">("div", {
  base: ({ theme }) => ({
    color: theme.colors.text.secondary,
    marginTop: "4rem",
    textAlign: "center",
    whiteSpace: "nowrap",
  }),
});

const TimelineDotLabel = polymorphic<"div", SystemProps<any>>((props, ref) => (
  <StyledDotLabel {...props} ref={ref} />
));

TimelineDotLabel.displayName = "TimelineDotLabel";

type _TimelineLineProps = {
  color?: TimelineItemColor;
  direction: TimelineDirection;
  progress?: number;
};

const StyledLine = styled<
  "div",
  { lineColor: string; lineDirection: TimelineDirection }
>("div", {
  base: ({ lineColor, lineDirection, theme }) => [
    {
      height: 3,
    },
    lineDirection === "horizontal" && {
      "&.progress .bar": {
        backgroundColor: lineColor,
      },
    },
    lineDirection === "vertical" && {
      borderColor: "currentColor",
      borderLeftStyle: "solid",
      borderLeftWidth: 2,
      borderRadius: theme.radius.sm,
      color: lineColor,
      position: "absolute",
    },
  ],
});

const lineColorsMap = (theme: Theme) =>
  ({
    horizontal: {
      green: theme.colors.primary.base,
      grey: "unset",
    },
    vertical: {
      green: theme.colors.primary.base,
      grey: theme.colors.neutral["30"],
    },
  }) as const;

export const TimelineLine = polymorphic<"div", _TimelineLineProps>(
  ({ color = "grey", direction, progress = 100, ...rest }, ref) => {
    const theme = useOvrseaTheme();
    const lineColor = lineColorsMap(theme)[direction][color];

    if (direction === "horizontal") {
      return (
        <Progress
          sx={{ "& .bar": { backgroundColor: lineColor } }}
          {...rest}
          progress={progress}
          ref={ref}
        />
      );
    }

    return (
      <StyledLine
        className="line"
        lineColor={lineColor}
        lineDirection={direction}
        ref={ref}
      />
    );
  },
);

TimelineLine.displayName = "TimelineLine";

const StyledDot = styled<typeof Center, { dotColor: DotColor }>(Center, {
  base: ({ dotColor, theme }) => {
    const { background, color } = dotColors(theme)[dotColor];

    return {
      background,
      border: `2px solid ${color}`,
      borderRadius: theme.radius.round,
      height: 12,
      left: 0,
      position: "absolute",
      top: 3,
      width: 12,
    };
  },
});

type _TimelineDotProps = {
  color?: DotColor;
  tooltipLabel?: TooltipProps["label"];
  tooltipOffset?: TooltipProps["offset"];
  tooltipPosition?: TooltipProps["position"];
} & TooltipProps;

export const TimelineDot = polymorphic<"div", _TimelineDotProps>(
  (
    { color = "green", tooltipLabel, tooltipOffset, tooltipPosition, ...rest },
    ref,
  ) => (
    <Tooltip
      label={tooltipLabel}
      offset={tooltipOffset}
      openDelay={250}
      position={tooltipPosition}
    >
      <StyledDot className="dot" dotColor={color} {...rest} ref={ref} />
    </Tooltip>
  ),
);

TimelineDot.displayName = "TimelineDot";

export const TimelineItem = polymorphic<"li", _TimelineItemProps>(
  (
    {
      children,
      dataTestId = "TimelineItem",
      direction = "vertical",
      dotColor,
      dotLabel,
      hideLine,
      lineColor,
      onClick,
      progress,
      showDot,
      ...rest
    },
    ref,
  ) => (
    <StyledTimelineItem
      className="item"
      data-testid={dataTestId}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      {!hideLine && lineColor && (
        <TimelineLine
          color={lineColor}
          direction={direction}
          progress={progress}
        />
      )}
      {showDot && (
        <TimelineDot color={dotColor}>
          {isDefined(dotLabel) && <StyledDotLabel>{dotLabel}</StyledDotLabel>}
        </TimelineDot>
      )}
      {children}
    </StyledTimelineItem>
  ),
);

TimelineItem.displayName = "TimelineItem";

export const TimelineItemHeader = styled("header", {
  base: ({ theme }) => ({
    color: theme.colors.neutral.base,
    fontWeight: theme.font.weight.medium,
    marginBottom: 1,
  }),
});

TimelineItemHeader.displayName = "TimelineItemHeader";

type _TimelineItemContentProps = {
  secondary?: boolean;
};

export const TimelineItemContent = styled<"div", _TimelineItemContentProps>(
  "div",
  {
    base: ({ secondary, theme }) => ({
      color: secondary
        ? theme.colors.text.secondary
        : theme.colors.text.primary,
      padding: "5px 0 18px",
      position: "relative",
    }),
  },
);

TimelineItemContent.displayName = "TimelineItemContent";

type _TimelineIconProps = {
  dimension?: string;
};

const StyledIconBlock = styled<"div", { dimension: string }>("div", {
  base: ({ dimension }) => ({
    "& .icon": {
      margin: 0,
    },
    height: dimension,
    left: 0,
    position: "absolute",
    top: -4,
    width: dimension,
  }),
});

export const TimelineIcon = polymorphic<"div", _TimelineIconProps>(
  ({ children, dimension = "30px", ...rest }, ref) => {
    return (
      <StyledIconBlock
        className="icon-block"
        dimension={dimension}
        ref={ref}
        {...rest}
      >
        {children}
      </StyledIconBlock>
    );
  },
);

TimelineIcon.displayName = "TimelineIcon";

type _TimelineEventProps = {
  description?: ReactNode;
};

const Event = styled("div", {
  base: {
    paddingBottom: 3,
  },
});

export const TimelineEvent = polymorphic<"div", _TimelineEventProps>(
  ({ children, description, ...rest }, ref) => (
    <Event ref={ref} {...rest}>
      <span>{children}</span>
      {isDefined(description) && (
        <>
          {" • "}
          <Body isInline isSecondary>
            {description}
          </Body>
        </>
      )}
    </Event>
  ),
);

TimelineEvent.displayName = "TimelineEvent";
