import { includes } from "lodash-es";
import type { KronosCategory as KronosCategoryGenerated } from "../sharedTypes";

const KRONOS_CATEGORIES = [
  "arrival_fees",
  "arrival_truck_freight",
  "departure_fees",
  "departure_truck_freight",
  "freight",
  "insurance",
] as const;

export type KronosCategory = (typeof KRONOS_CATEGORIES)[number];

const kronosCategoriesMap: Record<KronosCategoryGenerated, KronosCategory> = {
  arrival_fees: "arrival_fees",
  arrival_truck_freight: "arrival_truck_freight",
  departure_fees: "departure_fees",
  departure_truck_freight: "departure_truck_freight",
  freight: "freight",
  insurance: "insurance",
};

export const isKronosCategory = (str: string): str is KronosCategory =>
  includes(Object.keys(kronosCategoriesMap), str);

export const KRONOS_CATEGORIES_TO_LABELS: { [key in KronosCategory]: string } =
  {
    arrival_fees: "Arrival Fees",
    arrival_truck_freight: "On-Carriage",
    departure_fees: "Departure Fees",
    departure_truck_freight: "Pre-Carriage",
    freight: "Freight",
    insurance: "Insurance",
  };
