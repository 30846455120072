import { useEffect } from "react";
import { usePresence } from "framer-motion";
import { isDefined } from "@ovrsea/ovrutils";

const useRemovePresence = () => {
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    if (!isPresent && isDefined(safeToRemove)) {
      setTimeout(safeToRemove);
    }
  }, [isPresent, safeToRemove]);
};

export { useRemovePresence };
