import type { SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { normalizedSearch } from "@ovrsea/ovrutils";
import { noop } from "../../../utils/events";
import type { SelectOptionType } from "../Shared/types";

type Params = {
  filterBy?: (query: string) => (option: SelectOptionType) => boolean;
  handleFocusInput: () => void;
  isClearable?: boolean;
  isCreatable: boolean;
  onChange: (value: string) => void;
  onChangeError?: (value?: string) => void;
  onSearchChangeProp?: (value: string) => void;
  options: SelectOptionType[];
  setActiveIndex: (index: number) => void;
  setClosed: () => void;
  setOpened: () => void;
  sortBy?: (
    query: string,
  ) => (a: SelectOptionType, b: SelectOptionType) => number;
  value?: string;
};

const defaultFilterBy = (query: string) => (option: SelectOptionType) => {
  if (typeof option.description === "string") {
    return Boolean(
      normalizedSearch(option.label, query) ??
        normalizedSearch(option.description, query),
    );
  }

  return Boolean(normalizedSearch(option.label, query));
};

export const useSelectFilter = ({
  filterBy = defaultFilterBy,
  handleFocusInput,
  isClearable,
  isCreatable,
  onChange,
  onChangeError,
  onSearchChangeProp = noop,
  options,
  setActiveIndex,
  setClosed,
  setOpened,
  sortBy,
  value,
}: Params) => {
  const [createdOptions, setCreatedOptions] = useState<SelectOptionType[]>([]);

  const allOptions = options.concat(createdOptions);

  useEffect(() => {
    if (onChangeError && value) {
      if (!allOptions.some((option) => option.value === value)) {
        onChangeError(value);
      }
    }
  }, [value, options]);

  const selectedOption = allOptions.find((item) => item.value === value);

  const [searchQuery, setSearch] = useState("");

  const unSortedFilteredOptions = allOptions.filter(filterBy(searchQuery));
  const filteredOptions = sortBy
    ? unSortedFilteredOptions.slice().sort(sortBy(searchQuery))
    : unSortedFilteredOptions;

  const exactSearchMatch = filteredOptions.find(
    (option) => option.label.toLowerCase() === searchQuery.toLowerCase(),
  );

  const onSearchChange = (value: string) => {
    const filteredOptions = allOptions.filter(filterBy(value));

    // if there's at least one result, we set the first one to active.
    // it enables users to have fewer keyboard interactions
    if (filteredOptions.length) {
      setActiveIndex(0);
    } else {
      setActiveIndex(-1);
    }

    onSearchChangeProp(value);
    setSearch(value);

    if (value.length) {
      setOpened();
    }
  };

  const handleCreate = () => {
    const created = {
      label: searchQuery,
      value: searchQuery,
    };

    setCreatedOptions((options) => options.concat(created));
    onChange(searchQuery);
    onSearchChange("");
    handleFocusInput();
    setClosed();
  };

  const handleReset = (event: SyntheticEvent, stopPropagation = true) => {
    if (stopPropagation) {
      event.stopPropagation();
    }

    if (isClearable) {
      onSearchChange("");

      // @ts-expect-error typescript limitation: onChange/isClearable is typed in Select but can't be passed down to hooks
      onChange(null);
      setTimeout(handleFocusInput, 0);
      setClosed();
    }
  };

  const canCreate =
    isCreatable &&
    Boolean(searchQuery) &&
    (!filteredOptions.length ||
      !filteredOptions.some(
        ({ label }) => label.toLowerCase() === searchQuery.toLowerCase(),
      ));

  return {
    allOptions,
    canCreate,
    exactSearchMatch,
    filteredOptions,
    handleCreate,
    handleReset,
    onSearchChange,
    searchQuery,
    selectedOption,
  };
};
