import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import type { IconName } from "../Typography/Icon";
import type { BaseInputProps } from "./Input/BaseInput";
import { BaseInput } from "./Input/BaseInput";

type InputType =
  | "email"
  | "money"
  | "number"
  | "password"
  | "phone"
  | "search"
  | "text"
  | "time";

export type InputProps = {
  type?: InputType;
} & Omit<BaseInputProps, "leftIcon">;

const iconNames: Record<InputType, IconName | undefined> = {
  email: "envelope",
  money: "dollar fill",
  number: undefined,
  password: undefined,
  phone: "phone",
  search: "search",
  text: undefined,
  time: "clock",
};

const computeInputType = (type: InputType): SystemProps<"input">["type"] => {
  switch (type) {
    case "phone":
      return "tel";
    case "time":
      return "text";
    case "email":
      return "email";
    case "money":
    case "number":
      return "number";

    default:
      return type;
  }
};

export const Input = polymorphic<"div", InputProps>(
  ({ type = "text", ...props }, ref) => (
    <BaseInput
      leftIcon={iconNames[type]}
      ref={ref}
      type={computeInputType(type)}
      {...props}
    />
  ),
);

Input.displayName = "Input";
