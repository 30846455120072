import React from "react";
import { dayjs } from "@ovrsea/ovrutils";
import { range } from "lodash-es";
import { styled } from "../../../utils/system/factory";
import { HStack } from "../../Layout/Stack";
import { Center } from "../../Layout/Center";
import { calendarDimensions } from "../Calendar";
import { Body } from "../../Typography/Body";
import { CalendarCell } from "./CalendarCell";
import { useCalendarContext } from "./CalendarContext";
import type { CalendarDate, DateDuration } from "./hooks/useCalendar";

type CalendarGridProps = {
  offset?: DateDuration;
  onChange: (date: number) => void;
  shouldDisableDate?: (date: number) => boolean;
};

const StyledDay = styled<typeof Center>(Center, {
  base: ({ theme }) => [
    {
      color: theme.colors.text.secondary,
      fontSize: theme.font.size.xs,
      fontWeight: theme.font.weight.medium,
      height: calendarDimensions.cellWidth,
      lineHeight: theme.lineHeight.sm,
      width: calendarDimensions.cellWidth,
    },
  ],
});

const StyledGrid = styled<"div">("div", {
  base: () => ({
    display: "grid",
    gridTemplateColumns: `36px repeat(7, ${calendarDimensions.cellWidth}px)`,
  }),
});

const StyledWeekNumberContainer = styled<typeof Center>(Center, {
  base: () => ({
    height: calendarDimensions.cellWidth,
    width: calendarDimensions.cellWidth,
  }),
});

const StyledWeekNumber = styled<typeof Body>(Body, {
  base: ({ theme }) => ({
    color: theme.colors.neutral["40"],
    fontSize: theme.font.size.xxs,
    fontWeight: theme.font.weight.medium,
  }),
});

const extractWeekNumber = ({
  date,
  timezone,
}: {
  date: CalendarDate | null;
  timezone: string;
}) => (date ? dayjs(date.toDate(timezone)).format("W") : "");

const CalendarGrid = ({
  offset = {},
  onChange,
  shouldDisableDate,
}: CalendarGridProps) => {
  const {
    endOfMonth,
    getWeeksInMonth,
    selectedTimezone,
    state,
    useCalendarGrid,
  } = useCalendarContext();
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid({
    endDate,
    startDate,
  });

  const numberOfWeeksInThisMonth = getWeeksInMonth(startDate);

  return (
    <div {...gridProps}>
      <div {...headerProps}>
        <HStack justifyContent="center">
          <StyledDay sx={(theme) => ({ color: theme.colors.neutral["30"] })}>
            #
          </StyledDay>
          {weekDays.map((day, index) => (
            <StyledDay key={index}>{day.substring(0, 2)}</StyledDay>
          ))}
        </HStack>
      </div>
      <StyledGrid>
        {range(numberOfWeeksInThisMonth).map((weekIndex) => {
          const datesInWeek = state.getDatesInWeek(weekIndex, startDate);
          const date = datesInWeek[0] ?? null;

          return (
            <>
              <StyledWeekNumberContainer>
                <Center>
                  <StyledWeekNumber isSecondary>
                    {extractWeekNumber({
                      date,
                      timezone: selectedTimezone,
                    })}
                  </StyledWeekNumber>
                </Center>
              </StyledWeekNumberContainer>
              {datesInWeek.map((date) => {
                if (!date) {
                  return null;
                }

                const timestamp = date.toDate(selectedTimezone).getTime();

                return (
                  <CalendarCell
                    date={date}
                    disabled={shouldDisableDate?.(timestamp)}
                    key={`${date.day}-${date.month}-${date.year}`}
                    month={startDate}
                    onChange={onChange}
                  />
                );
              })}
            </>
          );
        })}
      </StyledGrid>
    </div>
  );
};

export { CalendarGrid };
