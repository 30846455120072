import { SHIPOWNER_IDS } from "../config";

export const alternativeFuelPriceOvrseaNames = [
  "LNG Surcharge -25",
  "Biofuel Surcharge -84",
  "B100 Surcharge -60",
  "Pre-Carriage (Electric Truck)",
  "On-Carriage (Electric Truck)",
] as const;

export type AlternativeFuelPriceOvrseaName =
  (typeof alternativeFuelPriceOvrseaNames)[number];

export const mapOvrseaNameToCo2Reduction: Record<
  AlternativeFuelPriceOvrseaName,
  number
> = {
  "B100 Surcharge -60": 0.6,
  "Biofuel Surcharge -84": 0.84,
  "LNG Surcharge -25": 0.25,
  "On-Carriage (Electric Truck)": 0.8,
  "Pre-Carriage (Electric Truck)": 0.8,
} as const;

export const alternativeFuelNames = [
  "LNG",
  "B100",
  "Biofuel",
  "Electric",
] as const;

export type AlternativeFuelName = (typeof alternativeFuelNames)[number];

export const mapOvrseaNameToAlternativeFuel: Record<
  AlternativeFuelPriceOvrseaName,
  AlternativeFuelName
> = {
  "B100 Surcharge -60": "B100",
  "Biofuel Surcharge -84": "Biofuel",
  "LNG Surcharge -25": "LNG",
  "On-Carriage (Electric Truck)": "Electric",
  "Pre-Carriage (Electric Truck)": "Electric",
};

export const isAlternativeFuelInOvrseaName =
  (ovrseaName: string) =>
  (alternativeFuelOvrseaName: AlternativeFuelPriceOvrseaName) =>
    ovrseaName.includes(alternativeFuelOvrseaName);

export const rateIsAlternativeFuel = (rate: { ovrseaName: string }) =>
  alternativeFuelPriceOvrseaNames.some(
    isAlternativeFuelInOvrseaName(rate.ovrseaName),
  );

export const extractAlterrnativeFuelFromOvrseaName = (ovrseaName: string) => {
  const alternativeFuelUsed = alternativeFuelPriceOvrseaNames.find(
    isAlternativeFuelInOvrseaName(ovrseaName),
  );

  if (!alternativeFuelUsed) {
    return null;
  }

  return mapOvrseaNameToAlternativeFuel[alternativeFuelUsed];
};

export const shipownersAlwaysUsingAlternativeFuel = [
  SHIPOWNER_IDS.ROAD_TRANS_SHIPOWNER_ID,
] as const;

export type ShipownerAlwaysUsingAlternativeFuel =
  (typeof shipownersAlwaysUsingAlternativeFuel)[number];

export const mapShipownerToAlternativeFuel: Record<
  ShipownerAlwaysUsingAlternativeFuel,
  AlternativeFuelPriceOvrseaName
> = {
  [SHIPOWNER_IDS.ROAD_TRANS_SHIPOWNER_ID]: "B100 Surcharge -60",
};

export const mapShipownerToReducedPriceOvrseaName: Record<
  ShipownerAlwaysUsingAlternativeFuel,
  string[]
> = {
  [SHIPOWNER_IDS.ROAD_TRANS_SHIPOWNER_ID]: ["Delivery", "Pickup"],
};

export const findCorrespondingAlternativeFuelOvrseaName = (
  ovrseaName: string,
): AlternativeFuelPriceOvrseaName | null =>
  alternativeFuelPriceOvrseaNames.find((fuelOvrseaName) =>
    ovrseaName.startsWith(fuelOvrseaName),
  ) ?? null;

type MinimalPrice = {
  shipownerId?: null | string;
};

export const isShipownerAlwaysUsingAlternativeFuel = <T extends MinimalPrice>(
  price: T,
): price is { shipownerId: ShipownerAlwaysUsingAlternativeFuel } & T =>
  !!price.shipownerId &&
  shipownersAlwaysUsingAlternativeFuel.includes(
    price.shipownerId as ShipownerAlwaysUsingAlternativeFuel,
  );

export const isOvrseaNameAffectedByShipownerReduction = ({
  category,
  shipownerId,
}: {
  category: string;
  shipownerId: ShipownerAlwaysUsingAlternativeFuel;
}) => mapShipownerToReducedPriceOvrseaName[shipownerId].includes(category);

export const findShipownerAlternativeFuel = (
  shipownerId: ShipownerAlwaysUsingAlternativeFuel,
) => mapShipownerToAlternativeFuel[shipownerId];
