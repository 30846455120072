import React from "react";
import type { Property } from "csstype";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import type { SpacingScale } from "../../utils/system/system";
import type { FlexProps } from "./Flex";
import { Flex } from "./Flex";

type StackDirection = Property.FlexDirection;

export type DirectionProp = {
  direction?: StackDirection;
};

export type SpacingProp = {
  spacing?: SpacingScale;
};

type Props = FlexProps & SpacingProp;

type _StackProps = DirectionProp & Props;

export type StackProps = _StackProps & SystemProps<"div">;

export const Stack = polymorphic<"div", _StackProps>(
  ({ children, direction = "row", isInline, spacing, ...rest }, ref) => (
    <Flex
      flexDirection={direction}
      gap={spacing}
      isInline={isInline}
      ref={ref}
      {...rest}
    >
      {children}
    </Flex>
  ),
);

Stack.displayName = "Stack";

export const HStack = polymorphic<"div", Props>((props, ref) => (
  <Stack {...props} direction="row" ref={ref} />
));

HStack.displayName = "HStack";

export const VStack = polymorphic<"div", Props>((props, ref) => (
  <Stack {...props} direction="column" ref={ref} />
));

VStack.displayName = "VStack";
