type Holiday = {
  countryCode: string;
  date: string;
  name: string;
  type:
    | "bank_holiday"
    | "federal_holiday"
    | "gazetted_holiday"
    | "joint_holiday"
    | "national_holiday"
    | "private_sector"
    | "public_holiday"
    | "statutory_holiday";
};

// From https://www.timeanddate.com/holidays
export const holidays: Record<string, Holiday[]> = {
  ar: [
    {
      countryCode: "ar",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-02-12T00:00:00",
      name: "Carnival / Shrove Monday",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-02-13T00:00:00",
      name: "Carnival / Shrove Tuesday / Pancake Day",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-03-24T00:00:00",
      name: "Memorial Day",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-04-01T00:00:00",
      name: "Tourist Bridge Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-04-02T00:00:00",
      name: "Day of the Veterans",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-05-01T00:00:00",
      name: "Labor Day / May Day",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-05-25T00:00:00",
      name: "National Day/May 1810 Revolution",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-06-17T00:00:00",
      name: "Commemoration of General Don Mart\u00edn Miguel de G\u00fcemes",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-06-20T00:00:00",
      name: "Flag Day",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-06-21T00:00:00",
      name: "Tourist Bridge Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-07-09T00:00:00",
      name: "Independence day",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-08-17T00:00:00",
      name: "San Mart\u00edn Day",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-10-11T00:00:00",
      name: "Tourist Bridge Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-10-12T00:00:00",
      name: "Day of Respect for Cultural Diversity",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-11-18T00:00:00",
      name: "National Sovereignty Day",
      type: "national_holiday",
    },
    {
      countryCode: "ar",
      date: "2024-12-08T00:00:00",
      name: "Immaculate Conception",
      type: "national_holiday",
    },
  ],
  au: [
    {
      countryCode: "au",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "au",
      date: "2024-01-26T00:00:00",
      name: "Australia Day",
      type: "national_holiday",
    },
    {
      countryCode: "au",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "national_holiday",
    },
    {
      countryCode: "au",
      date: "2024-04-01T00:00:00",
      name: "Easter Monday",
      type: "national_holiday",
    },
    {
      countryCode: "au",
      date: "2024-04-25T00:00:00",
      name: "ANZAC Day",
      type: "national_holiday",
    },
    {
      countryCode: "au",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "national_holiday",
    },
    {
      countryCode: "au",
      date: "2024-12-26T00:00:00",
      name: "Boxing Day",
      type: "national_holiday",
    },
  ],
  bd: [
    {
      countryCode: "bd",
      date: "2024-01-07T00:00:00",
      name: "Election Day",
      type: "national_holiday",
    },
    {
      countryCode: "bd",
      date: "2024-07-01T00:00:00",
      name: "July 1 Bank Holiday",
      type: "bank_holiday",
    },
    {
      countryCode: "bd",
      date: "2024-12-31T00:00:00",
      name: "New Year's Eve",
      type: "bank_holiday",
    },
  ],
  be: [
    {
      countryCode: "be",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "bank_holiday",
    },
    {
      countryCode: "be",
      date: "2024-03-31T00:00:00",
      name: "Easter Sunday",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-04-01T00:00:00",
      name: "Easter Monday",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-05-01T00:00:00",
      name: "Labor Day / May Day",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-05-09T00:00:00",
      name: "Ascension Day",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-05-10T00:00:00",
      name: "Day after Ascension Day",
      type: "bank_holiday",
    },
    {
      countryCode: "be",
      date: "2024-05-19T00:00:00",
      name: "Whit Sunday",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-05-20T00:00:00",
      name: "Whit Monday",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-07-21T00:00:00",
      name: "Belgian National Day",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-08-15T00:00:00",
      name: "Assumption of Mary",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-11-01T00:00:00",
      name: "All Saints' Day",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-11-11T00:00:00",
      name: "Armistice Day",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "national_holiday",
    },
    {
      countryCode: "be",
      date: "2024-12-26T00:00:00",
      name: "Boxing Day",
      type: "bank_holiday",
    },
  ],
  br: [
    {
      countryCode: "br",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-04-21T00:00:00",
      name: "Tiradentes Day",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-05-01T00:00:00",
      name: "Labor Day / May Day",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-09-07T00:00:00",
      name: "Independence Day",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-10-12T00:00:00",
      name: "Our Lady of Aparecida / Children's Day",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-11-02T00:00:00",
      name: "All Souls' Day",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-11-15T00:00:00",
      name: "Republic Proclamation Day",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-11-20T00:00:00",
      name: "Black Awareness Day",
      type: "national_holiday",
    },
    {
      countryCode: "br",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "national_holiday",
    },
  ],
  ca: [
    {
      countryCode: "ca",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-04-01T00:00:00",
      name: "Easter Monday",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-04-01T00:00:00",
      name: "Easter Monday",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-05-20T00:00:00",
      name: "Victoria Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-07-01T00:00:00",
      name: "Canada Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-08-05T00:00:00",
      name: "Civic/Provincial Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-09-02T00:00:00",
      name: "Labour Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-09-30T00:00:00",
      name: "National Day for Truth and Reconciliation",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-10-14T00:00:00",
      name: "Thanksgiving Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-10-14T00:00:00",
      name: "Thanksgiving Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-11-11T00:00:00",
      name: "Remembrance Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "statutory_holiday",
    },
    {
      countryCode: "ca",
      date: "2024-12-26T00:00:00",
      name: "Boxing Day",
      type: "statutory_holiday",
    },
  ],
  ch: [
    {
      countryCode: "ch",
      date: "2024-08-01T00:00:00",
      name: "Swiss National Day",
      type: "national_holiday",
    },
  ],
  cn: [
    {
      countryCode: "cn",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-02-10T00:00:00",
      name: "Chinese New Year",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-02-11T00:00:00",
      name: "Spring Festival Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-02-12T00:00:00",
      name: "Spring Festival Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-02-13T00:00:00",
      name: "Spring Festival Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-02-14T00:00:00",
      name: "Spring Festival Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-02-15T00:00:00",
      name: "Spring Festival Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-02-16T00:00:00",
      name: "Spring Festival Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-02-17T00:00:00",
      name: "Spring Festival Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-04-04T00:00:00",
      name: "Qing Ming Jie",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-04-05T00:00:00",
      name: "Qing Ming Jie holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-04-06T00:00:00",
      name: "Qing Ming Jie holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-05-01T00:00:00",
      name: "Labour Day",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-05-02T00:00:00",
      name: "Labour Day Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-05-03T00:00:00",
      name: "Labour Day Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-05-04T00:00:00",
      name: "Labour Day Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-05-05T00:00:00",
      name: "Labour Day Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-06-10T00:00:00",
      name: "Dragon Boat Festival",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-09-15T00:00:00",
      name: "Mid-Autumn Festival holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-09-16T00:00:00",
      name: "Mid-Autumn Festival holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-09-17T00:00:00",
      name: "Mid-Autumn Festival",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-10-01T00:00:00",
      name: "National Day",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-10-02T00:00:00",
      name: "National Day Golden Week Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-10-03T00:00:00",
      name: "National Day Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-10-04T00:00:00",
      name: "National Day Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-10-05T00:00:00",
      name: "National Day Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-10-06T00:00:00",
      name: "National Day Golden Week holiday",
      type: "national_holiday",
    },
    {
      countryCode: "cn",
      date: "2024-10-07T00:00:00",
      name: "National Day Golden Week holiday",
      type: "national_holiday",
    },
  ],
  de: [
    {
      countryCode: "de",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "de",
      date: "2024-05-01T00:00:00",
      name: "May Day",
      type: "national_holiday",
    },
    {
      countryCode: "de",
      date: "2024-10-03T00:00:00",
      name: "Day of German Unity",
      type: "national_holiday",
    },
    {
      countryCode: "de",
      date: "2024-12-31T00:00:00",
      name: "New Year's Eve",
      type: "bank_holiday",
    },
  ],
  dz: [
    {
      countryCode: "dz",
      date: "2024-01-01T00:00:00",
      name: "New Year",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-01-12T00:00:00",
      name: "Berber New Year",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-04-10T00:00:00",
      name: "Eid al-Fitr",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-04-11T00:00:00",
      name: "Eid al-Fitr Holiday",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-04-12T00:00:00",
      name: "Eid al-Fitr Holiday",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-05-01T00:00:00",
      name: "May Day",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-06-17T00:00:00",
      name: "Eid al-Adha (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-06-18T00:00:00",
      name: "Eid al-Adha Holiday (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-06-19T00:00:00",
      name: "Eid al-Adha Holiday (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-07-05T00:00:00",
      name: "Independence Day",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-07-08T00:00:00",
      name: "Muharram (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-07-17T00:00:00",
      name: "Day of Achura (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-09-16T00:00:00",
      name: "The Prophet's Birthday (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "dz",
      date: "2024-11-01T00:00:00",
      name: "Anniversary of the Revolution",
      type: "public_holiday",
    },
  ],
  es: [
    {
      countryCode: "es",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "es",
      date: "2024-01-06T00:00:00",
      name: "Epiphany",
      type: "national_holiday",
    },
    {
      countryCode: "es",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "national_holiday",
    },
    {
      countryCode: "es",
      date: "2024-05-01T00:00:00",
      name: "Labor Day / May Day",
      type: "national_holiday",
    },
    {
      countryCode: "es",
      date: "2024-08-15T00:00:00",
      name: "Assumption of Mary",
      type: "national_holiday",
    },
    {
      countryCode: "es",
      date: "2024-10-12T00:00:00",
      name: "Hispanic Day",
      type: "national_holiday",
    },
    {
      countryCode: "es",
      date: "2024-11-01T00:00:00",
      name: "All Saints' Day",
      type: "national_holiday",
    },
    {
      countryCode: "es",
      date: "2024-12-06T00:00:00",
      name: "Constitution Day",
      type: "national_holiday",
    },
    {
      countryCode: "es",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "national_holiday",
    },
  ],
  fr: [
    {
      countryCode: "fr",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-04-01T00:00:00",
      name: "Easter Monday",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-05-01T00:00:00",
      name: "Labor Day / May Day",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-05-08T00:00:00",
      name: "WWII Victory Day",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-05-09T00:00:00",
      name: "Ascension Day",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-05-20T00:00:00",
      name: "Whit Monday",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-07-14T00:00:00",
      name: "Bastille Day",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-08-15T00:00:00",
      name: "Assumption of Mary",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-11-01T00:00:00",
      name: "All Saints' Day",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-11-11T00:00:00",
      name: "Armistice Day",
      type: "national_holiday",
    },
    {
      countryCode: "fr",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "national_holiday",
    },
  ],
  id: [
    {
      countryCode: "id",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-02-08T00:00:00",
      name: "Ascension of the Prophet Muhammad",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-02-09T00:00:00",
      name: "Lunar New Year Joint Holiday",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-02-10T00:00:00",
      name: "Lunar New Year's Day",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-02-14T00:00:00",
      name: "Election Day",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-03-11T00:00:00",
      name: "Bali's Day of Silence and Hindu New Year (Nyepi)",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-03-12T00:00:00",
      name: "Joint Holiday for Bali's Day of Silence and Hindu New Year (Nyepi)",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-03-31T00:00:00",
      name: "Easter Sunday",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-04-08T00:00:00",
      name: "Idul Fitri Joint Holiday",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-04-09T00:00:00",
      name: "Idul Fitri Joint Holiday",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-04-10T00:00:00",
      name: "Idul Fitri",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-04-11T00:00:00",
      name: "Idul Fitri Holiday",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-04-12T00:00:00",
      name: "Idul Fitri Joint Holiday",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-04-15T00:00:00",
      name: "Idul Fitri Joint Holiday",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-05-01T00:00:00",
      name: "International Labor Day",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-05-09T00:00:00",
      name: "Ascension Day of Jesus Christ",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-05-10T00:00:00",
      name: "Joint Holiday after Ascension Day",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-05-23T00:00:00",
      name: "Waisak Day (Buddha's Anniversary)",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-05-24T00:00:00",
      name: "Joint Holiday for Waisak Day",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-06-01T00:00:00",
      name: "Pancasila Day",
      type: "national_holiday",
    },
    {
      countryCode: "id",
      date: "2024-06-17T00:00:00",
      name: "Idul Adha (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-06-18T00:00:00",
      name: "Joint Holiday for Idul Adha (Tentative Date)",
      type: "joint_holiday",
    },
    {
      countryCode: "id",
      date: "2024-07-07T00:00:00",
      name: "Muharram / Islamic New Year (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-08-17T00:00:00",
      name: "Indonesian Independence Day",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-09-15T00:00:00",
      name: "Maulid Nabi Muhammad (The Prophet Muhammad's Birthday) (Tentative Date)",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "public_holiday",
    },
    {
      countryCode: "id",
      date: "2024-12-26T00:00:00",
      name: "Boxing Day",
      type: "joint_holiday",
    },
  ],
  in: [
    {
      countryCode: "in",
      date: "2024-01-26T00:00:00",
      name: "Republic Day",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-03-25T00:00:00",
      name: "Holi",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-04-11T00:00:00",
      name: "Ramzan Id/Eid-ul-Fitar",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-04-17T00:00:00",
      name: "Rama Navami",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-04-21T00:00:00",
      name: "Mahavir Jayanti",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-05-23T00:00:00",
      name: "Buddha Purnima/Vesak",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-06-17T00:00:00",
      name: "Bakrid/Eid ul-Adha (Tentative Date)",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-07-17T00:00:00",
      name: "Muharram/Ashura (Tentative Date)",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-08-15T00:00:00",
      name: "Independence Day",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-08-26T00:00:00",
      name: "Janmashtami",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-09-16T00:00:00",
      name: "Milad un-Nabi/Id-e-Milad (Tentative Date)",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-10-02T00:00:00",
      name: "Mahatma Gandhi Jayanti",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-10-12T00:00:00",
      name: "Dussehra",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-10-31T00:00:00",
      name: "Diwali/Deepavali",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-11-15T00:00:00",
      name: "Guru Nanak Jayanti",
      type: "gazetted_holiday",
    },
    {
      countryCode: "in",
      date: "2024-12-25T00:00:00",
      name: "Christmas",
      type: "gazetted_holiday",
    },
  ],
  it: [
    {
      countryCode: "it",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-01-06T00:00:00",
      name: "Epiphany",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-03-31T00:00:00",
      name: "Easter Sunday",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-04-01T00:00:00",
      name: "Easter Monday",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-04-25T00:00:00",
      name: "Liberation Day",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-05-01T00:00:00",
      name: "Labor Day / May Day",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-06-02T00:00:00",
      name: "Republic Day",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-08-15T00:00:00",
      name: "Assumption of Mary",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-11-01T00:00:00",
      name: "All Saints' Day",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-12-08T00:00:00",
      name: "Feast of the Immaculate Conception",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "national_holiday",
    },
    {
      countryCode: "it",
      date: "2024-12-26T00:00:00",
      name: "St. Stephen's Day",
      type: "national_holiday",
    },
  ],
  jp: [
    {
      countryCode: "jp",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-01-01T00:00:00",
      name: "January 1 Bank Holiday",
      type: "bank_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-01-02T00:00:00",
      name: "January 2 Bank Holiday",
      type: "bank_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-01-03T00:00:00",
      name: "January 3 Bank Holiday",
      type: "bank_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-01-08T00:00:00",
      name: "Coming of Age Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-02-11T00:00:00",
      name: "National Foundation Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-02-12T00:00:00",
      name: "National Foundation Day observed",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-02-23T00:00:00",
      name: "Emperor's Birthday",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-03-20T00:00:00",
      name: "Spring Equinox",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-04-29T00:00:00",
      name: "Sh\u014dwa Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-05-03T00:00:00",
      name: "Constitution Memorial Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-05-04T00:00:00",
      name: "Greenery Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-05-05T00:00:00",
      name: "Children's Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-05-06T00:00:00",
      name: "Children's Day observed",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-07-15T00:00:00",
      name: "Sea Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-08-11T00:00:00",
      name: "Mountain Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-08-12T00:00:00",
      name: "Day off for Mountain Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-09-16T00:00:00",
      name: "Respect for the Aged Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-09-22T00:00:00",
      name: "Autumn Equinox",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-09-23T00:00:00",
      name: "Autumn Equinox observed",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-10-14T00:00:00",
      name: "Sports Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-11-03T00:00:00",
      name: "Culture Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-11-04T00:00:00",
      name: "Culture Day observed",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-11-23T00:00:00",
      name: "Labor Thanksgiving Day",
      type: "national_holiday",
    },
    {
      countryCode: "jp",
      date: "2024-12-31T00:00:00",
      name: "December 31 Bank Holiday",
      type: "bank_holiday",
    },
  ],
  mx: [
    {
      countryCode: "mx",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-02-05T00:00:00",
      name: "Constitution Day",
      type: "national_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-03-18T00:00:00",
      name: "Benito Ju\u00e1rez's Birthday Memorial",
      type: "national_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-03-28T00:00:00",
      name: "Maundy Thursday",
      type: "bank_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "bank_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-05-01T00:00:00",
      name: "Labor Day / May Day",
      type: "national_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-06-02T00:00:00",
      name: "General Election Day",
      type: "national_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-09-16T00:00:00",
      name: "Independence Day",
      type: "national_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-10-01T00:00:00",
      name: "Inauguration day",
      type: "national_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-11-18T00:00:00",
      name: "Revolution Day Memorial",
      type: "national_holiday",
    },
    {
      countryCode: "mx",
      date: "2024-12-12T00:00:00",
      name: "Day of the Virgin of Guadalupe",
      type: "bank_holiday",
    },
  ],
  nl: [
    {
      countryCode: "nl",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-03-31T00:00:00",
      name: "Easter Sunday",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-04-01T00:00:00",
      name: "Easter Monday",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-04-27T00:00:00",
      name: "King's Birthday",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-05-09T00:00:00",
      name: "Ascension Day",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-05-19T00:00:00",
      name: "Whit Sunday",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-05-20T00:00:00",
      name: "Whit Monday",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "public_holiday",
    },
    {
      countryCode: "nl",
      date: "2024-12-26T00:00:00",
      name: "Second Day of Christmas",
      type: "public_holiday",
    },
  ],
  pt: [
    {
      countryCode: "pt",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-03-31T00:00:00",
      name: "Easter Sunday",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-04-25T00:00:00",
      name: "Liberty Day",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-05-01T00:00:00",
      name: "Labor Day / May Day",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-05-30T00:00:00",
      name: "Corpus Christi",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-06-10T00:00:00",
      name: "Portugal Day",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-08-15T00:00:00",
      name: "Assumption of Mary",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-10-05T00:00:00",
      name: "Republic Day",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-11-01T00:00:00",
      name: "All Saints' Day",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-12-01T00:00:00",
      name: "Restoration of Independence",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-12-08T00:00:00",
      name: "Feast of the Immaculate Conception",
      type: "national_holiday",
    },
    {
      countryCode: "pt",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "national_holiday",
    },
  ],
  sg: [
    {
      countryCode: "sg",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-02-10T00:00:00",
      name: "Lunar New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-02-11T00:00:00",
      name: "Second Day of Lunar New Year",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-02-12T00:00:00",
      name: "Day off for Lunar New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-04-10T00:00:00",
      name: "Hari Raya Puasa",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-05-01T00:00:00",
      name: "Labour Day",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-05-22T00:00:00",
      name: "Vesak Day",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-06-17T00:00:00",
      name: "Hari Raya Haji (Tentative Date)",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-08-09T00:00:00",
      name: "National Day",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-10-31T00:00:00",
      name: "Diwali/Deepavali",
      type: "national_holiday",
    },
    {
      countryCode: "sg",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "national_holiday",
    },
  ],
  tw: [
    {
      countryCode: "tw",
      date: "2024-01-01T00:00:00",
      name: "Republic Day/New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-02-08T00:00:00",
      name: "Lunar New Year Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-02-09T00:00:00",
      name: "Lunar New Year's Eve",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-02-10T00:00:00",
      name: "Lunar New Year's Day",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-02-11T00:00:00",
      name: "Lunar New Year Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-02-12T00:00:00",
      name: "Lunar New Year Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-02-13T00:00:00",
      name: "Lunar New Year Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-02-14T00:00:00",
      name: "Lunar New Year Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-02-28T00:00:00",
      name: "Peace Memorial Day",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-04-04T00:00:00",
      name: "Tomb Sweeping Day",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-04-04T00:00:00",
      name: "Children's Day",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-04-05T00:00:00",
      name: "Children's Day/Tomb Sweeping Day Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-04-06T00:00:00",
      name: "Children's Day/Tomb Sweeping Day Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-04-07T00:00:00",
      name: "Children's Day/Tomb Sweeping Day Holiday",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-05-01T00:00:00",
      name: "Labor Day",
      type: "private_sector",
    },
    {
      countryCode: "tw",
      date: "2024-06-10T00:00:00",
      name: "Dragon Boat Festival",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-09-17T00:00:00",
      name: "Mid-Autumn Festival",
      type: "national_holiday",
    },
    {
      countryCode: "tw",
      date: "2024-10-10T00:00:00",
      name: "National Day",
      type: "national_holiday",
    },
  ],
  uk: [
    {
      countryCode: "uk",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "bank_holiday",
    },
    {
      countryCode: "uk",
      date: "2024-03-29T00:00:00",
      name: "Good Friday",
      type: "bank_holiday",
    },
    {
      countryCode: "uk",
      date: "2024-05-06T00:00:00",
      name: "Early May Bank Holiday",
      type: "bank_holiday",
    },
    {
      countryCode: "uk",
      date: "2024-05-27T00:00:00",
      name: "Spring Bank Holiday",
      type: "bank_holiday",
    },
    {
      countryCode: "uk",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "bank_holiday",
    },
    {
      countryCode: "uk",
      date: "2024-12-26T00:00:00",
      name: "Boxing Day",
      type: "bank_holiday",
    },
  ],
  us: [
    {
      countryCode: "us",
      date: "2024-01-01T00:00:00",
      name: "New Year's Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-01-15T00:00:00",
      name: "Martin Luther King Jr. Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-02-19T00:00:00",
      name: "Presidents' Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-05-27T00:00:00",
      name: "Memorial Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-06-19T00:00:00",
      name: "Juneteenth",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-07-04T00:00:00",
      name: "Independence Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-09-02T00:00:00",
      name: "Labor Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-10-14T00:00:00",
      name: "Columbus Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-11-11T00:00:00",
      name: "Veterans Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-11-28T00:00:00",
      name: "Thanksgiving Day",
      type: "federal_holiday",
    },
    {
      countryCode: "us",
      date: "2024-12-25T00:00:00",
      name: "Christmas Day",
      type: "federal_holiday",
    },
  ],
};
