import { contextFactory } from "../../../utils/contextFactory";

export type RadioGroupContextType = {
  isDisabled?: boolean;
  onChange: (value: string) => void;
  value: string;
};

export const [RadioGroupProvider, useRadioGroupContext] =
  contextFactory<RadioGroupContextType>({
    displayName: "RadioGroupProvider",
    errorMessage: "Radio components should be wrapped in `RadioGroup`.",
  });
