type EcoFreightScore = "A" | "B" | "C" | "D" | "E";

export const findEcoFreightScore = (co2Intensity: number): EcoFreightScore => {
  if (co2Intensity < 13) {
    return "A";
  }
  if (co2Intensity < 25) {
    return "B";
  }
  if (co2Intensity < 250) {
    return "C";
  }
  if (co2Intensity < 850) {
    return "D";
  }

  return "E";
};
