import type { Dispatch, KeyboardEvent, SetStateAction } from "react";
import { noop } from "../../../utils/events";
import type { SelectOptionType } from "../Shared/types";
import {
  computeNextIndex,
  computePreviousIndex,
  findSelectedIndex,
} from "../Shared/utils";
import type { ScrollToItem } from "../Shared/useSelectScroll";

type Space = " ";
type Keys =
  | "ArrowDown"
  | "ArrowUp"
  | "End"
  | "Enter"
  | "Escape"
  | "Home"
  | Space;

type KeyHandler = (event: KeyboardEvent<HTMLInputElement>) => void;

type KeyboardHandlersMap = Record<"fallback" | Keys, KeyHandler>;

type Params = {
  activeIndex: number;
  canCreate?: boolean;
  exactSearchMatch?: SelectOptionType;
  filteredOptions: SelectOptionType[];
  handleCreate: () => void;
  handleSelect: (value: string) => void;
  isOpened: boolean;
  isSearchable?: boolean;
  scrollToItem: ScrollToItem;
  scrollToSelectedItem: () => void;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  setClosed: () => void;
  setOpened: () => void;
  value?: string;
};

export const useSelectKeydown = ({
  activeIndex,
  canCreate,
  exactSearchMatch,
  filteredOptions,
  handleCreate,
  handleSelect,
  isOpened,
  isSearchable,
  scrollToItem,
  scrollToSelectedItem,
  setActiveIndex,
  setClosed,
  setOpened,
  value,
}: Params) => {
  const selectedIndex = findSelectedIndex(value)(filteredOptions);

  // adds an extra option count at the end when creatable to make it interactive
  const optionsCount = canCreate
    ? filteredOptions.length + 1
    : filteredOptions.length;

  const onArrowUp: KeyHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isOpened) {
      setActiveIndex((active) => {
        const previousIndex = computeNextIndex(
          selectedIndex,
          optionsCount,
        )(active);

        const isNotActiveIndex = previousIndex !== active;

        if (isNotActiveIndex) {
          const value = filteredOptions[previousIndex]?.value;

          scrollToItem({ from: "start", value });
        }

        return previousIndex;
      });
    } else {
      setOpened();
      setActiveIndex(Math.max(selectedIndex, 0));

      scrollToSelectedItem();
    }
  };

  const onArrowDown: KeyHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isOpened) {
      setActiveIndex((active) => {
        const nextIndex = computePreviousIndex(
          selectedIndex,
          optionsCount,
        )(active);

        const isNotActiveIndex = nextIndex !== active;

        if (isNotActiveIndex) {
          const value = filteredOptions[nextIndex]?.value;

          scrollToItem({ from: "end", value });
        }

        return nextIndex;
      });
    } else {
      setOpened();
      setActiveIndex(Math.max(selectedIndex, 0));

      scrollToSelectedItem();
    }
  };

  const onEscape: KeyHandler = (event) => {
    event.preventDefault();
    setClosed();
    setActiveIndex(-1);
  };

  const onEnter: KeyHandler = (event) => {
    if (activeIndex === filteredOptions.length) {
      event.preventDefault();

      return handleCreate();
    }

    const activeOption = filteredOptions[activeIndex];

    if (exactSearchMatch) {
      event.preventDefault();
      setClosed();
      handleSelect(exactSearchMatch.value);
    }

    if (activeOption && isOpened) {
      event.preventDefault();
      handleSelect(activeOption.value);
    }
  };

  const onSpace: KeyHandler = (event) => {
    if (isSearchable) {
      return;
    }

    const activeOption = filteredOptions[activeIndex];

    if (activeOption && isOpened) {
      event.preventDefault();
      handleSelect(activeOption.value);
    }
  };

  const onEnd: KeyHandler = (event) => {
    if (isSearchable) {
      return;
    }

    event.preventDefault();
    setOpened();
    setActiveIndex(optionsCount - 1);
    const lastValue = filteredOptions[optionsCount - 1]?.value;

    if (lastValue) {
      scrollToItem({ from: "end", value: lastValue });
    }
  };

  const onHome: KeyHandler = (event) => {
    if (isSearchable) {
      return;
    }

    event.preventDefault();
    setOpened();
    setActiveIndex(0);
    const firstValue = filteredOptions[0]?.value;

    if (firstValue) {
      scrollToItem({ from: "start", value: firstValue });
    }
  };

  return (event: KeyboardEvent<HTMLInputElement>) => {
    const keydownMap: KeyboardHandlersMap = {
      " ": onSpace,
      ArrowDown: onArrowDown,
      ArrowUp: onArrowUp,
      End: onEnd,
      Enter: onEnter,
      Escape: onEscape,
      Home: onHome,
      fallback: noop,
    };

    const handler =
      event.key in keydownMap
        ? keydownMap[event.key as Keys]
        : keydownMap.fallback;

    return handler(event);
  };
};
