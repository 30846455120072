type ComputeDistanceParams = {
  child: HTMLElement;
  parent: HTMLElement;
};

const computeDistance = ({ child, parent }: ComputeDistanceParams) => {
  const parentPosition = parent.getBoundingClientRect();
  const childPosition = child.getBoundingClientRect();

  const distance = childPosition.top - parentPosition.top;

  return {
    childHeight: childPosition.height,
    distance,
    parentHeight: parentPosition.height,
  };
};

type Params = {
  child: HTMLElement;
  offset?: number;
  parent: HTMLElement;
};

export const computeRelativeDistance = ({
  child,
  offset = 0,
  parent,
}: Params) => {
  const { childHeight, distance, parentHeight } = computeDistance({
    child,
    parent,
  });

  if (!distance) {
    return {
      end: 0,
      start: 0,
    };
  }

  const computeScrollFromEnd = () => {
    const scroll = distance + offset - parentHeight + childHeight;

    return Math.max(scroll, 0);
  };

  const computeScrollFromStart = () => {
    const scroll = distance - offset;

    return Math.min(scroll, 0);
  };

  return {
    end: computeScrollFromEnd(),
    start: computeScrollFromStart(),
  };
};
