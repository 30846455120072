import { contextFactory } from "../../../utils/contextFactory";

type DocumentContextType = {
  name: string;
  shouldShowActionsOnHover?: boolean;
};

export const [DocumentProvider, useDocumentContext] =
  contextFactory<DocumentContextType>({
    displayName: "DocumentProvider",
    errorMessage: "Document components should be wrapped in a `Document`.",
  });
