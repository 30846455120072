import React from "react";
import { cloneChildren } from "../../utils/children";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import type { SpacingScale } from "../../utils/system/system";
import { BreadcrumbLink } from "./Breadcrumb/BreadcrumbLink";
import { BreadcrumbItem } from "./Breadcrumb/BreadcrumbItem";

type Props = {
  spacing?: SpacingScale;
};

export type BreadcrumbProps = Props & SystemProps<"nav">;

type Compounds = {
  Item: typeof BreadcrumbItem;
  Link: typeof BreadcrumbLink;
};

const StyledList = styled("ol", {
  base: {
    margin: 0,
    padding: 0,
  },
});

export const Breadcrumb = polymorphic<"nav", Props, Compounds>(
  ({ children, className, spacing = "0.5rem", ...props }, ref) => {
    const cloned = cloneChildren(children, (_, index, childrenCount) => ({
      isLastChild: index + 1 === childrenCount,
      spacing,
    }));

    return (
      <styled.nav aria-label="breadcrumb" {...props} ref={ref}>
        <StyledList>{cloned}</StyledList>
      </styled.nav>
    );
  },
);

Breadcrumb.displayName = "Breadcrumb";
Breadcrumb.Item = BreadcrumbItem;
Breadcrumb.Link = BreadcrumbLink;
