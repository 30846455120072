import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { Tab } from "./Tabs/Tab";
import { TabList } from "./Tabs/TabList";
import { TabPanel } from "./Tabs/TabPanel";
import { TabPanels } from "./Tabs/TabPanels";
import type { TabsContextType } from "./Tabs/useTabs";
import { TabsProvider, useTabsController } from "./Tabs/useTabs";
import { TabActions } from "./Tabs/TabActions";

type Props = {
  dataTestId?: string;
  defaultActiveIndex?: number;
  flex?: number;
  flexDirection?: string; // TODO: add proper typing here
} & Pick<TabsContextType, "activeIndex" | "isLazy">;

export type TabsProps = Props & SystemProps<"div">;

type Compounds = {
  Actions: typeof TabActions;
  List: typeof TabList;
  Panel: typeof TabPanel;
  Panels: typeof TabPanels;
  Tab: typeof Tab;
};

const StyledTabs = styled("div", {
  base: ({ theme }) => ({
    "&:first-of-type": {
      marginTop: 0,
    },
    borderColor: theme.colors.neutral["20"],
    margin: "1em 0",

    overflowWrap: "break-word",
  }),
});

export const Tabs = polymorphic<"div", Props, Compounds>(
  (
    {
      activeIndex: activeIndexProp,
      children,
      dataTestId = "Tabs",
      defaultActiveIndex = 0,
      isLazy = true,
      ...rest
    },
    ref,
  ) => {
    const { activeIndex, generateTabId, generateTabPanelId, setActiveIndex } =
      useTabsController(defaultActiveIndex);

    return (
      <TabsProvider
        value={{
          activeIndex: activeIndexProp ?? activeIndex,
          generateTabId,
          generateTabPanelId,
          isLazy,
          setActiveIndex,
        }}
      >
        <StyledTabs data-testid={dataTestId} ref={ref} {...rest}>
          {children}
        </StyledTabs>
      </TabsProvider>
    );
  },
);

Tabs.displayName = "Tabs";
Tabs.Tab = Tab;
Tabs.List = TabList;
Tabs.Panels = TabPanels;
Tabs.Panel = TabPanel;
Tabs.Actions = TabActions;
