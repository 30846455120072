import { contextFactory } from "../../../utils/contextFactory";
import type { UseCalendar } from "./hooks/useCalendar";

type CalendarContextType = { value?: number } & Pick<
  UseCalendar,
  | "endMonthName"
  | "endOfMonth"
  | "getWeeksInMonth"
  | "isSameMonth"
  | "isToday"
  | "locale"
  | "selectedTimezone"
  | "startMonthName"
  | "state"
  | "useCalendarCell"
  | "useCalendarGrid"
  | "useFocusForAccessibility"
>;

export const [CalendarProvider, useCalendarContext] =
  contextFactory<CalendarContextType>({
    displayName: "CalendarProvider",
    errorMessage: "Calendar elements should be wrapped in Calendar.",
  });
