import { isEqual } from "lodash-es";
import type { FreightMethodOvrUtils } from "../orderUtils/orderFreightMethodSelectors";
import type { LoadOvrutilsWithUnits } from "../cargaisonUtils/loadUtils";
import type { ContainerForAutofill, PriceForAutofill } from "./types";
import { determinePriceQuantityForNewLoads } from "./determinePriceQuantityForNewLoads";
import { determinePriceQuantityForNewContainers } from "./determinePriceQuantityForNewContainers";

export const determinePriceQuantityForNewCargo =
  <Load extends LoadOvrutilsWithUnits, Container extends ContainerForAutofill>({
    cargaisonValue,
    freightMethod,
    newContainers,
    newLoads,
    oldContainers,
    oldLoads,
  }: {
    cargaisonValue?: null | string;
    freightMethod?: FreightMethodOvrUtils;
    newContainers: Container[];
    newLoads: Load[];
    oldContainers: Container[];
    oldLoads: Load[];
  }) =>
  <Price extends PriceForAutofill>(price: Price): null | number | undefined => {
    // Some of the most used quantity units are not related to the cargo of the shipment, so we handle them at this higher level.
    if (price.quantityUnit === "cargaison_value") {
      return cargaisonValue ? parseFloat(cargaisonValue) : price.quantity;
    }
    if (price.quantityUnit === "unit") {
      if (price.usedRate?.rateUnit === "km" && price.usedRate.ratesFixed) {
        return newContainers.length;
      }

      return 1;
    }

    const haveLoadsChanged = !isEqual(newLoads, oldLoads);

    if (haveLoadsChanged) {
      return determinePriceQuantityForNewLoads({
        freightMethod: freightMethod ?? "air",
        newLoads,
        oldLoads,
      })(price);
    }

    // if loads have not changed, then it can only be containers.
    return determinePriceQuantityForNewContainers({
      newContainers,
      oldContainers,
    })(price);
  };
