import { isEqual } from "lodash-es";
import { isEuropeanUnionCountry } from "../operations/countriesDirectory";
import type { FreightMethodOvrUtils } from "../orderUtils/orderFreightMethodSelectors";
import { DOCUMENT_TAGS } from "./documentTypes";

type Location = {
  countryCode: string;
};

type Harbor = Location;

type Routing = {
  arrivalHarbor: Harbor | null;
  deliveryLocation: Location | null;
  departureHarbor: Harbor | null;
  pickupLocation: Location | null;
};

export type Shipment = {
  freightMethod: FreightMethodOvrUtils;
  incoterm: null | string;
  routing: Routing;
};

export type Document = {
  documentType: null | string;
};

const SUBSTITUABLE_DOCUMENTS = {
  UECustomsEntranceDocument: ["IMA", "T1"],
  UECustomsExitDocument: [DOCUMENT_TAGS.EXA, "T1"],
  airWayBill: ["HAWB", "MAWB"],
  billsOfLading: ["HBL", "MBL"],
} as const;

export type SubstituableDocument =
  (typeof SUBSTITUABLE_DOCUMENTS)[keyof typeof SUBSTITUABLE_DOCUMENTS];

type UnsubstituableDocument = "ENTRY SUMMARY" | "INVOICE" | "POD/CMR";

export type DocumentRequired = SubstituableDocument | UnsubstituableDocument;

export const isSubstituableMissingDocuments = (
  documentRequired: DocumentRequired,
): documentRequired is SubstituableDocument => {
  if (
    isEqual(
      documentRequired,
      SUBSTITUABLE_DOCUMENTS["UECustomsEntranceDocument"],
    )
  ) {
    return true;
  }

  if (
    isEqual(documentRequired, SUBSTITUABLE_DOCUMENTS["UECustomsExitDocument"])
  ) {
    return true;
  }

  if (isEqual(documentRequired, SUBSTITUABLE_DOCUMENTS["billsOfLading"])) {
    return true;
  }

  if (isEqual(documentRequired, SUBSTITUABLE_DOCUMENTS["airWayBill"])) {
    return true;
  }

  return false;
};

const determineTransportationBillsDocumentsRequired = ({
  freightMethod,
}: {
  freightMethod: FreightMethodOvrUtils;
  incoterm: null | string;
}): DocumentRequired[] => {
  if (freightMethod === "air") {
    return [["HAWB", "MAWB"]];
  } else if (freightMethod === "ocean") {
    return [["HBL", "MBL"]];
  }

  return [];
};

const determineUSACustomsDeclarationDocumentsRequired = ({
  incoterm,
  routing,
}: {
  incoterm: null | string;
  routing: Routing;
}): DocumentRequired[] => {
  if (!routing.arrivalHarbor) {
    return [];
  }
  if (routing.arrivalHarbor.countryCode !== "us") {
    return [];
  }
  if (incoterm === "dap" || (incoterm && incoterm.startsWith("c"))) {
    return [];
  }

  return ["ENTRY SUMMARY"];
};

const determineUECustomsDeclarationDocumentsRequired = ({
  arrivalHarbor,
  departureHarbor,
}: Routing): SubstituableDocument[] => {
  if (!departureHarbor || !arrivalHarbor) {
    return [];
  }
  const isDepartureHarborInUE = isEuropeanUnionCountry(
    departureHarbor.countryCode,
  );

  const isArrivalHarborInUE = isEuropeanUnionCountry(arrivalHarbor.countryCode);

  if (isDepartureHarborInUE && !isArrivalHarborInUE) {
    return [[DOCUMENT_TAGS.EXA, "T1"]];
  }
  if (isArrivalHarborInUE && !isDepartureHarborInUE) {
    return [["IMA", "T1"]];
  }

  return [];
};

const determineCustomsDeclarationDocumentsRequired = ({
  incoterm,
  routing,
}: {
  incoterm: null | string;
  routing: Routing;
}): DocumentRequired[] => {
  const USACustomsDeclarationDocument =
    determineUSACustomsDeclarationDocumentsRequired({ incoterm, routing });
  const UECustomsDeclarationDocument =
    determineUECustomsDeclarationDocumentsRequired(routing);

  return [...USACustomsDeclarationDocument, ...UECustomsDeclarationDocument];
};

const determineTruckSpecificDocumentsRequired = (
  freightMethod: FreightMethodOvrUtils,
): DocumentRequired[] => {
  if (freightMethod !== "truck") {
    return [];
  }

  return ["POD/CMR"];
};

const isDocumentMissing =
  (shipmentDocumentsTypes: (null | string)[]) =>
  (documentRequired: DocumentRequired) => {
    if (isSubstituableMissingDocuments(documentRequired)) {
      return !documentRequired.some((documentRequired) =>
        shipmentDocumentsTypes.includes(documentRequired),
      );
    }

    return !shipmentDocumentsTypes.includes(documentRequired);
  };

export const determineDocumentsRequired = (
  shipment: Shipment,
): DocumentRequired[] => {
  const documentsAlwaysRequired: DocumentRequired[] = ["INVOICE"];

  const transportationBillsDocumentsRequired =
    determineTransportationBillsDocumentsRequired(shipment);

  const customsDeclarationDocumentsRequired =
    determineCustomsDeclarationDocumentsRequired(shipment);

  const truckDocumentsRequired = determineTruckSpecificDocumentsRequired(
    shipment.freightMethod,
  );

  return [
    ...documentsAlwaysRequired,
    ...truckDocumentsRequired,
    ...transportationBillsDocumentsRequired,
    ...customsDeclarationDocumentsRequired,
  ];
};

// TODO => MOOVE IN BACKEND

export const determineMissingRequiredDocuments = (
  shipment: Shipment,
  shipmentDocuments: Document[],
) => {
  const shipmentDocumentsTypes = shipmentDocuments.map(
    ({ documentType }) => documentType,
  );

  const documentsRequired = determineDocumentsRequired(shipment);

  const documentsMissing = documentsRequired.filter(
    isDocumentMissing(shipmentDocumentsTypes),
  );

  return documentsMissing;
};
