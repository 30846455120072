import { contextFactory } from "../../../utils/contextFactory";

export type TableSize = "md" | "sm";

type TableContextType = {
  highlightOnHover?: boolean;
  isFixed: boolean;
  size: TableSize;
};

export const [TableProvider, useTableContext] =
  contextFactory<TableContextType>({
    displayName: "TableProvider",
    errorMessage: "Table components should be wrapped in a `Table`.",
  });
