import React from "react";
import { styled } from "../../utils/system/factory";
import { styles } from "../../theme/commons";
import { polymorphic } from "../../utils/ref";
import type { Theme } from "../../theme/theme";
import type { SystemProps } from "../../utils/types/system";

type Size = keyof Theme["caption"];

type Props = {
  isTruncated?: boolean;
  size?: Size;
};

export type CaptionProps = Props & SystemProps<"h4">;

const textTransformMap = {
  xs: undefined,
  xxs: "uppercase" as const,
};

const StyledCaption = styled<"div", Props>("div", {
  base: ({ isTruncated, size = "xs", theme }) => {
    const textTransform = textTransformMap[size];
    const { fontSize, fontWeight, letterSpacing, lineHeight } =
      theme.caption[size];

    return [
      {
        color: theme.colors.neutral.base,
        fontSize,
        fontWeight,
        letterSpacing,
        lineHeight,
        textTransform,
      },
      isTruncated && styles.truncated,
    ];
  },
});

export const Caption = polymorphic<"h4", Props>(({ size, ...rest }, ref) => (
  <StyledCaption ref={ref} size={size} {...rest} />
));

Caption.displayName = "Caption";
