import React from "react";
import { keyframes } from "../../utils/system";
import type { SystemProps } from "../../utils/types/system";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";

const sizes = {
  md: "2em",
  sm: "1em",
} as const;

type SpinnerSize = keyof typeof sizes;

type Props = {
  isActive?: boolean;
  isInverted?: boolean;
  size?: SpinnerSize;
};

export type SpinnerProps = Props & SystemProps<"div">;

const spin = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
});

const StyledSpinner = styled<"div", Props>("div", {
  base: ({ isInverted, size = "md", theme }) => ({
    animation: `${spin} 0.5s linear infinite`,
    borderBottomColor: "transparent",
    borderColor: isInverted
      ? "white white transparent transparent"
      : theme.colors.neutral.base,
    borderLeftColor: "transparent",
    borderRadius: theme.radius.round,
    borderStyle: "solid",
    borderWidth: "2px",
    display: "inline-block",
    height: sizes[size],
    minHeight: sizes[size],
    minWidth: sizes[size],
    width: sizes[size],
  }),
});

export const Spinner = polymorphic<"div", Props>(
  ({ isActive, isInverted, size, ...rest }, ref) => {
    if (!isActive) {
      return null;
    }

    return (
      <StyledSpinner isInverted={isInverted} ref={ref} size={size} {...rest} />
    );
  },
);

Spinner.displayName = "Spinner";
