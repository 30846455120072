import React from "react";
import type { DropzoneOptions } from "react-dropzone-esm";
import { deepHasChild } from "../../utils/children";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { mapPseudoFocusRing } from "../../theme/commons";
import { dataAttribute } from "../../utils/attributes";
import {
  DropzoneButton,
  DropzoneContent,
  DropzoneDescription,
  DropzoneDocument,
  DropzoneDocuments,
  DropzoneDrawer,
  DropzoneGroup,
  DropzoneIcon,
  DropzoneTitle,
} from "./Dropzone/DropzoneComponents";
import { useDropzoneProps } from "./Dropzone/useDropzone";

type Props = {
  isDisabled?: boolean;
  options?: Omit<DropzoneOptions, "onDrop">;
} & Pick<DropzoneOptions, "onDrop">;

export type DropzoneProps = Omit<SystemProps<"div">, "onDrop"> & Props;

const StyledDropzone = styled("div", {
  base: ({ theme }) => [
    mapPseudoFocusRing(theme),
    {
      background: theme.colors.background.light,
      border: `1px dashed ${theme.colors.neutral[10]}`,
      borderRadius: theme.radius.base,
      transitionDuration: theme.transition.duration.slow,
      transitionProperty: "all",
      transitionTimingFunction: theme.transition.easing.color,
      userSelect: "none",
    },
    {
      "&:hover:not([data-disabled])": {
        background: theme.colors.neutral[10],
        borderColor: theme.colors.primary.base,
        color: "black",
      },
    },
    {
      "&[data-active]": {
        background: theme.colors.neutral.light,
        border: `1px solid ${theme.colors.neutral["10"]}`,
      },
    },
    {
      "&[data-disabled]": {
        ".content": {
          cursor: "not-allowed",
        },
        opacity: 0.5,
      },
    },
  ],
});

type Compounds = {
  Button: typeof DropzoneButton;
  Content: typeof DropzoneContent;
  Description: typeof DropzoneDescription;
  Document: typeof DropzoneDocument;
  Documents: typeof DropzoneDocuments;
  Drawer: typeof DropzoneDrawer;
  Group: typeof DropzoneGroup;
  Icon: typeof DropzoneIcon;
  Title: typeof DropzoneTitle;
};

export const Dropzone = polymorphic<"div", Props, Compounds>(
  ({ children, className, isDisabled, onDrop, options, ...rest }, ref) => {
    const [rootProps, inputProps, { isDragActive }] = useDropzoneProps({
      disabled: isDisabled,
      onDrop,
      ...options,
    });

    const hasDocuments = deepHasChild(children, DropzoneDocument);

    const isActive = isDragActive || hasDocuments;

    return (
      <StyledDropzone
        ref={ref}
        {...rootProps}
        {...rest}
        data-active={dataAttribute(isActive)}
        data-disabled={dataAttribute(isDisabled)}
      >
        <input {...inputProps} />
        {children}
      </StyledDropzone>
    );
  },
);

Dropzone.displayName = "Dropzone";
Dropzone.Button = DropzoneButton;
Dropzone.Content = DropzoneContent;
Dropzone.Description = DropzoneDescription;
Dropzone.Drawer = DropzoneDrawer;
Dropzone.Document = DropzoneDocument;
Dropzone.Documents = DropzoneDocuments;
Dropzone.Group = DropzoneGroup;
Dropzone.Icon = DropzoneIcon;
Dropzone.Title = DropzoneTitle;
