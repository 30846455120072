import type { ReactNode } from "react";
import React, { Fragment } from "react";
import { computeStartAndEndOfWeek, dayjs } from "@ovrsea/ovrutils";
import type { Sx, SystemProps } from "../../utils/types/system";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import { HStack, VStack } from "../Layout/Stack";
import { Body } from "../Typography/Body";
import { Box } from "../Meta/Box";

type DayWithItem = {
  content: ReactNode[];
  date: dayjs.Dayjs;
};

type Props = {
  date: dayjs.Dayjs;
  daysWithItems: DayWithItem[];
};

export type WeekProps = Props & SystemProps<"div">;

const weekDays = [...Array(7)];

const headersContainerSx: Sx = (theme) => ({
  borderBottom: `${theme.spacing[1]} solid ${theme.colors.neutral[10]}`,
});

const StyledWeekColumn = styled<typeof VStack>(VStack, {
  base: ({ theme }) => ({
    "&:not(:last-child)": {
      borderRight: `${theme.spacing[1]} solid ${theme.colors.neutral[10]}`,
    },
    flex: 1,
    minWidth: 0,
    paddingTop: theme.spacing[4],
  }),
});

const StyledDayHeader = styled<typeof Body, { isToday?: boolean }>(Body, {
  base: ({ isToday = false, theme }) => [
    {
      alignSelf: "center",
      display: "flex",
      flex: 1,
      fontSize: theme.font.size.sm,
      fontWeight: theme.font.weight.medium,
      gap: 4,
      justifyContent: "center",
      marginBottom: theme.spacing[8],
      textAlign: "center",
    },
    isToday && {
      backgroundColor: theme.colors.primary.light,
      borderRadius: theme.radius.base,
      color: theme.colors.primary.base,
      padding: `0 ${theme.spacing[4]}`,
    },
  ],
});

const extractItemsOfDay = ({
  day,
  items,
}: {
  day: dayjs.Dayjs;
  items: DayWithItem[];
}) => items.filter(({ date }) => day.isSame(dayjs(date), "day"));

const Week = polymorphic<"div", WeekProps>(
  ({ date, daysWithItems, sx, ...rest }, ref) => {
    const today = dayjs();
    const { startOfWeek } = computeStartAndEndOfWeek(date);

    return (
      <VStack sx={sx} {...rest} ref={ref}>
        <HStack flex={0} ref={ref} sx={headersContainerSx}>
          {weekDays.map((_, dayIndex) => {
            const currentDay = startOfWeek.add(dayIndex, "days");
            const isToday = currentDay.isSame(today, "day");

            return (
              <StyledDayHeader key={dayIndex}>
                <span>{currentDay.format("ddd")}</span>
                <Box
                  sx={(theme) =>
                    isToday && {
                      backgroundColor: theme.colors.primary.light,
                      borderRadius: theme.radius.base,
                      color: theme.colors.primary.base,
                      padding: `0 ${theme.spacing[4]}`,
                    }
                  }
                >
                  {currentDay.format("D")}
                </Box>
              </StyledDayHeader>
            );
          })}
        </HStack>
        <HStack flex={1} sx={{ maxWidth: "100%" }}>
          {weekDays.map((_, dayIndex) => {
            const currentDay = startOfWeek.add(dayIndex, "days");

            const currentDayItems = extractItemsOfDay({
              day: currentDay,
              items: daysWithItems,
            });

            return (
              <StyledWeekColumn key={dayIndex}>
                {currentDayItems.map(({ content }, index) => (
                  <Fragment key={index}>{content}</Fragment>
                ))}
              </StyledWeekColumn>
            );
          })}
        </HStack>
      </VStack>
    );
  },
);

Week.displayName = "Week";

export { Week };
