import type { TimelineSize } from "../Timeline";
import { styled } from "../../../utils/system/factory";
import type { CSSObject } from "../../../utils/types/css";

type Props = {
  size: TimelineSize;
};

const smallStyles: CSSObject = {
  "& .icon-block": {
    height: 25,
    position: "relative",
    width: 25,
  },
  "& .item .icon-block, & .item .icon-block .icon": {
    fontSize: 11,
    verticalAlign: "top",
  },
  "& .item .label": {
    left: 12,
    top: -9,
  },
  "& .progress": {
    height: 2,
    margin: "8px -1px 0",
  },
};

const mediumStyles: CSSObject = {
  "& .icon-block": {
    position: "relative",
  },
  "& .item .dot": {
    top: 5,
  },
  "& .progress": {
    height: 4,
    margin: "9px -1px 0",
  },
};

const sizesMap = {
  medium: mediumStyles,
  small: smallStyles,
};

const HorizontalTimeline = styled<"ul", Props>("ul", {
  base: ({ size = "medium" }) => [
    sizesMap[size],
    {
      "& .item": {
        "&:not(:last-child), &:not(:last-child) .progress": {
          flexGrow: 1,
        },
        display: "flex",

        flexDirection: "row-reverse",
      },
      display: "flex",
      height: 25,
      margin: 0,

      padding: "4px 0 0",
    },
  ],
});

HorizontalTimeline.displayName = "HorizontalTimeline";

export { HorizontalTimeline };
