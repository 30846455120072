import type { ComponentProps } from "react";
import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import type { SystemProps } from "../../utils/types/system";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import type { FlexProps } from "../Layout/Flex";
import { Flex } from "../Layout/Flex";
import type { IconProps } from "../Typography/Icon";
import { Icon } from "../Typography/Icon";
import { useBooleanState } from "../../utils/hooks/useBooleanState";
import type { BaseInputProps } from "./Input/BaseInput";
import { StyledInput } from "./Input/BaseInput";

type Props = {
  autoFocus?: boolean;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
} & Pick<ComponentProps<"input">, "value">;

export type SearchbarProps = Props & SystemProps<"div">;

const StyledSearchInput = styled<
  typeof StyledInput,
  Omit<BaseInputProps, "onChange">
>(StyledInput, {
  base: ({ theme }) => ({
    borderRadius: theme.radius.round,
    height: theme.spacing[32],
    minHeight: theme.spacing[32],
    paddingLeft: "36px !important",
    paddingRight: "36px",
  }),
});

const StyledContainer = styled<typeof Flex, FlexProps>(Flex, {
  base: {
    position: "relative",
  },
});

const LeftIcon = styled<typeof Icon, { isFocused: boolean } & IconProps>(Icon, {
  base: ({ isFocused, theme }) => [
    {
      color: theme.colors.neutral["40"],
      left: theme.spacing[12],
      position: "absolute",
      top: theme.spacing[8],
    },
    isFocused && {
      color: theme.colors.neutral.base,
    },
  ],
});

const RightIcon = styled<typeof Icon, IconProps>(Icon, {
  base: ({ theme }) => ({
    color: theme.colors.neutral["40"],
    cursor: "pointer",
    position: "absolute",
    right: theme.spacing[12],
    top: theme.spacing[8],
  }),
});

export const Searchbar = polymorphic<"div", Props>(
  (
    {
      autoFocus,
      isDisabled,
      onChange: onChangeProp,
      placeholder,
      value = "",
      ...rest
    },
    ref,
  ) => {
    const [isFocused, setFocused, setBlurred] = useBooleanState();

    const onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) =>
      onChangeProp(target.value);

    const onReset = () => onChangeProp("");

    const hasValue =
      isDefined(value) &&
      (((typeof value === "string" || Array.isArray(value)) &&
        value.length > 0) ||
        typeof value === "number");

    return (
      <StyledContainer {...rest} ref={ref}>
        <LeftIcon isFocused={isFocused} name="search" />
        <StyledSearchInput
          autoFocus={autoFocus}
          disabled={isDisabled}
          onBlur={setBlurred}
          onChange={onChange}
          onFocus={setFocused}
          placeholder={placeholder}
          value={value}
        />
        {hasValue && <RightIcon name="cross-circle" onClick={onReset} />}
      </StyledContainer>
    );
  },
);
