import type { ComponentProps } from "react";
import React from "react";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { styled } from "../../../utils/system/factory";
import { polymorphic } from "../../../utils/ref";
import type { Sx, SystemProps } from "../../../utils/types/system";
import { generateRange } from "../../../utils/numbers";
import type { IconName, IconProps } from "../../Typography/Icon";
import { Icon } from "../../Typography/Icon";

const SegmentedControlIndicator = styled("span", {
  base: ({ theme }) => [
    {
      border: "none",
      display: "none",
      height: "100%",
      left: 0,
      pointerEvents: "none",
      position: "absolute",
      top: 0,
      transitionDuration: theme.transition.duration.fast,
      transitionProperty: "transform",
      transitionTimingFunction: "cubic-bezier(.445, .05, .55, .95)",
      width: "calc(100% / 3)",
      zIndex: -1,
    },
    {
      "&:before": {
        "[data-theme='dark'] &": {
          backgroundColor: theme.colors.neutral["30"],
        },
        backgroundColor: "white",
        borderRadius: theme.radius.md,
        boxShadow: `0 0 0 1px ${theme.colors.neutral["30"]}`,
        content: '""',
        inset: "1px",
        position: "absolute",
      },
    },
  ],
});

/* list of selectors to translate the position of the Indicator */
const transformSelectors = generateRange(2, 20).reduce(
  (selector, nthChild) => ({
    ...selector,
    [`& :where([data-state='on']:nth-child(${nthChild})) ~ [data-type='indicator']`]:
      {
        transform: `translateX(${nthChild - 1}00%)`,
      },
  }),
  {},
);

/* list of selectors to span the indicator to the largest item width */
const widthSelectors = generateRange(0, 20).reduce(
  (selector, itemsCount) => ({
    ...selector,
    [`& [data-type='indicator']:where(:nth-child(${itemsCount + 1}))`]: {
      width: `calc(100% / ${itemsCount})`,
    },
  }),
  {},
);

const SegmentedControlRoot = styled<typeof ToggleGroupPrimitive.Root>(
  ToggleGroupPrimitive.Root,
  {
    base: ({ theme }) => [
      {
        alignItems: "stretch",
        background: theme.colors.neutral["10"],
        borderRadius: theme.radius.md,
        color: theme.colors.text.primary,
        display: "inline-grid",
        gridAutoColumns: "1fr",
        gridAutoFlow: "column",
        height: theme.spacing[32],
        isolation: "isolate",
        minWidth: "max-content",
        position: "relative",
        textAlign: "center",
        verticalAlign: "top",
      },
      {
        "[data-theme='dark'] &": {
          backgroundColor: theme.colors.neutral["10"],
        },
      },
      {
        // indicator is always at the beginning when first item is selected
        "& :where([data-state='on']:nth-of-type(1)) ~ [data-type='indicator']":
          {
            transform: "translateX(0%)",
          },
        [`& :where([data-state='on']) ~ [data-type='indicator']`]: {
          display: "block",
        },
        // hide item separator when neighbor of a selected item or when neighbor of a focused item
        [`& :where([data-type='item']:first-of-type) [data-type='separator'],\
      & :where([data-type='item']:where([data-state='on'], :focus-visible)) [data-type='separator'],\
      & :where([data-type='item']:where([data-state='on'], :focus-visible)) + * [data-type='separator']`]:
          {
            opacity: 0,
            transitionTimingFunction: "ease-in",
          },
        ...widthSelectors,
        ...transformSelectors,
      },
    ],
  },
);

type ItemProps = {
  icon?: IconName;
  value: string;
} & Omit<
  ComponentProps<typeof ToggleGroupPrimitive.Item>,
  "disabled" | "type" | "value"
> &
  SystemProps<"button">;

const StyledItem = styled<typeof ToggleGroupPrimitive.Item, ItemProps>(
  ToggleGroupPrimitive.Item,
  {
    base: ({ theme }) => ({
      "&:where([data-state='on']) [data-type='wrapper-active']": {
        opacity: 1,
        transitionTimingFunction: "ease-out",
      },
      "&:where([data-state='on']) [data-type='wrapper-inactive']": {
        opacity: 0,
        transitionTimingFunction: "ease-in",
      },
      "&[data-state='off']:hover [data-type='label']": {
        "[data-theme='dark'] &": {
          backgroundColor: theme.colors.neutral["20"],
        },
        background: theme.colors.neutral["20"],
      },
      alignItems: "stretch",
      background: "none",
      display: "flex",
      fontSize: theme.font.size.sm,
      userSelect: "none",
    }),
  },
);

const StyledSeparator = styled("span", {
  base: ({ theme }) => ({
    backgroundColor: theme.colors.neutral["20"],
    margin: "6px -0.5px",
    transition: "opacity 80ms",
    transitionTimingFunction: "ease-in",
    width: 1,
    zIndex: -1,
  }),
});
const StyledControlLabel = styled("span", {
  base: ({ theme }) => ({
    alignItems: "center",
    borderRadius: theme.radius.md,
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    margin: "1px",
    padding: `0 ${theme.spacing[16]} 1px`,
  }),
});
const StyledActiveItem = styled("span", {
  base: ({ theme }) => ({
    "[data-theme='dark'] &": {
      color: "white",
    },
    alignItems: "center",
    color: theme.colors.primary.base,
    display: "flex",
    fontWeight: 500,
    opacity: 0,
  }),
});
const StyledInactiveItem = styled("span", {
  base: () => ({
    alignItems: "center",
    display: "flex",
    fontWeight: 400,
    opacity: 1,
    position: "absolute",
  }),
});

const iconSx: Sx = (theme) => ({
  "&[data-active]": {
    color: theme.colors.neutral.base,
  },
  color: theme.colors.neutral["60"],
});

const ItemIcon = (props: IconProps) => (
  <Icon {...props} marginRight={4} sx={iconSx} />
);

export const SegmentedControlItem = polymorphic<"div", ItemProps>(
  ({ children, icon, ...props }, ref) => (
    <StyledItem
      ref={ref}
      {...props}
      asChild={false}
      data-type="item"
      disabled={false}
    >
      <StyledSeparator data-type="separator" />
      <StyledControlLabel data-type="label">
        <StyledActiveItem data-type="wrapper-active">
          {icon && <ItemIcon data-active name={icon} />}
          {children}
        </StyledActiveItem>
        <StyledInactiveItem data-type="wrapper-inactive">
          {icon && <ItemIcon name={icon} />}
          {children}
        </StyledInactiveItem>
      </StyledControlLabel>
    </StyledItem>
  ),
);

SegmentedControlItem.displayName = "SegmentedControl.Item";

export { SegmentedControlIndicator, SegmentedControlRoot };
