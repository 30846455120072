import type { Dispatch, KeyboardEvent, SetStateAction } from "react";
import { last } from "lodash-es";
import { isDefined, isNotDefined } from "@ovrsea/ovrutils";
import { noop } from "../../../utils/events";
import type { SelectOptionType } from "../Shared/types";
import type { ScrollToItem } from "../Shared/useSelectScroll";
import { clampIndex } from "../Shared/utils";

type Space = " ";
type Keys =
  | "ArrowDown"
  | "ArrowUp"
  | "Backspace"
  | "End"
  | "Enter"
  | "Escape"
  | "Home"
  | Space;

type KeyHandler = (event: KeyboardEvent<HTMLInputElement>) => void;

type KeyboardHandlersMap = Record<"fallback" | Keys, KeyHandler>;

type Params = {
  activeIndex: number;
  filteredOptions: SelectOptionType[];
  handleRemoveOption: (value: string) => void;
  handleSelect: (value: string) => void;
  isOpened: boolean;
  isSearchable?: boolean;
  scrollToItem: ScrollToItem;
  searchQuery: string;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  setClosed: () => void;
  setOpened: () => void;
  value?: string[];
};

export const useMultiSelectKeydown = ({
  activeIndex,
  filteredOptions,
  handleRemoveOption,
  handleSelect,
  isOpened,
  isSearchable,
  scrollToItem,
  searchQuery,
  setActiveIndex,
  setClosed,
  setOpened,
  value,
}: Params) => {
  const optionsCount = filteredOptions.length;

  const onArrowUp: KeyHandler = (event) => {
    event.preventDefault();

    if (!isOpened) {
      setOpened();
      setActiveIndex(0);

      return;
    }

    setActiveIndex((active) => {
      const nextIndex = clampIndex(optionsCount, active - 1);

      const isNotActiveIndex = nextIndex !== active;

      if (isNotActiveIndex) {
        const value = filteredOptions[nextIndex]?.value;

        scrollToItem({ from: "start", value });
      }

      return nextIndex;
    });
  };

  const onArrowDown: KeyHandler = (event) => {
    event.preventDefault();

    if (!isOpened) {
      setOpened();
      setActiveIndex(0);

      return;
    }

    setActiveIndex((active) => {
      const previousIndex = clampIndex(optionsCount, active + 1);

      const isNotActiveIndex = previousIndex !== active;

      if (isNotActiveIndex) {
        const value = filteredOptions[previousIndex]?.value;

        scrollToItem({ from: "end", value });
      }

      return previousIndex;
    });
  };

  const onEscape: KeyHandler = (event) => {
    event.preventDefault();
    setClosed();
    setActiveIndex(-1);
  };

  const onEnter: KeyHandler = (event) => {
    if (!isSearchable) {
      event.preventDefault();
    }

    const activeOption = filteredOptions[activeIndex];

    if (activeOption && isOpened) {
      event.preventDefault();
      handleSelect(activeOption.value);
    }
  };

  const onSpace: KeyHandler = (event) => {
    if (isSearchable) {
      return;
    }

    const activeOption = filteredOptions[activeIndex];

    if (activeOption && isOpened) {
      event.preventDefault();
      handleSelect(activeOption.value);
    }
  };

  const onEnd: KeyHandler = (event) => {
    if (isSearchable) {
      return;
    }

    event.preventDefault();
    setOpened();
    setActiveIndex(optionsCount - 1);
    const lastValue = filteredOptions[optionsCount - 1]?.value;

    if (lastValue) {
      scrollToItem({ from: "end", value: lastValue });
    }
  };

  const onHome: KeyHandler = (event) => {
    if (isSearchable) {
      return;
    }

    event.preventDefault();
    setOpened();
    setActiveIndex(0);
    const firstValue = filteredOptions[0]?.value;

    if (firstValue) {
      scrollToItem({ from: "start", value: firstValue });
    }
  };

  const onBackspace: KeyHandler = () => {
    const lastValue = last(value);

    const noSearchAndHasValue =
      (isNotDefined(searchQuery) ?? searchQuery.length === 0) &&
      isDefined(lastValue);

    if (noSearchAndHasValue) {
      handleRemoveOption(lastValue);
    }
  };

  return (event: KeyboardEvent<HTMLInputElement>) => {
    const keydownMap: KeyboardHandlersMap = {
      " ": onSpace,
      ArrowDown: onArrowDown,
      ArrowUp: onArrowUp,
      Backspace: onBackspace,
      End: onEnd,
      Enter: onEnter,
      Escape: onEscape,
      Home: onHome,
      fallback: noop,
    };

    const handler =
      event.key in keydownMap
        ? keydownMap[event.key as Keys]
        : keydownMap.fallback;

    return handler(event);
  };
};
