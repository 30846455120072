import { first } from "lodash-es";
import type { LoadType } from "../cargaisonUtils/loadUtils";
import type { EnumOrLiteral } from "../typescriptHelpers/enumOrTypeLiteral";

export type FreightMethodOvrUtils = "air" | "ocean" | "rail" | "truck";

const isOrderFullTruckOrVrac = (order: {
  loads: { loadType: EnumOrLiteral<LoadType> }[];
}) => {
  const firstLoad = first(order.loads);

  if (!firstLoad) {
    return false;
  }

  return ["full_truck", "vrac"].includes(firstLoad.loadType);
};

const isOrderFullTruck = (order: {
  loads: { loadType: EnumOrLiteral<LoadType> }[];
}) => {
  const firstLoad = first(order.loads);

  if (firstLoad) {
    return firstLoad.loadType === "full_truck";
  }

  return false;
};

const isCargoFullTruckOrVrac = (loads?: { loadType?: null | string }[]) => {
  const firstLoad = first(loads);

  if (!firstLoad?.loadType) {
    return false;
  }

  return ["full_truck", "vrac"].includes(firstLoad.loadType);
};
const isCargoFullTruck = (loads?: { loadType?: null | string }[]) => {
  const firstLoad = first(loads);

  if (firstLoad) {
    return firstLoad.loadType === "full_truck";
  }

  return false;
};

const isOceanOrRail = (freightMethod: FreightMethodOvrUtils) =>
  ["ocean", "rail"].includes(freightMethod);

const isOceanOrRailAndFcl = (
  freightMethod: FreightMethodOvrUtils,
  loadType?: string,
) => isOceanOrRail(freightMethod) && loadType === "fcl";

const isAir = (freightMethod: string) => freightMethod === "air";

export {
  isAir,
  isCargoFullTruck,
  isCargoFullTruckOrVrac,
  isOceanOrRail,
  isOceanOrRailAndFcl,
  isOrderFullTruck,
  isOrderFullTruckOrVrac,
};
