import type { DOMHandlers, DOMHandlersKeys } from "./types/dom";

const composeHandlers =
  <T extends HTMLElement, V extends DOMHandlersKeys<T>>(
    ...handlers: (DOMHandlers<T>[V] | undefined)[]
  ) =>
  (event: Parameters<NonNullable<DOMHandlers<T>[V]>>[0]) =>
    handlers.forEach((handler) => handler?.(event as any));

export { composeHandlers };
