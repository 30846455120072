import type React from "react";
import { useContext } from "react";
import type { Theme } from "./theme";
import { ThemeContext } from "./serializer";

/** All components are theme-aware, therefore this should only be used
 * in the very rare occasions where theme is not accessible,
 * like mapping colors outside a component. */
export const useOvrseaTheme = <T extends object = Theme>() => {
  const theme = useContext(
    ThemeContext as unknown as React.Context<T | undefined>,
  );

  if (!theme) {
    throw new Error(
      "useTheme: `theme` is undefined. Seems you forgot to wrap your app in `<ThemeProvider />`",
    );
  }

  return theme;
};
