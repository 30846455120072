import type { SyntheticEvent } from "react";
import { useBooleanState } from "../../../utils/hooks/useBooleanState";
import { ariaAttribute } from "../../../utils/attributes";
import { useRadioGroupContext } from "./RadioGroupContext";

export type UseRadioOptions = {
  checked?: boolean;
  option: string;
};

const useRadio = ({ checked: checkedProp, option }: UseRadioOptions) => {
  const { isDisabled, onChange, value } = useRadioGroupContext();
  const [isFocused, setFocused, setBlured] = useBooleanState();

  const isChecked = Boolean(
    checkedProp || (value !== undefined && value === option),
  );

  const onLabelMouseDown = (event: SyntheticEvent) => {
    onChange(option);
    event.preventDefault();
    event.stopPropagation();
  };

  return {
    isChecked: ariaAttribute(isChecked),
    isDisabled,
    isFocused: ariaAttribute(isFocused),
    onInputBlur: setBlured,
    onInputFocus: setFocused,
    onLabelMouseDown,
  } as const;
};

export { useRadio };
