import React from "react";
import { usePrefixedId } from "../../utils/hooks/usePrefixedId";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import type { SystemProps } from "../../utils/types/system";
import { deepHasChild } from "../../utils/children";
import { FormFieldCaption } from "./FormField/FormFieldComponents";
import { FormFieldProvider } from "./FormField/FormFieldProvider";

export type FormFieldContextType = {
  feedbackId?: string;
  id: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  placeholder?: string;
};

type Props = Omit<FormFieldContextType, "feedbackId" | "id">;

export type FormFieldProps = Props & SystemProps<"div">;

export const FormField = polymorphic<"div", Props>(
  ({ children, isDisabled, isErrored, isRequired, ...rest }, ref) => {
    const id = usePrefixedId("field");
    const feedbackId = usePrefixedId("feedback");
    const hasCaption = deepHasChild(children, FormFieldCaption);

    // to control if we need to add an aria-describedby on the field context consumers.
    const hasFeedback = isErrored || hasCaption;

    return (
      <FormFieldProvider
        value={{
          feedbackId: hasFeedback ? feedbackId : undefined,
          id,
          isDisabled,
          isErrored,
          isRequired,
        }}
      >
        <styled.div ref={ref} role="group" {...rest}>
          {children}
        </styled.div>
      </FormFieldProvider>
    );
  },
);

FormField.displayName = "FormField";
