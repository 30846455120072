import React from "react";
import { AnimatePresence } from "../Meta/motion/motion";
import { Portal } from "../Meta/Portal";
import { polymorphic } from "../../utils/ref";
import { ModalProvider } from "./Modal/modalContext";
import type { ModalSize } from "./Modal/ModalComponents";
import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "./Modal/ModalComponents";

export type ModalProps = {
  isOpened?: boolean;
  onClose?: () => void;
  shouldCloseOnEscape?: boolean;
  shouldCloseOnOutsideClick?: boolean;
  size?: ModalSize;
};

type Compounds = {
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
  Header: typeof ModalHeader;
};

export const Modal = polymorphic<"div", ModalProps, Compounds>(
  (
    {
      children,
      isOpened,
      onClose,
      shouldCloseOnEscape,
      shouldCloseOnOutsideClick,
      size,
      ...rest
    },
    ref,
  ) => (
    <ModalProvider
      value={{
        isOpened,
        onClose,
        shouldCloseOnEscape,
        shouldCloseOnOutsideClick,
        size,
      }}
    >
      <AnimatePresence>
        {isOpened && (
          <Portal>
            <ModalContent ref={ref} {...rest}>
              {children}
            </ModalContent>
          </Portal>
        )}
      </AnimatePresence>
    </ModalProvider>
  ),
);

Modal.displayName = "Modal";
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;
