import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import type { SystemProps } from "../../../utils/types/system";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { Divider } from "../../Layout/Divider";
import { HeadlessMenu } from "../../Meta/Headless";
import { Button } from "../../Action/Button";
import { keyframes } from "../../../utils/system";
import { Box } from "../../Meta/Box";

type _MenuItemProps = {
  dataTestId?: string;
  isActive?: boolean;
  isDisabled?: boolean;
};

const StyledMenuItem = styled<"button", _MenuItemProps>("button", {
  base: ({ theme }) => ({
    "&:focus": {
      background: theme.colors.neutral["10"],
    },
    "&[data-disabled]": {
      opacity: 0.3,
      pointerEvents: "none",
    },
    WebkitAppearance: "unset",
    alignItems: "center",
    background: "none",
    border: "none",
    borderRadius: theme.radius.lg,
    color: theme.colors.neutral["70"],
    display: "flex",
    font: theme.font.family.base,
    fontSize: theme.font.size.sm,
    fontWeight: theme.font.weight.medium,
    outline: "none",
    padding: `${theme.spacing[8]} ${theme.spacing[12]}`,
    textAlign: "left",
    userSelect: "none",
    width: "100%",
  }),
});

const DefaultMenuButton = polymorphic<typeof Button>(
  ({ children, ...rest }, ref) => (
    <Button
      {...rest}
      color="neutral"
      icon="chevron-down"
      iconPosition="right"
      label={children}
      ref={ref}
      type="filled"
    />
  ),
);

const MenuButton = polymorphic<typeof Button>(
  ({ as = DefaultMenuButton, children, ...rest }, ref) => {
    return (
      <HeadlessMenu.Trigger {...rest} asChild>
        <Box as={as} ref={ref}>
          {children}
        </Box>
      </HeadlessMenu.Trigger>
    );
  },
);

MenuButton.displayName = "MenuButton";

export type MenuItemProps = _MenuItemProps &
  HeadlessMenu.DropdownMenuItemProps &
  SystemProps<"div">;

const MenuItem = polymorphic<"div", MenuItemProps>(
  ({ children, dataTestId, ...rest }, ref) => (
    <HeadlessMenu.Item
      asChild
      data-testid={dataTestId ? dataTestId : "MenuItem"}
      disabled={rest.isDisabled}
      ref={ref}
      {...rest}
    >
      <StyledMenuItem>{children}</StyledMenuItem>
    </HeadlessMenu.Item>
  ),
);

const enter = keyframes({
  "0%": {
    opacity: "var(--enter-opacity, 1)",
    transform:
      "translate3d(var(--enter-translate-x, 0), var(--enter-translate-y, 0), 0) scale3d(var(--enter-scale, 1), var(--enter-scale, 1), var(--enter-scale, 1))",
  },
});

const StyledMenuContent = styled("div", {
  base: ({ theme }) => ({
    "&&[data-side='bottom']": { "--enter-translate-y": "-8px" },
    "&&[data-side='left']": { "--enter-translate-x": "8px" },
    "&&[data-side='right']": { "--enter-translate-x": "-8px" },
    "&&[data-side='top']": { "--enter-translate-y": "8px" },
    "&[data-state='open']": {
      "--enter-opacity": 0,
      "--enter-scale": 0.95,
      "--enter-translate-x": "initial",
      "--enter-translate-y": "initial",
      animationDuration: theme.transition.duration.fast,
      animationName: enter,
    },
    background: theme.colors.background.light,
    borderRadius: theme.radius.md,
    boxShadow: `${theme.shadows.md}, 0 0 0 1px rgb(0 0 0 / 5%)`,
    outline: "none",
    overflow: "hidden",
    padding: theme.spacing[4],
    width: 210,
    zIndex: theme.zIndex.popover,
  }),
});

export type MenuContentProps = DropdownMenuPrimitive.DropdownMenuContentProps &
  SystemProps<"div">;

const MenuContent = polymorphic<typeof StyledMenuContent, MenuContentProps>(
  ({ align = "start", children, side = "bottom", sx, ...rest }, ref) => (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        align={align}
        asChild
        ref={ref}
        side={side}
        sideOffset={4}
        {...rest}
      >
        <StyledMenuContent sx={sx}>{children}</StyledMenuContent>
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  ),
);

const MenuDivider = polymorphic<typeof Divider>((props, ref) => (
  <Divider orientation="horizontal" spacing={4} {...props} ref={ref} />
));

export { MenuButton, MenuContent, MenuDivider, MenuItem };
