// For more information on formatting: https://day.js.org/docs/en/display/format
export type DateFormat = {
  en: string;
  es: string;
  fr: string;
  it: string;
};

const dayMonth: DateFormat = {
  en: "MMMM DD",
  es: "DD MMMM",
  fr: "DD MMMM",
  it: "DD MMMM",
};

const dayMonthInverse: DateFormat = {
  en: "MMM DD",
  es: "MMM DD",
  fr: "MMM DD",
  it: "MMM DD",
};

const dayMonthNumerical: DateFormat = {
  en: "MMM DD",
  es: "DD MMM",
  fr: "DD MMM",
  it: "DD MMM",
};

const dayMonthYearNumerical: DateFormat = {
  en: "MMM DD, YYYY",
  es: "DD MMM YYYY",
  fr: "DD MMM YYYY",
  it: "DD MMM YYYY",
};

const dayMonthYearOrdinal: DateFormat = {
  en: "dddd, DD MMMM YYYY",
  es: "dddd DD MMMM YYYY",
  fr: "dddd DD MMMM YYYY",
  it: "dddd DD MMMM YYYY",
};

const dayMonthOrdinal: DateFormat = {
  en: "MMMM Do",
  es: "Do MMMM",
  fr: "Do MMMM",
  it: "Do MMMM",
};

const dayMonthTime: DateFormat = {
  en: "MMMM DD [at] hh:mm A",
  es: "DD MMMM [a las] HH[h]mm",
  fr: "DD MMMM à HH[h]mm",
  it: "DD MMMM [alle] HH:mm",
};

const dayMonthTimeNumerical: DateFormat = {
  en: "MMM DD [at] hh:mm A",
  es: "DD MMM [a las] HH[h]mm",
  fr: "DD MMM à HH[h]mm",
  it: "DD MMM [alle] HH:mm",
};

const dayMonthYearTimeNumerical: DateFormat = {
  en: "MMM DD YYYY [at] hh:mm A",
  es: "DD MMM YYYY [a las] HH[h]mm",
  fr: "DD MMM YYYY à HH[h]mm",
  it: "DD MMM YYYY [alle] HH:mm",
};

const dayMonthWeek: DateFormat = {
  en: "MMMM DD ([Week] w)",
  es: "DD MMMM ([Semana] w)",
  fr: "DD MMMM ([Semaine] w)",
  it: "DD MMMM ([Settimana] w)",
};

const dayMonthTimeCompact: DateFormat = {
  en: "MMMM DD hh:mm A",
  es: "DD MMMM HH[h]mm",
  fr: "DD MMMM HH[h]mm",
  it: "DD MMMM HH:mm",
};

const dayMonthYear: DateFormat = {
  en: "MMM DD YYYY",
  es: "DD MMM YYYY",
  fr: "DD MMM YYYY",
  it: "DD MMM YYYY",
};

const hoursMinutes: DateFormat = {
  en: "hh:mm A",
  es: "HH:mm",
  fr: "HH:mm",
  it: "HH:mm",
};

export {
  dayMonth,
  dayMonthInverse,
  dayMonthNumerical,
  dayMonthOrdinal,
  dayMonthTime,
  dayMonthTimeCompact,
  dayMonthTimeNumerical,
  dayMonthWeek,
  dayMonthYear,
  dayMonthYearNumerical,
  dayMonthYearOrdinal,
  dayMonthYearTimeNumerical,
  hoursMinutes,
};
