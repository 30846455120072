import { groupBy, includes, sumBy } from "lodash-es";
import type { EnumOrLiteral } from "../typescriptHelpers/enumOrTypeLiteral";
import { isDefined } from "../other/isDefined";

export const CONTAINER_TYPES = [
  "twenty_foot_standard",
  "forty_foot_standard",
  "forty_foot_highcube_standard",
  "forty_five_foot_highcube_standard",
  "twenty_foot_reefer",
  "forty_foot_reefer",
  "forty_foot_highcube_reefer",
  "twenty_foot_open_top",
  "forty_foot_open_top",
  "forty_foot_highcube_open_top",
  "twenty_foot_flat_rack",
  "forty_foot_flat_rack",
  "forty_foot_highcube_flat_rack",
  "twenty_foot_non_operating_reefer",
  "forty_foot_non_operating_reefer",
  "forty_foot_high_cube_non_operating_reefer",
] as const satisfies Readonly<string[]>;

export type ContainerType = (typeof CONTAINER_TYPES)[number];

export type ContainerCapacity = {
  maximumPayloadInKgs: number;
  volumeCapacityInCubicMeters: number;
};

export const CONTAINER_CAPACITY: Record<ContainerType, ContainerCapacity> = {
  forty_five_foot_highcube_standard: {
    maximumPayloadInKgs: 27860,
    volumeCapacityInCubicMeters: 86,
  },
  forty_foot_flat_rack: {
    maximumPayloadInKgs: 40000,
    volumeCapacityInCubicMeters: 62.2,
  },
  forty_foot_high_cube_non_operating_reefer: {
    maximumPayloadInKgs: 29520,
    volumeCapacityInCubicMeters: 67.3,
  },
  forty_foot_highcube_flat_rack: {
    maximumPayloadInKgs: 47300,
    volumeCapacityInCubicMeters: 66.7,
  },
  forty_foot_highcube_open_top: {
    maximumPayloadInKgs: 25580,
    volumeCapacityInCubicMeters: 67.2,
  },
  forty_foot_highcube_reefer: {
    maximumPayloadInKgs: 29520,
    volumeCapacityInCubicMeters: 67.3,
  },
  forty_foot_highcube_standard: {
    maximumPayloadInKgs: 28560,
    volumeCapacityInCubicMeters: 76.4,
  },
  forty_foot_non_operating_reefer: {
    maximumPayloadInKgs: 27700,
    volumeCapacityInCubicMeters: 59.3,
  },
  forty_foot_open_top: {
    maximumPayloadInKgs: 26630,
    volumeCapacityInCubicMeters: 66.4,
  },
  forty_foot_reefer: {
    maximumPayloadInKgs: 27700,
    volumeCapacityInCubicMeters: 59.3,
  },
  forty_foot_standard: {
    maximumPayloadInKgs: 27600,
    volumeCapacityInCubicMeters: 67.7,
  },
  twenty_foot_flat_rack: {
    maximumPayloadInKgs: 30140,
    volumeCapacityInCubicMeters: 32.7,
  },
  twenty_foot_non_operating_reefer: {
    maximumPayloadInKgs: 27400,
    volumeCapacityInCubicMeters: 28.3,
  },

  twenty_foot_open_top: {
    maximumPayloadInKgs: 28130,
    volumeCapacityInCubicMeters: 32.5,
  },
  twenty_foot_reefer: {
    maximumPayloadInKgs: 27400,
    volumeCapacityInCubicMeters: 28.3,
  },
  twenty_foot_standard: {
    maximumPayloadInKgs: 25000,
    volumeCapacityInCubicMeters: 33.2,
  },
};

export const twentyContainerTypes: ContainerType[] = [
  "twenty_foot_flat_rack",
  "twenty_foot_open_top",
  "twenty_foot_reefer",
  "twenty_foot_standard",
  "twenty_foot_non_operating_reefer",
];

export const fortyContainerTypes: ContainerType[] = [
  "forty_five_foot_highcube_standard",
  "forty_foot_flat_rack",
  "forty_foot_highcube_flat_rack",
  "forty_foot_highcube_open_top",
  "forty_foot_highcube_reefer",
  "forty_foot_highcube_standard",
  "forty_foot_open_top",
  "forty_foot_reefer",
  "forty_foot_standard",
  "forty_foot_non_operating_reefer",
  "forty_foot_high_cube_non_operating_reefer",
];

export type ContainerOvrutils = {
  containerType: EnumOrLiteral<ContainerType>;
  hazardous?: boolean | null;
  hazardousDetails?: null | string;
  quantity?: number;
};

const countContainerTeusQuantity = (container: ContainerOvrutils) => {
  if (twentyContainerTypes.includes(container.containerType)) {
    return container.quantity ?? 1;
  }
  if (fortyContainerTypes.includes(container.containerType)) {
    return isDefined(container.quantity) ? container.quantity * 2 : 2;
  }

  return 0;
};

export const countTeus = (containers: ContainerOvrutils[]) => {
  return sumBy(containers, countContainerTeusQuantity);
};

export const countContainersByType = (containerArray: ContainerOvrutils[]) => ({
  nbForty: containerArray.filter((c) =>
    fortyContainerTypes.includes(c.containerType),
  ).length,
  nbTwenty: containerArray.filter((c) =>
    twentyContainerTypes.includes(c.containerType),
  ).length,
  total: containerArray.length,
});

export const CONTAINER_TYPES_LABELS: { [key in ContainerType]: string } = {
  // CURRENT
  forty_five_foot_highcube_standard: "45' High Cube",
  forty_foot_flat_rack: "40' Flat Rack",
  forty_foot_high_cube_non_operating_reefer: "40' Non-operating-reefer HC",
  forty_foot_highcube_flat_rack: "40'HC Flat Rack",
  forty_foot_highcube_open_top: "40'HC Open Top",
  forty_foot_highcube_reefer: "40'HC Reefer",
  forty_foot_highcube_standard: "40' High Cube",
  forty_foot_non_operating_reefer: "40' Non-operating-reefer",
  forty_foot_open_top: "40' Open Top",
  forty_foot_reefer: "40' Reefer",
  forty_foot_standard: "40' Standard",
  twenty_foot_flat_rack: "20' Flat Rack",
  twenty_foot_non_operating_reefer: "20' Non-operating-reefer",
  twenty_foot_open_top: "20' Open Top",
  twenty_foot_reefer: "20' Reefer",
  twenty_foot_standard: "20' Standard",
};

export const mapContainerTypeToLabel = (
  containerType: EnumOrLiteral<ContainerType>,
): string => CONTAINER_TYPES_LABELS[containerType];

export const CONTAINER_TYPES_SHORT_LABELS: {
  [key in ContainerType]: string;
} = {
  // CURRENT
  forty_five_foot_highcube_standard: "45' HC",
  forty_foot_flat_rack: "40' FR",
  forty_foot_high_cube_non_operating_reefer: "40' NOR HC",
  forty_foot_highcube_flat_rack: "40' HC FR",
  forty_foot_highcube_open_top: "40' HC OT",
  forty_foot_highcube_reefer: "40' HC RF",
  forty_foot_highcube_standard: "40' HC",
  forty_foot_non_operating_reefer: "40' NOR",
  forty_foot_open_top: "40' OT",
  forty_foot_reefer: "40' RF",
  forty_foot_standard: "40' ST",
  twenty_foot_flat_rack: "20' FR",
  twenty_foot_non_operating_reefer: "20' NOR",
  twenty_foot_open_top: "20' OT",
  twenty_foot_reefer: "20' RF",
  twenty_foot_standard: "20' ST",
};

export const mapContainerTypeToShortLabel = (
  containerType: ContainerType,
): string => CONTAINER_TYPES_SHORT_LABELS[containerType];

export const containersToString = (
  containers: { containerType: ContainerType | null }[],
  separator = "\n",
): null | string => {
  if (containers.length > 0) {
    return Object.entries(groupBy(containers, (c) => c.containerType))
      .map(
        (keyValue) =>
          `${keyValue[1].length} x ${mapContainerTypeToLabel(
            keyValue[0] as ContainerType,
          )}`,
      )
      .join(separator);
  }

  return null;
};

export const containersToShortString = (
  containers: { containerType: ContainerType | null }[],
  separator = "\n",
): null | string => {
  if (containers.length > 0) {
    return Object.entries(groupBy(containers, (c) => c.containerType))
      .map(
        (keyValue) =>
          `${keyValue[1].length} x ${mapContainerTypeToShortLabel(
            keyValue[0] as ContainerType,
          )}`,
      )
      .join(separator);
  }

  return null;
};

export const PREFERRED_CONTAINER_OPTIONS_ORDER: ContainerType[] = [
  "twenty_foot_standard",
  "forty_foot_standard",
  "forty_foot_highcube_standard",
  "forty_five_foot_highcube_standard",
  "twenty_foot_reefer",
  "forty_foot_reefer",
  "forty_foot_highcube_reefer",
  "twenty_foot_flat_rack",
  "forty_foot_flat_rack",
  "forty_foot_highcube_flat_rack",
  "twenty_foot_open_top",
  "forty_foot_open_top",
  "forty_foot_highcube_open_top",
];

const sortByReference = (reference: string[]) => (a: string, b: string) => {
  const aIndex = reference.indexOf(a);
  const bIndex = reference.indexOf(b);

  if (aIndex === -1 && bIndex === -1) {
    return 0;
  }

  if (aIndex === -1) {
    return 1;
  }

  if (bIndex === -1) {
    return -1;
  }

  return aIndex - bIndex;
};

export const sortContainersByPreferredType = (
  a: ContainerType,
  b: ContainerType,
) => {
  return sortByReference(PREFERRED_CONTAINER_OPTIONS_ORDER)(a, b);
};

export const isContainerType = (
  str: null | string | undefined,
): str is ContainerType => includes(CONTAINER_TYPES, str);
