import type {
  ComponentProps,
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  Ref,
  SyntheticEvent,
} from "react";
import React from "react";
import { compact, keys } from "lodash-es";
import { polymorphic } from "../../../utils/ref";
import { ariaAttribute, dataAttribute } from "../../../utils/attributes";
import { StyledInput } from "../Input/BaseInput";
import { styled } from "../../../utils/system/factory";
import { Popover } from "../../Overlay/Popover";
import { HStack, VStack } from "../../Layout/Stack";
import type { Sx } from "../../../utils/types/system";
import type { SetItemRef } from "../../../utils/hooks/useRefList";
import { Divider } from "../../Layout/Divider";
import {
  SelectAction,
  SelectCreateOption,
  SelectEmptyStates,
  SelectGroupLabel,
  SelectIcon,
  SelectOption,
} from "../Shared/SelectComponents";
import type { SelectOptionType } from "../Shared/types";
import {
  findSelectedIndex,
  groupOptions,
  hasOtherGroups,
} from "../Shared/utils";
import { Spinner } from "../../Feedback/Spinner";
import { Icon } from "../../Typography/Icon";
import { Flex } from "../../Layout/Flex";

const containerSx: Sx = (theme) => ({
  "& input + div button:is(:focus, :hover):not(:disabled) svg": {
    color: theme.colors.neutral.base,
  },
  "& input:is(:focus, :hover) + div button:not(:disabled) svg": {
    color: theme.colors.neutral.base,
  },
  "& input[readonly]": {
    cursor: "pointer",
  },
});

const StyledInnerInput = styled<"input", ComponentProps<"input">>("input", {
  base: ({ theme }) => [
    {
      background: "transparent",
      flex: 1,
      lineHeight: "24px",
      outline: "none",
      overflow: "hidden",
      width: "100%",
    },
    {
      "&:placeholder-shown": {
        textOverflow: "ellipsis",
      },
    },
    {
      "&:disabled": {
        cursor: "not-allowed",
        userSelect: "none",
      },
    },
    {
      "&[data-hasvalues]:not([data-opened])::placeholder": {
        color: theme.colors.neutral.base,
      },
    },
    {
      "&:focus-visible": {
        outline: "none",
      },
    },
  ],
});

const rightElementsSx: Sx = ({ spacing }) => ({
  height: spacing[40],
  position: "absolute",
  right: spacing[12],
});

const outerInputSx: Sx = (theme) => ({
  alignItems: "center",
  background: theme.colors.background.light,
  display: "flex",
  flex: 1,
  height: "unset",
  paddingBlock: 6,
});

const errorSx: Sx = ({ colors }) => ({
  color: colors.alert.danger,
});

type SelectIconsProps = {
  hasValue: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
  isLoading?: boolean;
  onClear: (event: SyntheticEvent) => void;
  onOpen: () => void;
};

const SelectIcons = ({
  hasValue,
  isClearable,
  isDisabled,
  isErrored,
  isLoading,
  onClear,
  onOpen,
}: SelectIconsProps) => (
  <HStack alignItems="center" spacing={12} sx={rightElementsSx}>
    {isLoading && <Spinner isActive size="sm" />}
    {!isLoading && (
      <SelectIcon
        hasValue={hasValue}
        isClearable={isClearable}
        isDisabled={isDisabled}
        onClear={onClear}
        onOpen={onOpen}
      />
    )}
    {isErrored && <Icon name="error-circle" sx={errorSx} />}
  </HStack>
);

type PopulatedComponentParams = {
  isDisabled?: boolean;
  isErrored?: boolean;
  isLoading?: boolean;
  onReset: (event: SyntheticEvent) => void;
  value: string;
};

type SelectTriggerProps = {
  activeIndex: number;
  ariaActiveDescendant?: string;
  ariaControls?: string;
  autoFocus?: boolean;
  filledValueComponent?: (params: PopulatedComponentParams) => ReactNode;
  inputId: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
  isLoading?: boolean;
  isOpened: boolean;
  isSearchable: boolean;
  onChange: (value: string) => void;
  onClear: (event: SyntheticEvent, stopPropagation: boolean) => void;
  onOpen: () => void;
  placeholder?: string;
  searchQuery: string;
  selectedLabel?: string;
  setActiveOption: (id: number) => void;
  sx?: Sx;
  value?: string;
};

const SelectTrigger = polymorphic<"input", SelectTriggerProps>(
  (
    {
      ariaActiveDescendant,
      ariaControls,
      autoFocus,
      filledValueComponent,
      inputId,
      isClearable,
      isDisabled,
      isErrored,
      isLoading,
      isOpened,
      isSearchable = true,
      onChange,
      onClear,
      onClick,
      onOpen,
      placeholder,
      searchQuery,
      selectedLabel,
      setActiveOption,
      sx,
      value,
      ...rest
    },
    ref,
  ) => {
    const displayFilledValueComponent = filledValueComponent && value;

    return (
      <Popover.Trigger>
        <styled.div
          aria-controls={inputId}
          aria-expanded={ariaAttribute(isOpened)}
          aria-haspopup="listbox"
          aria-owns={ariaControls}
          onClick={onClick}
          onMouseLeave={() => setActiveOption(-1)}
          role="combobox"
          sx={[containerSx, sx]}
          tabIndex={-1}
        >
          {displayFilledValueComponent ? (
            <div
              data-disabled={dataAttribute(isDisabled)}
              style={{ cursor: "pointer" }}
              tabIndex={undefined}
            >
              {filledValueComponent({
                isDisabled,
                isErrored,
                isLoading,
                onReset: (e) => {
                  onClear(e, false);
                  onOpen();
                },
                value,
              })}
            </div>
          ) : (
            <Flex alignItems="center" sx={{ position: "relative" }}>
              <StyledInput
                as="div"
                className={compact(["field-root", rest.className]).join(" ")}
                data-disabled={dataAttribute(isDisabled)}
                disabled={isDisabled}
                hasRightElement
                isDisabled={isDisabled}
                isErrored={isErrored}
                isLoading={isLoading}
                sx={outerInputSx}
                tabIndex={undefined}
                {...rest}
              >
                <StyledInnerInput
                  autoComplete="off"
                  autoFocus={autoFocus}
                  data-hasvalues={ariaAttribute(!!selectedLabel)}
                  data-isopened={ariaAttribute(!!isOpened)}
                  data-value={value}
                  disabled={isDisabled}
                  onChange={(event) => onChange(event.target.value)}
                  placeholder={selectedLabel || placeholder}
                  readOnly={!isSearchable || isDisabled}
                  ref={ref}
                  type="search"
                  value={searchQuery}
                />
              </StyledInput>
              <SelectIcons
                hasValue={Boolean(value)}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isErrored={isErrored}
                isLoading={isLoading}
                onClear={(e) => onClear(e, true)}
                onOpen={onOpen}
              />
            </Flex>
          )}
        </styled.div>
      </Popover.Trigger>
    );
  },
);

type OptionGroupProps = {
  activeIndex: number;
  dataTestId?: string;
  group: string;
  groupOptions: SelectOptionType[];
  handleListClick: MouseEventHandler<HTMLDivElement>;
  handleSelect: (value: string) => void;
  options: SelectOptionType[];
  setActiveIndex: (index: number) => void;
  setItemRef: SetItemRef<HTMLDivElement>;
  value?: string;
};

const OptionGroup = ({
  activeIndex,
  dataTestId,
  group,
  groupOptions,
  handleListClick,
  handleSelect,
  options,
  setActiveIndex,
  setItemRef,
  value,
}: OptionGroupProps) => (
  <>
    <SelectGroupLabel group={group} />
    <VStack
      data-testid={dataTestId && `${dataTestId}-listbox`}
      onMouseDown={handleListClick}
      padding={4}
      role="listbox"
      spacing={4}
    >
      {groupOptions.map(({ description, label, value: optionValue }) => {
        const selectedIndex = findSelectedIndex(optionValue)(options);

        return (
          <SelectOption
            description={description}
            handleSelect={handleSelect}
            isActive={activeIndex === selectedIndex}
            isSelected={value === optionValue}
            key={optionValue}
            label={label}
            optionValue={optionValue}
            selectedIndex={selectedIndex}
            setActiveIndex={setActiveIndex}
            setItemRef={setItemRef}
          />
        );
      })}
    </VStack>
  </>
);

OptionGroup.displayName = "OptionGroup";

const contentInnerStyle = (width: CSSProperties["width"]): Sx => ({
  "& > div": {
    overflow: "hidden",
  },
  minWidth: width,
  width: "var(--radix-popover-trigger-width)",
});

const contentSx: Sx = (theme) => [
  {
    "& div[role='option']": {
      "& .description": {
        color: theme.colors.text.secondary,
      },
      "& > div": {
        minWidth: 0,
      },
      alignItems: "center",
      borderRadius: theme.radius.base,
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      padding: `${theme.spacing[4]} ${theme.spacing[8]}`,
    },
  },
  {
    "& div[role='option'][data-active]": {
      "& .description": {
        color: theme.colors.neutral["60"],
      },
      background: theme.colors.neutral["10"],
    },
  },
  {
    "& div[role='option'][aria-selected]": {
      "& .description": {
        color: theme.colors.neutral["60"],
      },
      background: theme.colors.primary.light,
      color: theme.colors.primary.base,
    },
  },
];

const overflowContainer = (maxHeight = 220): CSSProperties => ({
  maxHeight,
  overflow: "auto",
});

type SelectOptionsProps = {
  activeIndex: number;
  canCreate: boolean;
  creatableMessage?: ReactNode;
  dataTestId?: string;
  handleCreate: (value: string) => void;
  handleListClick: MouseEventHandler<HTMLDivElement>;
  handleSelect: (value: string) => void;
  initialOptionCount: number;
  maxMenuHeight?: number;
  menuOffset?: number;
  menuWidth: CSSProperties["width"];
  noOptionsMessage?: ReactNode;
  nothingFoundMessage?: ReactNode;
  options: SelectOptionType[];
  renderAction?: (search: string) => ReactNode;
  scrollableRef: Ref<HTMLDivElement>;
  searchQuery: string;
  setActiveIndex: (index: number) => void;
  setItemRef: SetItemRef<HTMLDivElement>;
  value?: string;
};

const SelectOptions = polymorphic<"div", SelectOptionsProps>(
  (
    {
      activeIndex,
      canCreate,
      creatableMessage,
      dataTestId,
      handleCreate,
      handleListClick,
      handleSelect,
      initialOptionCount,
      maxMenuHeight,
      menuOffset,
      menuWidth,
      noOptionsMessage,
      nothingFoundMessage,
      options,
      renderAction,
      scrollableRef,
      searchQuery,
      setActiveIndex,
      setItemRef,
      value,
    },
    ref,
  ) => {
    const creatableIndex = options.length;

    const groups = groupOptions(options);

    return (
      <Popover.Content
        data-animatescale={false}
        data-testid="select-portal"
        innerSx={contentInnerStyle(menuWidth)}
        onCloseAutoFocus={(event) => event.preventDefault()}
        preventAutoFocus
        ref={ref}
        sideOffset={menuOffset}
        sx={contentSx}
      >
        <div ref={scrollableRef} style={overflowContainer(maxMenuHeight)}>
          <SelectEmptyStates
            canCreate={canCreate}
            initialOptionCount={initialOptionCount}
            noOptionsMessage={noOptionsMessage}
            nothingFoundMessage={nothingFoundMessage}
            optionCount={options.length}
          />
          {keys(groups).map((group) => (
            <OptionGroup
              activeIndex={activeIndex}
              dataTestId={dataTestId}
              group={group}
              groupOptions={groups[group]}
              handleListClick={handleListClick}
              handleSelect={handleSelect}
              key={group}
              options={options}
              setActiveIndex={setActiveIndex}
              setItemRef={setItemRef}
              value={value}
            />
          ))}
          {canCreate && hasOtherGroups(options) && <Divider />}
          {canCreate && (
            <SelectCreateOption
              isActive={activeIndex === creatableIndex}
              label={creatableMessage}
              onMouseEnter={() => setActiveIndex(creatableIndex)}
              onPointerDown={() => handleCreate(searchQuery)}
              ref={setItemRef("creatable")}
            >
              {searchQuery}
            </SelectCreateOption>
          )}
        </div>
        {renderAction && (
          <SelectAction>{renderAction(searchQuery)}</SelectAction>
        )}
      </Popover.Content>
    );
  },
);

export { SelectOptions, SelectTrigger };
