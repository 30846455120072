import type { FreightMethodOvrUtils } from "../../orderUtils/orderFreightMethodSelectors";

type ShipownerType = "agent" | "carrier" | "gsa" | "logistics";
export type { ShipownerType as ShipownerTypeOvrutils };

export const isAirlineShipowner = (shipowner: {
  freightMethod: FreightMethodOvrUtils | null | undefined;
  shipownerType: ShipownerType;
}) =>
  shipowner.shipownerType === "carrier" && shipowner.freightMethod === "air";

export const isOceanCarrierShipowner = (shipowner: {
  freightMethod: FreightMethodOvrUtils | null | undefined;
  shipownerType: ShipownerType;
}) =>
  shipowner.shipownerType === "carrier" && shipowner.freightMethod === "ocean";

export const isTruckShipowner = (shipowner: {
  freightMethod: FreightMethodOvrUtils | null | undefined;
  shipownerType: ShipownerType;
}) =>
  shipowner.shipownerType === "carrier" && shipowner.freightMethod === "truck";

export const isRailShipowner = (shipowner: {
  freightMethod: FreightMethodOvrUtils | null | undefined;
  shipownerType: ShipownerType;
}) =>
  shipowner.shipownerType === "carrier" && shipowner.freightMethod === "rail";

export const isGsaShipowner = (shipowner: { shipownerType: ShipownerType }) =>
  shipowner.shipownerType === "gsa";

export const isAgentShipowner = (shipowner: { shipownerType: ShipownerType }) =>
  shipowner.shipownerType === "agent";

export const isLogisticsShipowner = (shipowner: {
  shipownerType: ShipownerType;
}) => shipowner.shipownerType === "logistics";

export const isParentShipowner = (shipowner: {
  affiliatedShipowners: { id: string }[];
  freightMethod: FreightMethodOvrUtils | null;
  shipownerType: ShipownerType;
}) =>
  (isAgentShipowner(shipowner) || isOceanCarrierShipowner(shipowner)) &&
  !!shipowner.affiliatedShipowners.length;
