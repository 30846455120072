import { styled } from "../../../utils/system/factory";

export const RadioCircle = styled("span", {
  base: ({ theme }) => [
    {
      '&[aria-checked="true"]': {
        "&::before": {
          background: "currentcolor",
          borderRadius: "50%",
          content: '""',
          display: "inline-block",
          height: "50%",
          position: "relative",
          width: "50%",
        },
        background: theme.colors.primary.base,
        border: `2px solid ${theme.colors.primary.base}`,
        color: "white",
      },
      alignItems: "center",
      border: `1px solid ${theme.colors.neutral[50]}`,
      borderRadius: theme.radius.round,
      color: "white",
      display: "inline-flex",
      flexShrink: "0",
      height: "16px",
      justifyContent: "center",
      marginLeft: "auto",
      width: "16px",
    },
    {
      "&[data-disabled]": {
        background: "none",
        borderColor: theme.colors.neutral["30"],
        pointerEvents: "none",
      },
    },
    {
      '&[data-disabled][aria-checked="true"]': {
        background: theme.colors.neutral["30"],
        pointerEvents: "none",
      },
    },
  ],
});
