import type { PropsWithChildren } from "react";
import React, { memo, useMemo } from "react";
import type { EmotionCache } from "@emotion/cache";
import type { Theme } from "./theme";
import { theme as DEFAULT_THEME } from "./theme";
import { serializerCache } from "./serializer/cache";
import { SerializerCacheProvider, SerializerThemeProvider } from "./serializer";
import { Preflight } from "./head/Preflight";
import { enhanceTheme } from "./transform/transform";
import type { DependenciesContext } from "./dependencies";
import { DEFAULT_DEPENDENCIES, DependenciesProvider } from "./dependencies";

type Props = {
  cache?: EmotionCache;
  dependencies?: DependenciesContext;
  isNested?: boolean;
  overrideColors?: Partial<Theme["colors"]>;
  theme?: Theme;
};

export type ThemeProviderDependencies = DependenciesContext;

const ThemeProvider = memo(
  ({
    cache = serializerCache,
    children,
    dependencies = DEFAULT_DEPENDENCIES,
    isNested,
    overrideColors,
    theme = DEFAULT_THEME,
  }: PropsWithChildren<Props>) => {
    const themeWithOverridenColors: Theme = {
      ...theme,
      colors: { ...theme.colors, ...overrideColors },
    };
    const enhancedTheme = useMemo(
      () => enhanceTheme(themeWithOverridenColors),
      [themeWithOverridenColors],
    );

    return (
      <DependenciesProvider value={dependencies}>
        <SerializerCacheProvider value={cache}>
          <SerializerThemeProvider theme={enhancedTheme}>
            {!isNested && <Preflight />}
            {children}
          </SerializerThemeProvider>
        </SerializerCacheProvider>
      </DependenciesProvider>
    );
  },
);

ThemeProvider.displayName = "ThemeProvider";

export { ThemeProvider };
