import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import {
  PaginationNextPage,
  PaginationPage,
  PaginationPagesBlock,
  PaginationPreviousPage,
} from "./Pagination/PaginationComponents";
import {
  generatePaginationBlocks,
  paginationController,
} from "./Pagination/pagination";
import { PaginationProvider } from "./Pagination/PaginationContext";

const StyledNav = styled("nav", {
  base: ({ theme }) => ({
    alignItems: "center",
    display: "flex",
    gap: theme.spacing[8],
  }),
});

type Props = {
  activePage: number;
  hasPageBlocks?: boolean;
  onChange: (page: number) => void;
  pagesCount: number;
};

export type PaginationProps = Props & SystemProps<"nav">;

export const Pagination = polymorphic<"nav", Props>(
  (
    { activePage, hasPageBlocks = true, onChange, pagesCount, ...rest },
    ref,
  ) => {
    const { goToNextPage, goToPage, goToPreviousPage } = paginationController({
      activePage,
      pagesCount,
      setPage: onChange,
    });

    const pages = generatePaginationBlocks({
      currentPage: activePage,
      pagesCount,
    });

    return (
      <PaginationProvider
        value={{
          activePage,
          goToNextPage,
          goToPage,
          goToPreviousPage,
          pagesCount,
        }}
      >
        <StyledNav aria-label="pagination" marginTop={4} ref={ref} {...rest}>
          <PaginationPreviousPage />
          {hasPageBlocks && (
            <PaginationPagesBlock>
              {pages.map((page) => (
                <PaginationPage key={page} page={page} />
              ))}
            </PaginationPagesBlock>
          )}
          <PaginationNextPage />
        </StyledNav>
      </PaginationProvider>
    );
  },
);

Pagination.displayName = "Pagination";
