import { computeRelativeDistance } from "./scroll";

export type ScrollFrom = "end" | "start";

type Params = {
  child: HTMLElement | null;
  from?: ScrollFrom;
  offset?: number;
  parent: HTMLElement | null;
};

export const scrollIntoView = ({
  child,
  from = "end",
  offset,
  parent,
}: Params) => {
  if (!child || !parent) {
    return;
  }

  const distance = computeRelativeDistance({
    child,
    offset,
    parent,
  })[from];

  const distanceWithOffset = distance + parent.scrollTop;

  parent.scrollTop = distanceWithOffset;
};
