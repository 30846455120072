import React from "react";
import { RemoveScroll } from "react-remove-scroll";
import { FocusLock } from "../../Meta/FocusLock";
import { polymorphic } from "../../../utils/ref";
import { OverlayMotion } from "../../Meta/OverlayMotion";
import { styled } from "../../../utils/system/factory";
import { useOverlayContext } from "./overlayContext";

const OverlayDimmer = styled(OverlayMotion, {
  base: ({ theme }) => ({
    background: "rgba(0, 0, 0, 0.48)",
    zIndex: theme.zIndex.modal,
  }),
});

OverlayDimmer.displayName = "OverlayDimmer";

const OverlayContent = polymorphic<"div">(({ children, ...rest }, ref) => {
  const {
    contentRef,
    onContentClick,
    onContentKeyDown,
    onContentMouseDown,
    returnFocusRef,
    shouldTrapFocus,
  } = useOverlayContext();

  return (
    <div
      ref={ref}
      {...rest}
      onClick={onContentClick}
      onKeyDown={onContentKeyDown}
      onMouseDown={onContentMouseDown}
    >
      <FocusLock
        contentRef={contentRef}
        isDisabled={!shouldTrapFocus}
        returnFocusRef={returnFocusRef}
      >
        <RemoveScroll allowPinchZoom enabled removeScrollBar>
          {children}
        </RemoveScroll>
      </FocusLock>
    </div>
  );
});

OverlayContent.displayName = "OverlayContent";

export { OverlayContent, OverlayDimmer };
