import type { FormEvent } from "react";
import React from "react";
import type { SystemProps } from "../../utils/types/system";
import { polymorphic } from "../../utils/ref";
import { noop } from "../../utils/events";
import type { DirectionProp, SpacingProp, StackProps } from "./Stack";
import { VStack } from "./Stack";
import type { _FlexProps } from "./Flex";

export type FormProps = _FlexProps &
  DirectionProp &
  SpacingProp &
  SystemProps<"form">;

export const Form = polymorphic<"form", FormProps>(
  ({ onSubmit = noop, ...rest }, ref) => (
    <VStack
      as="form"
      ref={ref}
      {...(rest as StackProps)}
      onSubmit={(event) => {
        event.preventDefault();

        return onSubmit(event as unknown as FormEvent<HTMLFormElement>);
      }}
    />
  ),
);
