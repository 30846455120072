import type { OpUnitType, QUnitType } from "dayjs";
import { dayjs } from "./ovrDayjs";

type TimestampInMilliseconds = number;
export const calculateDiffInDaysBetweenTwoDates = ({
  newTimestamp,
  oldTimestamp,
  timezone = "UTC",
}: {
  newTimestamp: TimestampInMilliseconds;
  oldTimestamp: TimestampInMilliseconds;
  timezone?: string;
}) => {
  const firstStartOfDay = dayjs(oldTimestamp).tz(timezone).startOf("day");
  const secondStartOfDay = dayjs(newTimestamp).tz(timezone).startOf("day");

  return secondStartOfDay.diff(firstStartOfDay, "day");
};

export const calculateDiffBetweenTwoDates = ({
  firstDate,
  format = "hour",
  secondDate,
}: {
  firstDate: Date;
  format?: OpUnitType | QUnitType;
  secondDate: Date;
}) => {
  return dayjs(secondDate).diff(dayjs(firstDate), format);
};

export const calculateDiffBetweenTwoUnixTimestamps = ({
  end,
  format = "hour",
  start,
}: {
  end: number;
  format?: OpUnitType | QUnitType;
  start: number;
}) => {
  return dayjs.unix(end).diff(dayjs.unix(start), format);
};
