import { sum } from "lodash-es";
import { computeVAT } from "../pricesUtils/priceUtils";

type Category = {
  prices: Array<{
    amount: number | string;
    vatRate?: null | number;
  }>;
};

export const calculateSumOfCategoriesVat = (categories: Category[]) => {
  const allPrices = categories.flatMap((category) => category.prices);

  return sum(
    allPrices.map(({ amount, vatRate }) =>
      computeVAT({
        total: Number(amount),
        vatRate,
        withVat: true,
      }),
    ),
  );
};
