// A mapper is a function that will map a source object to a property in the destination
type Mapper<Source, Destination, property extends keyof Destination> = (
  source: Source,
) => Destination[property];

/**
 * The Mappers are all the function needed to map a source object to a destination object
 * we have some implicit mappers that will be optional if the property already exists in the source object
 * and is compatible with the destination property
 *
 * Example:
 * Source: {id: number, name: string, isAdmin: boolean }
 * Destination: {id: string, name: string,  role: "admin" | "member"}
 *
 * The that will be needed are :
 *  - id (type mismatch)
 *  - role (doesn't exist on source)
 */
type Mappers<Source, Destination> = {
  [property in keyof Destination]: property extends keyof Source
    ? Source[property] extends Destination[property]
      ? Mapper<Source, Destination, property> | undefined
      : Mapper<Source, Destination, property>
    : Mapper<Source, Destination, property>;
};

// Util type to pick all the undefined properties
type UndefinedProperties<T> = {
  [P in keyof T]-?: undefined extends T[P] ? P : never;
}[keyof T];

// Util type to map "| undefined", to optional, to help the DX a bit
type ToOptional<T> = Partial<Pick<T, UndefinedProperties<T>>> &
  Pick<T, Exclude<keyof T, UndefinedProperties<T>>>;

//  We apply the "ToOptional"on the mappers
type NeededMappers<Source, Destination> = ToOptional<
  Mappers<Source, Destination>
>;

export const mapSourceToDestination =
  <Source, Destination>(mappers: NeededMappers<Source, Destination>) =>
  (source: Source): Destination => {
    // This is needed because I map implictly common fields with same type
    const destination = { ...source } as unknown as Destination;

    Object.entries(mappers).forEach(([destinationKey, mapper]) => {
      const property = destinationKey as keyof Destination;
      const usedMapper = mapper as Mapper<Source, Destination, typeof property>;

      destination[property] = usedMapper(source);
    });

    return destination as Destination;
  };
