import type { AnimationEventHandler, CSSProperties } from "react";
import React from "react";
import { AnimatePresence, motion } from "../Meta/motion/motion";
import { useIsMounted } from "../../utils/hooks/useIsMounted";
import { mergeStyles } from "../../utils/mergeStyles";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import type { SystemProps } from "../../utils/types/system";

const ease = [0.25, 0.1, 0.25, 1];

const variants = {
  enter: {
    height: "auto",
    opacity: 1,
    overflow: "hidden",
    transition: {
      height: { duration: 0.3, ease },
      opacity: { duration: 0.4, ease },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: { duration: 0.2, ease },
      opacity: { duration: 0.3, ease },
    },
  },
};

const enterEndTransition = {
  enter: {
    overflow: "initial",
  },
};

const enterMountTransition = {
  enter: {
    duration: 0,
  },
};

type Props = {
  isAnimated?: boolean;
  isLazy?: boolean;
  on?: boolean;
  onAnimationEnd?: AnimationEventHandler<HTMLDivElement>;
  overflow?: CSSProperties["overflow"];
};

export type RevealProps = Props & SystemProps<"div">;

const block = {
  display: "block",
};

const StyledMotion = styled<typeof motion.div, Pick<Props, "overflow">>(
  motion.div,
  {
    base: ({ overflow = "hidden" }) => ({
      overflow: `${overflow} !important`,
    }),
  },
);

export const Reveal = polymorphic<"div", Props>(
  (
    {
      children,
      className,
      isAnimated = true,
      isLazy,
      on,
      onAnimationEnd,
      onAnimationStart,
      onDragEnd,
      onDragStart,
      overflow,
      style,
      ...rest
    },
    ref,
  ) => {
    const isMounted = useIsMounted();

    if (!isAnimated) {
      return on ? <>{children}</> : null;
    }

    const custom = {
      animateOpacity: true,
      endingHeight: "auto",
      startingHeight: 0,
      transition: isMounted ? undefined : enterMountTransition,
      transitionEnd: enterEndTransition,
    };

    return (
      <AnimatePresence custom={custom} initial={false}>
        <StyledMotion
          as={motion.div}
          onAnimationEnd={onAnimationEnd}
          overflow={on ? "visible" : "hidden"}
          ref={ref}
          {...rest}
          animate={on ? "enter" : "exit"}
          custom={custom}
          exit="exit"
          initial={false}
          style={mergeStyles(block, style)}
          variants={variants}
        >
          {isLazy && !on ? null : children}
        </StyledMotion>
      </AnimatePresence>
    );
  },
);

Reveal.displayName = "Reveal";
