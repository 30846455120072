import { useRef } from "react";
import type { ScrollFrom } from "../../../utils/scrollIntoView";
import { scrollIntoView } from "../../../utils/scrollIntoView";
import { useRefList } from "../../../utils/hooks/useRefList";

type Params = {
  from: ScrollFrom;
  value?: string;
};

export type ScrollToItem = (params: Params) => void;

const ITEM_OFFSET = 5;

export const useSelectScroll = (value?: string) => {
  const scrollableRef = useRef<HTMLDivElement>(null);

  const { refs, setRef: setItemRef } = useRefList<HTMLDivElement>();

  const scrollToSelectedItem = () =>
    setTimeout(() => {
      if (!value) {
        return;
      }
      const child = refs.current[value] ?? null;

      scrollIntoView({
        child,
        offset: ITEM_OFFSET,
        parent: scrollableRef.current,
      });
    }, 0);

  const scrollToItem: ScrollToItem = ({ from, value }) => {
    if (!value) {
      return;
    }
    const child = refs.current[value] ?? null;

    scrollIntoView({
      child,
      from,
      offset: ITEM_OFFSET,
      parent: scrollableRef.current,
    });
  };

  return {
    refs,
    scrollToItem,
    scrollToSelectedItem,
    scrollableRef,
    setItemRef,
  } as const;
};
