import type { ReactNode } from "react";
import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { ariaAttribute, dataAttribute } from "../../../utils/attributes";
import { styles } from "../../../theme/commons";
import { Body } from "../../Typography/Body";
import type { UseRadioOptions } from "./useRadio";
import { useRadio } from "./useRadio";
import { RadioCircle } from "./RadioCircle";

const StyledLabel = styled("label", {
  base: ({ theme }) => [
    {
      "&:hover:not([data-checked]) span[aria-hidden='true']": {
        background: theme.colors.background.light,
        borderColor: theme.colors.neutral["50"],
      },
      "&[data-disabled]": {
        pointerEvents: "none",
      },
      alignItems: "center",
      background: theme.colors.background.light,
      border: `1px solid ${theme.colors.neutral["20"]}`,
      borderRadius: theme.radius.base,
      color: theme.colors.neutral.base,
      cursor: "pointer",
      display: "inline-flex",
      letterSpacing: theme.letterSpacing.wide,
      lineHeight: theme.lineHeight.md,
      padding: `calc(${theme.spacing[12]} - 1px) calc(${theme.spacing[16]} - 1px)`,
      position: "relative",
      transitionDuration: theme.transition.duration.fast,
      transitionProperty: "color, background, border-color",
      transitionTimingFunction: theme.transition.easing.base,
    },
    {
      "&:hover": {
        background: theme.colors.neutral.light,
        border: `1px solid ${theme.colors.neutral["30"]}`,
        color: theme.colors.neutral.dark,
      },
    },
    {
      "&[data-checked]": {
        background: theme.colors.background.light,
        border: `2px solid ${theme.colors.primary.base}`,
        padding: `calc(${theme.spacing[12]} - 2px) calc(${theme.spacing[16]} - 2px)`,
      },
    },
  ],
});

const HiddenInput = styled("input", { base: styles.hidden });

const Description = styled(Body, {
  base: ({ theme }) => ({
    letterSpacing: theme.letterSpacing.base,
  }),
});

const InnerLabel = styled("span", {
  base: ({ theme }) => ({
    fontWeight: theme.font.weight.medium,
    marginRight: theme.spacing[24],
    userSelect: "none",
  }),
});

type Props = {
  checked?: boolean;
  dataTestId?: string;
  description?: ReactNode;
  labelClassName?: string;
  option: string;
} & UseRadioOptions;

export const Radio = polymorphic<"label", Props>(
  (
    {
      checked,
      children,
      dataTestId = "Radio",
      description,
      labelClassName,
      option,
      ...rest
    },
    ref,
  ) => {
    const {
      isChecked,
      isDisabled,
      isFocused,
      onInputBlur,
      onInputFocus,
      onLabelMouseDown,
    } = useRadio({
      checked,
      option,
    });

    return (
      <StyledLabel
        data-checked={dataAttribute(isChecked)}
        data-disabled={dataAttribute(isDisabled)}
        data-focused={dataAttribute(isFocused)}
        data-testid={dataTestId}
        onMouseDown={onLabelMouseDown}
        {...rest}
        ref={ref}
      >
        <HiddenInput
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          type="radio"
          value={option}
        />
        <InnerLabel className={labelClassName}>
          {children}
          {isDefined(description) && (
            <Description className="description" isSecondary>
              {description}
            </Description>
          )}
        </InnerLabel>
        <RadioCircle
          aria-checked={ariaAttribute(isChecked)}
          aria-hidden={ariaAttribute(true)}
        />
      </StyledLabel>
    );
  },
);

Radio.displayName = "Radio";
