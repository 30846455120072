import type { FC } from "react";
import React from "react";
import type { NodeOrRenderProp } from "../../utils/types/dom";
import { HeadlessPopover } from "../Meta/Headless";
import { renderChildren } from "../../utils/children";
import { useDisclosure } from "../../utils/hooks/useDisclosure";
import {
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "./Popover/PopoverComponents";

type RenderParams = {
  isOpened: boolean;
  onClose: () => void;
};

export type PopoverProps = {
  children: NodeOrRenderProp<RenderParams>;
  isDisabled?: boolean;
  isOpened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  openDelay?: number;
};

type PopoverType = {
  Body: typeof PopoverBody;
  Content: typeof PopoverContent;
  Footer: typeof PopoverFooter;
  Header: typeof PopoverHeader;
  Trigger: typeof PopoverTrigger;
} & FC<PopoverProps>;

export const Popover: PopoverType = ({
  children,
  isDisabled,
  isOpened: isOpenedProp,
  onClose: onCloseProp,
  onOpen: onOpenProp,
  openDelay,
}: PopoverProps) => {
  const { isOpened, setClosed, setOpened } = useDisclosure({
    isDisabled,
    isOpened: isOpenedProp,
    onClose: onCloseProp,
    onOpen: onOpenProp,
    openDelay,
  });

  return (
    <HeadlessPopover.Root
      onOpenChange={(open) => (open ? setOpened() : setClosed())}
      open={isOpened}
    >
      {renderChildren(children, {
        isOpened,
        onClose: setClosed,
      })}
    </HeadlessPopover.Root>
  );
};

Popover.displayName = "Popover";
Popover.Body = PopoverBody;
Popover.Content = PopoverContent;
Popover.Footer = PopoverFooter;
Popover.Header = PopoverHeader;
Popover.Trigger = PopoverTrigger;
