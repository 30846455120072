import type { CssFlexProps } from "../../utils/system/flex";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";

export type _FlexProps = {
  isInline?: boolean;
  shouldWrapChildren?: boolean;
} & CssFlexProps;

export type FlexProps = _FlexProps & SystemProps<"div">;

export const Flex = styled<"div", _FlexProps>("div", {
  base: ({
    alignItems,
    alignSelf,
    flex,
    flexBasis,
    flexDirection,
    flexFlow,
    flexGrow,
    flexShrink,
    flexWrap,
    isInline,
    justifyContent,
    justifySelf,
    shouldWrapChildren,
  }) => ({
    alignItems,
    alignSelf,
    display: isInline ? "inline-flex" : "flex",
    flex,
    flexBasis,
    flexDirection,
    flexFlow,
    flexGrow,
    flexShrink,
    flexWrap,
    justifyContent,
    justifySelf,
    minWidth: shouldWrapChildren ? 0 : undefined,
  }),
});

Flex.displayName = "Flex";
