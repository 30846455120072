import type { CSSProperties } from "react";
import { compact, merge } from "lodash-es";
import { isNotDefined } from "@ovrsea/ovrutils";

const mergeStyles = (...styles: (CSSProperties | undefined)[]) =>
  merge({}, ...compact(styles));

const translatePixels = (pixels?: number | string) => {
  if (isNotDefined(pixels)) {
    return undefined;
  }

  return typeof pixels === "number" ? `${pixels}px` : pixels;
};

const hasHandlers = (...handlers: (((event: any) => void) | undefined)[]) =>
  handlers.some((handler) => Boolean(handler));

const selectTabIndex = (...handlers: (((event: any) => void) | undefined)[]) =>
  hasHandlers(...handlers) ? 0 : -1;

export { mergeStyles, selectTabIndex, translatePixels };
