import type { OfficeId } from "../offices/types";

type CountryCodeFromOfficeId = Record<OfficeId, null | string>;

const countryCodeFromOfficeIds: CountryCodeFromOfficeId = {
  barca_es: "es",
  master_world: null,
  milan_it: "it",
  nyc_usa: "usa",
  paris_fr: "fr",
};

const extractCountryCodeFromOfficeId = (officeId?: OfficeId): null | string => {
  if (!officeId) {
    return null;
  }

  return countryCodeFromOfficeIds[officeId];
};

export default extractCountryCodeFromOfficeId;
