import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import type { IconName } from "../Typography/Icon";
import { Icon } from "../Typography/Icon";
import { Body } from "../Typography/Body";
import { HStack } from "../Layout/Stack";
import { ariaAttribute, dataAttribute } from "../../utils/attributes";
import type { SystemProps } from "../../utils/types/system";
import { useFormFieldContext } from "./FormField/FormFieldProvider";

type Props = {
  icon?: IconName;
  isChecked?: boolean;
  isDisabled?: boolean;
  label?: React.ReactNode;
  onChange: (isChecked: boolean) => void;
};

export type CheckboxProps = Props & SystemProps<"button">;

const StyledContainer = styled<"button", Pick<Props, "isChecked">>("button", {
  base: ({ theme }) => [
    {
      background: "none",
      display: "block",
    },
    {
      "&:focus-visible": {
        outline: "none",
      },
    },
    {
      '&:disabled[aria-checked="false"] .box': {
        background: theme.colors.neutral.light,
        border: `${theme.spacing[1]} solid ${theme.colors.neutral[20]}`,
      },
    },
    {
      "&:disabled[aria-checked='true'] .box": {
        background: theme.colors.neutral[20],
        borderColor: theme.colors.neutral[20],
      },
    },
    {
      ["&:hover:not([disabled]) .box, &:focus-visible:not([disabled]) .box"]: {
        boxShadow: `0 0 0 2px ${theme.colors.primary.light}`,
      },
    },
  ],
});

const StyledBox = styled<"div", Pick<Props, "isChecked">>("div", {
  base: ({ theme }) => [
    {
      alignItems: "center",
      background: theme.colors.background.light,
      border: `${theme.spacing[1]} solid ${theme.colors.neutral[30]}`,
      borderRadius: theme.radius.xs,
      color: "white",
      display: "flex",
      height: theme.spacing[20],
      justifyContent: "center",
      minHeight: theme.spacing[20],
      minWidth: theme.spacing[20],
      outline: "none",
      transition: "box-shadow, background, transform",
      transitionDuration: theme.transition.duration.slow,
      transitionTimingFunction: theme.transition.easing.translateX,
      width: theme.spacing[20],
    },
    {
      "&[data-checked]": {
        backgroundColor: theme.colors.primary.base,
        borderColor: theme.colors.primary.base,
      },
    },
  ],
});

const CheckIcon = polymorphic<typeof Icon, object>((props, ref) => (
  <Icon name="check" ref={ref} {...props} />
));

export const Checkbox = polymorphic<"button", Props>(
  (
    { icon, isChecked, isDisabled: isDisabledProp, label, onChange, ...rest },
    ref,
  ) => {
    const context = useFormFieldContext();

    const isDisabled = isDisabledProp || context?.isDisabled;

    return (
      <StyledContainer
        aria-checked={isChecked}
        aria-disabled={ariaAttribute(isDisabled)}
        checked={isChecked}
        disabled={isDisabled}
        onClick={() => onChange(!isChecked)}
        ref={ref}
        role="checkbox"
        tabIndex={0}
        type="button"
        {...rest}
      >
        <HStack alignItems="center">
          <StyledBox
            className="box"
            data-checked={dataAttribute(isChecked)}
            disabled={isDisabled}
          >
            {isChecked && <CheckIcon />}
          </StyledBox>
          {isDefined(label) && (
            <Body marginLeft={8} sx={{ textAlign: "left" }}>
              {icon && <Icon isDecorative marginRight={4} name={icon} />}
              {label}
            </Body>
          )}
        </HStack>
      </StyledContainer>
    );
  },
);

Checkbox.displayName = "Checkbox";
