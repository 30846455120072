import React from "react";
import type { SystemProps } from "../../utils/types/system";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import { computeTextAreaRows } from "../../utils/hooks/computeTextAreaRows";
import { ariaAttribute } from "../../utils/attributes";
import { useFormFieldContext } from "./FormField/FormFieldProvider";

type Props = {
  "data-testid"?: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  value?: string;
};

export type TextAreaProps = Props & SystemProps<"textarea">;

const StyledTextArea = styled("textarea", {
  base: ({ theme }) => [
    {
      "&::placeholder": {
        color: theme.colors.neutral["50"],
      },
      backgroundColor: theme.colors.background.feed,
      border: `1px solid ${theme.colors.neutral["30"]}`,
      borderRadius: theme.radius.base,
      padding: `${theme.spacing[8]} ${theme.spacing[12]}`,
      transitionDuration: theme.transition.duration.fast,
      transitionProperty: "border, color, box-shadow",
      transitionTimingFunction: theme.transition.easing.color,
      verticalAlign: "top",
      width: "100%",
      ...theme.body.md,
    },
    {
      "&:hover": {
        border: `1px solid ${theme.colors.neutral["70"]}`,
      },
    },
    {
      "&:focus": {
        border: `1px solid ${theme.colors.primary.base}`,
        boxShadow: `0 0 0 2px ${theme.colors.primary.light}`,
        outline: "none",
      },
    },
    {
      "&:disabled": {
        "&, &::placeholder": {
          color: theme.colors.neutral["30"],
        },
        background: theme.colors.neutral.light,
        border: `1px solid ${theme.colors.neutral["20"]}`,
        cursor: "not-allowed",
      },
    },
  ],
});

export const TextArea = polymorphic<"textarea", Props>(
  (
    { isDisabled: isDisabledProp, onChange: onChangeProp, value = "", ...rest },
    ref,
  ) => {
    const rows = computeTextAreaRows({ value });

    const context = useFormFieldContext();

    const isDisabled = context?.isDisabled || isDisabledProp;

    const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = ({
      target,
    }) => onChangeProp(target.value);

    return (
      <StyledTextArea
        rows={rows}
        {...rest}
        aria-describedby={context?.feedbackId}
        aria-disabled={ariaAttribute(isDisabled)}
        aria-invalid={ariaAttribute(context?.isErrored)}
        aria-required={ariaAttribute(context?.isRequired)}
        disabled={isDisabled}
        id={context?.id}
        onChange={onChange}
        ref={ref}
        value={value}
      />
    );
  },
);

TextArea.displayName = "TextArea";
