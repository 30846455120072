import type { CSSProperties, PropsWithChildren } from "react";
import React from "react";
import type { FlexProps } from "../../Layout/Flex";
import { Flex } from "../../Layout/Flex";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { useOvrseaTheme } from "../../../theme/useTheme";
import { generateSpacing } from "../../../theme/commons";
import type { Sx, SystemProps } from "../../../utils/types/system";
import { HeadlessPopover } from "../../Meta/Headless";
import { Box } from "../../Meta/Box";
import { animateEnter, animateExit } from "../../../utils/system/animate";

const StyledFooter = styled(Flex, {
  base: ({ theme }) => [
    generateSpacing(theme)(8, "left"),
    {
      padding: 12,
    },
  ],
});

type PopoverContentProps = {
  innerSx?: Sx;
  preventAutoFocus?: boolean;
  zIndex?: number;
} & HeadlessPopover.PopoverContentProps &
  SystemProps<"div">;

const PopoverContent = polymorphic<"div", PopoverContentProps>(
  (
    {
      align,
      children,
      innerSx,
      onOpenAutoFocus,
      preventAutoFocus,
      side,
      sideOffset = 4,
      sx,
      zIndex: zIndexProp = 1800,
      ...rest
    },
    ref,
  ) => {
    const themeZIndex = useOvrseaTheme().zIndex.popover;
    const zIndex = zIndexProp || themeZIndex;

    return (
      <HeadlessPopover.Portal>
        <HeadlessPopover.Content
          align={align}
          asChild
          onOpenAutoFocus={(event) => {
            if (preventAutoFocus) event.preventDefault();
            onOpenAutoFocus?.(event);
          }}
          side={side}
          sideOffset={sideOffset}
          {...rest}
        >
          <Box
            sx={[
              (theme) => ({
                "&&[data-side='bottom']": { "--enter-translate-y": "-8px" },
                "&&[data-side='left']": { "--enter-translate-x": "8px" },
                "&&[data-side='right']": { "--enter-translate-x": "-8px" },
                "&&[data-side='top']": { "--enter-translate-y": "8px" },
                "&[data-state='closed']": {
                  "--exit-opacity": 0,
                  "--exit-translate-x": "initial",
                  "--exit-translate-y": "initial",
                  animationDuration: theme.transition.duration.fast,
                  animationName: animateExit,
                },
                "&[data-state='closed']:not([data-animatescale='false'])": {
                  "--exit-scale": 0.95,
                },
                "&[data-state='open']": {
                  "--enter-opacity": 0,
                  "--enter-translate-x": "initial",
                  "--enter-translate-y": "initial",
                  animationDuration: theme.transition.duration.fast,
                  animationName: animateEnter,
                },
                "&[data-state='open']:not([data-animatescale='false'])": {
                  "--enter-scale": 0.95,
                },
                background: theme.colors.background.light,
                border: `1px solid ${theme.colors.neutral["10"]}`,
                borderRadius: theme.radius.md,
                boxShadow: theme.shadows.md,
                outline: "none",
                zIndex,
              }),
              innerSx,
            ]}
          >
            <Box ref={ref} sx={sx}>
              {children}
            </Box>
          </Box>
        </HeadlessPopover.Content>
      </HeadlessPopover.Portal>
    );
  },
);

PopoverContent.displayName = "PopoverContent";

type PopoverBodyProps = {
  width?: CSSProperties["width"];
};

const PopoverBody = styled<"div", PopoverBodyProps>("div", {
  base: ({ theme, width }) => ({
    padding: theme.spacing[16],
    width,
  }),
});

PopoverBody.displayName = "PopoverBody";

const PopoverHeader = styled(Flex, {
  base: ({ theme }) => ({
    color: theme.colors.text.primary,
    fontSize: theme.font.size.md,
    fontWeight: theme.font.weight.medium,
    padding: "16px 12px 8px",
  }),
});

PopoverHeader.displayName = "PopoverHeader";

const PopoverFooter = polymorphic<"div", FlexProps>((props, ref) => (
  <StyledFooter justifyContent="flex-end" ref={ref} {...props} />
));

PopoverFooter.displayName = "PopoverFooter";

const PopoverTrigger = ({ children }: PropsWithChildren) => (
  <HeadlessPopover.PopoverTrigger asChild type={undefined}>
    {children}
  </HeadlessPopover.PopoverTrigger>
);

export {
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
};
