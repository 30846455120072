const AMD_DUNKERQUE_ID = "679";
const AMD_FOS_ID = "680";
const AMD_LEH_ID = "1401";
const BOLLORE_LE_HAVRE_ID = "1012";
const OVRSEA_ID = "657";
const TOURNEBIZE_LE_HAVRE_ID = "2167";
const AMD_MTX = "AMD_MTX";
// We use AMD Le Havre at Montoir, but with a different code. We create this fake id to handle this specific case.

const CUSTOMS_CLEARANCE_SHIPOWNER_IDS = [
  AMD_DUNKERQUE_ID,
  AMD_FOS_ID,
  AMD_LEH_ID,
  BOLLORE_LE_HAVRE_ID,
  OVRSEA_ID,
  TOURNEBIZE_LE_HAVRE_ID,
  AMD_MTX,
] as const;

type ShipownerIds = (typeof CUSTOMS_CLEARANCE_SHIPOWNER_IDS)[number];

export const customsClearanceCode: Record<ShipownerIds, string> = {
  [AMD_DUNKERQUE_ID]: "DELPIERR",
  [AMD_FOS_ID]: "DELPIERR",
  [AMD_LEH_ID]: "AP+ LEH : 2302180 - DELPIERRE_SHIPPING",
  [AMD_MTX]: "AP+ MTX: 408ADSS - DELPIERRE",
  [BOLLORE_LE_HAVRE_ID]: "AP+ 2307428",
  [OVRSEA_ID]: "S-ONE 2302208",
  [TOURNEBIZE_LE_HAVRE_ID]: "AP+ LEH 2302071",
};

export const hasCustomsClearanceCode = (id: string): id is ShipownerIds =>
  CUSTOMS_CLEARANCE_SHIPOWNER_IDS.includes(id as ShipownerIds);
