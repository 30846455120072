import { first, uniqBy } from "lodash-es";
import type { EnumOrLiteral } from "../../typescriptHelpers/enumOrTypeLiteral";
import { OvrseaDataError } from "../../errors/OvrseaDataError";
import {
  convertCbfToCbi,
  convertCbfToCcm,
  convertCbiToCbf,
  convertCbiToCbm,
  convertCbiToCcm,
  convertCbmToCbf,
  convertCbmToCbi,
  convertCbmToCcm,
  convertCcmToCbf,
  convertCcmToCbi,
  convertCcmToCbm,
} from "../../conversions";
import roundValue from "../../roundValue";
import type { LoadType, VolumeUnit, VolumeUnitFullName } from "../loadUtils";
import { mapperVolumeUnitShortForm } from "../loadUtils";
import { isNotDefined } from "../../other/isNotDefined";
export type Load = {
  loadType: EnumOrLiteral<LoadType>;
  totalVolume?: null | number;
  unitHeight?: null | number;
  unitLength?: null | number;
  unitNumber?: null | number;
  unitWidth?: null | number;
  volumeUnit: VolumeUnit;
};

const sumVolumes = (summedVolume: number, load: Load): number => {
  if (["full_truck", "vrac"].includes(load.loadType)) {
    const totalVolume = Number(load.totalVolume);

    return summedVolume + totalVolume;
  }
  if (
    isNotDefined(load.unitNumber) ||
    isNotDefined(load.unitHeight) ||
    isNotDefined(load.unitLength) ||
    isNotDefined(load.unitWidth)
  ) {
    throw new OvrseaDataError(
      `Standard load missing feature (unitNumber, unitHeight, unitLength or unitWidth): ${JSON.stringify(
        load,
        null,
        2,
      )}`,
    );
  }

  return (
    summedVolume +
    load.unitNumber * load.unitHeight * load.unitLength * load.unitWidth
  );
};

export const checkVolumeUnitUnicity = (loads: Load[]) => {
  if (uniqBy(loads, (load) => load.volumeUnit).length !== 1) {
    throw new Error(
      `The loads must all have one unit and all the same, here loads have:${loads
        .map((load) => load.volumeUnit)
        .join(",")}`,
    );
  }
};

export const convertFromBaseToTargetVolumeUnit =
  ({
    base,
    target,
  }: {
    base: VolumeUnitFullName;
    target: VolumeUnitFullName;
  }) =>
  (volume: number) => {
    if (base === target) {
      return volume;
    }
    if (base === "centimeter") {
      switch (target) {
        case "inch":
          return convertCcmToCbi(volume);
          break;
        case "meter":
          return convertCcmToCbm(volume);
          break;
        case "feet":
          return convertCcmToCbf(volume);
          break;
      }
    }
    if (base === "meter") {
      switch (target) {
        case "inch":
          return convertCbmToCbi(volume);
          break;
        case "centimeter":
          return convertCbmToCcm(volume);
          break;
        case "feet":
          return convertCbmToCbf(volume);
          break;
      }
    }
    if (base === "inch") {
      switch (target) {
        case "centimeter":
          return convertCbiToCcm(volume);
          break;
        case "meter":
          return convertCbiToCbm(volume);
          break;
        case "feet":
          return convertCbiToCbf(volume);
          break;
      }
    }
    if (base === "feet") {
      switch (target) {
        case "centimeter":
          return convertCbfToCcm(volume);
          break;
        case "meter":
          return convertCbiToCbm(volume);
          break;
        case "inch":
          return convertCbfToCbi(volume);
          break;
      }
    }

    throw new Error(`Impossible conversion between ${base} and ${target}`);
  };

export const computeTotalVolume = ({
  loads,
  roundedValue,
  targetUnit,
}: {
  loads: Load[];
  roundedValue?: boolean;
  targetUnit: VolumeUnitFullName;
}) => {
  const firstLoad = first(loads);

  if (!firstLoad) {
    return 0;
  }

  const currentUnit = mapperVolumeUnitShortForm[firstLoad.volumeUnit];

  checkVolumeUnitUnicity(loads);
  const totalVolumeInCurrentUnit = loads.reduce(sumVolumes, 0);

  const convertedTotalVolume = convertFromBaseToTargetVolumeUnit({
    base: currentUnit,
    target: targetUnit,
  })(totalVolumeInCurrentUnit);

  return roundedValue
    ? roundValue(convertedTotalVolume, 3)
    : convertedTotalVolume;
};
