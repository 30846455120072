import type { ErrorInfo } from "react";
import React, { useEffect, useState } from "react";
import { polymorphic } from "../../utils/ref";
import { SilentErrorBoundary } from "../Meta/SilentErrorBoundary";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { useDependenciesContext } from "../../theme/dependencies";
import { deepHasChild } from "../../utils/children";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateTitle,
  ErrorState,
  ErrorStateDescription,
  ErrorStateIcon,
  ErrorStateTitle,
  LoadingState,
  StateContent,
} from "./State/FetchStateComponents";
import type { FetchStateContextType } from "./State/FetchStateContext";
import { FetchStateProvider } from "./State/FetchStateContext";

export type FetchStateProps = {
  onError?: (error: Error, info: ErrorInfo) => void;
} & Omit<
  FetchStateContextType,
  "setIsEmpty" | "setIsErrored" | "setIsLoading"
> &
  Omit<SystemProps<"div">, "onError">;

const StyledContent = styled("div", {
  base: {
    minHeight: 100,
    position: "relative",
  },
});

type Compounds = {
  Content: typeof StateContent;
  Empty: typeof EmptyState;
  EmptyDescription: typeof EmptyStateDescription;
  EmptyTitle: typeof EmptyStateTitle;
  Error: typeof ErrorState;
  ErrorDescription: typeof ErrorStateDescription;
  ErrorIcon: typeof ErrorStateIcon;
  ErrorTitle: typeof ErrorStateTitle;
  Loading: typeof LoadingState;
};

export const FetchState = polymorphic<"div", FetchStateProps, Compounds>(
  (
    {
      children,
      isDisabledWhileRefetching,
      isEmpty,
      isErrored,
      isLoading,
      name,
      onError,
      //TODO: add possibility to access the error in the error component
      ...rest
    },
    ref,
  ) => {
    const { logger } = useDependenciesContext();

    const [isEmptyState, setIsEmpty] = useState(isEmpty);

    useEffect(() => {
      setIsEmpty(isEmpty);
    }, [isEmpty]);

    const [isErroredState, setIsErrored] = useState(isErrored);

    useEffect(() => {
      setIsErrored(isErrored);
    }, [isErrored]);

    const [isLoadingState, setIsLoading] = useState(isLoading);

    useEffect(() => {
      setIsLoading(isLoading);
    }, [isLoading]);

    const hasNoEmptyStateElement = !deepHasChild(children, EmptyState);

    if (isEmpty && hasNoEmptyStateElement) {
      return null;
    }

    return (
      <FetchStateProvider
        value={{
          isDisabledWhileRefetching,
          isEmpty: isEmptyState,
          isErrored: isErroredState,
          isLoading: isLoadingState,
          name,
          setIsEmpty,
          setIsErrored,
          setIsLoading,
        }}
      >
        <SilentErrorBoundary
          onError={(error, info) => {
            if (error?.name === "EmptyDataError") {
              setIsEmpty(true);
            } else {
              setIsErrored(true);
              logger(error, info);
            }
          }}
        >
          <StyledContent ref={ref} {...rest}>
            {children}
          </StyledContent>
        </SilentErrorBoundary>
      </FetchStateProvider>
    );
  },
);

FetchState.displayName = "FetchState";
FetchState.Content = StateContent;
FetchState.Empty = EmptyState;
FetchState.EmptyDescription = EmptyStateDescription;
FetchState.EmptyTitle = EmptyStateTitle;
FetchState.Error = ErrorState;
FetchState.ErrorDescription = ErrorStateDescription;
FetchState.ErrorIcon = ErrorStateIcon;
FetchState.ErrorTitle = ErrorStateTitle;
FetchState.Loading = LoadingState;
