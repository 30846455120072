const carrierIdsWithAvailableSchedules = [
  "1",
  "12",
  "82",
  "2153",
  "763",
  "4",
] as const;

export type CarrierIdWithAvailableSchedule =
  (typeof carrierIdsWithAvailableSchedules)[number];

export const carrierHasAvailableSchedules = (carrier: {
  id: string;
  parentShipownerGroup: { id: string } | null;
}) => {
  const groupId = carrier.parentShipownerGroup?.id ?? carrier.id;

  return carrierIdsWithAvailableSchedules.includes(
    groupId as CarrierIdWithAvailableSchedule,
  );
};
