import { merge, omit } from "lodash-es";
import type { Theme } from "../theme";
import { themeToCssVariables, tokenizeTheme } from "./tokenize";

const computedThemeValues: (keyof Theme)[] = ["above", "below"];

export const enhanceTheme = (theme: Theme) => {
  const themeConstants = omit(theme, computedThemeValues);

  return {
    ...tokenizeTheme(themeConstants),
    __cssVariables: themeToCssVariables(themeConstants),
    above: theme.above,
    below: theme.below,
  };
};

export const extendTheme = (defaultTheme: Theme, overrides?: Partial<Theme>) =>
  merge({}, defaultTheme, overrides);
