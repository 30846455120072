import React from "react";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import type { BoxProps } from "../Meta/Box";
import type { CSSObject } from "../../utils/types/css";
import type { SystemProps } from "../../utils/types/system";

const classes = {
  on: "on",
  out: "out",
};

const display: CSSObject = {
  [`& .${classes.on}`]: {
    display: "none",
  },
  [`& .${classes.out}`]: {
    display: "initial",
  },

  [`&:hover .${classes.on}, &:focus .${classes.on}, &:focus-within .${classes.on}`]:
    {
      display: "initial",
    },
  [`&:hover .${classes.out}, &:focus .${classes.out}, &:focus-within .${classes.out}`]:
    {
      display: "none",
    },
};

const visibility: CSSObject = {
  [`& .${classes.on}`]: {
    visibility: "hidden",
  },
  [`& .${classes.out}`]: {
    visibility: "visible",
  },

  [`&:hover .${classes.on}, &:focus .${classes.on}, &:focus-within .${classes.on}`]:
    {
      visibility: "initial",
    },
  [`&:hover .${classes.out}, &:focus .${classes.out}, &:focus-within .${classes.out}`]:
    {
      visibility: "hidden",
    },
};

const variants = {
  display,
  visibility,
};

type Props = {
  alignItems?: string;
  variant?: keyof typeof variants; // TODO: type properly
};

export type VisibilityProps = Props & SystemProps<"div">;

const VisibilityMouseOut = polymorphic<"div", BoxProps>((props, ref) => (
  <styled.div className={classes.out} ref={ref} {...props} />
));

const VisibilityMouseOn = polymorphic<"div", BoxProps>((props, ref) => (
  <styled.div className={classes.on} ref={ref} {...props} />
));

type Compounds = {
  MouseOn: typeof VisibilityMouseOn;
  MouseOut: typeof VisibilityMouseOut;
};

const StyledVisibility = styled<"div", Props>("div", {
  base: ({ variant = "display" }) => variants[variant],
});

const Visibility = polymorphic<"div", Props, Compounds>(
  ({ variant, ...rest }, ref) => (
    <StyledVisibility variant={variant} {...rest} ref={ref} />
  ),
);

VisibilityMouseOut.displayName = "VisibilityMouseOut";
VisibilityMouseOn.displayName = "VisibilityMouseOn";
Visibility.displayName = "Visibility";
Visibility.MouseOut = VisibilityMouseOut;
Visibility.MouseOn = VisibilityMouseOn;

export { Visibility };
