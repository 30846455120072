import type { FreightMethodOvrUtils } from "../../orderUtils/orderFreightMethodSelectors";
import { isDefined } from "../../other/isDefined";
import { isOceanCarrierShipowner, isRailShipowner } from "./shipownerTypes";
type ShipownerType = "agent" | "carrier" | "gsa" | "logistics";

type IsShipownerCashPaymentArgs = {
  shipment: {
    agentScope: { isOvrseaAtOrigin: boolean };
    arrivalCountry: null | string;
    departureCountry: null | string;
    freightMethod: FreightMethodOvrUtils;
  };
  shipowner: {
    country: null | string;
    exportPaymentDelayDays: number;
    freightMethod: FreightMethodOvrUtils | null | undefined;
    importPaymentDelayDays: number;
    paymentDelayDays: number;
    shipownerType: ShipownerType;
  };
  shipownerRole: string | undefined;
};

export const isShipownerCashPayment = ({
  shipment,
  shipowner,
  shipownerRole,
}: IsShipownerCashPaymentArgs) => {
  const isPaymentNeverManagedByOvrsea =
    shipownerRole === "freight" &&
    shipment.freightMethod === "air" &&
    !shipment.agentScope.isOvrseaAtOrigin;

  if (isPaymentNeverManagedByOvrsea) {
    return false;
  }

  if (isOceanCarrierShipowner(shipowner) || isRailShipowner(shipowner)) {
    const isPaymentCashAtDeparture =
      isDefined(shipowner.country) &&
      shipowner.country === shipment.departureCountry &&
      shipowner.exportPaymentDelayDays === 0;

    const isPaymentCashAtArrival =
      isDefined(shipowner.country) &&
      shipowner.country === shipment.arrivalCountry &&
      shipowner.importPaymentDelayDays === 0;

    return isPaymentCashAtDeparture || isPaymentCashAtArrival;
  }

  return shipowner.paymentDelayDays === 0;
};
