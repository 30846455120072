import type { SystemProps } from "../../../../utils/types/system";
import { styled } from "../../../../utils/system/factory";

type NextTimelineDotStatus = "done" | "pending";

type Props = {
  status?: NextTimelineDotStatus;
};

type NextTimelineDotProps = Props & SystemProps<"div">;

export const NextTimelineDot = styled<"div", NextTimelineDotProps>("div", {
  base: ({ status = "pending", theme }) => [
    {
      backgroundColor: "white",
      border: `2px solid ${theme.colors.neutral[20]}`,
      borderRadius: theme.radius.round,
      boxSizing: "border-box",
      display: "block",
      minHeight: theme.spacing[16],
      minWidth: theme.spacing[16],
    },
    status === "done" && {
      backgroundColor: theme.colors.alert.successLight,
      borderColor: theme.colors.alert.success,
    },
  ],
});

NextTimelineDot.displayName = "NextTimelineDot";
