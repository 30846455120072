const specialUnicodesExp = /[\u0300-\u036f]/g;
const safeStringExp = /[^A-Z a-z0-9]*/g;
const multipleSpacesExp = / +(?= )/g;

const removeDiacritics = (str: string) =>
  str.normalize("NFD").replace(specialUnicodesExp, "");

export const safePathString = (str: string) =>
  removeDiacritics(str)
    .replace(safeStringExp, "")
    .replace(multipleSpacesExp, "")
    .trim();
