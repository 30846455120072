import React from "react";
import { injectIndex } from "../../../utils/children";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";

const StyledTabPanels = styled("div", {
  base: {
    width: "100%",
  },
});

export const TabPanels = polymorphic<"div">(({ children, ...rest }, ref) => (
  <StyledTabPanels ref={ref} {...rest}>
    {injectIndex(children)}
  </StyledTabPanels>
));

TabPanels.displayName = "TabPanels";
