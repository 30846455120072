import { styled } from "../../../utils/system/factory";
import type { BadgeProps } from "../Badge";
import { Badge } from "../Badge";

export type MarkerBadgeProps = BadgeProps;

export const MarkerBadge = styled<typeof Badge, BadgeProps>(Badge, {
  base: ({ theme }) => ({
    border: "1px solid white",
    gap: theme.spacing[4],
    left: theme.spacing[24],
    padding: "2px 4px",
    position: "absolute",
    top: "-8px",
  }),
});

MarkerBadge.displayName = "MarkerBadge";
