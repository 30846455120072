import type { ReactNode } from "react";
import React from "react";
import { compact } from "lodash-es";
import { Center } from "../Layout/Center";
import { Spinner } from "../Feedback/Spinner";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import type { Theme } from "../../theme/theme";
import type { IconName } from "../Typography/Icon";
import { Icon } from "../Typography/Icon";
import { useButtonGroupContext } from "./ButtonGroup";

const colorVariations = (theme: Theme) =>
  ({
    filled: {
      danger: {
        background: theme.colors.alert.danger,
        border: "none",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
        hover: theme.colors.alert.dangerDark,
        text: "white",
      },
      neutral: {
        background: theme.colors.neutral["10"],
        border: "none",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
        hover: theme.colors.neutral["30"],
        text: theme.colors.neutral.base,
      },
      primary: {
        background: theme.colors.primary.base,
        border: "none",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
        hover: theme.colors.primary.dark,
        text: "white",
      },
    },
    outlined: {
      danger: {
        background: theme.colors.background.light,
        border: `1px solid ${theme.colors.neutral["20"]}`,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
        hover: theme.colors.alert.dangerLight,
        text: theme.colors.alert.danger,
      },
      neutral: {
        background: undefined,
        border: undefined,
        boxShadow: undefined,
        hover: undefined,
        text: undefined,
      },
      primary: {
        background: theme.colors.background.light,
        border: `1px solid ${theme.colors.neutral["20"]}`,
        boxShadow: undefined,
        hover: theme.colors.primary.light,
        text: theme.colors.primary.base,
      },
    },
    text: {
      danger: {
        background: "none",
        border: "none",
        boxShadow: undefined,
        hover: theme.colors.alert.dangerLight,
        text: theme.colors.alert.danger,
      },
      neutral: {
        background: undefined,
        border: undefined,
        boxShadow: undefined,
        hover: undefined,
        text: undefined,
      },
      primary: {
        background: "none",
        border: "none",
        boxShadow: undefined,
        hover: theme.colors.primary.light,
        text: theme.colors.primary.base,
      },
    },
  }) as const;

const sizeVariations = (theme: Theme) =>
  ({
    md: {
      height: theme.spacing[40],
      padding: theme.spacing[16],
    },
    sm: {
      height: theme.spacing[32],
      padding: theme.spacing[8],
    },
    xs: {
      height: theme.spacing[24],
      padding: theme.spacing[8],
    },
  }) as const;

type Variations = ReturnType<typeof colorVariations>;

export type ButtonColor = "danger" | "neutral" | "primary";

export type ButtonType = keyof Variations;

export type ButtonSize = keyof ReturnType<typeof sizeVariations>;

type Props = {
  buttonType?: "button" | "submit";
  className?: string;
  color?: ButtonColor;
  href?: string;
  icon?: IconName;
  iconPosition?: "left" | "right";
  isDisabled?: boolean;
  isExternal?: boolean;
  isLoading?: boolean;
  isUnstyled?: boolean;
  label: ReactNode;
  size?: ButtonSize;
  type?: ButtonType;
};

export type ButtonProps = Omit<SystemProps<"button">, "type"> & Props;

const StyledContent = styled<typeof Center, { isLoading?: boolean }>(Center, {
  base: ({ isLoading }) => [
    {
      fontSize: "inherit",
      opacity: 1,
    },
    isLoading && {
      opacity: 0,
    },
  ],
});

const Absolute = styled("div", {
  base: {
    alignItems: "center",
    display: "flex",
    position: "absolute",
  },
});

const disabledVariations = (theme: Theme) => ({
  filled: {
    background: theme.colors.neutral[10],
    border: "none",
    color: theme.colors.neutral[50],
  },
  outlined: {
    background: theme.colors.background.light,
    border: `1px solid ${theme.colors.neutral[20]}`,
    color: theme.colors.neutral[30],
  },
  text: {
    background: theme.colors.background.light,
    border: "none",
    color: theme.colors.neutral[10],
  },
});

const StyledButton = styled<
  "button",
  {
    buttonColor: ButtonColor;
    buttonType: ButtonType;
    size: ButtonSize;
  }
>("button", {
  base: ({ buttonColor, buttonType, size, theme }) => {
    const { height, padding } = sizeVariations(theme)[size];
    const { background, border, boxShadow, hover, text } =
      colorVariations(theme)[buttonType][buttonColor];
    const { fontSize } = theme.button[size];

    const {
      background: disabledBackground,
      border: disabledBorder,
      color: disabledColor,
    } = disabledVariations(theme)[buttonType];

    const focusRing = `0 0 0 3px ${theme.colors.focus.ring}`;

    return [
      {
        alignItems: "center",
        background,
        borderRadius: theme.radius.base,
        color: text,
        cursor: "pointer",
        display: "inline-flex",
        fontFamily: theme.font.family.base,
        fontSize,
        fontWeight: 500,
        height,
        justifyContent: "center",
        letterSpacing: theme.letterSpacing.wide,
        lineHeight: theme.lineHeight.md,
        outline: border,
        outlineOffset: -1,
        padding: `0px ${padding}`,
        position: "relative",
        textAlign: "center",
        transitionDuration: theme.transition.duration.slow,
        transitionProperty:
          "color, background-color, background, box-shadow, border, outline",
        transitionTimingFunction: theme.transition.easing.color,
        userSelect: "none",
        whiteSpace: "nowrap",
      },
      {
        "&:disabled": {
          background: disabledBackground,
          color: disabledColor,
          cursor: "not-allowed",
          outline: disabledBorder,
        },
        "&:hover:not([disabled])": {
          background: hover,
        },
      },
      {
        "&&:focus-visible": {
          boxShadow: `${compact([focusRing, boxShadow]).join(", ")}`,
        },
      },
    ];
  },
});

export const Button = polymorphic<"button", Props>(
  (
    {
      buttonType = "button",
      color: colorProp,
      icon,
      iconPosition = "left",
      isDisabled: isDisabledProp,
      isLoading: isLoadingProp,
      label,
      size: sizeProp,
      type: typeProp = "filled",
      ...rest
    },
    ref,
  ) => {
    const groupContext = useButtonGroupContext();

    const isLoading = isLoadingProp ?? groupContext?.isLoading;
    const isDisabled = isDisabledProp ?? groupContext?.isDisabled ?? isLoading;
    const size = sizeProp ?? groupContext?.size ?? "md";
    const color = colorProp ?? groupContext?.color ?? "primary";

    const iconSpacing = size === "xs" ? 4 : 8;

    return (
      <StyledButton
        buttonColor={color}
        buttonType={typeProp}
        disabled={isDisabled ?? isLoading}
        ref={ref}
        size={size}
        type={buttonType}
        {...rest}
      >
        <Absolute>
          <Spinner isActive={isLoading} size="sm" />
        </Absolute>
        <StyledContent isLoading={isLoading}>
          {icon && iconPosition === "left" && (
            <Icon marginRight={iconSpacing} name={icon} />
          )}
          {label}
          {icon && iconPosition === "right" && (
            <Icon marginLeft={iconSpacing} name={icon} />
          )}
        </StyledContent>
      </StyledButton>
    );
  },
);

Button.displayName = "Button";
