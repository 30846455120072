import { type ContainerType, countTeus } from "../../..";
import {
  calculateTotalTaxableWeight,
  calculateWM1,
  type LoadOvrutilsWithUnits,
} from "../../cargaisonUtils/loadUtils";
import type {
  FreightMethod,
  LclOrFcl,
} from "../../propositions/isPropositionEligibleForAutomaticBooking/types";
import roundValue from "../../roundValue";

type Proposition = {
  containers: { containerType: ContainerType }[];
  freightMethod: FreightMethod;
  loadType: LclOrFcl;
  loads: LoadOvrutilsWithUnits[];
};

const propositionToRelevantQuantityUnit = (
  proposition: Proposition,
): { quantity: number; unit: "teu" | "tw" | "wm" } | null => {
  const isFcl = proposition.loadType === "fcl";
  const isOceanLcl =
    proposition.loadType === "lcl" && proposition.freightMethod === "ocean";
  const isAir = proposition.freightMethod === "air";

  if (isAir) {
    const taxableWeight = calculateTotalTaxableWeight(proposition.loads);

    return taxableWeight !== 0 && taxableWeight
      ? {
          quantity: taxableWeight,
          unit: "tw",
        }
      : null;
  }
  if (isFcl) {
    const numberOfTeu = countTeus(proposition.containers);

    return numberOfTeu !== 0
      ? {
          quantity: numberOfTeu,
          unit: "teu",
        }
      : null;
  }
  if (isOceanLcl) {
    const chargeableWeight = calculateWM1(proposition.loads);

    return chargeableWeight !== 0 && chargeableWeight
      ? {
          quantity: chargeableWeight,
          unit: "wm",
        }
      : null;
  }

  return null;
};

export const determineMarginPerUnit =
  (proposition: Proposition | null) =>
  (margin: number): { margin: number; unit: "teu" | "tw" | "wm" } | null => {
    if (!proposition) {
      return null;
    }
    const relevantQuantityAndUnit =
      propositionToRelevantQuantityUnit(proposition);

    if (!relevantQuantityAndUnit) {
      return null;
    }

    return {
      margin: roundValue(margin / relevantQuantityAndUnit.quantity),
      unit: relevantQuantityAndUnit.unit,
    };
  };
