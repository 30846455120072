import React from "react";
import { isNumber } from "lodash-es";
import type { Transition, Variants } from "framer-motion";
import { motion } from "framer-motion";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";

const StyledOverlay = styled(motion.div, {
  base: {
    height: "100vh",
    left: "0",
    position: "fixed",
    top: "0",
    width: "100vw",
  },
});

const enterDelay = (
  transition: Transition,
  delay?: { enter?: string } | number,
) => ({
  delay: isNumber(delay) ? delay : delay?.["enter"],
  duration: 0.2,
  ease: [0, 0, 0.2, 1],
});

const exitDelay = (delay?: { exit?: string } | number) => ({
  delay: isNumber(delay) ? delay : delay?.["exit"],
  duration: 0.1,
  ease: [0.4, 0, 1, 1],
});

const variants: Variants = {
  enter: ({ delay, transition, transitionEnd } = {}) => ({
    opacity: 1,
    transition: transition?.enter ?? enterDelay(delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ delay, transition, transitionEnd } = {}) => ({
    opacity: 0,
    transition: transition?.exit ?? exitDelay(delay),
    transitionEnd: transitionEnd?.exit,
  }),
};

const OverlayMotion = polymorphic<"div">(
  (
    {
      onAnimationEnd,
      onAnimationStart,
      onDrag,
      onDragEnd,
      onDragStart,
      ...rest
    },
    ref,
  ) => (
    <StyledOverlay
      animate="enter"
      exit="exit"
      initial="exit"
      ref={ref}
      variants={variants}
      {...rest}
    />
  ),
);

OverlayMotion.displayName = "OverlayMotion";

export { OverlayMotion };
