import { useCallback, useRef } from "react";

export type SetItemRef<Node extends HTMLElement> = (
  value: string,
) => (node: Node) => void;

type List<Node extends HTMLElement> = Record<string, Node>;

export const useRefList = <Node extends HTMLElement>() => {
  const refs = useRef<List<Node>>({});

  const setRef: SetItemRef<Node> = useCallback(
    (value) => (node) => (refs.current[value] = node),
    [],
  );

  return { refs, setRef } as const;
};
