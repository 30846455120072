import type { ReactNode } from "react";
import React from "react";
import type { IconName } from "../Typography/Icon";
import { Icon } from "../Typography/Icon";
import type { Theme } from "../../theme/theme";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import type { SystemProps } from "../../utils/types/system";
import { Caption } from "../Typography/Caption";

const colorMapper = ({ colors }: Theme) =>
  ({
    accent: {
      background: colors.accent.base,
      text: colors.accent.light,
    },
    "accent-light": {
      background: colors.accent.light,
      text: colors.accent.base,
    },
    danger: {
      background: colors.alert.danger,
      text: colors.alert.dangerLight,
    },
    "danger-light": {
      background: colors.alert.dangerLight,
      text: colors.alert.danger,
    },
    info: {
      background: colors.alert.info,
      text: colors.alert.infoLight,
    },
    "info-light": {
      background: colors.alert.infoLight,
      text: colors.alert.info,
    },
    neutral: {
      background: colors.alert.neutral,
      text: colors.alert.neutralLight,
    },
    "neutral-light": {
      background: colors.alert.neutralLight,
      text: colors.alert.neutral,
    },
    primary: {
      background: colors.primary.base,
      text: colors.primary.light,
    },
    success: {
      background: colors.alert.success,
      text: colors.alert.successLight,
    },
    "success-light": {
      background: colors.alert.successLight,
      text: colors.alert.success,
    },
    warning: {
      background: colors.alert.warning,
      text: colors.alert.warningLight,
    },
    "warning-light": {
      background: colors.alert.warningLight,
      text: colors.alert.warning,
    },
  }) as const;

export type BadgeColor = keyof ReturnType<typeof colorMapper>;

export type BadgeSize = "md" | "sm";

type Props = {
  color: BadgeColor;
  dataTestId?: string;
  icon?: IconName;
  label: ReactNode;
  size?: BadgeSize;
};

export type BadgeProps = Props & SystemProps<"div">;

const StyledBadge = styled<
  typeof Caption,
  { badgeSize: BadgeSize; color: BadgeColor }
>(Caption, {
  base: ({ badgeSize, color, theme }) => ({
    backgroundColor: colorMapper(theme)[color].background,
    borderRadius: theme.radius.base,
    color: colorMapper(theme)[color].text,
    display: "inline-flex",
    justifyContent: "center",
    minWidth: "1rem",
    paddingBlock: badgeSize === "sm" ? undefined : theme.spacing[4],
    paddingInline: badgeSize === "sm" ? theme.spacing[4] : theme.spacing[8],
    whiteSpace: "nowrap",
  }),
});

export const Badge = polymorphic<"div", Props>(
  ({ color, dataTestId = "Badge", icon, label, size = "md", ...rest }, ref) => {
    return (
      <StyledBadge
        badgeSize={size}
        color={color}
        data-testid={dataTestId}
        ref={ref}
        /** this prop is forwarded to Caption */
        size={size === "md" ? "xs" : "xxs"}
        {...rest}
      >
        {icon && <Icon marginRight={4} name={icon} />}
        {label}
      </StyledBadge>
    );
  },
);

Badge.displayName = "Badge";
