import type { RefObject } from "react";
import { useEffect, useRef } from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { useCallbackRef } from "./useCallbackRef";

type UseOutsideClickProps = {
  callback?: (e: Event) => void;
  ref: RefObject<HTMLElement>;
  when?: boolean;
};

const getOwnerDocument = (node?: Element | null): Document =>
  node?.ownerDocument ?? document;

const isValidEvent = (event: any, ref: RefObject<HTMLElement>) => {
  const target = event.target as HTMLElement;

  if (event.button > 0) {
    return false;
  }

  if (isDefined(target)) {
    const ownerDocument = getOwnerDocument(target);

    if (!ownerDocument.contains(target)) {
      return false;
    }
  }

  return !ref.current?.contains(target);
};

const useOutsideClick = ({
  callback,
  ref,
  when = true,
}: UseOutsideClickProps) => {
  const callbackRef = useCallbackRef(callback);

  const stateRef = useRef({
    isPointerDown: false,
  });

  const state = stateRef.current;

  useEffect(() => {
    if (!when) {
      return;
    }

    const onPointerDown = (event: Event) => {
      if (isValidEvent(event, ref)) {
        state.isPointerDown = true;
      }
    };

    const onMouseUp = (event: Event) => {
      if (state.isPointerDown && callback && isValidEvent(event, ref)) {
        state.isPointerDown = false;
        callbackRef(event);
      }
    };

    document.addEventListener("mousedown", onPointerDown);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousedown", onPointerDown);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [callback, ref, callbackRef, state, when]);
};

export { useOutsideClick };
