import { usePrefixedId } from "../../../utils/hooks/usePrefixedId";

type Params = {
  activeIndex: number;
  isOpened: boolean;
};

export const useSelectAccessibilityProps = ({
  activeIndex,
  isOpened,
}: Params) => {
  const inputId = usePrefixedId("select");

  const ariaProps = {
    activeDescendant:
      activeIndex >= 0 ? `${inputId}-${activeIndex}` : undefined,
    controls: isOpened ? `${inputId}-items` : undefined,
  };

  return {
    ariaProps,
    inputId,
  } as const;
};
