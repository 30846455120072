import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import type { FlexProps } from "./Flex";
import { Flex } from "./Flex";

type Props = FlexProps;

export type CenterProps = Props & SystemProps<"div">;

export const Center = polymorphic<"div", Props>(
  ({ children, ...rest }, ref) => (
    <Flex alignItems="center" justifyContent="center" {...rest} ref={ref}>
      {children}
    </Flex>
  ),
);

Center.displayName = "Center";
