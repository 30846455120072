type Params = {
  value?: string;
};

const MINIMUM_ROWS = 2;

export const computeTextAreaRows = ({ value }: Params) => {
  const linesCount = value?.split("\n").length ?? 0;

  return Math.max(linesCount, MINIMUM_ROWS);
};
