/** When spreading on a JSX intrinsic element (e.g. <div {...props} />),
 * React omits all invalid HTML props by default;
 * but sometimes a prop is conflicting with a native attribute (like `color`, `width`).
 * Oftentimes it correspondis to old, deprecated attributes (e.g. from HTML3, like `color`).
 * To fix this, use the base below with the props that should not be forwarded.
 * */
export const omitProps =
  <Props, ForwardedProps extends keyof Props = keyof Props>(
    excludeProps: ForwardedProps[],
  ) =>
  (prop: ForwardedProps) =>
    !["as", "sx", ...excludeProps].includes(prop);

export type ShouldForwardProp = ReturnType<typeof omitProps>;
