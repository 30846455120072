import { injectors } from "./inject";

export const spacingProps = {
  gap: injectors.spacing("gap"),

  margin: injectors.spacing("margin"),
  marginBottom: injectors.spacing("marginBottom"),
  marginLeft: injectors.spacing("marginLeft"),
  marginRight: injectors.spacing("marginRight"),
  marginTop: injectors.spacing("marginTop"),
  marginX: injectors.spacing(["marginInlineStart", "marginInlineEnd"]),
  marginY: injectors.spacing(["marginTop", "marginBottom"]),

  padding: injectors.spacing("padding"),
  paddingBottom: injectors.spacing("paddingBottom"),
  paddingLeft: injectors.spacing("paddingLeft"),
  paddingRight: injectors.spacing("paddingRight"),
  paddingTop: injectors.spacing("paddingTop"),
  paddingX: injectors.spacing(["paddingInlineStart", "paddingInlineEnd"]),
  paddingY: injectors.spacing(["paddingTop", "paddingBottom"]),
};
