import React from "react";
import { polymorphic } from "../../utils/ref";
import {
  SegmentedControlIndicator,
  SegmentedControlItem,
  SegmentedControlRoot,
} from "./SegmentedControl/SegmentedControlComponents";

export type SegmentedControlProps = {
  onChange: (value: string) => void;
  value: string;
};

type Compounds = {
  Item: typeof SegmentedControlItem;
};

export const SegmentedControl = polymorphic<
  "div",
  SegmentedControlProps,
  Compounds
>(({ children, onChange, ...rest }, ref) => (
  <SegmentedControlRoot
    onValueChange={(value?: string) =>
      value && onChange(value)
    } /* can't be de-selected */
    ref={ref}
    {...rest}
    asChild={false}
    disabled={false}
    type="single"
  >
    {children}
    <SegmentedControlIndicator data-type="indicator" />
  </SegmentedControlRoot>
));

SegmentedControl.displayName = "SegmentedControl";
SegmentedControl.Item = SegmentedControlItem;
