import { isNotDefined } from "../../other/isNotDefined";
import type { Customs, Incoterm } from "../../sharedTypes";

const incotermsRequiringImportAndExportCustoms = ["exw", "ddp", "dpu"];
const incotermsRequiringImportCustoms = ["fca", "fas", "fob"];
const incotermsRequiringExportCustoms = [
  "dpu",
  "dap",
  "cpt",
  "cfr",
  "cif",
  "cip",
  "dap",
];

export const areExportCustomsNeeded = (
  incoterm: Incoterm | null | undefined,
  clientCustomsSpecificities: Customs | null,
): boolean => {
  if (isNotDefined(incoterm)) {
    return false;
  }

  const isIncotermRequiringExportCustoms =
    incotermsRequiringExportCustoms.includes(incoterm) ||
    incotermsRequiringImportAndExportCustoms.includes(incoterm);

  const isClientRequiringExportCustoms =
    isNotDefined(clientCustomsSpecificities) ||
    clientCustomsSpecificities === "only_export_customs" ||
    clientCustomsSpecificities === "both_import_and_export_customs";

  return isIncotermRequiringExportCustoms && isClientRequiringExportCustoms;
};

export const areImportCustomsNeeded = (
  incoterm: Incoterm | null | undefined,
  clientCustomsSpecificities: Customs | null,
): boolean => {
  if (isNotDefined(incoterm)) {
    return false;
  }

  const isIncotermRequiringExportCustoms =
    incotermsRequiringImportCustoms.includes(incoterm) ||
    incotermsRequiringImportAndExportCustoms.includes(incoterm);

  const isClientRequiringExportCustoms =
    isNotDefined(clientCustomsSpecificities) ||
    clientCustomsSpecificities === "only_import_customs" ||
    clientCustomsSpecificities === "both_import_and_export_customs";

  return isIncotermRequiringExportCustoms && isClientRequiringExportCustoms;
};
