import type { Country, OfficeId, OvrseaOffice } from "./types";
import { officeIds } from "./types";
import { physicalOvrseaOffices } from "./officeConfig";

export const NO_OFFICE_FOUND_WITH_ID = (officeId: string) =>
  `No office found for with the ID: ${officeId}`;

export const findOvrseaOfficeByCountry = (
  country: Country,
): OvrseaOffice | null => {
  const officeFound = physicalOvrseaOffices.find((office) =>
    office.responsibilityArea.includes(country),
  );

  if (!officeFound) {
    return null;
  }

  return officeFound;
};

export const computeWorldOffice = (
  physicalOffices: OvrseaOffice[],
): OvrseaOffice => {
  return {
    address: null,
    countryCode: null,
    defaultOpsOwner: null,
    defaultPaymentDelayInDays: 7,
    entityDescription: "OVRSEA France, 23 rue Nollet, 75017 Paris (France)",
    id: "master_world",
    legalDescription:
      "FORESEA TECHNOLOGIES, société par actions simplifiée dont le siège social est situé 23 rue Nollet, 75017 Paris (France), immatriculée au registre du commerce et des sociétés de Paris sous le numéro 832 541 189 - numéro de TVA : FR67832541189",
    legalName: null,
    name: "Ovrsea Master Office",
    responsibilityArea: physicalOffices
      .map((office) => office.responsibilityArea)
      .flat(),
    termsAndConditionsOfSaleLink:
      "https://www.ovrsea.com/termes-et-conditions-generales-de-ventes",
    timezone: "Europe/Paris",
  };
};

export const findOvrseaOffices = (): OvrseaOffice[] => {
  return [...physicalOvrseaOffices, computeWorldOffice(physicalOvrseaOffices)];
};

export const isOfficeId = (officeId: string): officeId is OfficeId =>
  officeIds.includes(officeId as OfficeId);

export const findOvrseaOfficeById = (id: OfficeId): OvrseaOffice => {
  const officeFound = findOvrseaOffices().find((office) => office.id === id);

  if (!officeFound) {
    throw new Error(NO_OFFICE_FOUND_WITH_ID(id));
  }

  return officeFound;
};

export const findAllOvrseaOfficesByIds = (ids: OfficeId[]): OvrseaOffice[] => {
  return ids.map((id: OfficeId) => findOvrseaOfficeById(id));
};
