import React from "react";
import { polymorphic } from "../../utils/ref";
import type { Theme } from "../../theme/theme";
import { styled } from "../../utils/system/factory";
import { Center } from "../Layout/Center";
import type { SystemProps } from "../../utils/types/system";

export const carbonIntensityScoreColorMapper = ({ colors }: Theme) =>
  ({
    A: {
      background: colors.nzi.backgroundScoreA,
      textColor: colors.nzi.scoreA,
    },
    B: {
      background: colors.nzi.backgroundScoreB,
      textColor: colors.nzi.scoreB,
    },
    C: {
      background: colors.nzi.backgroundScoreC,
      textColor: colors.nzi.scoreC,
    },
    D: {
      background: colors.nzi.backgroundScoreD,
      textColor: colors.nzi.scoreD,
    },
    E: {
      background: colors.nzi.backgroundScoreE,
      textColor: colors.nzi.scoreE,
    },
  }) as const;

export type CarbonIntensityScoreType = keyof ReturnType<
  typeof carbonIntensityScoreColorMapper
>;

type StyledProps = {
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  score: CarbonIntensityScoreType;
};

const StyledScore = styled<typeof Center, StyledProps>(Center, {
  base: ({
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    score,
    theme,
  }) => [
    {
      backgroundColor: carbonIntensityScoreColorMapper(theme)[score].background,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderRadius: theme.radius.base,
      borderTopLeftRadius,
      borderTopRightRadius,
      color: carbonIntensityScoreColorMapper(theme)[score].textColor,
      fontSize: theme.font.size.md,
      fontWeight: theme.font.weight.bold,
      height: theme.spacing[24],
      lineHeight: theme.lineHeight.md,
      width: theme.spacing[24],
    },
  ],
});

type Props = {
  score: CarbonIntensityScoreType;
};

export type CarbonIntensityScoreProps = Props & SystemProps<"div">;

const CarbonIntensityScore = polymorphic<"div", Props>(
  ({ score, ...rest }, ref) => (
    <StyledScore ref={ref} score={score} {...rest} isInline>
      {score}
    </StyledScore>
  ),
);

CarbonIntensityScore.displayName = "CarbonIntensityScore";

export { CarbonIntensityScore };
