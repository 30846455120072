type RIB =
  | "bnpParibasFranceEUR"
  | "bnpParibasFranceUSD"
  | "iBanFirstEur"
  | "iBanFirstSpain"
  | "iBanFirstUsd"
  | "ibanFirstUsaEur"
  | "ovrseaInc"
  | "qonto"
  | "qontoItaly"
  | "qontoSpain"
  | "revolutItaly"
  // DO NOT USE SIGNATURE ANYMORE, IT IS LEFT FOR LEGACY INVOICES USING IT
  | "societeGeneraleUSD"
  | "transferWiseUSD";

export type RIBInfo = {
  accountName?: string;
  accountNumber?: string;
  achRoutingNumber?: string;
  bic?: string;
  iban?: string;
  intermediaryBank?: string;
  intermediaryBic?: string;
  name: RIB;
  nameOfBank: string;
  wireRoutingNumber?: string;
};

const allRIBs = {
  bnpParibasFranceEUR: {
    bic: "BNPAFRPPXXX",
    iban: "FR76 3000 4013 2800 0140 6170 704",
    name: "bnpParibasFranceEUR",
    nameOfBank: "BNP Paribas",
  },
  bnpParibasFranceUSD: {
    bic: "BNPAFRPPXXX",
    iban: "FR76 3000 4013 2800 0102 3187 641",
    name: "bnpParibasFranceUSD",
    nameOfBank: "BNP Paribas",
  },
  iBanFirstEur: {
    bic: "FXBBFRPPXXX",
    iban: "FR76 2003 3000 0100 0000 6439 641",
    name: "iBanFirstEur",
    nameOfBank: "iBanFirst",
  },
  iBanFirstSpain: {
    bic: "FXBBBEBBXXX",
    iban: "BE62 9140 3737 9061",
    name: "iBanFirstSpain",
    nameOfBank: "Ibanfirst Spain",
  },
  iBanFirstUsd: {
    bic: "FXBBFRPPXXX",
    iban: "FR76 2003 3000 0100 0000 6441 581",
    intermediaryBank: "ING BELGIUM NV/SA",
    intermediaryBic: "BBRUBEBBXXX",
    name: "iBanFirstUsd",
    nameOfBank: "iBanFirst",
  },
  ibanFirstUsaEur: {
    bic: "FXBBBEBBXXX",
    iban: "BE16 9140 4119 2474",
    name: "ibanFirstUsaEur",
    nameOfBank: "iBanFirst USA EUR",
  },
  // DO NOT USE SIGNATURE ANYMORE, IT IS LEFT FOR LEGACY INVOICES USING IT
  ovrseaInc: {
    accountName: "OVRSEA INC.",
    accountNumber: "1504378566",
    bic: "SIGNUS33",
    name: "ovrseaInc",
    nameOfBank: "SIGNATURE BANK, 565 FIFTH AVENUE, NEW YORK, NY 10017",
    wireRoutingNumber: "026013576",
  },

  // DO NOT USE QONTO ANYMORE, IT IS LEFT FOR LEGACY INVOICES USING IT
  /**
   * @deprecated
   */
  qonto: {
    bic: "QNTOFRP1XXX",
    iban: "FR76 1695 8000 0127 9759 4884 903",
    name: "qonto",
    nameOfBank: "QONTO",
  },

  qontoItaly: {
    bic: "QNTOITM2XXX",
    iban: "IT94L3609201600267243695880",
    name: "qontoItaly",
    nameOfBank: "QONTO",
  },
  qontoSpain: {
    bic: "QNTOESB2XXX",
    iban: "ES14 6888 0001 6139 7203 8028",
    name: "qontoSpain",
    nameOfBank: "QONTO",
  },
  revolutItaly: {
    bic: "REVOLT21",
    iban: "LT69 3250 0015 9209 9112",
    name: "revolutItaly",
    nameOfBank: "Revolut OVRSEA SRL",
  },
  societeGeneraleUSD: {
    accountName: "OVRSEA INC.",
    accountNumber: "00208922",
    bic: "SOGEUS33",
    name: "societeGeneraleUSD",
    nameOfBank:
      "Societe Generale New York Branch 245 Park Avenue, New York, NY 10167 USA",
    wireRoutingNumber: "026004226",
  },
  transferWiseUSD: {
    accountNumber: "8310037672",
    achRoutingNumber: "026073150",
    name: "transferWiseUSD",
    nameOfBank: "TransferWise",
    wireRoutingNumber: "026073008",
  },
} as const satisfies Record<RIB, RIBInfo>;

const RIBsWithDefault: {
  default: RIBInfo;
} & Record<RIB, RIBInfo> = {
  ...allRIBs,
  default: allRIBs.bnpParibasFranceEUR,
  /**
   * @deprecated DO NOT USE QONTO ANYMORE, IT IS LEFT FOR LEGACY INVOICES USING IT
   */
  qonto: allRIBs.qonto,
};

export { RIBsWithDefault as RIBs };
