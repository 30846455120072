export const extensionMap: { [key in string]: string } = {
  "application/pdf": "pdf",
  "image/gif": "gif",
  "image/jpeg": "jpeg",
  "image/jpg": "jpg",
  "image/png": "png",
};

export const mapMimeTypeToExtension = (mimeType: string | undefined) => {
  if (!mimeType) {
    return undefined;
  }

  return extensionMap[mimeType];
};
