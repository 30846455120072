import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { VerticalTimeline } from "./Timeline/VerticalTimeline";
import { HorizontalTimeline } from "./Timeline/HorizontalTimeline";
import {
  TimelineDot,
  TimelineEvent,
  TimelineIcon,
  TimelineItem,
  TimelineItemContent,
  TimelineItemHeader,
  TimelineLine,
} from "./Timeline/TimelineComponents";

export type TimelineDirection = "horizontal" | "vertical";

export type TimelineItemColor = "green" | "grey";

export type TimelineSize = "medium" | "small";

type Props = {
  dataTestId?: string;
  direction?: TimelineDirection;
  size?: TimelineSize;
};

export type TimelineProps = Props & SystemProps<"ul">;

type Compounds = {
  Content: typeof TimelineItemContent;
  Dot: typeof TimelineDot;
  Event: typeof TimelineEvent;
  Header: typeof TimelineItemHeader;
  Icon: typeof TimelineIcon;
  Item: typeof TimelineItem;
  Line: typeof TimelineLine;
};

export const Timeline = polymorphic<"ul", Props, Compounds>(
  (
    {
      dataTestId = "Timeline",
      direction = "vertical",
      size = "medium",
      ...rest
    },
    ref,
  ) => {
    if (direction === "vertical") {
      return (
        <VerticalTimeline
          data-testid={dataTestId}
          ref={ref}
          size={size}
          {...rest}
        />
      );
    }

    return (
      <HorizontalTimeline
        data-testid="Timeline"
        ref={ref}
        size={size}
        {...rest}
      />
    );
  },
);

Timeline.displayName = "Timeline";
Timeline.Item = TimelineItem;
Timeline.Header = TimelineItemHeader;
Timeline.Content = TimelineItemContent;
Timeline.Icon = TimelineIcon;
Timeline.Line = TimelineLine;
Timeline.Dot = TimelineDot;
Timeline.Event = TimelineEvent;
