import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { omitProps } from "../../theme/serializer/shouldForwardProp";
import type { Theme } from "../../theme/theme";

const sizeMap = ({ font, lineHeight }: Theme) => ({
  md: {
    fontSize: font.size.md,
    fontWeight: font.weight.base,
    lineHeight: lineHeight.md,
  },
  sm: {
    fontSize: font.size.sm,
    fontWeight: font.weight.medium,
    lineHeight: lineHeight.md,
  },
});

export type LinkProps = {
  isExternal?: boolean;
  isUnstyled?: boolean;
  size?: keyof ReturnType<typeof sizeMap>;
} & SystemProps<"a">;

const StyledLink = styled<"a", LinkProps>("a", {
  base: ({ isUnstyled, size = "md", theme }) => {
    if (isUnstyled) {
      return;
    }

    return {
      ...sizeMap(theme)[size],
      "&:focus": {
        boxShadow: `${theme.colors.focus.ring} 0 0 0 3px`,
        outline: "none",
      },
      "&:focus:not(:focus-visible)": {
        boxShadow: "none",
      },
      "&:hover": {
        color: theme.colors.text.link,
        textDecoration: "underline",
      },
      color: theme.colors.text.link,
      cursor: "pointer",
      textDecoration: "underline",
      transitionDuration: theme.transition.duration.base,
      transitionProperty: "all",
      transitionTimingFunction: theme.transition.easing.color,
    };
  },
  shouldForwardProp: omitProps<LinkProps>(["isUnstyled"]),
});

export const Link = polymorphic<"a", LinkProps>(
  ({ isExternal, isUnstyled, size, ...rest }, ref) => (
    <StyledLink
      isUnstyled={isUnstyled}
      ref={ref}
      rel={isExternal ? "noopener noreferrer" : undefined}
      size={size}
      target={isExternal ? "_blank" : undefined}
      {...rest}
    />
  ),
);

Link.displayName = "Link";
