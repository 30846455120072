import { useCallback, useEffect, useRef, useState } from "react";

const useUnmountEffect = (callback: () => void, deps: any[] = []) =>
  useEffect(() => () => callback(), deps);

const useForceUpdate = () => {
  const unloadingRef = useRef(false);
  const [count, setCount] = useState(0);

  useUnmountEffect(() => {
    unloadingRef.current = true;
  });

  return useCallback(() => {
    if (!unloadingRef.current) {
      setCount(count + 1);
    }
  }, [count]);
};

export { useForceUpdate, useUnmountEffect };
