import type { ReactElement, ReactNode } from "react";
import React, { Children, cloneElement } from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { callIfFunction } from "./objects";

const filterValidChildren = (children: ReactNode) =>
  Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  ) as ReactElement[];

const clampElements = (elements: ReactElement[], min = 0, max?: number) => {
  return isDefined(max) ? elements.slice(min, max) : elements;
};

const injectIndex = (children: ReactNode) =>
  Children.map(filterValidChildren(children), (child, index) =>
    cloneElement(child as ReactElement, { dataIndex: index }),
  );

type CloneChildCallback = (
  child: ReactElement<object>,
  index: number,
  childrenCount: number,
) => Partial<object>;

const cloneChildren = (children: ReactNode, callback: CloneChildCallback) => {
  const validChildren = filterValidChildren(children);

  return validChildren.map((child, index) =>
    cloneElement(child, callback(child, index, validChildren.length)),
  );
};

const renderChildren = <T, U>(
  maybeFunction: ((...fnArgs: U[]) => T) | T,
  ...args: U[]
): T => callIfFunction<T, U>(maybeFunction)(...args);

const deepHasChild = (
  children: ReactNode,
  type: ReactElement["type"],
): boolean =>
  Children.toArray(children).some((child) => {
    if (
      !React.isValidElement(child) ||
      typeof child === "string" ||
      typeof child === "number"
    ) {
      return false;
    }

    return child.type === type || deepHasChild(child.props?.children, type);
  });

export {
  clampElements,
  cloneChildren,
  deepHasChild,
  filterValidChildren,
  injectIndex,
  renderChildren,
};
