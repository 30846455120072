import type { EnumOrLiteral } from "../..";
import { isOrderFullTruckOrVrac } from "../..";
import type { ContainerOvrutils } from "./containerUtils";
import type { LoadOvrutils } from "./loadUtils";

type LithiumCategory = "alone" | "contained" | "packed";

export type OrderOvrUtils = {
  containers: ContainerOvrutils[];
  lithiumDetails: EnumOrLiteral<LithiumCategory> | null;
  loadType: null | string;
  loads: LoadOvrutils[];
  refrigeratedDetails: null | string;
};

type SelectOrderHazardousDetailsArgs = {
  containers: ContainerOvrutils[];
  loads: LoadOvrutils[];
};

const selectOrderHazardousDetails = (
  order: SelectOrderHazardousDetailsArgs,
) => {
  const hazardousLoad = order.loads.find((load) => load.hazardous);
  const loadHazardousDetails = hazardousLoad?.hazardousDetails;

  const hazardousContainer = order.containers.find(
    (container) => container.hazardous,
  );
  const containerHazardousDetails = hazardousContainer?.hazardousDetails;

  const isHazardous = !!hazardousLoad || !!hazardousContainer || false;
  const hazardousDetails =
    loadHazardousDetails || containerHazardousDetails || null;

  return [isHazardous, hazardousDetails] as const;
};

const selectOrderRefrigeratedDetails = (order: {
  refrigeratedDetails: null | string;
}) => {
  const refrigeratedDetails = order.refrigeratedDetails || null;

  return [!!refrigeratedDetails, refrigeratedDetails] as const;
};

type SelectOrderLithiumDetailsArgs = {
  lithiumDetails: LithiumCategory | null;
  loads: LoadOvrutils[];
};
const selectOrderLithiumDetails = (order: SelectOrderLithiumDetailsArgs) => {
  const lithiumLoad = order.loads.find((load) => load.lithium);
  const lithiumDetails = order.lithiumDetails ?? null;

  return [!!lithiumLoad, lithiumDetails] as const;
};

const isOrderMagnetic = (order: { loads: LoadOvrutils[] }) => {
  const magneticLoad = order.loads.find((load) => load.magnetic);

  return !!magneticLoad;
};

// this assumes the cargo is LCL and not bulk.
const isOrderCargoNotStackable = (order: { loads: LoadOvrutils[] }) => {
  const notStackableLoad = order.loads.find((load) => load.nonStackable);

  return !!notStackableLoad;
};

const isOrderCargoSpecific = (order: OrderOvrUtils) => {
  const [isOrderHazardous] = selectOrderHazardousDetails(order);
  const [isOrderRefrigerated] = selectOrderRefrigeratedDetails(order);
  const [isOrderLithium] = selectOrderLithiumDetails(order);
  const isOrderCargoMagnetic = isOrderMagnetic(order);
  const isStackable =
    !isOrderFullTruckOrVrac(order) && order.loadType === "lcl";

  return (
    isOrderHazardous ||
    isOrderRefrigerated ||
    isOrderLithium ||
    isOrderCargoMagnetic ||
    isStackable
  );
};

export {
  isOrderCargoNotStackable,
  isOrderCargoSpecific,
  isOrderMagnetic,
  selectOrderHazardousDetails,
  selectOrderLithiumDetails,
  selectOrderRefrigeratedDetails,
};
