import type {
  ComponentProps,
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  Ref,
  SyntheticEvent,
} from "react";
import React from "react";
import { keys } from "lodash-es";
import { isDefined } from "@ovrsea/ovrutils";
import { polymorphic } from "../../../utils/ref";
import { ariaAttribute, dataAttribute } from "../../../utils/attributes";
import { StyledInput } from "../Input/BaseInput";
import { styled } from "../../../utils/system/factory";
import { Icon } from "../../Typography/Icon";
import { Popover } from "../../Overlay/Popover";
import { HStack, VStack } from "../../Layout/Stack";
import type { Sx } from "../../../utils/types/system";
import type { SetItemRef } from "../../../utils/hooks/useRefList";
import { noop } from "../../../utils/events";
import { Button } from "../../Action/Button";
import {
  SelectAction,
  SelectEmptyStates,
  SelectGroupLabel,
  SelectIcon,
  SelectOption,
} from "../Shared/SelectComponents";
import { Flex } from "../../Layout/Flex";
import { Spinner } from "../../Feedback/Spinner";
import type { SelectOptionType } from "../Shared/types";
import { findSelectedIndex, groupOptions } from "../Shared/utils";
import type { BoxProps } from "../../Meta/Box";
import { Box } from "../../Meta/Box";
import type { BodyProps } from "../../Typography/Body";
import { Body } from "../../Typography/Body";
import { Tooltip } from "../../Overlay/Tooltip";
import { Center } from "../../Layout/Center";

type SelectTriggerProps = {
  activeIndex: number;
  ariaActiveDescendant?: string;
  ariaControls?: string;
  autoFocus?: boolean;
  handleRemoveOption: (value: string) => void;
  inputId: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
  isLoading?: boolean;
  isOpened: boolean;
  isSearchable?: boolean;
  onChange: (value: string) => void;
  onClear: (event: SyntheticEvent) => void;
  onOpen: () => void;
  placeholder?: string;
  searchQuery: string;
  selectedOptions: SelectOptionType[];
  setActiveOption: (id: number) => void;
  setSearchQuery: (query: string) => void;
  sx?: Sx;
  value?: string[];
};

const StyledInnerInput = styled<
  "input",
  { isOpened: boolean } & ComponentProps<"input">
>("input", {
  base: ({ isOpened }) => [
    {
      background: "transparent",
      flex: 1,
      minWidth: 60,
      outline: "none",
      overflow: "hidden",
      width: 0,
    },
    {
      "&:disabled": {
        cursor: "not-allowed",
        userSelect: "none",
      },
    },
    {
      "&[data-hasvalues]": {
        height: isOpened ? undefined : 0,
        margin: isOpened ? 2 : 0,
        marginLeft: isOpened ? 2 : 0,
      },
    },
  ],
});

const containerSx: Sx = (theme) => ({
  "& input + div button:is(:focus, :hover):not(:disabled) svg": {
    color: theme.colors.neutral.base,
  },
  "& input:is(:focus, :hover) + div button:not(:disabled) svg": {
    color: theme.colors.neutral.base,
  },
  "& input[readonly]": {
    cursor: "pointer",
  },
  minHeight: 40,
});

const IconWrapper = styled(Center, {
  base: ({ theme }) => ({
    "&:hover": {
      "& svg": {
        color: theme.colors.neutral.base,
      },
      background: theme.colors.neutral["20"],
    },
    height: "100%",
  }),
});

const removeIconSx: Sx = (theme) => ({
  color: theme.colors.neutral["40"],
  fontSize: theme.font.size.xs,
  margin: theme.spacing[4],
});

type SelectedOptionLabelProps = {
  handleRemoveOption: (value: string) => void;
} & SelectOptionType;

const StyledSelectedOption = styled<typeof Box, BoxProps>(Box, {
  base: ({ theme }) => ({
    ...theme.body.sm,
    alignItems: "center",
    backgroundColor: theme.colors.neutral.light,
    borderColor: theme.colors.neutral["20"],
    borderRadius: theme.radius.sm,
    borderWidth: 1,
    color: theme.colors.neutral.base,
    cursor: "pointer",
    display: "flex",
    fontWeight: theme.font.weight.medium,
    height: theme.spacing[24],
    marginBottom: "2px",
    marginRight: theme.spacing[8],
    marginTop: "2px",
    overflow: "hidden",
    userSelect: "none",
  }),
});

const StyledSelectedOptionLabel = styled<typeof Body, BodyProps>(Body, {
  base: ({ theme }) => ({
    ...theme.body.sm,
    color: theme.colors.neutral.base,
    fontWeight: theme.font.weight.medium,
    marginLeft: theme.spacing[8],
    marginRight: theme.spacing[8],
  }),
});

const SelectedOption = ({
  handleRemoveOption,
  label,
  value,
}: SelectedOptionLabelProps) => {
  const onMouseDown = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    handleRemoveOption(value);
  };

  return (
    <StyledSelectedOption data-testid="Chip">
      <Tooltip label={label} openDelay={300}>
        <StyledSelectedOptionLabel isTruncated>
          {label}
        </StyledSelectedOptionLabel>
      </Tooltip>
      <IconWrapper>
        <Icon
          data-testid="remove-option"
          name="cross-sm"
          onMouseDown={onMouseDown}
          sx={removeIconSx}
        />
      </IconWrapper>
    </StyledSelectedOption>
  );
};

const outerInputSx: Sx = (theme) => ({
  alignItems: "center",
  background: theme.colors.background.light,
  display: "flex",
  flex: 1,
  flexWrap: "wrap",
  height: "unset",
  paddingBlock: 6,
});

const rightElementsSx: Sx = ({ spacing }) => ({
  height: spacing[40],
  position: "absolute",
  right: spacing[12],
});

const SelectTrigger = polymorphic<"input", SelectTriggerProps>(
  (
    {
      ariaActiveDescendant,
      ariaControls,
      handleRemoveOption,
      inputId,
      isClearable,
      isDisabled,
      isErrored,
      isLoading,
      isOpened,
      isSearchable = true,
      onChange,
      onClear,
      onClick,
      onOpen,
      placeholder,
      searchQuery,
      selectedOptions,
      setActiveOption,
      setSearchQuery,
      sx,
      value = [],
      ...rest
    },
    ref,
  ) => {
    return (
      <Popover.Trigger>
        <styled.div
          aria-controls={inputId}
          aria-expanded={ariaAttribute(isOpened)}
          aria-haspopup="listbox"
          aria-owns={ariaControls}
          onClick={onClick}
          onMouseLeave={() => setActiveOption(-1)}
          role="combobox"
          sx={[containerSx, sx]}
          tabIndex={-1}
        >
          <Flex alignItems="center" sx={{ position: "relative" }}>
            <StyledInput
              as="div"
              data-disabled={dataAttribute(isDisabled)}
              disabled={isDisabled}
              hasRightElement={!isLoading}
              isDisabled={isDisabled}
              isErrored={isErrored}
              isLoading={isLoading}
              readOnly={!isSearchable || isDisabled}
              sx={outerInputSx}
              tabIndex={undefined}
              {...rest}
            >
              {selectedOptions.map((option) => (
                <SelectedOption
                  handleRemoveOption={handleRemoveOption}
                  key={option.value}
                  label={option.label}
                  value={option.value}
                />
              ))}
              <StyledInnerInput
                autoComplete="off"
                data-hasvalues={ariaAttribute(Boolean(value.length))}
                data-value={value.length ? value.join(", ") : undefined}
                disabled={isDisabled}
                isOpened={isOpened}
                onChange={(event) => onChange(event.target.value)}
                placeholder={value.length ? undefined : placeholder}
                ref={ref}
                type="search"
                value={searchQuery}
              />
            </StyledInput>
            <HStack alignItems="center" spacing={12} sx={rightElementsSx}>
              {isLoading && <Spinner isActive size="sm" />}
              {!isLoading && (
                <SelectIcon
                  hasValue={Boolean(value.length)}
                  isClearable={isClearable}
                  isDisabled={isDisabled}
                  onClear={onClear}
                  onOpen={onOpen}
                />
              )}
              {isErrored && (
                <Icon
                  name="error-circle"
                  sx={({ colors }) => ({ color: colors.alert.danger })}
                />
              )}
            </HStack>
          </Flex>
        </styled.div>
      </Popover.Trigger>
    );
  },
);

type OptionGroupProps = {
  activeIndex: number;
  dataTestId?: string;
  group: string;
  groupOptions: SelectOptionType[];
  handleListClick: MouseEventHandler<HTMLDivElement>;
  handleSelect: (value: string) => void;
  options: SelectOptionType[];
  setActiveIndex: (index: number) => void;
  setItemRef: SetItemRef<HTMLDivElement>;
};

const OptionGroup = ({
  activeIndex,
  dataTestId,
  group,
  groupOptions,
  handleListClick,
  handleSelect,
  options,
  setActiveIndex,
  setItemRef,
}: OptionGroupProps) => (
  <>
    <SelectGroupLabel group={group} />
    <VStack
      data-testid={dataTestId && `${dataTestId}-listbox`}
      onMouseDown={handleListClick}
      padding={4}
      role="listbox"
      spacing={4}
    >
      {groupOptions.map(({ description, label, value: optionValue }) => {
        const selectedIndex = findSelectedIndex(optionValue)(options);

        return (
          <SelectOption
            description={description}
            handleSelect={handleSelect}
            isActive={activeIndex === selectedIndex}
            key={optionValue}
            label={label}
            optionValue={optionValue}
            selectedIndex={selectedIndex}
            setActiveIndex={setActiveIndex}
            setItemRef={setItemRef}
          />
        );
      })}
    </VStack>
  </>
);

OptionGroup.displayName = "OptionGroup";

const contentInnerStyle = (width: CSSProperties["width"]): Sx => ({
  "& > div": {
    overflow: "hidden",
  },
  minWidth: width,
  width: "var(--radix-popover-trigger-width)",
});

const contentSx: Sx = (theme) => [
  {
    "& div[role='option']": {
      "& .description": {
        color: theme.colors.text.secondary,
      },
      "& > div": {
        minWidth: 0,
      },
      alignItems: "center",
      borderRadius: theme.radius.base,
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      padding: `${theme.spacing[4]} ${theme.spacing[8]}`,
    },
  },
  {
    "& div[role='option'][data-active]": {
      "& .description": {
        color: theme.colors.neutral["60"],
      },
      background: theme.colors.neutral["10"],
    },
  },
];

const overflowContainerStyle: CSSProperties = {
  maxHeight: 220,
  overflow: "auto",
};

type SelectOptionsProps = {
  actionLabel?: ReactNode;
  activeIndex: number;
  dataTestId?: string;
  handleListClick: MouseEventHandler<HTMLDivElement>;
  handleSelect: (value: string) => void;
  initialOptionCount: number;
  isOpened: boolean;
  menuWidth: CSSProperties["width"];
  noOptionsMessage?: ReactNode;
  nothingFoundMessage?: ReactNode;
  onActionClick?: (search: string) => void;
  options: SelectOptionType[];
  scrollableRef: Ref<HTMLDivElement>;
  searchQuery: string;
  setActiveIndex: (index: number) => void;
  setClosed: () => void;
  setItemRef: SetItemRef<HTMLDivElement>;
  value?: string;
};

const SelectOptions = polymorphic<"div", SelectOptionsProps>(
  (
    {
      actionLabel,
      activeIndex,
      dataTestId,
      handleListClick,
      handleSelect,
      initialOptionCount,
      menuWidth,
      noOptionsMessage,
      nothingFoundMessage,
      onActionClick = noop,
      options,
      scrollableRef,
      searchQuery,
      setActiveIndex,
      setItemRef,
    },
    ref,
  ) => {
    const groups = groupOptions(options);

    return (
      <Popover.Content
        data-animatescale={false}
        data-testid="select-portal"
        innerSx={contentInnerStyle(menuWidth)}
        onCloseAutoFocus={(event) => event.preventDefault()}
        preventAutoFocus
        ref={ref}
        sx={contentSx}
      >
        <div ref={scrollableRef} style={overflowContainerStyle}>
          <SelectEmptyStates
            initialOptionCount={initialOptionCount}
            noOptionsMessage={noOptionsMessage}
            nothingFoundMessage={nothingFoundMessage}
            optionCount={options.length}
          />
          {keys(groups).map((group) => (
            <OptionGroup
              activeIndex={activeIndex}
              dataTestId={dataTestId}
              group={group}
              groupOptions={groups[group]}
              handleListClick={handleListClick}
              handleSelect={handleSelect}
              key={group}
              options={options}
              setActiveIndex={setActiveIndex}
              setItemRef={setItemRef}
            />
          ))}
        </div>
        {isDefined(actionLabel) && (
          <SelectAction
            data-testid="select-action"
            onClick={() => onActionClick(searchQuery)}
          >
            <Button label={actionLabel} size="sm" type="text" />
          </SelectAction>
        )}
      </Popover.Content>
    );
  },
);

export { SelectOptions, SelectTrigger };
