import { contextFactory } from "../../../utils/contextFactory";

type PaginationContextType = {
  activePage: number;
  goToNextPage: () => void;
  goToPage: (page: number) => void;
  goToPreviousPage: () => void;
  pagesCount: number;
};

export const [PaginationProvider, usePaginationContext] =
  contextFactory<PaginationContextType>({
    displayName: "PaginationProvider",
    errorMessage: "Pagination elements should be wrapped in Pagination.",
  });
