import type { FC, PropsWithChildren } from "react";
import React from "react";
import { HeadlessHoverCard } from "../Meta/Headless";
import { styled } from "../../utils/system/factory";
import { animateEnter, animateExit } from "../../utils/system/animate";
import { polymorphic } from "../../utils/ref";

const StyledContent = styled<"div", HeadlessHoverCard.HoverCardContentProps>(
  "div",
  {
    base: ({ theme }) => ({
      "&&[data-side='bottom']": { "--enter-translate-y": "-8px" },
      "&&[data-side='left']": { "--enter-translate-x": "8px" },
      "&&[data-side='right']": { "--enter-translate-x": "-8px" },
      "&&[data-side='top']": { "--enter-translate-y": "8px" },
      "&[data-state='closed']": {
        "--exit-opacity": 0,
        "--exit-scale": 0.95,
        "--exit-translate-x": "initial",
        "--exit-translate-y": "initial",
        animationDuration: theme.transition.duration.fast,
        animationName: animateExit,
      },
      "&[data-state='open']": {
        "--enter-opacity": 0,
        "--enter-scale": 0.95,
        "--enter-translate-x": "initial",
        "--enter-translate-y": "initial",
        animationDuration: theme.transition.duration.fast,
        animationName: animateEnter,
      },
      background: theme.colors.background.light,
      border: `1px solid ${theme.colors.neutral["10"]}`,
      borderRadius: theme.radius.md,
      boxShadow: theme.shadows.md,
      padding: theme.spacing[16],
      zIndex: theme.zIndex.popover,
    }),
  },
);

const HoverCardContent = polymorphic<
  typeof HeadlessHoverCard.HoverCardContent,
  HeadlessHoverCard.HoverCardContentProps
>(({ children, sideOffset = 4, ...rest }, ref) => (
  <HeadlessHoverCard.Portal>
    <HeadlessHoverCard.HoverCardContent
      {...rest}
      asChild
      ref={ref}
      sideOffset={sideOffset}
    >
      <StyledContent>{children}</StyledContent>
    </HeadlessHoverCard.HoverCardContent>
  </HeadlessHoverCard.Portal>
));

HoverCardContent.displayName = "HoverCardContent";

const HoverCardTrigger = ({ children }: PropsWithChildren) => (
  <HeadlessHoverCard.Trigger asChild>{children}</HeadlessHoverCard.Trigger>
);

export type HoverCardProps = HeadlessHoverCard.HoverCardProps;

type HoverCardType = {
  Content: typeof HoverCardContent;
  Trigger: typeof HoverCardTrigger;
} & FC<HoverCardProps>;

const HoverCard: HoverCardType = ({
  closeDelay = 50,
  openDelay = 200,
  ...rest
}) => (
  <HeadlessHoverCard.Root
    {...rest}
    closeDelay={closeDelay}
    openDelay={openDelay}
  />
);

HoverCard.Trigger = HoverCardTrigger;
HoverCard.Content = HoverCardContent;

export { HoverCard };
