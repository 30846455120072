import React from "react";
import { polymorphic } from "../../../utils/ref";
import type { FlexProps } from "../../Layout/Flex";
import { Flex } from "../../Layout/Flex";
import type { BoxProps } from "../../Meta/Box";
import { styled } from "../../../utils/system/factory";
import { dataAttribute } from "../../../utils/attributes";
import { styles } from "../../../theme/commons";
import type { IconProps } from "../../Typography/Icon";
import { Icon } from "../../Typography/Icon";
import type { TableSize } from "./TableContext";
import { useTableContext } from "./TableContext";

const paddings = {
  cell: {
    md: 14,
    sm: 12,
  },
  header: {
    md: "12px 14px",
    sm: "10px 12px",
  },
} as const;

export const TableBody = polymorphic<"tbody", BoxProps>((props, ref) => (
  <styled.tbody ref={ref} {...props} />
));

type _CellProps = {
  isFitted?: boolean;
  isNumeric?: boolean;
  shouldWrap?: boolean;
};

const StyledCell = styled<"td", _CellProps & { size: TableSize }>("td", {
  base: ({ isFitted, isNumeric, shouldWrap, size, theme }) => [
    {
      borderBottom: `1px solid ${theme.colors.neutral["10"]}`,
      padding: paddings.cell[size],
    },
    isNumeric && {
      textAlign: "right",
    },
    isFitted && {
      width: 1 /** only solution to make a cell adjust to max-content */,
    },
    !shouldWrap && styles.truncated,
  ],
});

export const TableCell = polymorphic<"td", _CellProps>(
  ({ className, isFitted, isNumeric, shouldWrap, ...rest }, ref) => {
    const { size } = useTableContext();

    return (
      <StyledCell
        isFitted={isFitted}
        isNumeric={isNumeric}
        ref={ref}
        shouldWrap={shouldWrap}
        size={size}
        {...rest}
      />
    );
  },
);

const StyledTable = styled<"table", { isFixed?: boolean }>("table", {
  base: ({ isFixed, theme }) => [
    {
      background: theme.colors.background.light,
      borderCollapse: "separate",
      borderSpacing: 0,
      width: "100%",
    },
    isFixed && {
      tableLayout: "fixed",
    },
  ],
});

export const TableContent = polymorphic<"table">(({ ...rest }, ref) => {
  const { isFixed } = useTableContext();

  return <StyledTable isFixed={isFixed} ref={ref} {...rest} />;
});

const StyledFooter = styled(Flex, {
  base: ({ theme }) => ({
    background: theme.colors.background.light,
    borderTop: `1px solid ${theme.colors.neutral["10"]}`,
    paddingBottom: theme.spacing[16],
    paddingLeft: theme.spacing[24],
    paddingRight: theme.spacing[24],
    paddingTop: theme.spacing[16],
  }),
});

export const TableFooter = polymorphic<"div", FlexProps>((props, ref) => (
  <StyledFooter ref={ref} {...props} />
));

export const TableHeader = polymorphic<"thead", BoxProps>(
  ({ children, ...rest }, ref) => (
    <styled.thead ref={ref} {...rest}>
      <tr>{children}</tr>
    </styled.thead>
  ),
);

type SortDirection = "ascending" | "descending";

type SortIconProps = {
  direction?: SortDirection;
} & Partial<IconProps>;

const sortIconsMap = {
  ascending: "sort up",
  default: "sort",
  descending: "sort down",
} as const;

const StyledIconBlock = styled("div", {
  base: {
    display: "inline",
    marginLeft: 3,
    width: 16,
  },
});

const StyledSortIcon = styled(Icon, {
  base: ({ theme }) => [
    {
      color: theme.colors.neutral["60"],
      fontSize: theme.font.size.sm,
    },
    {
      "&[data-active]": {
        color: theme.colors.neutral["70"],
        verticalAlign: "text-bottom",
      },
      "&[data-direction='descending']": {
        verticalAlign: "initial",
      },
    },
  ],
});

const SortIcon = ({ direction, ...rest }: SortIconProps) => (
  <StyledIconBlock>
    <StyledSortIcon
      data-active={dataAttribute(Boolean(direction))}
      data-direction={direction}
      {...rest}
      name={sortIconsMap[direction ?? "default"]}
    />
  </StyledIconBlock>
);

type _HeaderCellProps = {
  isNumeric?: boolean;
  isSortable?: boolean;
  sortDirection?: SortDirection;
};

const StyledHeaderCell = styled<"td", _HeaderCellProps & { size: TableSize }>(
  "td",
  {
    base: ({ isNumeric, isSortable, size, theme }) => [
      {
        background: theme.colors.neutral.light,
        borderBottom: `1px solid ${theme.colors.neutral["10"]}`,
        color: theme.colors.neutral["60"],
        fontWeight: theme.font.weight.medium,
        padding: paddings.header[size],
        textAlign: "left",
        transitionDuration: theme.transition.duration.fast,
        transitionProperty: "color, background",
        transitionTimingFunction: theme.transition.easing.color,
        userSelect: "none",
        zIndex: 2,
      },
      isSortable && {
        "&:hover": {
          background: theme.colors.neutral["10"],
          color: theme.colors.neutral["70"],
        },
        "&[data-active]": {
          background: theme.colors.neutral["10"],
          color: theme.colors.neutral["70"],
        },
        cursor: "pointer",
      },
      isNumeric && {
        textAlign: "right",
      },
    ],
  },
);

const StyledHeaderCellContent = styled(Flex, {
  base: {
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    overflow: "hidden",
  },
});

export const TableHeaderCell = polymorphic<"th", _HeaderCellProps>(
  ({ children, isNumeric, isSortable, sortDirection, ...rest }, ref) => {
    const { size } = useTableContext();
    const isActive = Boolean(isSortable && sortDirection);

    return (
      <StyledHeaderCell
        isNumeric={isNumeric}
        isSortable={isSortable}
        ref={ref}
        size={size}
        {...rest}
        data-active={dataAttribute(isActive)}
      >
        <StyledHeaderCellContent>
          {children}
          {isSortable && <SortIcon direction={sortDirection} />}
        </StyledHeaderCellContent>
      </StyledHeaderCell>
    );
  },
);

const StyledRow = styled<"tr", { hoverable?: boolean }>("tr", {
  base: ({ hoverable, theme }) => [
    {
      "&:last-child td": {
        border: "none",
      },
      background: theme.colors.background.light,
    },
    hoverable && {
      "&:hover": {
        background: theme.colors.neutral.light,
      },
      cursor: "pointer",
    },
  ],
});

export const TableRow = polymorphic<"tr", BoxProps>((props, ref) => {
  const { highlightOnHover = Boolean(props.onClick) } = useTableContext();

  return <StyledRow ref={ref} {...props} hoverable={highlightOnHover} />;
});

TableBody.displayName = "TableBody";
TableCell.displayName = "TableCell";
TableContent.displayName = "TableContent";
TableFooter.displayName = "TableFooter";
TableHeader.displayName = "TableHeader";
TableHeaderCell.displayName = "TableHeaderCell";
TableRow.displayName = "TableRow";
