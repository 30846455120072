import React from "react";
import type { AlertType } from "../Alert";
import type { FlexProps } from "../../Layout/Flex";
import { Flex } from "../../Layout/Flex";
import { polymorphic } from "../../../utils/ref";
import type { IconName, IconProps } from "../../Typography/Icon";
import { Icon } from "../../Typography/Icon";
import { Subtitle } from "../../Typography/Subtitle";
import { Body } from "../../Typography/Body";
import { styled } from "../../../utils/system/factory";

const iconNames: Record<AlertType, IconName> = {
  error: "error-circle",
  info: "info-circle",
  success: "check-circle",
  warning: "warning",
};

const AlertErrorIcon = polymorphic<"svg", IconProps>((props, ref) => (
  <Icon alertType="error" {...props} name="error-circle" ref={ref} />
));

type AlertIconProps = {
  type: AlertType;
} & Partial<IconProps>;

const AlertIcon = polymorphic<typeof Icon, AlertIconProps>(
  ({ type, ...rest }, ref) => (
    <Icon
      marginRight={8}
      marginTop={4}
      ref={ref}
      type={type}
      {...rest}
      name={iconNames[type]}
    />
  ),
);

AlertIcon.displayName = "AlertIcon";

const AlertDescription = polymorphic<"div", FlexProps>(
  ({ children, flexDirection = "column", ...rest }, ref) => (
    <Flex flexDirection={flexDirection} ref={ref} {...rest}>
      <Body sx={{ color: "inherit" }}>{children}</Body>
    </Flex>
  ),
);

AlertDescription.displayName = "AlertDescription";

const AlertTitle = styled(Subtitle, {
  base: ({ theme }) => ({
    color: "inherit",
    fontWeight: theme.font.weight.bold,
  }),
});

AlertTitle.displayName = "AlertTitle";

export { AlertDescription, AlertErrorIcon, AlertIcon, AlertTitle };
