import type { ReactNode } from "react";
import React, { Fragment } from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { polymorphic } from "../../../utils/ref";
import { HStack } from "../../Layout/Stack";
import type { IconName } from "../../Typography/Icon";
import { Marker } from "../Marker";
import type { SystemProps } from "../../../utils/types/system";
import { Tooltip } from "../../Overlay/Tooltip";
import { NextTimelineDot } from "./Components/Dot";
import { NextTimelineHorizontalLine } from "./Components/Line";

type Props = {
  currentStep?: number;
  firstStepIcon?: IconName;
  firstStepTooltipLabel?: ReactNode;
  lastStepIcon?: IconName;
  lastStepTooltipLabel?: ReactNode;
  stepsAmount: number;
};

export type NextHorizontalTimelineProps = Props & SystemProps<"div">;

const NextHorizontalTimelineMarker = ({
  icon,
  status,
  tooltipLabel,
}: {
  icon: IconName;
  status: "done" | "pending";
  tooltipLabel?: ReactNode;
}) => {
  const marker = <Marker icon={icon} status={status} />;

  if (isDefined(tooltipLabel)) {
    return <Tooltip label={tooltipLabel}>{marker}</Tooltip>;
  }

  return marker;
};

const NextHorizontalTimelineStep = ({
  currentStepIndex,
  firstStepIcon,
  firstStepTooltipLabel,
  isLastStep = false,
  lastStepIcon,
  lastStepTooltipLabel,
  stepIndex,
}: {
  currentStepIndex: number;
  firstStepIcon?: IconName;
  firstStepTooltipLabel?: ReactNode;
  isLastStep?: boolean;
  lastStepIcon?: IconName;
  lastStepTooltipLabel?: ReactNode;
  stepIndex: number;
}) => {
  const status = currentStepIndex < stepIndex ? "pending" : "done";

  if (stepIndex === 0 && firstStepIcon) {
    return (
      <NextHorizontalTimelineMarker
        icon={firstStepIcon}
        status={status}
        tooltipLabel={firstStepTooltipLabel}
      />
    );
  }
  if (isLastStep && lastStepIcon) {
    return (
      <NextHorizontalTimelineMarker
        icon={lastStepIcon}
        status={status}
        tooltipLabel={lastStepTooltipLabel}
      />
    );
  }

  return <NextTimelineDot status={status} />;
};

const computeTimelineLineStatus = ({
  currentStepIndex,
  stepIndex,
}: {
  currentStepIndex: number;
  stepIndex: number;
}) => {
  if (currentStepIndex === stepIndex) {
    return "inProgress";
  }

  return currentStepIndex > stepIndex ? "done" : "pending";
};

const NextHorizontalTimelineLine = ({
  currentStepIndex,
  stepIndex,
}: {
  currentStepIndex: number;
  stepIndex: number;
}) => {
  const status = computeTimelineLineStatus({
    currentStepIndex,
    stepIndex,
  });

  return <NextTimelineHorizontalLine status={status} />;
};

export const NextHorizontalTimeline = polymorphic<
  "div",
  NextHorizontalTimelineProps
>(
  (
    {
      currentStep,
      firstStepIcon,
      firstStepTooltipLabel,
      lastStepIcon,
      lastStepTooltipLabel,
      stepsAmount,
      ...rest
    },
    ref,
  ) => {
    const currentStepIndex = (currentStep ?? 0) - 1;

    if (stepsAmount < 2) {
      return null;
    }

    return (
      <HStack alignItems="center" ref={ref} {...rest}>
        {[...Array(stepsAmount)].map((_, index) => {
          const isLastStep = index === stepsAmount - 1;

          return (
            <Fragment key={`horizontal-timeline-step-${index}`}>
              <NextHorizontalTimelineStep
                currentStepIndex={currentStepIndex}
                firstStepIcon={firstStepIcon}
                firstStepTooltipLabel={firstStepTooltipLabel}
                isLastStep={isLastStep}
                lastStepIcon={lastStepIcon}
                lastStepTooltipLabel={lastStepTooltipLabel}
                stepIndex={index}
              />
              {!isLastStep && (
                <NextHorizontalTimelineLine
                  currentStepIndex={currentStepIndex}
                  stepIndex={index}
                />
              )}
            </Fragment>
          );
        })}
      </HStack>
    );
  },
);

NextHorizontalTimeline.displayName = "NextHorizontalTimeline";
