import { useEffect, useState } from "react";
import { useBooleanState } from "../../../utils/hooks/useBooleanState";
import { useSelectAccessibilityProps } from "../Shared/useSelectAccessibilityProps";
import { useSelectFocus } from "../Shared/useSelectFocus";
import { useSelectScroll } from "../Shared/useSelectScroll";
import type { SelectOptionType } from "../Shared/types";
import { useSelectKeydown } from "./useSelectKeydown";
import { useSelectFilter } from "./useSelectFilter";
import { useSelectInteractions } from "./useSelectInteractions";

type Params = {
  autoFocus?: boolean;
  filterBy?: (query: string) => (option: SelectOptionType) => boolean;
  isClearable?: boolean;
  isCreatable: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable: boolean;
  onChange: (value: string) => void;
  onChangeError?: (value?: string) => void;
  onSearchChange?: (value: string) => void;
  options: SelectOptionType[];
  shouldReturnFocusOnSelect: boolean;
  sortBy?: (
    query: string,
  ) => (a: SelectOptionType, b: SelectOptionType) => number;
  value?: string;
};

export const useSelect = ({
  autoFocus,
  filterBy,
  isClearable,
  isCreatable,
  isDisabled,
  isLoading,
  isSearchable,
  onChange,
  onChangeError,
  onSearchChange: onSearchChangeProp,
  options,
  shouldReturnFocusOnSelect,
  sortBy,
  value,
}: Params) => {
  const [isOpened, setOpened, setClosed] = useBooleanState();

  useEffect(() => {
    if (autoFocus) setOpened();
  }, []);

  const [activeIndex, setActiveIndex] = useState(-1);

  const { handleFocusInput, inputRef } = useSelectFocus();

  const {
    allOptions,
    canCreate,
    exactSearchMatch,
    filteredOptions,
    handleCreate,
    handleReset,
    onSearchChange,
    searchQuery,
    selectedOption,
  } = useSelectFilter({
    filterBy,
    handleFocusInput,
    isClearable,
    isCreatable,
    onChange,
    onChangeError,
    onSearchChangeProp,
    options,
    setActiveIndex,
    setClosed,
    setOpened,
    sortBy,
    value,
  });

  const { scrollToItem, scrollToSelectedItem, scrollableRef, setItemRef } =
    useSelectScroll(value);

  const { ariaProps, inputId } = useSelectAccessibilityProps({
    activeIndex,
    isOpened,
  });

  const { handleInputBlur, handleInputClick, handleListClick, handleSelect } =
    useSelectInteractions({
      activeIndex,
      filteredOptions,
      handleFocusInput,
      isDisabled,
      isOpened,
      onChange,
      onSearchChange,
      scrollToSelectedItem,
      setActiveIndex,
      setClosed,
      shouldReturnFocusOnSelect,
      value,
    });

  const handleInputKeydown = useSelectKeydown({
    activeIndex,
    canCreate,
    exactSearchMatch,
    filteredOptions,
    handleCreate,
    handleSelect,
    isOpened,
    isSearchable,
    scrollToItem,
    scrollToSelectedItem,
    setActiveIndex,
    setClosed,
    setOpened,
    value,
  });

  const containsInvalidValue =
    !isLoading &&
    value !== undefined &&
    value !== null &&
    allOptions.every((option) => option.value !== value);

  return {
    activeIndex,
    ariaProps,
    canCreate,
    containsInvalidValue,
    filteredOptions,
    handleCreate,
    handleInputBlur,
    handleInputChange: onSearchChange,
    handleInputClick,
    handleInputKeydown,
    handleListClick,
    handleReset,
    handleSelect,
    inputId,
    inputRef,
    isOpened,
    scrollableRef,
    searchQuery,
    selectedOption,
    setActiveIndex,
    setClosed,
    setItemRef,
    setOpened,
  } as const;
};
