/* eslint-disable perfectionist/sort-objects */
import type { ReactNode } from "react";
import React from "react";
import { polymorphic } from "../../utils/ref";
import { Flex } from "../Layout/Flex";
import type { Sx, SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { generateSpacing } from "../../theme/commons";
import type { SpacingScale } from "../../utils/system/system";
import { Radio } from "./RadioGroup/Radio";
import { useFormFieldContext } from "./FormField/FormFieldProvider";
import type { RadioGroupContextType } from "./RadioGroup/RadioGroupContext";
import { RadioGroupProvider } from "./RadioGroup/RadioGroupContext";

export type RadioOption<ValueType = any> = {
  "data-testid"?: string;
  description?: ReactNode;
  label: ReactNode;
  value: ValueType;
};

// eslint-disable-next-line no-use-before-define
export type RadioProps = Props & SystemProps<"div">;

type Props = {
  "data-testid"?: string;
  options?: RadioOption[];
  optionsSx?: Sx | Sx[];
  spacing?: SpacingScale;
} & Pick<RadioGroupContextType, "isDisabled" | "onChange" | "value">;

const StyledGroup = styled<typeof Flex, Pick<Props, "isDisabled" | "spacing">>(
  Flex,
  {
    base: ({ isDisabled, spacing, theme }) => [
      generateSpacing(theme)(spacing, "left"),
      isDisabled && {
        cursor: "not-allowed",
        opacity: 0.4,
      },
    ],
  },
);

export const RadioGroup = polymorphic<"div", Props>(
  (
    {
      children,
      className,
      isDisabled,
      onChange,
      options = [],
      optionsSx = {},
      spacing = 12,
      value,
      ...rest
    },
    ref,
  ) => {
    const context = useFormFieldContext();

    return (
      <RadioGroupProvider
        value={{
          onChange,
          value,
          isDisabled: context?.isDisabled,
        }}
      >
        <StyledGroup
          data-testid={rest["data-testid"] ?? "RadioGroup"}
          flexDirection="row"
          isDisabled={isDisabled}
          orientation="horizontal"
          ref={ref}
          role="radiogroup"
          spacing={spacing}
          {...rest}
        >
          {children ??
            options.map(({ label, value, ...rest }) => (
              <Radio key={value} option={value} sx={optionsSx} {...rest}>
                {label}
              </Radio>
            ))}
        </StyledGroup>
      </RadioGroupProvider>
    );
  },
);

RadioGroup.displayName = "RadioGroup";
