import type { CSSProperties, ReactNode, SyntheticEvent } from "react";
import React from "react";
import type { ReactRef } from "../../utils/ref";
import { mergeRefs, polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { composeHandlers } from "../../utils/composeHandlers";
import { Popover } from "../Overlay/Popover";
import { useSelect } from "./Select/useSelect";
import { useFormFieldContext } from "./FormField/FormFieldProvider";
import { SelectOptions, SelectTrigger } from "./Select/SelectComponents";
import type { SelectOptionType } from "./Shared/types";

type Clearable<Value extends string = string> =
  | {
      isClearable: false;
      onChange: (value: Value) => void;
    }
  | {
      isClearable: true;
      onChange: (value: Value | null) => void;
    }
  | {
      isClearable?: undefined;
      onChange: (value: Value) => void;
    };

type Props<Value extends string = string> = {
  autoFocus?: boolean;
  creatableMessage?: ReactNode;
  ["data-testid"]?: string;
  filledValueComponent?: (params: {
    onReset: (event: SyntheticEvent) => void;
    value: string;
  }) => ReactNode;
  filterBy?: (query: string) => (option: SelectOptionType) => boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  menuOffset?: number;
  menuWidth?: CSSProperties["width"];
  noOptionsMessage?: ReactNode;
  nothingFoundMessage?: ReactNode;
  onChange: (value: Value) => void;
  onChangeError?: (value?: string) => void;
  onSearchChange?: (value: string) => void;
  options: SelectOptionType[];
  placeholder?: string;
  ref?: ReactRef<HTMLInputElement>;
  renderAction?: (search: string) => ReactNode;
  shouldReturnFocusOnSelect?: boolean;
  sortBy?: (
    query: string,
  ) => (a: SelectOptionType, b: SelectOptionType) => number;
  value?: Value;
} & Clearable<Value>;

export type SelectProps<Value extends string = string> = Omit<
  SystemProps<"div">,
  "filterBy" | "onChange" | "value"
> &
  Props<Value>;

type GenericSelect = {
  <Value extends string>(props: SelectProps<Value>): ReactNode;
  displayName?: string;
};

export const Select = polymorphic<"div", Props>(
  (
    {
      autoFocus,
      creatableMessage,
      ["data-testid"]: dataTestId,
      filledValueComponent,
      filterBy,
      isClearable,
      isCreatable = false,
      isLoading,
      isDisabled: isDisabledProp = isLoading,
      isErrored: isErroredProp,
      isSearchable = true,
      maxMenuHeight,
      menuOffset,
      menuWidth = "unset",
      noOptionsMessage,
      nothingFoundMessage,
      onBlur,
      onChange,
      onChangeError,
      onFocus,
      onKeyDown,
      onSearchChange,
      options,
      placeholder,
      renderAction,
      shouldReturnFocusOnSelect = true,
      sortBy,
      value,
      ...rest
    },
    ref,
  ) => {
    const {
      activeIndex,
      ariaProps,
      canCreate,
      containsInvalidValue,
      filteredOptions,
      handleCreate,
      handleInputBlur,
      handleInputChange,
      handleInputClick,
      handleInputKeydown,
      handleListClick,
      handleReset,
      handleSelect,
      inputId,
      inputRef,
      isOpened,
      scrollableRef,
      searchQuery,
      selectedOption,
      setActiveIndex,
      setClosed,
      setItemRef,
      setOpened,
    } = useSelect({
      autoFocus,
      filterBy,
      isClearable,
      isCreatable,
      isLoading,
      isSearchable,
      onChange,
      onChangeError,
      onSearchChange,
      options,
      shouldReturnFocusOnSelect,
      sortBy,
      value,
    });

    const context = useFormFieldContext();

    const isDisabled = context?.isDisabled || isDisabledProp;
    const isErrored =
      context?.isErrored || isErroredProp || containsInvalidValue;

    return (
      <Popover
        isDisabled={isDisabled}
        isOpened={isOpened}
        onClose={setClosed}
        onOpen={setOpened}
      >
        <SelectTrigger
          activeIndex={activeIndex}
          ariaActiveDescendant={ariaProps.activeDescendant}
          ariaControls={ariaProps.controls}
          autoFocus={autoFocus}
          data-testid={dataTestId}
          filledValueComponent={filledValueComponent}
          inputId={inputId}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isErrored={isErrored}
          isLoading={isLoading}
          isOpened={isOpened}
          isSearchable={isSearchable}
          onBlur={composeHandlers(onBlur, handleInputBlur)}
          onChange={handleInputChange}
          onClear={handleReset}
          onClick={handleInputClick}
          onFocus={onFocus}
          onKeyDown={composeHandlers(onKeyDown, handleInputKeydown)}
          onOpen={setOpened}
          placeholder={placeholder}
          ref={mergeRefs(ref, inputRef)}
          searchQuery={searchQuery}
          selectedLabel={selectedOption?.label}
          setActiveOption={setActiveIndex}
          value={value}
          {...rest}
        />
        <SelectOptions
          activeIndex={activeIndex}
          canCreate={canCreate}
          creatableMessage={creatableMessage}
          dataTestId={dataTestId}
          handleCreate={handleCreate}
          handleListClick={handleListClick}
          handleSelect={handleSelect}
          initialOptionCount={options.length}
          maxMenuHeight={maxMenuHeight}
          menuOffset={menuOffset}
          menuWidth={menuWidth}
          noOptionsMessage={noOptionsMessage}
          nothingFoundMessage={nothingFoundMessage}
          options={filteredOptions}
          renderAction={renderAction}
          scrollableRef={scrollableRef}
          searchQuery={searchQuery}
          setActiveIndex={setActiveIndex}
          setItemRef={setItemRef}
          value={value}
        />
      </Popover>
    );
  },
) as GenericSelect;

Select.displayName = "Select";
