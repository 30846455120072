import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import quarterPlugin from "dayjs/plugin/quarterOfYear";
import duration from "dayjs/plugin/duration";

import "dayjs/locale/fr";
import "dayjs/locale/en";
import "dayjs/locale/it";
import "dayjs/locale/es";
import "dayjs/plugin/utc";
import "dayjs/plugin/advancedFormat";
import "dayjs/plugin/relativeTime";
import "dayjs/plugin/timezone";
import "dayjs/plugin/isoWeek";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(quarterPlugin);
dayjs.extend(duration);

export const DAYJS_WEEKDAY_MAPPING = {
  friday: 5,
  monday: 1,
  saturday: 6,
  sunday: 0,
  thursday: 4,
  tuesday: 2,
  wednesday: 3,
};

export { dayjs };

export const isUnixTimestamp = (date: number): boolean => {
  // works from 2003 till like 2065
  return 1 <= date / 1_000_000_000 && date / 1_000_000_000 <= 3;
};
