export const RECORD_NOT_FOUND = "Record not found";
export const RECORD_NOT_FOUND_WITH_DETAILS = (details: string) =>
  `Record ${details} not found`;
export const RECORD_NOT_FOUND_WITH_ENTITY_NAME_INDEX_AND_VALUE = (
  entityName: string,
  index: string,
  indexValue: string,
) => `Record ${entityName} with {${index}: ${indexValue}} not found`;

export type ErrorKind =
  | "BAD USER INPUT"
  | "DOMAIN EXCEPTION"
  | "EXTERNAL SERVICE ERROR"
  | "UNEXPECTED";

type ErrorCriticity =
  | "CRITICAL"
  | "FATAL"
  | "IMPORTANT"
  | "NOT_CRITICAL"
  | "WARNING";

export class OvrseaError extends Error {
  kind: ErrorKind;
  criticity: ErrorCriticity;

  constructor({
    cause,
    context = {},
    criticity = "WARNING",
    kind = "BAD USER INPUT",
    message,
  }: {
    cause?: unknown;
    context?: Record<string, unknown>;
    criticity?: ErrorCriticity;
    kind?: ErrorKind;
    message: string;
  }) {
    const formattedContext =
      Object.keys(context).length > 0
        ? `context: ${JSON.stringify(context)}`
        : "";

    const messageWithContext = `[${criticity}][${kind}] ${message} ${formattedContext}`;

    super(messageWithContext, { cause });
    this.criticity = criticity;
    this.kind = kind;
  }
}
