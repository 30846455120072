import React from "react";
import type { Theme } from "../../../../theme/theme";
import { polymorphic } from "../../../../utils/ref";
import { styled } from "../../../../utils/system/factory";
import type { SystemProps } from "../../../../utils/types/system";

type NextTimelineLineStatus = "done" | "inProgress" | "pending";

type Orientation = "horizontal" | "vertical";

type Props = {
  isLoading?: boolean;
  orientation?: Orientation;
  status?: NextTimelineLineStatus;
};

type NextTimelineLineProps = Props & SystemProps<"div">;

const statusPercentageMap = {
  done: 1000,
  inProgress: 50,
  pending: 0,
} as const;

const barOrientationDimensionsMap = (theme: Theme) => (percentage: number) =>
  ({
    horizontal: {
      borderBottomRightRadius: theme.radius.xs,
      borderTopRightRadius: theme.radius.xs,
      height: "100%",
      width: `${percentage}%`,
    },
    vertical: {
      borderBottomLeftRadius: theme.radius.xs,
      borderBottomRightRadius: theme.radius.xs,
      height: `${percentage}%`,
      width: "100%",
    },
  }) as const;

const horizontalAnimation = {
  "@keyframes next-timeline-horizontal-animation": {
    "0%": {
      marginLeft: 0,
      width: "100%",
    },
    "30%": {
      marginLeft: 0,
      width: "100%",
    },
    "60%": {
      marginLeft: "100%",
      width: 0,
    },
    "65%": {
      marginLeft: 0,
      width: 0,
    },
    "100%": {
      marginLeft: 0,
      width: "100%",
    },
  },

  animation: "next-timeline-horizontal-animation 3500ms ease-in-out infinite",
};

const StyledBar = styled<
  "div",
  {
    isLoading: boolean;
    orientation: Orientation;
    status: NextTimelineLineStatus;
  }
>("div", {
  base: ({ isLoading, orientation, status, theme }) => [
    {
      ...barOrientationDimensionsMap(theme)(statusPercentageMap[status])[
        orientation
      ],
      backgroundColor: theme.colors.alert.success,
    },
    isLoading && orientation === "horizontal" && horizontalAnimation,
  ],
});

const containerOrientationDimensionsMap = {
  horizontal: {
    height: "2px",
    width: "100%",
  },
  vertical: {
    height: "100%",
    width: "2px",
  },
} as const;

const StyledBarContainer = styled<"div", { orientation: Orientation }>("div", {
  base: ({ orientation, theme }) => ({
    ...containerOrientationDimensionsMap[orientation],
    background: theme.colors.neutral[10],
    overflow: "hidden",
    position: "relative",
  }),
});

const NextTimelineLine = polymorphic<"div", NextTimelineLineProps>(
  (
    {
      isLoading = false,
      orientation = "horizontal",
      status = "pending",
      ...rest
    },
    ref,
  ) => (
    <StyledBarContainer orientation={orientation} {...rest} ref={ref}>
      <StyledBar
        isLoading={isLoading}
        orientation={orientation}
        status={status}
      />
    </StyledBarContainer>
  ),
);

NextTimelineLine.displayName = "NextTimelineLine";

export const NextTimelineHorizontalLine = polymorphic<
  "div",
  { isLoading?: boolean } & NextTimelineLineProps
>((props, ref) => (
  <NextTimelineLine {...props} orientation="horizontal" ref={ref} />
));

NextTimelineHorizontalLine.displayName = "NextTimelineHorizontalLine";

export const NextTimelineVerticalLine = polymorphic<
  "div",
  Omit<NextTimelineLineProps, "isLoading">
>((props, ref) => (
  <NextTimelineLine {...props} orientation="vertical" ref={ref} />
));

NextTimelineVerticalLine.displayName = "NextTimelineVerticalLine";
