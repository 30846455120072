export const DEFAULT_TRANSIT_TIME_AIR = 3;

export const DEFAULT_TRUCK_FREIGHT_TRANSIT_TIMES = {
  air: {
    fcl: {
      arrival_truck_freight: null,
      departure_truck_freight: null,
    },
    lcl: {
      arrival_truck_freight: 3,
      departure_truck_freight: 3,
    },
  },

  ocean: {
    fcl: {
      arrival_truck_freight: {
        barge_truck: 9,
        not_applicable: null,
        rail_truck: 9,
        truck_one_way: 5,
        truck_round_trip: 5,
      },
      departure_truck_freight: {
        barge_truck: 9,
        not_applicable: null,
        rail_truck: 9,
        truck_one_way: 7,
        truck_round_trip: 7,
      },
    },

    lcl: {
      arrival_truck_freight: 10,
      departure_truck_freight: 10,
    },
  },
  rail: {
    fcl: {
      arrival_truck_freight: 8,
      departure_truck_freight: 10,
    },
    lcl: {
      arrival_truck_freight: 5,
      departure_truck_freight: 6,
    },
  },
  truck: {
    fcl: {
      arrival_truck_freight: null,
      departure_truck_freight: null,
    },
    lcl: {
      arrival_truck_freight: null,
      departure_truck_freight: null,
    },
  },
} as const;
