import React from "react";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import type { SystemProps } from "../../utils/types/system";
import type { LinkProps } from "./Link";
import { Link } from "./Link";

export type LinkBoxProps = SystemProps<"div">;
export type LinkOverlayProps = SystemProps<"div">;

const StyledLinkOverlay = styled(Link, {
  base: {
    "&::before": {
      content: "''",
      cursor: "inherit",
      display: "block",
      height: "100%",
      left: "0",
      position: "absolute",
      top: "0",
      width: "100%",
      zIndex: "0",
    },
    position: "static",
  },
});

const LinkOverlay = polymorphic<"a", LinkProps>((props, ref) => (
  <StyledLinkOverlay className="overlay" isUnstyled ref={ref} {...props} />
));

const LinkBox = styled("div", {
  base: {
    "a:not(.overlay)": {
      position: "relative",
      zIndex: 1,
    },
    position: "relative",
  },
});

LinkBox.displayName = "LinkBox";
LinkOverlay.displayName = "LinkOverlay";

export { LinkBox, LinkOverlay };
