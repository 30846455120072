import React from "react";
import { polymorphic } from "../../../utils/ref";
import type { Theme } from "../../../theme/theme";
import { styled } from "../../../utils/system/factory";
import type { CSSObject } from "../../../utils/types/css";
import { mapFocusRing } from "../../../theme/commons";
import { Icon } from "../../Typography/Icon";
import { usePaginationContext } from "./PaginationContext";
import type { PaginationBlock } from "./pagination";
import { isPaginationSpacer } from "./pagination";

const mapItemStyles = (theme: Theme): CSSObject => ({
  alignItems: "center",
  borderRadius: theme.radius.base,
  display: "flex",
  height: theme.spacing[32],
  justifyContent: "center",
  lineHeight: theme.spacing[32],
  minWidth: theme.spacing[32],
  padding: "0 8px",
  userSelect: "none",
});

type PaginationButtonProps = {
  isActive?: boolean;
  isBordered?: boolean;
  isBoundary?: boolean;
  isDisabled?: boolean;
};

type PaginationPageProps = {
  page: PaginationBlock;
};

const StyledButton = styled<"button", PaginationButtonProps>("button", {
  base: ({ isActive, isBordered, theme }) => [
    mapItemStyles(theme),
    {
      "&[disabled]": {
        cursor: "not-allowed",
        opacity: 0.5,
      },
      background: theme.colors.background.light,
      border: "1px solid transparent",
      cursor: "pointer",
      fontFamily: theme.font.family.base,
      fontWeight: theme.font.weight.medium,
      transitionDuration: theme.transition.duration.base,
      transitionProperty: "outline, box-shadow",
      transitionTimingFunction: theme.transition.easing.color,
    },
    {
      "&:focus-visible": {
        ...mapFocusRing(theme),
        outline: "none",
        zIndex: 1,
      },
    },
    !isActive && {
      "&:hover:not([disabled])": {
        background: theme.colors.background["nav-active"],
        color: theme.colors.primary.base,
      },
    },
    isActive && {
      background: theme.colors.primary.base,
      border: "1px solid transparent",
      color: "white",
      cursor: "default",
    },
    isBordered && {
      border: `1px solid ${theme.colors.neutral["20"]}`,
    },
  ],
});

const PaginationButton = polymorphic<"button", PaginationButtonProps>(
  ({ isActive, isBordered, isDisabled, type, ...rest }, ref) => (
    <StyledButton
      disabled={isDisabled}
      isActive={isActive}
      isBordered={isBordered}
      ref={ref}
      type="button"
      {...rest}
    />
  ),
);

PaginationButton.displayName = "PaginationButton";

const PaginationPreviousPage = polymorphic<"button">((props, ref) => {
  const { activePage, goToPreviousPage } = usePaginationContext();

  return (
    <PaginationButton
      aria-label="previous"
      isBordered
      isDisabled={activePage === 1}
      ref={ref}
      {...props}
      onClick={goToPreviousPage}
    >
      <Icon name="chevron-left" />
    </PaginationButton>
  );
});

PaginationPreviousPage.displayName = "PaginationPreviousPage";

const PaginationNextPage = polymorphic<"button">((props, ref) => {
  const { activePage, goToNextPage, pagesCount } = usePaginationContext();

  return (
    <PaginationButton
      aria-label="next"
      isBordered
      isDisabled={activePage === pagesCount}
      ref={ref}
      {...props}
      onClick={goToNextPage}
    >
      <Icon name="chevron-right" />
    </PaginationButton>
  );
});

PaginationNextPage.displayName = "PaginationNextPage";

const StyledPageButton = styled(PaginationButton, {
  base: ({ theme }) => ({
    borderRadius: "unset",
    height: `calc(${theme.spacing[32]} - 2px)`,
  }),
});

const StyledSpacer = styled("div", {
  base: ({ theme }) => [
    mapItemStyles(theme),
    {
      height: `calc(${theme.spacing[32]} - 2px)`,
    },
  ],
});

const PaginationPage = polymorphic<"button", PaginationPageProps>(
  ({ page, ...rest }, ref) => {
    const { activePage, goToPage } = usePaginationContext();

    if (isPaginationSpacer(page)) {
      return (
        <StyledSpacer key={page} ref={ref}>
          <Icon name="dots" />
        </StyledSpacer>
      );
    }

    return (
      <StyledPageButton
        isActive={activePage === page}
        ref={ref}
        {...rest}
        onClick={() => goToPage(page)}
      >
        {page}
      </StyledPageButton>
    );
  },
);

PaginationPage.displayName = "PaginationPage";

const PaginationPagesBlock = styled("div", {
  base: ({ theme }) => ({
    "& > button:first-of-type": {
      borderBottomLeftRadius: theme.radius.sm,
      borderTopLeftRadius: theme.radius.sm,
    },
    "& > button:last-child": {
      borderBottomRightRadius: theme.radius.sm,
      borderTopRightRadius: theme.radius.sm,
    },
    background: theme.colors.background.light,
    border: `1px solid ${theme.colors.neutral["20"]}`,
    borderRadius: theme.radius.base,
    display: "flex",
  }),
});

PaginationPagesBlock.displayName = "PaginationPagesBlock";

export {
  PaginationNextPage,
  PaginationPage,
  PaginationPagesBlock,
  PaginationPreviousPage,
};
