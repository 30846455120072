import type { ReactNode } from "react";
import React from "react";
import { styled } from "../../utils/system/factory";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { HStack, VStack } from "../Layout/Stack";
import { Body } from "../Typography/Body";
import { Box } from "../Meta/Box";
import type {
  CarbonIntensityScoreProps,
  CarbonIntensityScoreType,
} from "./CarbonIntensityScore";
import {
  CarbonIntensityScore,
  carbonIntensityScoreColorMapper,
} from "./CarbonIntensityScore";

export type EcoFreightScoreProps = {
  score: CarbonIntensityScoreType;
  size: "md" | "sm";
};

type LayoutProps = {
  carbonIntensityScoreComponent: ReactNode;
  carbonWeightBarComponent: ReactNode;
  size: EcoFreightScoreProps["size"];
} & SystemProps<"div">;

const EcoFreightScoreLayout = polymorphic<"div", LayoutProps>(
  ({ carbonIntensityScoreComponent, carbonWeightBarComponent, size }, ref) => {
    if (size === "sm") {
      return (
        <HStack
          ref={ref}
          sx={(theme) => ({
            height: "30px",
            minWidth: "104px",
            outline: `${theme.spacing["1"]} solid black}`,
          })}
        >
          <Box
            sx={(theme) => ({
              borderRight: `${theme.spacing["1"]} solid black}`,
            })}
          >
            {carbonIntensityScoreComponent}
          </Box>
          <VStack flex={1} justifyContent="space-between">
            <VStack alignItems="center" flex={1} justifyContent="center">
              <Box>
                <Body
                  sx={(theme) => ({
                    fontSize: "8px",
                    height: theme.spacing[8],
                    lineHeight: "8px",
                  })}
                  variant="bold"
                >
                  ECO-FREIGHT
                </Body>
                <Body
                  sx={(theme) => ({
                    fontSize: "8px",
                    height: theme.spacing[8],
                    lineHeight: "8px",
                  })}
                >
                  SCORE
                </Body>
              </Box>
            </VStack>
            {carbonWeightBarComponent}
          </VStack>
        </HStack>
      );
    }

    return (
      <VStack
        ref={ref}
        sx={(theme) => ({
          border: `${theme.spacing["1"]} solid black}`,
          height: "48px",
          width: "128px",
        })}
      >
        <HStack alignItems="center" margin={4} spacing={8}>
          {carbonIntensityScoreComponent}
          <VStack>
            <Body
              sx={(theme) => ({
                fontSize: theme.font.size.xxs,
                height: theme.spacing[16],
                lineHeight: theme.lineHeight.sm,
              })}
              variant="bold"
            >
              ECO-FREIGHT
            </Body>
            <Body
              sx={(theme) => ({
                fontSize: theme.font.size.xxs,
                height: theme.spacing[16],
                lineHeight: theme.lineHeight.sm,
              })}
            >
              SCORE
            </Body>
          </VStack>
        </HStack>
        {carbonWeightBarComponent}
      </VStack>
    );
  },
);

const StyledCarbonIntensityScore = styled<
  typeof CarbonIntensityScore,
  { size: EcoFreightScoreProps["size"] } & CarbonIntensityScoreProps
>(CarbonIntensityScore, {
  base: ({ size, theme }) => [
    { borderRadius: 0 },
    size === "md" && {
      fontSize: theme.font.size["3xl"],
      height: "36px",
      width: "36px",
    },
    size === "sm" && {
      fontSize: theme.font.size.sm,
      height: "30px",
      width: "30px",
    },
  ],
});

type StyledCarbonWeightSubBarProps = {
  isActive: boolean;
  score: CarbonIntensityScoreType;
  size: EcoFreightScoreProps["size"];
} & SystemProps<"div">;

const StyledCarbonWeightSubBar = styled<"div", StyledCarbonWeightSubBarProps>(
  "div",
  {
    base: ({ isActive, score, size, theme }) => [
      {
        backgroundColor:
          carbonIntensityScoreColorMapper(theme)[score].background,
        height: "4px",
      },
      size === "sm" && {
        height: "2px",
        width: "20%",
      },
      size === "md" && {
        width: isActive ? "60%" : "10%",
      },
    ],
  },
);

type CarbonWeightBar = {
  score: CarbonIntensityScoreType;
  size: "md" | "sm";
};

const CarbonWeightBar = polymorphic<"div", CarbonWeightBar>(
  ({ score, size, ...rest }, ref) => (
    <HStack ref={ref} sx={{ width: "100%" }} {...rest}>
      <StyledCarbonWeightSubBar
        isActive={score === "A"}
        score="A"
        size={size}
      />
      <StyledCarbonWeightSubBar
        isActive={score === "B"}
        score="B"
        size={size}
      />
      <StyledCarbonWeightSubBar
        isActive={score === "C"}
        score="C"
        size={size}
      />
      <StyledCarbonWeightSubBar
        isActive={score === "D"}
        score="D"
        size={size}
      />
      <StyledCarbonWeightSubBar
        isActive={score === "E"}
        score="E"
        size={size}
      />
    </HStack>
  ),
);

const EcoFreightScore = polymorphic<"div", EcoFreightScoreProps>(
  ({ score, size = "md", ...rest }, ref) => (
    <EcoFreightScoreLayout
      carbonIntensityScoreComponent={
        <StyledCarbonIntensityScore score={score} size={size} />
      }
      carbonWeightBarComponent={<CarbonWeightBar score={score} size={size} />}
      ref={ref}
      size={size}
      {...rest}
    />
  ),
);

EcoFreightScore.displayName = "EcoFreightScore";

export { EcoFreightScore };
