import type { PropsWithChildren } from "react";
import React from "react";
import type { SystemProps } from "../../../utils/types/system";
import { IconButton } from "../../Action/IconButton";
import { HStack } from "../../Layout/Stack";
import { Body } from "../../Typography/Body";
import type { CalendarType } from "../Calendar";
import { useCalendarContext } from "./CalendarContext";

type ButtonProps = {
  isDisabled?: boolean;
} & Pick<SystemProps<"button">, "onBlur" | "onFocus">;

type Props = {
  calendarType: CalendarType;
  nextButtonProps: ButtonProps;
  prevButtonProps: ButtonProps;
};

const StyledMonthName = (props: PropsWithChildren) => (
  <Body
    size="sm"
    sx={(theme) => ({ fontWeight: theme.font.weight.medium })}
    {...props}
  />
);

const CalendarHeader = ({
  calendarType,
  nextButtonProps,
  prevButtonProps,
}: Props) => {
  const { endMonthName, startMonthName } = useCalendarContext();

  return (
    <HStack alignItems="center" flex={1} justifyContent="space-between">
      <IconButton
        sx={(theme) => ({
          border: `1px solid ${theme.colors.neutral["10"]}`,
          borderRadius: theme.radius.base,
        })}
        {...prevButtonProps}
        label="Previous month"
        name="chevron-left"
      />
      <HStack flex={1} justifyContent="space-evenly" spacing={24}>
        <StyledMonthName>{startMonthName}</StyledMonthName>
        {calendarType === "double" && (
          <StyledMonthName>{endMonthName}</StyledMonthName>
        )}
      </HStack>
      <IconButton
        sx={(theme) => ({
          border: `1px solid ${theme.colors.neutral["10"]}`,
          borderRadius: theme.radius.base,
        })}
        {...nextButtonProps}
        label="Next month"
        name="chevron-right"
      />
    </HStack>
  );
};

export { CalendarHeader };
