/* eslint-disable perfectionist/sort-objects */
import type { ReactNode } from "react";
import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import { styles } from "../../theme/commons";
import type { Theme } from "../../theme/theme";
import { ariaAttribute } from "../../utils/attributes";
import { Caption } from "./Caption";

export type HeadingProps = {
  badge?: ReactNode;
  isTruncated?: boolean;
  size?: keyof Theme["heading"];
};

const StyledHeading = styled<"h3", { hasBadge?: boolean } & HeadingProps>(
  "h3",
  {
    base: ({ hasBadge, isTruncated, size = "md", theme }) => {
      const { fontSize, fontWeight, letterSpacing, lineHeight } =
        theme.heading[size];

      return [
        {
          color: theme.colors.neutral.base,
          fontSize,
          fontWeight,
          lineHeight,
          letterSpacing,
        },
        hasBadge && {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        },
        isTruncated && styles.truncated,
      ];
    },
  },
);

const HeadingBadge = styled<typeof Caption>(Caption, {
  base: ({ theme }) => ({
    lineHeight: theme.heading.sm.lineHeight,
    backgroundColor: theme.colors.alert.neutral,
    borderRadius: theme.radius.round,
    color: theme.colors.neutral.light,
    display: "inline-flex",
    whiteSpace: "nowrap",
    height: theme.spacing["24"],
    minHeight: theme.spacing["24"],
    width: theme.spacing["24"],
    minWidth: theme.spacing["24"],
    alignItems: "center",
    justifyContent: "center",
  }),
});

export const Heading = polymorphic<"h3", HeadingProps>(
  ({ as: Component = "h3", badge, children, size, ...rest }, ref) => {
    const hasBadge = isDefined(badge);

    return (
      <StyledHeading
        as={Component}
        hasBadge={hasBadge}
        ref={ref}
        size={size}
        {...rest}
      >
        {hasBadge && (
          <HeadingBadge aria-hidden={ariaAttribute(true)} marginRight={8}>
            {badge}
          </HeadingBadge>
        )}
        {children}
      </StyledHeading>
    );
  },
);

Heading.displayName = "Heading";
