import { useState } from "react";
import { contextFactory } from "../../../utils/contextFactory";
import { usePrefixedId } from "../../../utils/hooks/usePrefixedId";

const generateTabId = (id: string, index: number) => `tabs-${id}--tab-${index}`;

const generateTabPanelId = (id: string, index: number) =>
  `tabs-${id}--tabpanel-${index}`;

const useTabsController = (defaultActiveIndex?: number) => {
  const id = usePrefixedId("tabs");

  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  const handleGenerateTabId = (index: number) => generateTabId(id, index);

  const handleGenerateTabPanelId = (index: number) =>
    generateTabPanelId(id, index);

  return {
    activeIndex,
    generateTabId: handleGenerateTabId,
    generateTabPanelId: handleGenerateTabPanelId,
    setActiveIndex,
  };
};

export type TabsContextType = {
  activeIndex?: number;
  generateTabId: (index: number) => string;
  generateTabPanelId: (index: number) => string;
  isLazy?: boolean;
  setActiveIndex: (index: number) => void;
};

const [TabsProvider, useTabs] = contextFactory<TabsContextType>({
  displayName: "TabsProvider",
  errorMessage: "Tabs components should be wrapped in `TabsProvider`",
});

export {
  TabsProvider,
  generateTabId,
  generateTabPanelId,
  useTabs,
  useTabsController,
};
