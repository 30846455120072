import type { RefObject } from "react";
import React from "react";
import { useRemovePresence } from "../../utils/hooks/useRemovePresence";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { OverlayProvider } from "./Overlay/overlayContext";
import { useOverlay } from "./Overlay/useOverlay";
import { OverlayContent, OverlayDimmer } from "./Overlay/OverlayComponents";

type Props = {
  isOpened?: boolean;
  onClose?: () => void;
  returnFocusRef?: RefObject<HTMLElement>;
  shouldCloseOnEscape?: boolean;
  shouldCloseOnOutsideClick?: boolean;
  shouldTrapFocus?: boolean;
};

export type OverlayProps = Props & SystemProps<"div">;

type Compounds = {
  Content: typeof OverlayContent;
  Dimmer: typeof OverlayDimmer;
};

const StyledOverlay = styled("div", {
  base: ({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    left: 0,
    overflow: "hidden",
    position: "fixed",
    top: 0,
    width: "100vw",
    zIndex: theme.zIndex.modal,
  }),
});

export const Overlay = polymorphic<"div", Props, Compounds>(
  (
    {
      isOpened,
      onClose,
      returnFocusRef,
      shouldCloseOnEscape = true,
      shouldCloseOnOutsideClick = true,
      shouldTrapFocus = true,
      ...rest
    },
    ref,
  ) => {
    useRemovePresence();

    const context = useOverlay({
      isOpened,
      onClose,
      returnFocusRef,
      shouldCloseOnEscape,
      shouldCloseOnOutsideClick,
      shouldTrapFocus,
    });

    if (!isOpened) {
      return null;
    }

    return (
      <>
        <Overlay.Dimmer />
        <OverlayProvider value={context}>
          <Overlay.Content>
            <StyledOverlay ref={ref} {...rest} />
          </Overlay.Content>
        </OverlayProvider>
      </>
    );
  },
);

Overlay.displayName = "Overlay";
Overlay.Content = OverlayContent;
Overlay.Dimmer = OverlayDimmer;
