import type { MouseEventHandler } from "react";
import React, { useRef } from "react";
import { isWeekend } from "@internationalized/date";
import { mergeRefs, polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import type { SystemProps } from "../../../utils/types/system";
import { calendarDimensions } from "../Calendar";
import { useCalendarContext } from "./CalendarContext";
import type { CalendarDate } from "./hooks/useCalendar";

type CalendarCellProps = {
  date: CalendarDate;
  month: CalendarDate;
  onChange: (date: number) => void;
};

type StyledCellProps = {
  isActivated?: boolean;
  isOutsideVisibleRange: boolean;
  isSelected?: boolean;
  isToday?: boolean;
  isWeekend?: boolean;
  value?: number;
};

const StyledCell = styled<"button", StyledCellProps & SystemProps<"button">>(
  "button",
  {
    base: ({
      isOutsideVisibleRange,
      isSelected,
      isToday = false,
      isWeekend,
      theme,
    }) => [
      {
        "&:disabled": {
          color: theme.colors.neutral[30],
          cursor: "not-allowed",
        },
        [`&:hover:not([disabled]):not([aria-selected='true']),\
          &[data-focused='true']:not([aria-selected='true'])`]: {
          backgroundColor: theme.colors.primary.light,
          color: theme.colors.neutral.dark,
        },
        background: theme.colors.background.light,
        borderRadius: theme.radius.lg,
        fontSize: theme.font.size.sm,
        fontWeight: theme.font.weight.base,
        height: calendarDimensions.cellWidth,
        lineHeight: theme.lineHeight.md,
        transitionDuration: theme.transition.duration.fast,
        transitionProperty: "color, background-color",
        transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
        width: calendarDimensions.cellWidth,
      },
      isToday && {
        backgroundColor: theme.colors.neutral["10"],
        paddingTop: "3px",
      },
      isSelected && {
        backgroundColor: theme.colors.primary.base,
        color: "white",
      },
      isWeekend &&
        !isSelected && {
          color: theme.colors.neutral["30"],
        },
      isOutsideVisibleRange &&
        !isSelected && {
          color: theme.colors.neutral["30"],
        },
    ],
  },
);

export const CalendarCell = polymorphic<"button", CalendarCellProps>(
  ({ date, onChange, ...rest }, ref) => {
    const currentRef = useRef<HTMLElement>(null);

    const {
      isToday,
      locale,
      selectedTimezone,
      useCalendarCell,
      useFocusForAccessibility,
      value,
    } = useCalendarContext();
    const calendarCell = useCalendarCell({ date, ref: currentRef });

    const { buttonProps, cellProps, formattedDate, isOutsideVisibleRange } =
      calendarCell;

    const { focusProps, isFocusVisible } = useFocusForAccessibility();

    const onSelectDay: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault();
      date.toDate(selectedTimezone);

      const selectedDayTimestamp = date.toDate(selectedTimezone).getTime();

      onChange(selectedDayTimestamp);
    };

    const isSelected = date.toDate(selectedTimezone).getTime() === value;

    return (
      <StyledCell
        {...cellProps}
        {...buttonProps}
        {...focusProps}
        {...rest}
        data-focused={isFocusVisible}
        isOutsideVisibleRange={isOutsideVisibleRange}
        isSelected={isSelected}
        isToday={isToday(date, selectedTimezone)}
        isWeekend={isWeekend(date, locale)}
        onClick={onSelectDay}
        ref={mergeRefs(currentRef, ref)}
        value={value}
      >
        {formattedDate}
      </StyledCell>
    );
  },
);
