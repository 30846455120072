import type { ReactNode } from "react";
import React from "react";
import { useOvrseaTheme } from "../../theme/useTheme";
import { styled } from "../../utils/system/factory";
import { polymorphic } from "../../utils/ref";
import type { OverlayPosition } from "../Meta/Headless";
import { getSideAndAlignFromPlacement } from "../Meta/Headless";
import { HeadlessTooltip } from "../Meta/Headless";
import { animateEnter, animateExit } from "../../utils/system/animate";
import { SilentErrorBoundary } from "../Meta/SilentErrorBoundary";

export type TooltipPosition = OverlayPosition;

export type TooltipProps = {
  closeDelay?: number;
  isAnimated?: boolean;
  isDefaultOpened?: boolean;
  isDisabled?: boolean;
  isOpened?: boolean;
  label?: ReactNode;
  offset?: [number, number];
  onClose?: () => void;
  onOpen?: () => void;
  openDelay?: number;
  position?: TooltipPosition;
  shouldWrapChildren?: boolean;
  zIndex?: number;
};

const StyledContent = styled<
  typeof HeadlessTooltip.Content,
  { zIndex?: number } & HeadlessTooltip.TooltipContentProps
>(HeadlessTooltip.Content, {
  base: ({ theme, zIndex }) => ({
    "&&[data-side='bottom']": { "--enter-translate-y": "-4px" },
    "&&[data-side='left']": { "--enter-translate-x": "4px" },
    "&&[data-side='right']": { "--enter-translate-x": "-4px" },
    "&&[data-side='top']": { "--enter-translate-y": "4px" },
    "&[data-state='closed']:not([data-animatescale='false'])": {
      "--exit-opacity": 0,
      "--exit-scale": 0.95,
      "--exit-translate-x": "initial",
      "--exit-translate-y": "initial",
      animationDuration: theme.transition.duration.fast,
      animationName: animateExit,
    },
    "&[data-state='delayed-open']:not([data-animatescale='false'])": {
      "--enter-opacity": 0,
      "--enter-scale": 0.95,
      "--enter-translate-x": "initial",
      "--enter-translate-y": "initial",
      animationDuration: theme.transition.duration.fast,
      animationName: animateEnter,
    },
    background: theme.colors.neutral.dark,
    borderRadius: theme.radius.base,
    color: "white",
    fontSize: theme.font.size.sm,
    fontWeight: theme.font.weight.medium,
    lineHeight: theme.lineHeight.md,
    maxWidth: 385,
    padding: "4px 8px",
    zIndex,
  }),
});

const Tooltip = polymorphic(
  (
    {
      children,
      closeDelay,
      isAnimated = true,
      isDefaultOpened,
      isDisabled,
      isOpened: isOpenedProp,
      label,
      offset = [0, 4],
      onClose,
      onOpen,
      openDelay = 50,
      position = "top",
      shouldWrapChildren,
      zIndex: zIndexProp,
      ...rest
    },
    ref,
  ) => {
    const themeZIndex = useOvrseaTheme().zIndex.tooltip;
    const zIndex = zIndexProp ?? themeZIndex;

    if (!label) {
      return <>{children}</>;
    }

    const [alignOffset, sideOffset] = offset;
    const { align, side } = getSideAndAlignFromPlacement(position);

    return (
      <SilentErrorBoundary fallback={() => children}>
        <HeadlessTooltip.Provider>
          <HeadlessTooltip.Root delayDuration={openDelay}>
            {shouldWrapChildren ? (
              <HeadlessTooltip.Trigger asChild>
                <span ref={ref} tabIndex={0}>
                  {children}
                </span>
              </HeadlessTooltip.Trigger>
            ) : (
              <HeadlessTooltip.Trigger asChild {...rest} ref={ref}>
                {children}
              </HeadlessTooltip.Trigger>
            )}

            <HeadlessTooltip.Portal>
              <StyledContent
                align={align}
                alignOffset={alignOffset}
                data-animatescale={isAnimated}
                side={side}
                sideOffset={sideOffset}
                style={{ zIndex }}
              >
                {label}
              </StyledContent>
            </HeadlessTooltip.Portal>
          </HeadlessTooltip.Root>
        </HeadlessTooltip.Provider>
      </SilentErrorBoundary>
    );
  },
);

Tooltip.displayName = "Tooltip";

export { Tooltip };
