import { styled } from "../../../utils/system/factory";

export const TabActions = styled("div", {
  base: {
    marginLeft: "auto",
    paddingBottom: 2,
  },
});

TabActions.displayName = "TabActions";
