import type {
  DateValue,
  CalendarDate as ReactAriaCalendarDate,
  DateDuration as ReactAriaDateDuration,
} from "@internationalized/date";
import {
  createCalendar,
  endOfMonth,
  getLocalTimeZone,
  isSameMonth,
  isToday,
  parseAbsolute,
  getWeeksInMonth as reactAriaGetWeeksInMonth,
} from "@internationalized/date";
import type {
  CalendarAria,
  CalendarCellAria,
  CalendarGridAria,
} from "@react-aria/calendar";
import {
  useCalendar as useReactAriaCalendar,
  useCalendarCell as useReactAriaCalendarCell,
  useCalendarGrid as useReactAriaCalendarGrid,
} from "@react-aria/calendar";
import { useFocusRing } from "@react-aria/focus";
import { useDateFormatter } from "@react-aria/i18n";
import type { CalendarState } from "@react-stately/calendar";
import { useCalendarState } from "@react-stately/calendar";
import type { RefObject } from "react";
import { isDefined } from "@ovrsea/ovrutils";
import type { SystemProps } from "../../../../utils/types/system";
import type { CalendarType } from "../../Calendar";

export type CalendarDate = ReactAriaCalendarDate;

export type DateDuration = ReactAriaDateDuration;

export type CalendarTimezone = "local" | string;

type UseCalendarProps = {
  locale: string;
  timeZone?: CalendarTimezone;
  type: CalendarType;
  value?: number;
};

type CalendarProps = {
  "aria-describedby"?: string;
  "aria-label"?: string;
  "aria-labelledby"?: string;
  id?: string;
  role?: string;
};

type ButtonProps = {
  isDisabled?: boolean;
  onClick?: (e: any) => void;
} & Pick<SystemProps<"button">, "onBlur" | "onFocus">;

type UseCalendarGridParams = {
  endDate?: CalendarDate;
  startDate?: CalendarDate;
};

type UseCalendarCellParams = {
  date: CalendarDate;
  ref: RefObject<HTMLElement>;
};

export type UseCalendar = {
  calendarProps: CalendarProps;
  endMonthName: string;
  endOfMonth: typeof endOfMonth;
  getWeeksInMonth: (date: DateValue) => number;
  isSameMonth: typeof isSameMonth;
  isToday: typeof isToday;
  locale: string;
  nextButtonProps: ButtonProps;
  prevButtonProps: ButtonProps;
  selectedTimezone: string;
  startMonthName: string;
  state: CalendarState;
  useCalendarCell: (params: UseCalendarCellParams) => CalendarCellAria;
  useCalendarGrid: (params: UseCalendarGridParams) => CalendarGridAria;
  useFocusForAccessibility: typeof useFocusRing;
};

const useExtractMonthName = (date: ReactAriaCalendarDate, timeZone: string) => {
  const monthFormatter = useDateFormatter({
    calendar: date.calendar.identifier,
    month: "long",
    timeZone,
    year: "numeric",
  });

  return monthFormatter.format(date.toDate(timeZone));
};

const selectOnClick = (props: CalendarAria["nextButtonProps"]) => ({
  "aria-label": props["aria-label"],
  isDisabled: props.isDisabled,
  onBlur: props.onBlur,
  onClick: props.onPress,
  onFocus: props.onFocus,
});

const useCalendar = ({
  locale,
  timeZone = "utc",
  type,
  value,
}: UseCalendarProps): UseCalendar => {
  const selectedTimezone = timeZone === "local" ? getLocalTimeZone() : timeZone;

  const isoDate = (
    isDefined(value) ? new Date(value) : new Date()
  ).toISOString();

  const state = useCalendarState({
    createCalendar,
    locale,
    value: parseAbsolute(isoDate, selectedTimezone),
    visibleDuration: { months: type === "double" ? 2 : 1 },
  });

  const startMonthName = useExtractMonthName(
    state.visibleRange.start,
    selectedTimezone,
  );
  const endMonthName = useExtractMonthName(
    state.visibleRange.end,
    selectedTimezone,
  );

  const {
    calendarProps,
    nextButtonProps: reactAriaNextButtonProps,
    prevButtonProps: reactAriaPrevButtonProps,
  } = useReactAriaCalendar({}, state);

  const nextButtonProps = selectOnClick(reactAriaNextButtonProps);
  const prevButtonProps = selectOnClick(reactAriaPrevButtonProps);

  const useCalendarGrid = ({ endDate, startDate }: UseCalendarGridParams) =>
    useReactAriaCalendarGrid(
      {
        endDate,
        startDate,
        weekdayStyle: "short",
      },
      state,
    );

  const useCalendarCell = ({ date, ref }: UseCalendarCellParams) => {
    const reactAriaCalendarCell = useReactAriaCalendarCell(
      {
        date,
      },
      state,
      ref,
    );

    reactAriaCalendarCell.cellProps;
    const buttonProps = {
      "aria-describedby": reactAriaCalendarCell.buttonProps["aria-describedby"],
      "aria-disabled": reactAriaCalendarCell.buttonProps["aria-disabled"],
      "aria-invalid": reactAriaCalendarCell.buttonProps["aria-invalid"],
      "aria-label": reactAriaCalendarCell.buttonProps["aria-label"],
      onClick: reactAriaCalendarCell.buttonProps.onClick,
      onContextMenu: reactAriaCalendarCell.buttonProps.onContextMenu,
      onFocus: reactAriaCalendarCell.buttonProps.onFocus,
      onKeyDown: reactAriaCalendarCell.buttonProps.onKeyDown,
      onKeyUp: reactAriaCalendarCell.buttonProps.onKeyUp,
      role: reactAriaCalendarCell.buttonProps.role,
      tabIndex: reactAriaCalendarCell.buttonProps.tabIndex,
    };

    return { ...reactAriaCalendarCell, buttonProps };
  };

  const getWeeksInMonth = (date: DateValue) =>
    reactAriaGetWeeksInMonth(date, locale);

  return {
    calendarProps,
    endMonthName,
    endOfMonth,
    getWeeksInMonth,
    isSameMonth,
    isToday,
    locale,
    nextButtonProps,
    prevButtonProps,
    selectedTimezone,
    startMonthName,
    state,
    useCalendarCell,
    useCalendarGrid,
    useFocusForAccessibility: useFocusRing,
  };
};

export default useCalendar;
