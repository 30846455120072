import { useState } from "react";
import { useBooleanState } from "../../../utils/hooks/useBooleanState";
import { useSelectAccessibilityProps } from "../Shared/useSelectAccessibilityProps";
import { useSelectFocus } from "../Shared/useSelectFocus";
import { useSelectScroll } from "../Shared/useSelectScroll";
import type { SelectOptionType } from "../Shared/types";
import { useMultiSelectKeydown } from "./useMultiSelectKeydown";
import { useMultiSelectFilter } from "./useMultiSelectFilter";
import { useMultiSelectInteractions } from "./useMultiSelectInteractions";

type Params = {
  autoFocus?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  onChange: (value: string[]) => void;
  options: SelectOptionType[];
  value?: string[];
};

export const useMultiSelect = ({
  autoFocus,
  isClearable,
  isDisabled,
  isSearchable,
  onChange,
  options,
  value,
}: Params) => {
  const [isOpened, setOpened, setClosed] = useBooleanState(autoFocus);

  const [activeIndex, setActiveIndex] = useState(-1);

  const { handleFocusInput, inputRef } = useSelectFocus();

  const {
    filteredOptions,
    handleRemoveOption,
    handleReset,
    handleResetFilter,
    onSearchChange,
    searchQuery,
    selectedOptions,
    setSearchQuery,
  } = useMultiSelectFilter({
    handleFocusInput,
    isClearable,
    onChange,
    options,
    setClosed,
    value,
  });

  const { scrollToItem, scrollableRef, setItemRef } = useSelectScroll();

  const { ariaProps, inputId } = useSelectAccessibilityProps({
    activeIndex,
    isOpened,
  });

  const {
    handleInputBlur,
    handleInputChange,
    handleInputClick,
    handleListClick,
    handleSelect,
  } = useMultiSelectInteractions({
    activeIndex,
    filteredOptions,
    handleFocusInput,
    handleResetFilter,
    isDisabled,
    isOpened,
    isSearchable,
    onChange,
    onSearchChange,
    setActiveIndex,
    setClosed,
    setOpened,
    value,
  });

  const handleInputKeydown = useMultiSelectKeydown({
    activeIndex,
    filteredOptions,
    handleRemoveOption,
    handleSelect,
    isOpened,
    isSearchable,
    scrollToItem,
    searchQuery,
    setActiveIndex,
    setClosed,
    setOpened,
    value,
  });

  return {
    activeIndex,
    ariaProps,
    filteredOptions,
    handleInputBlur,
    handleInputChange,
    handleInputClick,
    handleInputKeydown,
    handleListClick,
    handleRemoveOption,
    handleReset,
    handleSelect,
    inputId,
    inputRef,
    isOpened,
    scrollableRef,
    searchQuery,
    selectedOptions,
    setActiveIndex,
    setClosed,
    setItemRef,
    setOpened,
    setSearchQuery,
  } as const;
};
