import { useLayoutEffect, useRef } from "react";
import { isNotDefined } from "@ovrsea/ovrutils";
import { useForceUpdate } from "../../../utils/hooks/useForceUpdate";

export const usePortalRef = () => {
  const temporaryRef = useRef<HTMLDivElement | null>(null);
  const portalRef = useRef<HTMLDivElement | null>(null);

  const forceUpdate = useForceUpdate();

  useLayoutEffect(() => {
    if (!temporaryRef.current) {
      return;
    }

    const doc = temporaryRef.current.ownerDocument;

    if (isNotDefined(doc.body)) {
      return;
    }

    portalRef.current = doc.createElement("div");
    portalRef.current.className = "portal";

    doc.body.appendChild(portalRef.current);
    forceUpdate();

    const portalNode = portalRef.current;

    return () => {
      if (doc.body.contains(portalNode)) {
        doc.body.removeChild(portalNode);
      }
    };
  }, []);

  return { portalRef, temporaryRef };
};
