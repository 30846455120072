import type { FreightMethodOvrUtils } from "../orderUtils/orderFreightMethodSelectors";

type InsurabilityArgs = {
  freightMethod: FreightMethodOvrUtils;
};

export const isInsurable = ({ freightMethod }: InsurabilityArgs): boolean =>
  freightMethod !== "rail";

export const MIN_INSURANCE_BUYING_PRICE_IN_EUROS = 10;
export const DEFAULT_CARGO_VALUE_PERCENT_INSURED = 110;
export const DEFAULT_INSURANCE_SELL_PRICE_IN_PERCENT_OF_INSURED_CARGO_VALUE = 0.4;
export const MIN_INSURANCE_SELL_PRICE_IN_PERCENT_OF_INSURED_CARGO_VALUE = 0.35;
export const DEFAULT_INSURANCE_SELL_PRICE_IN_PERCENT_OF_INSURED_CARGO_VALUE_TRUCK_NORTH_AMERICA = 0.75;
export const MAX_CARGO_VALUE_INSURABLE = 2_000_000;

export const computeCargoValueInCentsInsured = ({
  cargoValueInCents,
  cargoValuePercentInsured,
}: {
  cargoValueInCents: number;
  cargoValuePercentInsured: number;
}) => {
  return Math.round((cargoValueInCents * cargoValuePercentInsured) / 100);
};
