import type { DbPricingSteps } from "../../sharedTypes";
import type { Currency } from "../pricesUtils/priceUtils";
import { calculateSumOfAllCategoriesPrices } from "./calculateSumOfAllCategoriesPrices";

const notFreightCategories: DbPricingSteps[] = [
  "customs",
  "insurance",
  "other",
];

export const calculateSumOfFreightCategoriesPrices = (
  priceCategories: {
    category: DbPricingSteps;
    prices: {
      amount: number;
      priceCurrency: Currency;
      vatRate?: null | number;
    }[];
  }[],
  priceCurrency: Currency,
): number => {
  const freightCategories = priceCategories.filter(
    (categoryPrices) => !notFreightCategories.includes(categoryPrices.category),
  );

  return calculateSumOfAllCategoriesPrices({
    priceCategories: freightCategories,
    targetCurrency: priceCurrency,
  });
};
