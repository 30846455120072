import type {
  DropzoneInputProps,
  DropzoneOptions,
  DropzoneRootProps,
  DropzoneState,
} from "react-dropzone-esm";
import { useDropzone } from "react-dropzone-esm";

type UseDropzonePropsT = (
  options?: DropzoneOptions,
) => [DropzoneRootProps, DropzoneInputProps, DropzoneState];

const useDropzoneProps: UseDropzonePropsT = (options) => {
  const dropzoneProps = useDropzone(options);

  const dropzoneRootProps = dropzoneProps.getRootProps();
  const dropzoneInputProps = dropzoneProps.getInputProps();

  return [dropzoneRootProps, dropzoneInputProps, dropzoneProps];
};

export { useDropzoneProps };
