import { isArray, isObject, reduce } from "lodash-es";

const isNested = <T>(value: T) => isObject(value) && !isArray(value);

const transformKey = (left: string, delimiter: string, right: string) =>
  `${left}${delimiter}${right}`;

export const flattenDeep = <T extends Record<string, any>, Values>(
  object: T,
  delimiter = ".",
): Record<string, Values> =>
  reduce(
    object,
    (acc, value, key) => {
      if (!isNested(value)) {
        return { ...acc, [key]: value };
      }

      return {
        ...acc,
        ...reduce(
          flattenDeep(value, delimiter),
          (innerAcc, innerValue, innerKey) => ({
            ...innerAcc,
            [transformKey(key, delimiter, innerKey)]: innerValue,
          }),
          {} as Record<string, any>,
        ),
      };
    },
    {} as Record<string, Values>,
  );
