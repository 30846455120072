import type { CSSProperties, ReactNode } from "react";
import React from "react";
import { mergeRefs, polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { composeHandlers } from "../../utils/composeHandlers";
import { Popover } from "../Overlay/Popover";
import { useMultiSelect } from "./MultiSelect/useMultiSelect";
import { useFormFieldContext } from "./FormField/FormFieldProvider";
import {
  SelectOptions,
  SelectTrigger,
} from "./MultiSelect/MultiSelectComponents";
import type { SelectOptionType } from "./Shared/types";

type Props<Value extends string = string> = {
  actionLabel?: ReactNode;
  autoFocus?: boolean;
  ["data-testid"]?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  menuWidth?: CSSProperties["width"];
  noOptionsMessage?: ReactNode;
  nothingFoundMessage?: ReactNode;
  onActionClick?: (search: string) => void;
  onChange: (value: Value[]) => void;
  options: SelectOptionType[];
  placeholder?: string;
  value?: Value[];
};

export type MultiSelectProps<Value extends string = string> = Omit<
  SystemProps<"div">,
  "onChange" | "value"
> &
  Props<Value>;

type GenericMultiSelect = {
  <Value extends string>(props: MultiSelectProps<Value>): ReactNode;
  displayName?: string;
};

export const MultiSelect = polymorphic<"div", Props>(
  (
    {
      actionLabel,
      autoFocus,
      ["data-testid"]: dataTestId,
      isClearable = true,
      isErrored: isErroredProp,
      isLoading,
      isDisabled: isDisabledProp = isLoading,
      isSearchable = true,
      menuWidth = "unset",
      noOptionsMessage,
      nothingFoundMessage,
      onActionClick,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onMouseDown,
      options,
      placeholder,
      value = [],
      ...rest
    },
    ref,
  ) => {
    const context = useFormFieldContext();

    const isDisabled = context?.isDisabled || isDisabledProp;
    const isErrored = context?.isErrored || isErroredProp;

    const {
      activeIndex,
      ariaProps,
      filteredOptions,
      handleInputBlur,
      handleInputChange,
      handleInputClick,
      handleInputKeydown,
      handleListClick,
      handleRemoveOption,
      handleReset,
      handleSelect,
      inputId,
      inputRef,
      isOpened,
      scrollableRef,
      searchQuery,
      selectedOptions,
      setActiveIndex,
      setClosed,
      setItemRef,
      setOpened,
      setSearchQuery,
    } = useMultiSelect({
      autoFocus,
      isClearable,
      isDisabled,
      isSearchable,
      onChange,
      options,
      value,
    });

    return (
      <Popover
        isDisabled={isDisabled}
        isOpened={isOpened}
        onClose={setClosed}
        onOpen={setOpened}
      >
        <SelectTrigger
          activeIndex={activeIndex}
          ariaActiveDescendant={ariaProps.activeDescendant}
          ariaControls={ariaProps.controls}
          autoFocus={autoFocus}
          data-testid={dataTestId}
          handleRemoveOption={handleRemoveOption}
          inputId={inputId}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isErrored={isErrored}
          isLoading={isLoading}
          isOpened={isOpened}
          isSearchable={isSearchable}
          onBlur={composeHandlers(onBlur, handleInputBlur)}
          onChange={handleInputChange}
          onClear={handleReset}
          onFocus={onFocus}
          onKeyDown={composeHandlers(onKeyDown, handleInputKeydown)}
          onMouseDown={composeHandlers(onMouseDown, handleInputClick)}
          onOpen={setOpened}
          placeholder={placeholder}
          ref={mergeRefs(ref, inputRef)}
          searchQuery={searchQuery}
          selectedOptions={selectedOptions}
          setActiveOption={setActiveIndex}
          setSearchQuery={setSearchQuery}
          value={value}
          {...rest}
        />
        <SelectOptions
          actionLabel={actionLabel}
          activeIndex={activeIndex}
          dataTestId={dataTestId}
          handleListClick={handleListClick}
          handleSelect={handleSelect}
          initialOptionCount={options.length}
          isOpened={isOpened}
          menuWidth={menuWidth}
          noOptionsMessage={noOptionsMessage}
          nothingFoundMessage={nothingFoundMessage}
          onActionClick={onActionClick}
          options={filteredOptions}
          scrollableRef={scrollableRef}
          searchQuery={searchQuery}
          setActiveIndex={setActiveIndex}
          setClosed={setClosed}
          setItemRef={setItemRef}
        />
      </Popover>
    );
  },
) as GenericMultiSelect;

MultiSelect.displayName = "MultiSelect";
