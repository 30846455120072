export const flavorId =
  <T extends string>(type: T) =>
  (id: string) => ({
    id,
    type,
  });

export const unflavorIds = <Input extends { id: string; type: string }>(
  input: Input[],
  type: Input["type"],
): string[] =>
  input
    .filter((inputElement) => inputElement.type === type)
    .map((inputElement) => inputElement.id);
