import { dayjs } from "../..";
import { holidays } from "./allHolidays";

const holidayIsComing = (date: Date) => (holiday: { date: string }) => {
  const referenceDate = dayjs(date);
  const holidayDate = dayjs(holiday.date);

  const isInThePrevious2Days = holidayDate.diff(referenceDate, "days") >= -2;
  const isInTheNext10Days = holidayDate.diff(referenceDate, "days") <= 10;

  return isInThePrevious2Days && isInTheNext10Days;
};

export const comingHolidaysInCountry = (date: Date) => (countryCode: string) =>
  (holidays[countryCode] ?? []).filter(holidayIsComing(date));
