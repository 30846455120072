import { orderBy, sum, uniq } from "lodash-es";
import { OvrseaError } from "../../errors/errors";
import type { DbPricingSteps } from "../../sharedTypes";
import type { Currency } from "../pricesUtils/priceUtils";

export type Price = {
  category: DbPricingSteps;
  id: string;
  number: number;
  priceCurrency: Currency;
  priceName: string;
  priceQuantity: number;
  unitPrice: number;
  vatRate: null | number;
};

const sumAndFormatGroupedPrices = <P extends Price>(
  groupedPrices: P[],
): { pricesIds: string[] } & P => {
  const pricesIds = groupedPrices.map((price) => price.id);
  const priceAmount = sum(
    groupedPrices.map((price) => price.unitPrice * price.priceQuantity),
  );

  const [firstPrice] = groupedPrices;

  if (!firstPrice) {
    throw new OvrseaError({
      criticity: "WARNING",
      message: "Price group cannot contain zero price",
    });
  }

  const unitPrice =
    groupedPrices.length === 1 ? firstPrice.unitPrice : priceAmount;
  const priceQuantity =
    groupedPrices.length === 1 ? firstPrice.priceQuantity : 1;

  return {
    ...firstPrice,
    id: firstPrice.id,
    priceQuantity,
    pricesIds,
    unitPrice,
  };
};

const sumPricesWithSameName = <P extends Price>(
  prices: P[],
): ({ pricesIds: string[] } & P)[] => {
  const priceNames = uniq(prices.map((price) => price.priceName));

  return priceNames.map((priceName) => {
    const pricesWithSameName = prices.filter(
      (price) => price.priceName === priceName,
    );

    return sumAndFormatGroupedPrices(pricesWithSameName);
  });
};

export const groupPricesByCategoryAndSumByClientName = <T extends Price>(
  prices: T[],
) => {
  const categories = uniq(prices.map((price) => price.category));

  return categories.map((category) => {
    const categoryPrices = prices.filter(
      (price) => price.category === category,
    );
    const computedPrices = sumPricesWithSameName<T>(categoryPrices);

    const sortedPrices = orderBy(
      computedPrices,
      (price) => price.number,
      "asc",
    );

    return {
      category,
      prices: sortedPrices,
    };
  });
};
