import { identity, isObject, mapValues, reduce } from "lodash-es";

type Transform<T extends string = ""> = (value: T) => any;

const walk = <T extends object>(
  object: T,
  transformKey: Transform<string> = identity,
  transformValue: Transform<any> = identity,
) =>
  reduce(
    object,
    (acc, value, key) => ({
      ...acc,
      [transformKey(key)]: transformValue(value),
    }),
    {} as T,
  );

type Options = {
  delimiter?: string;
  prefix?: string;
  suffix?: string;
};

type RecursiveObject = {
  [x: string]: RecursiveObject | string;
};

const deepTransformValues = (
  objectOrValue: RecursiveObject | string,
  path = "",
  options: Options,
): RecursiveObject | string =>
  isObject(objectOrValue)
    ? mapValues(objectOrValue, (value, key) =>
        deepTransformValues(
          value,
          path
            ? `${path}${options.delimiter}${key.replace(/\./g, "-")}`
            : `${options.prefix}${key}`,
          options,
        ),
      )
    : `${path}${options.suffix}`;

type FunctionType = (...args: unknown[]) => unknown;

const isFunction = <T extends FunctionType>(value: T | unknown): value is T =>
  typeof value === "function";

const callIfFunction =
  <T, U>(maybeFunction: ((...args: U[]) => T) | T) =>
  (...args: U[]) =>
    //@ts-expect-error
    isFunction<(...args: U[]) => T>(maybeFunction)
      ? maybeFunction(...args)
      : maybeFunction;

export { callIfFunction, deepTransformValues, walk };
