import type { TimelineSize } from "../Timeline";
import { styled } from "../../../utils/system/factory";
import type { CSSObject } from "../../../utils/types/css";

type Props = {
  size: TimelineSize;
};

const smallStyles: CSSObject = {
  "& .item": {
    paddingLeft: 40,
  },
  "& .item .icon-block": {
    fontSize: 11,
    padding: 0,
    top: -2,
  },
  "& .item .icon-block, & .item .icon-block .icon": {
    fontSize: 11,
  },
  "& .item .line": {
    height: "calc(100% - 28px)",
    left: 15,
    top: 26,
  },
  "& .item .marker": {
    paddingTop: 0,
  },
  "& .item:not(:last-child)": {
    minHeight: 50,
  },
  padding: "9px 0",
};

const mediumStyles: CSSObject = {
  "& .item": {
    paddingLeft: 40,
  },
  "& .item .line": {
    height: "calc(100% - 34px)",
    left: 14,
    top: 28,
  },
  "& .item:not(:last-child):not([role='option'])": {
    minHeight: 60,
  },
  padding: "11px 0",
};

const sizesMap = {
  medium: mediumStyles,
  small: smallStyles,
};

const VerticalTimeline = styled<"ul", Props>("ul", {
  base: ({ size }) => [
    {
      listStyle: "none",
      paddingLeft: 0,
      paddingTop: 0,
    },
    sizesMap[size],
  ],
});

VerticalTimeline.displayName = "VerticalTimeline";

export { VerticalTimeline };
