import React from "react";
import { first, last } from "lodash-es";
import { Image } from "../Image";
import { styled } from "../../../utils/system/factory";
import { useOvrseaTheme } from "../../../theme/useTheme";

type Props = {
  name?: null | string;
  offset: string;
  src?: null | string;
};

const webkitTranslate = "translateZ(0)";
const webkitMaskClip =
  "-webkit-radial-gradient(circle, white 100%, black 100%)";

const StyledImage = styled<typeof Image, { offset: string }>(Image, {
  base: ({ offset, theme }) => ({
    [`@supports (-webkit-transform: ${webkitTranslate}) and (-webkit-mask-image: ${webkitMaskClip})`]:
      {
        WebkitMaskImage: webkitMaskClip,
        WebkitTransform: webkitTranslate,
        overflow: "hidden",
      },
    border: `${offset} solid white`,
    borderRadius: theme.radius.round,
    height: "100%",
    width: "100%",
  }),
});

const formatNameInitials = (name: string) => {
  const nameSplitted = name.split(" ");

  if (nameSplitted.length < 2) {
    return name.charAt(0);
  }
  const firstNameInitial = first(nameSplitted)?.charAt(0) ?? "";
  const lastNameInitial = last(nameSplitted)?.charAt(0) ?? "";

  return [firstNameInitial, lastNameInitial].flat().join("");
};

const AvatarContent = ({ name, offset, src }: Props) => {
  const theme = useOvrseaTheme();

  if (src) {
    return (
      <StyledImage
        dominantColor={theme.colors.neutral["10"]}
        isLazy={false}
        offset={offset}
        ratio={100}
        src={src}
      />
    );
  }

  if (name) {
    return <>{formatNameInitials(name)}</>;
  }

  return null;
};

export { AvatarContent };
