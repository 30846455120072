import Fuse from "fuse.js";

export const sortByOvrseaNameLikelyhood =
  (ovrseaNameCandidate: string) =>
  (ovrseaNameA: string, ovrseaNameB: string): number => {
    const fuseA = new Fuse([ovrseaNameA], {
      includeScore: true,
      isCaseSensitive: true,
    });
    const fuseB = new Fuse([ovrseaNameB], {
      includeScore: true,
      isCaseSensitive: true,
    });

    const searchMatchA = fuseA.search(ovrseaNameCandidate);
    const searchMatchB = fuseB.search(ovrseaNameCandidate);

    return (searchMatchA?.[0]?.score ?? 0) - (searchMatchB?.[0]?.score ?? 0);
  };
