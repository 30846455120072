import type { PropsWithChildren, ReactElement } from "react";
import React from "react";
import { cloneChildren } from "../../../utils/children";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import type { SpacingScale } from "../../../utils/system/system";
import { BreadcrumbSeparator } from "./BreadcrumbSeparator";
import { BreadcrumbLink } from "./BreadcrumbLink";

type InjectedProps = {
  isLastChild?: boolean;
  spacing?: SpacingScale;
};

type Props = {
  className?: string;
  isCurrentPage?: boolean;
} & InjectedProps;

const StyledBreadcrumbItem = styled("li", {
  base: {
    alignItems: "center",
    display: "inline-flex",
  },
});

export const BreadcrumbItem = polymorphic<"li", Props>(
  ({ children, isCurrentPage, isLastChild, ...rest }, ref) => {
    const cloned = cloneChildren(
      children,
      (child: ReactElement<PropsWithChildren<InjectedProps>>) => {
        if (child.type === BreadcrumbLink) {
          return { isCurrentPage };
        }

        if (child.type === BreadcrumbSeparator) {
          return {
            children: child.props.children,
          };
        }

        return child;
      },
    );

    return (
      <StyledBreadcrumbItem ref={ref} {...rest}>
        {cloned}
        {!isLastChild && <BreadcrumbSeparator>/</BreadcrumbSeparator>}
      </StyledBreadcrumbItem>
    );
  },
);

BreadcrumbItem.displayName = "BreadcrumbItem";
