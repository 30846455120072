import type { ReactNode } from "react";
import React from "react";
import type { IconName } from "../Typography/Icon";
import { Icon } from "../Typography/Icon";
import { polymorphic } from "../../utils/ref";
import type { Sx, SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { Spinner } from "../Feedback/Spinner";
import { mapPseudoFocusRing } from "../../theme/commons";
import { Center } from "../Layout/Center";
import type { Theme } from "../../theme/theme";
import { Tooltip } from "../Overlay/Tooltip";
import { IconButtonGroup } from "./IconButton/IconButtonGroup";

const colorVariations = (theme: Theme) =>
  ({
    danger: {
      background: theme.colors.alert.dangerLight,
      color: theme.colors.alert.danger,
      hovered: theme.colors.alert.danger,
    },
    neutral: {
      background: theme.colors.neutral["10"],
      color: theme.colors.neutral.base,
      hovered: theme.colors.neutral.base,
    },
    primary: {
      background: theme.colors.primary.light,
      color: theme.colors.primary.base,
      hovered: theme.colors.primary.dark,
    },
  }) as const;

type IconButtonColor = keyof ReturnType<typeof colorVariations>;

type Props = {
  color?: IconButtonColor;
  "data-testid"?: string;
  iconSx?: Sx;
  isDisabled?: boolean;
  isLoading?: boolean;
  label: ReactNode;
  name: IconName;
  tooltipOpenDelay?: number;
};

export type IconButtonProps = Props & SystemProps<"button">;

type Compounds = {
  Group: typeof IconButtonGroup;
};

const StyledButton = styled<"button", Pick<Props, "color">>("button", {
  base: ({ color: colorProp = "neutral", theme }) => {
    const { background, color, hovered } = colorVariations(theme)[colorProp];

    return [
      mapPseudoFocusRing(theme),
      {
        alignItems: "center",
        background: "none",
        border: "none",
        borderRadius: theme.radius.xl,
        color,
        cursor: "pointer",
        display: "inline-flex",
        fontSize: theme.font.size.md,
        height: "2rem",
        justifyContent: "center",
        minHeight: "2rem",
        minWidth: "2rem",
        outline: "transparent solid 2px",
        outlineOffset: "2px",
        transitionDuration: theme.transition.duration.slow,
        transitionProperty: "all",
        transitionTimingFunction: theme.transition.easing.color,
        width: "2rem",
      },
      {
        "&:is(:hover, :focus-visible):not(:disabled)": {
          background,
          color: hovered,
        },
      },
      {
        "&:disabled": {
          color: theme.colors.neutral["30"],
          cursor: "not-allowed",
        },
      },
    ];
  },
});

const Absolute = styled(Center, { base: { position: "absolute" } });

const LoadingContainer = styled<typeof Center, { isLoading?: boolean }>(
  Center,
  {
    base: ({ isLoading }) => [
      {
        fontSize: "inherit",
        opacity: 1,
      },
      isLoading && {
        opacity: 0,
      },
    ],
  },
);

export const IconButton = polymorphic<"button", Props, Compounds>(
  (
    {
      iconSx,
      isLoading,
      isDisabled = isLoading,
      label,
      name,
      onClick,
      onFocus,
      onMouseDown,
      onMouseEnter,
      tooltipOpenDelay = 300,
      ...rest
    },
    ref,
  ) => (
    <Tooltip
      label={label}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      openDelay={tooltipOpenDelay}
      position="top"
      ref={ref}
    >
      <StyledButton
        disabled={isDisabled}
        onClick={onClick}
        type="button"
        {...rest}
      >
        <Absolute>
          <Spinner isActive={isLoading} size="sm" />
        </Absolute>
        <LoadingContainer isLoading={isLoading}>
          <Icon data-testid={name} name={name} sx={iconSx} />
        </LoadingContainer>
      </StyledButton>
    </Tooltip>
  ),
);

IconButton.displayName = "IconButton";
IconButton.Group = IconButtonGroup;
