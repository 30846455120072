import React from "react";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { useTabs } from "./useTabs";

const ActiveWrapper = styled("span", {
  base: {
    position: "absolute",
  },
});
const InactiveWrapper = styled("span", {
  base: {
    visibility: "hidden",
  },
});

type TabProps = {
  dataIndex?: number;
  isSelected?: boolean;
  onClick?: (index: number) => void;
};

const StyledTab = styled("button", {
  base: ({ theme }) => {
    const { fontSize, fontWeight, lineHeight } = theme.body.md;

    return [
      {
        "&:focus": {
          boxShadow: `${theme.colors.focus.ring} 0 0 0 3px`,
          outline: "none",
        },
        "&:focus:not(:focus-visible)": {
          boxShadow: "none",
        },
        // fixes safari default appearance
        "@supports (-webkit-appearance: unset)": {
          ["&&"]: {
            WebkitAppearance: "unset",
          },
        },
        alignItems: "center",
        background: "transparent",
        borderBottomLeftRadius: theme.radius.none,
        borderBottomRightRadius: theme.radius.none,
        borderBottomWidth: "2px",
        borderColor: "transparent",
        borderStyle: "none none solid none",
        borderTopLeftRadius: theme.radius.sm,
        borderTopRightRadius: theme.radius.sm,
        color: theme.colors.neutral["60"],
        cursor: "pointer",
        display: "flex",
        fontFamily: theme.font.family.base,
        fontSize,
        fontWeight,
        justifyContent: "center",
        lineHeight,
        marginBottom: "-1px",
        minWidth: 0,
        paddingBlock: theme.spacing[8],
        paddingInline: theme.spacing[12],
        position: "relative",
      },
      {
        "&:hover": {
          borderBottomColor: "currentcolor",
          color: theme.colors.neutral.base,
        },
      },
      {
        "&[aria-selected='true']": {
          "[data-type='wrapper-active']": {
            color: theme.colors.primary.base,
            fontWeight: theme.font.weight.medium,
          },
          borderBottomColor: theme.colors.primary.base,
        },
      },
    ];
  },
});

export const Tab = polymorphic<"button", TabProps>(
  ({ children, dataIndex, isSelected, onClick, ...rest }, ref) => {
    const { activeIndex, generateTabId, generateTabPanelId, setActiveIndex } =
      useTabs();

    if (dataIndex === undefined) {
      return <>{null}</>;
    }

    const onTabClick = () => {
      if (onClick) {
        return onClick(dataIndex);
      }
      setActiveIndex(dataIndex);
    };

    const selected = isSelected || activeIndex === dataIndex;

    return (
      <StyledTab
        aria-controls={generateTabPanelId(dataIndex)}
        aria-selected={selected}
        data-index={dataIndex}
        id={generateTabId(dataIndex)}
        onClick={onTabClick}
        ref={ref}
        role="tab"
        tabIndex={selected ? -1 : 0}
        type="button"
        {...rest}
      >
        <ActiveWrapper data-type="wrapper-active">{children}</ActiveWrapper>
        <InactiveWrapper data-type="wrapper-inactive">
          {children}
        </InactiveWrapper>
      </StyledTab>
    );
  },
);

Tab.displayName = "Tab";
