import type { DependencyList } from "react";
import { useCallback, useLayoutEffect, useRef } from "react";

export function useCallbackRef<T extends (...args: any[]) => any>(
  callback: T | undefined,
  deps: DependencyList = [],
): T {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return useCallback(((...args) => callbackRef.current?.(...args)) as T, deps);
}
