export type CountryData = {
  code: string;
  flag?: string;
  id: string;
  idd: string;
};

const countries: CountryData[] = [
  {
    code: "uy",
    flag: "🇺🇾",
    id: "uy",
    idd: "+598",
  },
  {
    code: "py",
    flag: "🇵🇾",
    id: "py",
    idd: "+595",
  },
  {
    code: "gm",
    flag: "🇬🇲",
    id: "gm",
    idd: "+220",
  },
  {
    code: "dj",
    flag: "🇩🇯",
    id: "dj",
    idd: "+253",
  },
  {
    code: "mq",
    flag: "🇲🇶",
    id: "mq",
    idd: "+596",
  },
  {
    code: "gu",
    flag: "🇬🇺",
    id: "gu",
    idd: "+1671",
  },
  {
    code: "ge",
    flag: "🇬🇪",
    id: "ge",
    idd: "+995",
  },
  {
    code: "us",
    flag: "🇺🇸",
    id: "us",
    idd: "+1",
  },
  {
    code: "bz",
    flag: "🇧🇿",
    id: "bz",
    idd: "+501",
  },
  {
    code: "mu",
    flag: "🇲🇺",
    id: "mu",
    idd: "+230",
  },
  {
    code: "lb",
    flag: "🇱🇧",
    id: "lb",
    idd: "+961",
  },
  {
    code: "sa",
    flag: "🇸🇦",
    id: "sa",
    idd: "+966",
  },
  {
    code: "ec",
    flag: "🇪🇨",
    id: "ec",
    idd: "+593",
  },
  {
    code: "mh",
    flag: "🇲🇭",
    id: "mh",
    idd: "+692",
  },
  {
    code: "br",
    flag: "🇧🇷",
    id: "br",
    idd: "+55",
  },
  {
    code: "ug",
    flag: "🇺🇬",
    id: "ug",
    idd: "+256",
  },
  {
    code: "qa",
    flag: "🇶🇦",
    id: "qa",
    idd: "+974",
  },
  {
    code: "tl",
    flag: "🇹🇱",
    id: "tl",
    idd: "+670",
  },
  {
    code: "mn",
    flag: "🇲🇳",
    id: "mn",
    idd: "+976",
  },
  {
    code: "cl",
    flag: "🇨🇱",
    id: "cl",
    idd: "+56",
  },
  {
    code: "lr",
    flag: "🇱🇷",
    id: "lr",
    idd: "+231",
  },
  {
    code: "nr",
    flag: "🇳🇷",
    id: "nr",
    idd: "+674",
  },
  {
    code: "re",
    flag: "🇷🇪",
    id: "re",
    idd: "+262",
  },
  {
    code: "ms",
    flag: "🇲🇸",
    id: "ms",
    idd: "+1664",
  },
  {
    code: "tw",
    flag: "🇹🇼",
    id: "tw",
    idd: "+886",
  },
  {
    code: "pm",
    flag: "🇵🇲",
    id: "pm",
    idd: "+508",
  },
  {
    code: "ar",
    flag: "🇦🇷",
    id: "ar",
    idd: "+54",
  },
  {
    code: "tg",
    flag: "🇹🇬",
    id: "tg",
    idd: "+228",
  },
  {
    code: "at",
    flag: "🇦🇹",
    id: "at",
    idd: "+43",
  },
  {
    code: "gd",
    flag: "🇬🇩",
    id: "gd",
    idd: "+1473",
  },
  {
    code: "fo",
    flag: "🇫🇴",
    id: "fo",
    idd: "+298",
  },
  {
    code: "ma",
    flag: "🇲🇦",
    id: "ma",
    idd: "+212",
  },
  {
    code: "ai",
    flag: "🇦🇮",
    id: "ai",
    idd: "+1264",
  },
  {
    code: "pw",
    flag: "🇵🇼",
    id: "pw",
    idd: "+680",
  },
  {
    code: "mp",
    flag: "🇲🇵",
    id: "mp",
    idd: "+1670",
  },
  {
    code: "mr",
    flag: "🇲🇷",
    id: "mr",
    idd: "+222",
  },
  {
    code: "ua",
    flag: "🇺🇦",
    id: "ua",
    idd: "+380",
  },
  {
    code: "cn",
    flag: "🇨🇳",
    id: "cn",
    idd: "+86",
  },
  {
    code: "ls",
    flag: "🇱🇸",
    id: "ls",
    idd: "+266",
  },
  {
    code: "cy",
    flag: "🇨🇾",
    id: "cy",
    idd: "+357",
  },
  {
    code: "cd",
    flag: "🇨🇩",
    id: "cd",
    idd: "+243",
  },
  {
    code: "ru",
    flag: "🇷🇺",
    id: "ru",
    idd: "+7",
  },
  {
    code: "bd",
    flag: "🇧🇩",
    id: "bd",
    idd: "+880",
  },
  {
    code: "za",
    flag: "🇿🇦",
    id: "za",
    idd: "+27",
  },
  {
    code: "cw",
    flag: "🇨🇼",
    id: "cw",
    idd: "+599",
  },
  {
    code: "gt",
    flag: "🇬🇹",
    id: "gt",
    idd: "+502",
  },
  {
    code: "pr",
    flag: "🇵🇷",
    id: "pr",
    idd: "+1",
  },
  {
    code: "ag",
    flag: "🇦🇬",
    id: "ag",
    idd: "+1268",
  },
  {
    code: "il",
    flag: "🇮🇱",
    id: "il",
    idd: "+972",
  },
  {
    code: "gy",
    flag: "🇬🇾",
    id: "gy",
    idd: "+592",
  },
  {
    code: "ky",
    flag: "🇰🇾",
    id: "ky",
    idd: "+1345",
  },
  {
    code: "hr",
    flag: "🇭🇷",
    id: "hr",
    idd: "+385",
  },
  {
    code: "is",
    flag: "🇮🇸",
    id: "is",
    idd: "+354",
  },
  {
    code: "sx",
    flag: "🇸🇽",
    id: "sx",
    idd: "+1721",
  },
  {
    code: "na",
    flag: "🇳🇦",
    id: "na",
    idd: "+264",
  },
  {
    code: "dm",
    flag: "🇩🇲",
    id: "dm",
    idd: "+1767",
  },
  {
    code: "gi",
    flag: "🇬🇮",
    id: "gi",
    idd: "+350",
  },
  {
    code: "sn",
    flag: "🇸🇳",
    id: "sn",
    idd: "+221",
  },
  {
    code: "kn",
    flag: "🇰🇳",
    id: "kn",
    idd: "+1869",
  },
  {
    code: "om",
    flag: "🇴🇲",
    id: "om",
    idd: "+968",
  },
  {
    code: "kw",
    flag: "🇰🇼",
    id: "kw",
    idd: "+965",
  },
  {
    code: "ax",
    flag: "🇦🇽",
    id: "ax",
    idd: "+35818",
  },
  {
    code: "vi",
    flag: "🇻🇮",
    id: "vi",
    idd: "+1340",
  },
  {
    code: "bv",
    flag: "🇧🇻",
    id: "bv",
    idd: "+47",
  },
  {
    code: "gb",
    flag: "🇬🇧",
    id: "gb",
    idd: "+44",
  },
  {
    code: "hn",
    flag: "🇭🇳",
    id: "hn",
    idd: "+504",
  },
  {
    code: "gs",
    flag: "🇬🇸",
    id: "gs",
    idd: "+500",
  },
  {
    code: "kh",
    flag: "🇰🇭",
    id: "kh",
    idd: "+855",
  },
  {
    code: "mk",
    flag: "🇲🇰",
    id: "mk",
    idd: "+389",
  },
  {
    code: "ir",
    flag: "🇮🇷",
    id: "ir",
    idd: "+98",
  },
  {
    code: "pa",
    flag: "🇵🇦",
    id: "pa",
    idd: "+507",
  },
  {
    code: "ck",
    flag: "🇨🇰",
    id: "ck",
    idd: "+682",
  },
  {
    code: "ad",
    flag: "🇦🇩",
    id: "ad",
    idd: "+376",
  },
  {
    code: "bi",
    flag: "🇧🇮",
    id: "bi",
    idd: "+257",
  },
  {
    code: "tt",
    flag: "🇹🇹",
    id: "tt",
    idd: "+1868",
  },
  {
    code: "km",
    flag: "🇰🇲",
    id: "km",
    idd: "+269",
  },
  {
    code: "tf",
    flag: "🇹🇫",
    id: "tf",
    idd: "+262",
  },
  {
    code: "xk",
    flag: "🇽🇰",
    id: "xk",
    idd: "+383",
  },
  {
    code: "ng",
    flag: "🇳🇬",
    id: "ng",
    idd: "+234",
  },
  {
    code: "pl",
    flag: "🇵🇱",
    id: "pl",
    idd: "+48",
  },
  {
    code: "nu",
    flag: "🇳🇺",
    id: "nu",
    idd: "+683",
  },
  {
    code: "nc",
    flag: "🇳🇨",
    id: "nc",
    idd: "+687",
  },
  {
    code: "et",
    flag: "🇪🇹",
    id: "et",
    idd: "+251",
  },
  {
    code: "de",
    flag: "🇩🇪",
    id: "de",
    idd: "+49",
  },
  {
    code: "az",
    flag: "🇦🇿",
    id: "az",
    idd: "+994",
  },
  {
    code: "nl",
    flag: "🇳🇱",
    id: "nl",
    idd: "+31",
  },
  {
    code: "fr",
    flag: "🇫🇷",
    id: "fr",
    idd: "+33",
  },
  {
    code: "np",
    flag: "🇳🇵",
    id: "np",
    idd: "+977",
  },
  {
    code: "bb",
    flag: "🇧🇧",
    id: "bb",
    idd: "+1246",
  },
  {
    code: "je",
    flag: "🇯🇪",
    id: "je",
    idd: "+44",
  },
  {
    code: "in",
    flag: "🇮🇳",
    id: "in",
    idd: "+91",
  },
  {
    code: "kg",
    flag: "🇰🇬",
    id: "kg",
    idd: "+996",
  },
  {
    code: "ss",
    flag: "🇸🇸",
    id: "ss",
    idd: "+211",
  },
  {
    code: "iq",
    flag: "🇮🇶",
    id: "iq",
    idd: "+964",
  },
  {
    code: "it",
    flag: "🇮🇹",
    id: "it",
    idd: "+39",
  },
  {
    code: "cu",
    flag: "🇨🇺",
    id: "cu",
    idd: "+53",
  },
  {
    code: "bt",
    flag: "🇧🇹",
    id: "bt",
    idd: "+975",
  },
  {
    code: "bs",
    flag: "🇧🇸",
    id: "bs",
    idd: "+1242",
  },
  {
    code: "no",
    flag: "🇳🇴",
    id: "no",
    idd: "+47",
  },
  {
    code: "lt",
    flag: "🇱🇹",
    id: "lt",
    idd: "+370",
  },
  {
    code: "ke",
    flag: "🇰🇪",
    id: "ke",
    idd: "+254",
  },
  {
    code: "pn",
    flag: "🇵🇳",
    id: "pn",
    idd: "+64",
  },
  {
    code: "se",
    flag: "🇸🇪",
    id: "se",
    idd: "+46",
  },
  {
    code: "gp",
    flag: "🇬🇵",
    id: "gp",
    idd: "+590",
  },
  {
    code: "ga",
    flag: "🇬🇦",
    id: "ga",
    idd: "+241",
  },
  {
    code: "mo",
    flag: "🇲🇴",
    id: "mo",
    idd: "+853",
  },
  {
    code: "gg",
    flag: "🇬🇬",
    id: "gg",
    idd: "+44",
  },
  {
    code: "rw",
    flag: "🇷🇼",
    id: "rw",
    idd: "+250",
  },
  {
    code: "sy",
    flag: "🇸🇾",
    id: "sy",
    idd: "+963",
  },
  {
    code: "ca",
    flag: "🇨🇦",
    id: "ca",
    idd: "+1",
  },
  {
    code: "dz",
    flag: "🇩🇿",
    id: "dz",
    idd: "+213",
  },
  {
    code: "io",
    flag: "🇮🇴",
    id: "io",
    idd: "+246",
  },
  {
    code: "eh",
    flag: "🇪🇭",
    id: "eh",
    idd: "+2",
  },
  {
    code: "im",
    flag: "🇮🇲",
    id: "im",
    idd: "+44",
  },
  {
    code: "bw",
    flag: "🇧🇼",
    id: "bw",
    idd: "+267",
  },
  {
    code: "kz",
    flag: "🇰🇿",
    id: "kz",
    idd: "+7",
  },
  {
    code: "ve",
    flag: "🇻🇪",
    id: "ve",
    idd: "+58",
  },
  {
    code: "pf",
    flag: "🇵🇫",
    id: "pf",
    idd: "+689",
  },
  {
    code: "sd",
    flag: "🇸🇩",
    id: "sd",
    idd: "+249",
  },
  {
    code: "wf",
    flag: "🇼🇫",
    id: "wf",
    idd: "+681",
  },
  {
    code: "sk",
    flag: "🇸🇰",
    id: "sk",
    idd: "+421",
  },
  {
    code: "bl",
    flag: "🇧🇱",
    id: "bl",
    idd: "+590",
  },
  {
    code: "cx",
    flag: "🇨🇽",
    id: "cx",
    idd: "+61",
  },
  {
    code: "sb",
    flag: "🇸🇧",
    id: "sb",
    idd: "+677",
  },
  {
    code: "lv",
    flag: "🇱🇻",
    id: "lv",
    idd: "+371",
  },
  {
    code: "vg",
    flag: "🇻🇬",
    id: "vg",
    idd: "+1284",
  },
  {
    code: "jm",
    flag: "🇯🇲",
    id: "jm",
    idd: "+1876",
  },
  {
    code: "af",
    flag: "🇦🇫",
    id: "af",
    idd: "+93",
  },
  {
    code: "rs",
    flag: "🇷🇸",
    id: "rs",
    idd: "+381",
  },
  {
    code: "tj",
    flag: "🇹🇯",
    id: "tj",
    idd: "+992",
  },
  {
    code: "to",
    flag: "🇹🇴",
    id: "to",
    idd: "+676",
  },
  {
    code: "ki",
    flag: "🇰🇮",
    id: "ki",
    idd: "+686",
  },
  {
    code: "er",
    flag: "🇪🇷",
    id: "er",
    idd: "+291",
  },
  {
    code: "ml",
    flag: "🇲🇱",
    id: "ml",
    idd: "+223",
  },
  {
    code: "ht",
    flag: "🇭🇹",
    id: "ht",
    idd: "+509",
  },
  {
    code: "vu",
    flag: "🇻🇺",
    id: "vu",
    idd: "+678",
  },
  {
    code: "ba",
    flag: "🇧🇦",
    id: "ba",
    idd: "+387",
  },
  {
    code: "va",
    flag: "🇻🇦",
    id: "va",
    idd: "+3",
  },
  {
    code: "bj",
    flag: "🇧🇯",
    id: "bj",
    idd: "+229",
  },
  {
    code: "sj",
    flag: "🇸🇯",
    id: "sj",
    idd: "+4779",
  },
  {
    code: "ws",
    flag: "🇼🇸",
    id: "ws",
    idd: "+685",
  },
  {
    code: "bo",
    flag: "🇧🇴",
    id: "bo",
    idd: "+591",
  },
  {
    code: "mg",
    flag: "🇲🇬",
    id: "mg",
    idd: "+261",
  },
  {
    code: "st",
    flag: "🇸🇹",
    id: "st",
    idd: "+239",
  },
  {
    code: "bh",
    flag: "🇧🇭",
    id: "bh",
    idd: "+973",
  },
  {
    code: "gr",
    flag: "🇬🇷",
    id: "gr",
    idd: "+30",
  },
  {
    code: "pe",
    flag: "🇵🇪",
    id: "pe",
    idd: "+51",
  },
  {
    code: "sr",
    flag: "🇸🇷",
    id: "sr",
    idd: "+597",
  },
  {
    code: "as",
    flag: "🇦🇸",
    id: "as",
    idd: "+1684",
  },
  {
    code: "ne",
    flag: "🇳🇪",
    id: "ne",
    idd: "+227",
  },
  {
    code: "th",
    flag: "🇹🇭",
    id: "th",
    idd: "+66",
  },
  {
    code: "tv",
    flag: "🇹🇻",
    id: "tv",
    idd: "+688",
  },
  {
    code: "kp",
    flag: "🇰🇵",
    id: "kp",
    idd: "+850",
  },
  {
    code: "ci",
    flag: "🇨🇮",
    id: "ci",
    idd: "+225",
  },
  {
    code: "ye",
    flag: "🇾🇪",
    id: "ye",
    idd: "+967",
  },
  {
    code: "nz",
    flag: "🇳🇿",
    id: "nz",
    idd: "+64",
  },
  {
    code: "am",
    flag: "🇦🇲",
    id: "am",
    idd: "+374",
  },
  {
    code: "pk",
    flag: "🇵🇰",
    id: "pk",
    idd: "+92",
  },
  {
    code: "tk",
    flag: "🇹🇰",
    id: "tk",
    idd: "+690",
  },
  {
    code: "mw",
    flag: "🇲🇼",
    id: "mw",
    idd: "+265",
  },
  {
    code: "ie",
    flag: "🇮🇪",
    id: "ie",
    idd: "+353",
  },
  {
    code: "ph",
    flag: "🇵🇭",
    id: "ph",
    idd: "+63",
  },
  {
    code: "cz",
    flag: "🇨🇿",
    id: "cz",
    idd: "+420",
  },
  {
    code: "mm",
    flag: "🇲🇲",
    id: "mm",
    idd: "+95",
  },
  {
    code: "cc",
    flag: "🇨🇨",
    id: "cc",
    idd: "+61",
  },
  {
    code: "ro",
    flag: "🇷🇴",
    id: "ro",
    idd: "+40",
  },
  {
    code: "do",
    flag: "🇩🇴",
    id: "do",
    idd: "+1",
  },
  {
    code: "gq",
    flag: "🇬🇶",
    id: "gq",
    idd: "+240",
  },
  {
    code: "gh",
    flag: "🇬🇭",
    id: "gh",
    idd: "+233",
  },
  {
    code: "mt",
    flag: "🇲🇹",
    id: "mt",
    idd: "+356",
  },
  {
    code: "tr",
    flag: "🇹🇷",
    id: "tr",
    idd: "+90",
  },
  {
    code: "eg",
    flag: "🇪🇬",
    id: "eg",
    idd: "+20",
  },
  {
    code: "mz",
    flag: "🇲🇿",
    id: "mz",
    idd: "+258",
  },
  {
    code: "yt",
    flag: "🇾🇹",
    id: "yt",
    idd: "+262",
  },
  {
    code: "be",
    flag: "🇧🇪",
    id: "be",
    idd: "+32",
  },
  {
    code: "si",
    flag: "🇸🇮",
    id: "si",
    idd: "+386",
  },
  {
    code: "hu",
    flag: "🇭🇺",
    id: "hu",
    idd: "+36",
  },
  {
    code: "ae",
    flag: "🇦🇪",
    id: "ae",
    idd: "+971",
  },
  {
    code: "al",
    flag: "🇦🇱",
    id: "al",
    idd: "+355",
  },
  {
    code: "fj",
    flag: "🇫🇯",
    id: "fj",
    idd: "+679",
  },
  {
    code: "sm",
    flag: "🇸🇲",
    id: "sm",
    idd: "+378",
  },
  {
    code: "md",
    flag: "🇲🇩",
    id: "md",
    idd: "+373",
  },
  {
    code: "ee",
    flag: "🇪🇪",
    id: "ee",
    idd: "+372",
  },
  {
    code: "vc",
    flag: "🇻🇨",
    id: "vc",
    idd: "+1784",
  },
  {
    code: "um",
    flag: "🇺🇲",
    id: "um",
    idd: "+268",
  },
  {
    code: "by",
    flag: "🇧🇾",
    id: "by",
    idd: "+375",
  },
  {
    code: "lk",
    flag: "🇱🇰",
    id: "lk",
    idd: "+94",
  },
  {
    code: "sv",
    flag: "🇸🇻",
    id: "sv",
    idd: "+503",
  },
  {
    code: "au",
    flag: "🇦🇺",
    id: "au",
    idd: "+61",
  },
  {
    code: "bm",
    flag: "🇧🇲",
    id: "bm",
    idd: "+1441",
  },
  {
    code: "ni",
    flag: "🇳🇮",
    id: "ni",
    idd: "+505",
  },
  {
    code: "so",
    flag: "🇸🇴",
    id: "so",
    idd: "+252",
  },
  {
    code: "tc",
    flag: "🇹🇨",
    id: "tc",
    idd: "+1649",
  },
  {
    code: "fm",
    flag: "🇫🇲",
    id: "fm",
    idd: "+691",
  },
  {
    code: "ps",
    flag: "🇵🇸",
    id: "ps",
    idd: "+970",
  },
  {
    code: "tm",
    flag: "🇹🇲",
    id: "tm",
    idd: "+993",
  },
  {
    code: "bf",
    flag: "🇧🇫",
    id: "bf",
    idd: "+226",
  },
  {
    code: "cr",
    flag: "🇨🇷",
    id: "cr",
    idd: "+506",
  },
  {
    code: "vn",
    flag: "🇻🇳",
    id: "vn",
    idd: "+84",
  },
  {
    code: "cm",
    flag: "🇨🇲",
    id: "cm",
    idd: "+237",
  },
  {
    code: "gf",
    flag: "🇬🇫",
    id: "gf",
    idd: "+594",
  },
  {
    code: "sz",
    flag: "🇸🇿",
    id: "sz",
    idd: "+268",
  },
  {
    code: "zm",
    flag: "🇿🇲",
    id: "zm",
    idd: "+260",
  },
  {
    code: "li",
    flag: "🇱🇮",
    id: "li",
    idd: "+423",
  },
  {
    code: "me",
    flag: "🇲🇪",
    id: "me",
    idd: "+382",
  },
  {
    code: "lc",
    flag: "🇱🇨",
    id: "lc",
    idd: "+1758",
  },
  {
    code: "uz",
    flag: "🇺🇿",
    id: "uz",
    idd: "+998",
  },
  {
    code: "td",
    flag: "🇹🇩",
    id: "td",
    idd: "+235",
  },
  {
    code: "aw",
    flag: "🇦🇼",
    id: "aw",
    idd: "+297",
  },
  {
    code: "dk",
    flag: "🇩🇰",
    id: "dk",
    idd: "+45",
  },
  {
    code: "jp",
    flag: "🇯🇵",
    id: "jp",
    idd: "+81",
  },
  {
    code: "cv",
    flag: "🇨🇻",
    id: "cv",
    idd: "+238",
  },
  {
    code: "ch",
    flag: "🇨🇭",
    id: "ch",
    idd: "+41",
  },
  {
    code: "hk",
    flag: "🇭🇰",
    id: "hk",
    idd: "+852",
  },
  {
    code: "bg",
    flag: "🇧🇬",
    id: "bg",
    idd: "+359",
  },
  {
    code: "jo",
    flag: "🇯🇴",
    id: "jo",
    idd: "+962",
  },
  {
    code: "cg",
    flag: "🇨🇬",
    id: "cg",
    idd: "+242",
  },
  {
    code: "la",
    flag: "🇱🇦",
    id: "la",
    idd: "+856",
  },
  {
    code: "nf",
    flag: "🇳🇫",
    id: "nf",
    idd: "+672",
  },
  {
    code: "kr",
    flag: "🇰🇷",
    id: "kr",
    idd: "+82",
  },
  {
    code: "tn",
    flag: "🇹🇳",
    id: "tn",
    idd: "+216",
  },
  {
    code: "fi",
    flag: "🇫🇮",
    id: "fi",
    idd: "+358",
  },
  {
    code: "zw",
    flag: "🇿🇼",
    id: "zw",
    idd: "+263",
  },
  {
    code: "mv",
    flag: "🇲🇻",
    id: "mv",
    idd: "+960",
  },
  {
    code: "sg",
    flag: "🇸🇬",
    id: "sg",
    idd: "+65",
  },
  {
    code: "mc",
    flag: "🇲🇨",
    id: "mc",
    idd: "+377",
  },
  {
    code: "ao",
    flag: "🇦🇴",
    id: "ao",
    idd: "+244",
  },
  {
    code: "my",
    flag: "🇲🇾",
    id: "my",
    idd: "+60",
  },
  {
    code: "lu",
    flag: "🇱🇺",
    id: "lu",
    idd: "+352",
  },
  {
    code: "gn",
    flag: "🇬🇳",
    id: "gn",
    idd: "+224",
  },
  {
    code: "ly",
    flag: "🇱🇾",
    id: "ly",
    idd: "+218",
  },
  {
    code: "es",
    flag: "🇪🇸",
    id: "es",
    idd: "+34",
  },
  {
    code: "id",
    flag: "🇮🇩",
    id: "id",
    idd: "+62",
  },
  {
    code: "sc",
    flag: "🇸🇨",
    id: "sc",
    idd: "+248",
  },
  {
    code: "bn",
    flag: "🇧🇳",
    id: "bn",
    idd: "+673",
  },
  {
    code: "mx",
    flag: "🇲🇽",
    id: "mx",
    idd: "+52",
  },
  {
    code: "sh",
    flag: "🇸🇭",
    id: "sh",
    idd: "+2",
  },
  {
    code: "sl",
    flag: "🇸🇱",
    id: "sl",
    idd: "+232",
  },
  {
    code: "cf",
    flag: "🇨🇫",
    id: "cf",
    idd: "+236",
  },
  {
    code: "gl",
    flag: "🇬🇱",
    id: "gl",
    idd: "+299",
  },
  {
    code: "co",
    flag: "🇨🇴",
    id: "co",
    idd: "+57",
  },
  {
    code: "pg",
    flag: "🇵🇬",
    id: "pg",
    idd: "+675",
  },
  {
    code: "fk",
    flag: "🇫🇰",
    id: "fk",
    idd: "+500",
  },
  {
    code: "pt",
    flag: "🇵🇹",
    id: "pt",
    idd: "+351",
  },
  {
    code: "gw",
    flag: "🇬🇼",
    id: "gw",
    idd: "+245",
  },
  {
    code: "mf",
    flag: "🇲🇫",
    id: "mf",
    idd: "+590",
  },
  {
    code: "tz",
    flag: "🇹🇿",
    id: "tz",
    idd: "+255",
  },
];

type ContinentData = {
  code: string;
  flag: string;
  id: string;
};

const continents: ContinentData[] = [
  {
    code: "eu",
    flag: "🇪🇺",
    id: "eu",
  },
];

export { continents, countries };
