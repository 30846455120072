import { clamp, groupBy } from "lodash-es";
import type { SelectOptionType } from "./types";

const computeNextIndex =
  (selectedIndex: number, optionsCount: number) => (activeIndex: number) => {
    if (activeIndex >= 0) {
      return activeIndex === 0 ? 0 : activeIndex - 1;
    }

    if (selectedIndex > 0) {
      return selectedIndex - 1;
    }

    return optionsCount - 1;
  };

const computePreviousIndex =
  (selectedIndex: number, optionsCount: number) => (activeIndex: number) => {
    if (activeIndex >= 0) {
      return activeIndex + 1 < optionsCount ? activeIndex + 1 : activeIndex;
    }

    return selectedIndex + 1;
  };

const clampIndex = (optionsCount: number, index: number) =>
  clamp(index, 0, optionsCount - 1);

const findSelectedIndex = (value?: string) => (options: SelectOptionType[]) =>
  options.findIndex((option) => option.value === value);

const DEFAULT_GROUP = "ungrouped";

const groupOptions: ReturnType<typeof groupBy> = (
  options: SelectOptionType[],
) => groupBy(options, ({ group = DEFAULT_GROUP }) => group);

const hasOtherGroups = (options: SelectOptionType[]) =>
  options.some(({ group }) => group !== DEFAULT_GROUP);

export {
  DEFAULT_GROUP,
  clampIndex,
  computeNextIndex,
  computePreviousIndex,
  findSelectedIndex,
  groupOptions,
  hasOtherGroups,
};
