import { isDefined } from "../../other/isDefined";
import type { Currency } from "./priceUtils";
import { calculateTotalPriceWithCurrency } from "./priceUtils";

type Price = {
  quantity: number;
  rate: null | number;
  rateCurrency: Currency;
};

type KronosOffer = {
  prices: Price[];
};
type ExchangeRate = {
  from: Currency;
  to: Currency;
  value: number;
};

export const calculateKronosOfferPrice =
  (exchangeRates: ExchangeRate[] | undefined, targetCurrency: Currency) =>
  (kronosOffer: KronosOffer) => {
    const priceTotal = calculateTotalPriceWithCurrency({
      exchangeRateArray: exchangeRates ?? [],
      priceArray: kronosOffer.prices.map((price) => ({
        currency: price.rateCurrency,
        value: isDefined(price.rate) ? price.rate * price.quantity : 0,
      })),
      targetCurrency,
    });

    return priceTotal;
  };
