import { useEffect } from "react";
import { useBooleanState } from "./useBooleanState";

const useIsMounted = () => {
  const [isMounted, setMounted] = useBooleanState();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMounted();
    });

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
};

export { useIsMounted };
