import React from "react";
import { useFormFieldContext } from "../FormField/FormFieldProvider";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import { styles } from "../../../theme/commons";
import { Body } from "../../Typography/Body";
import type { SystemProps } from "../../../utils/types/system";

type _FormFieldErrorProps = {
  isErrored?: boolean;
};

type _FormFieldLabelProps = {
  isRequired?: boolean;
};

export type FormFieldLabelProps = _FormFieldLabelProps & SystemProps<"label">;
export type FormFieldErrorProps = _FormFieldErrorProps & SystemProps<"div">;
export type FormFieldCaptionProps = SystemProps<"caption">;

const StyledError = styled(Body, {
  base: ({ theme }) => ({
    color: theme.colors.alert.danger,
    margin: `${theme.spacing[4]} 0`,
  }),
});

const FormFieldError = polymorphic<"div", _FormFieldErrorProps>(
  ({ isErrored: isErroredProp, ...rest }, ref) => {
    const { feedbackId, isErrored: isErroredContext } =
      useFormFieldContext() ?? {};

    const isErrored = isErroredProp || isErroredContext;

    if (!isErrored) {
      return null;
    }

    return (
      <StyledError
        aria-live="polite"
        id={feedbackId}
        {...rest}
        as="div"
        ref={ref}
        size="sm"
      />
    );
  },
);

FormFieldError.displayName = "FormFieldError";

const StyledLabel = styled<"label", { isDisabled?: boolean }>("label", {
  base: ({ isDisabled, theme }) => [
    {
      color: theme.colors.neutral.base,
      display: "block",
      fontSize: theme.font.size.md,
      fontWeight: theme.font.weight.medium,
      opacity: isDisabled ? 0.4 : undefined,
      paddingBottom: theme.spacing[8],
    },
    styles.truncated,
  ],
});

const StyledRequired = styled("span", {
  base: ({ theme }) => ({
    color: theme.colors.alert.danger,
    marginLeft: theme.spacing[4],
  }),
});

const FormFieldLabel = polymorphic<"label", FormFieldLabelProps>(
  ({ children, isRequired: isRequiredProp, ...rest }, ref) => {
    const context = useFormFieldContext();

    const isRequired = isRequiredProp || context?.isRequired;

    return (
      <StyledLabel
        htmlFor={context?.id}
        isDisabled={context?.isDisabled}
        ref={ref}
        {...rest}
      >
        {children}
        {isRequired && (
          <StyledRequired aria-hidden="true" role="presentation">
            *
          </StyledRequired>
        )}
      </StyledLabel>
    );
  },
);

FormFieldLabel.displayName = "FormFieldLabel";

const StyledCaption = styled(Body, {
  base: ({ theme }) => ({
    color: theme.colors.neutral["50"],
    display: "block",
    margin: `${theme.spacing[4]} 0`,
    textAlign: "left",
  }),
});

const FormFieldCaption = polymorphic<"caption">((props, ref) => {
  const { feedbackId, isErrored } = useFormFieldContext() ?? {};

  if (isErrored) {
    return null;
  }

  return (
    <StyledCaption
      as="caption"
      id={feedbackId}
      ref={ref}
      size="sm"
      {...props}
    />
  );
});

FormFieldCaption.displayName = "FormFieldCaption";

export { FormFieldCaption, FormFieldError, FormFieldLabel };
