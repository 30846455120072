import { deepTransformValues, walk } from "../../utils/objects";
import { flattenDeep } from "./flattenDeep";

/**
 * Walks and transform a nested theme object values to css variables.
 *
 * entry shape: {
 *   colors: {
 *     blue: {
 *       ["50"]: "hsl(214, 100%, 97%)",
 *     },
 *   },
 *   ...
 * };
 * returned shape: {
 *  colors: {
 *     blue: {
 *       ["50"]: "var(--colors-blue-50)",
 *     },
 *   },
 *   ...
 * };
 * */
const tokenizeTheme = <Theme extends Record<string, any>>(theme: Theme) =>
  deepTransformValues(theme, "", {
    delimiter: "-",
    prefix: "var(--",
    suffix: ")",
  }) as Theme;

const tokenizeKey = (key: string) => `--${key.replace(/\./g, "-")}`;

/**
 * Walks and flatten a nested theme object and tokenize its keys.
 *
 * entry shape: {
 *   colors: {
 *     blue: {
 *       ["50"]: "hsl(214, 100%, 97%)",
 *     },
 *   },
 *   ...
 * };
 * returned shape: {
 *   ["--colors-blue-50"]: "hsl(214, 100%, 97%)",
 *   ...,
 * };
 * */
const themeToCssVariables = <Theme extends Record<string, any>>(
  theme: Theme,
) => {
  const flattenedTheme = flattenDeep<Theme, string>(theme);

  return walk(flattenedTheme, tokenizeKey);
};

export { themeToCssVariables, tokenizeTheme };
