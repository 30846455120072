import React from "react";
import type { IconName } from "../Typography/Icon";
import { Icon } from "../Typography/Icon";
import type { Theme } from "../../theme/theme";
import { styled } from "../../utils/system/factory";
import type { SystemProps } from "../../utils/types/system";
import { polymorphic } from "../../utils/ref";

const colorMapper = ({ colors }: Theme) =>
  ({
    "danger-light": {
      backgroundColor: colors.alert.dangerLight,
      borderColor: colors.alert.dangerLight,
      color: colors.alert.danger,
    },
    "info-light": {
      backgroundColor: colors.alert.infoLight,
      borderColor: colors.alert.infoLight,
      color: colors.alert.info,
    },
    "neutral-dark": {
      backgroundColor: colors.neutral.base,
      borderColor: colors.neutral.base,
      color: "white",
    },
    "neutral-light": {
      backgroundColor: colors.neutral["10"],
      borderColor: colors.neutral["20"],
      color: colors.neutral.dark,
    },
    "primary-light": {
      backgroundColor: colors.primary.light,
      borderColor: colors.primary.light,
      color: colors.primary.dark,
    },
    "success-light": {
      backgroundColor: colors.alert.successLight,
      borderColor: colors.alert.successLight,
      color: colors.alert.success,
    },
    "warning-light": {
      backgroundColor: colors.alert.warningLight,
      borderColor: colors.alert.warningLight,
      color: colors.alert.warning,
    },
  }) as const;

const radiusMapper = ({ radius }: Theme) => ({
  round: radius.round,
  square: radius.xl,
});

export type BackgroundIconColor = keyof ReturnType<typeof colorMapper>;
export type BackgroundIconType = keyof ReturnType<typeof radiusMapper>;

type Props = {
  color: BackgroundIconColor;
  hasBorder?: boolean;
  icon: IconName;
  type: BackgroundIconType;
};

export type BackgroundIconProps = Props & SystemProps<"div">;

export const BackgroundIconWrapper = styled<
  "div",
  Omit<BackgroundIconProps, "icon">
>("div", {
  base: ({ color: colorProp, hasBorder, theme, type }) => {
    const { backgroundColor, borderColor, color } =
      colorMapper(theme)[colorProp];

    return {
      alignItems: "center",
      backgroundColor,
      borderColor: hasBorder ? borderColor : "transparent",
      borderRadius: radiusMapper(theme)[type],
      borderStyle: "solid",
      borderWidth: 1,
      color,
      display: "inline-flex",
      fontSize: "1rem",
      height: "2rem",
      justifyContent: "center",
      minWidth: "2rem",
      minhHeight: "2rem",
      whiteSpace: "nowrap",
      width: "2rem",
    };
  },
});

BackgroundIconWrapper.displayName = "BackgroundIconWrapper";

export const BackgroundIcon = polymorphic<"div", BackgroundIconProps>(
  ({ icon, ...rest }: Props, ref) => (
    <BackgroundIconWrapper {...rest} ref={ref}>
      <Icon name={icon} />
    </BackgroundIconWrapper>
  ),
);

BackgroundIcon.displayName = "BackgroundIcon";
