/* eslint-disable perfectionist/sort-objects */

export const colors = {
  primary: {
    light: "#ADEEDD",
    base: "#00565B",
    dark: "#00323E",
  },

  accent: {
    light: "#A6E2FF",
    base: "#0034B0",
    dark: "#00006B",
  },

  brand: {
    light: "#49D6CB", // Elf
    base: "#00898F", // Shamrock
    dark: "#00565B", // Emerald green
  },

  zach: {
    background: "#EAF9FC",
    light: "#CCF0F6",
    base: "#00809A",
    primary: "#00B5D0",
  },

  alert: {
    info: "#00497B",
    infoLight: "#BAD7FD",
    success: "#00565B",
    successLight: "#ADEEDD",
    danger: "#A33029",
    dangerLight: "#F6D5D5",
    dangerDark: "#681712",
    warning: "#AA4603",
    warningLight: "#FAF2C6",
    neutral: "var(--colors-neutral-70)",
    neutralLight: "var(--colors-neutral-10)",
  },

  neutral: {
    light: "#F5F7F9",
    base: "#232E3D",
    dark: "#101720",

    10: "#D5DCE6",
    20: "#B7C2D1",
    30: "#9AA8BB",
    40: "#7F8FA4",
    50: "#65758B",
    60: "#4D5D73",
    70: "#374559",
  },

  data: {
    orange: "#FF9B04",
    green: "#239F5F",
    teal: "#40A58E",
    cyan: "#2798F7",
    blue: "#2D51FA",
    purple: "#6545D9",
    pink: "#C82DB0",
    red: "#D42D4C",
    orangeLight: "#FFE7C2",
    greenLight: "#C4F2DB",
    tealLight: "#CCEBE4",
    cyanLight: "#BADEFC",
    blueLight: "#B9C5FD",
    purpleLight: "#CEC4F3",
    pinkLight: "#F2C5EB",
    redLight: "#F3C4CC",
  },

  nzi: {
    scoreA: "#C2FFE0",
    scoreB: "#184701",
    scoreC: "#4D3B00",
    scoreD: "#4D3B00",
    scoreE: "#FFCECC",

    backgroundScoreA: "#127A46",
    backgroundScoreB: "#95DE03",
    backgroundScoreC: "#FFCC00",
    backgroundScoreD: "#FF954D",
    backgroundScoreE: "#B20600",
  },

  background: {
    ["nav-active"]: "var(--colors-primary-light)",
    atlas: "#F9FBFC",
    atlasHeader: "white",
    light: "white",
    feed: "#FDFDFD",
  },

  shadow: "rgb(0 0 0 / 0.1)",

  border: {
    base: "var(--colors-neutral-20)",
  },

  text: {
    primary: "var(--colors-neutral-base)",
    secondary: "var(--colors-neutral-50)",
    link: "var(--colors-primary-base)",
  },

  focus: {
    ring: "#BAD7FD",
    background: "var(--colors-neutral-light)",
  },
};
