import { generateRange } from "../../../utils/numbers";

// starts at 1
type HumanPageNumber = number;

export type PaginationState = {
  currentPage: HumanPageNumber;
  pagesCount: HumanPageNumber;
};

const PAGINATION_SPACER_LEFT = "SPACER_LEFT";
const PAGINATION_SPACER_RIGHT = "SPACER_RIGHT";

const BOUNDARIES = 1; // number of fixed pages at start and end
const NEIGHBOURS = 1; // number of minimum pages left and right of active page

type PaginationSpacer =
  | typeof PAGINATION_SPACER_LEFT
  | typeof PAGINATION_SPACER_RIGHT;

export type PaginationBlock = HumanPageNumber | PaginationSpacer;
const isPaginationSpacer = (page: PaginationBlock): page is PaginationSpacer =>
  page === PAGINATION_SPACER_LEFT || page === PAGINATION_SPACER_RIGHT;

const generatePaginationBlocks = ({
  currentPage,
  pagesCount,
}: PaginationState): PaginationBlock[] => {
  const totalPageNumbers = NEIGHBOURS * 2 + 3 + BOUNDARIES * 2;

  if (totalPageNumbers >= pagesCount) {
    return generateRange(1, pagesCount);
  }

  const leftSiblingIndex = Math.max(currentPage - NEIGHBOURS, BOUNDARIES);
  const rightSiblingIndex = Math.min(
    currentPage + NEIGHBOURS,
    pagesCount - BOUNDARIES,
  );

  const shouldShowLeftDots = leftSiblingIndex > BOUNDARIES + 2;
  const shouldShowRightDots = rightSiblingIndex < pagesCount - (BOUNDARIES + 1);

  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = NEIGHBOURS * 2 + BOUNDARIES + 2;

    return [
      ...generateRange(1, leftItemCount),
      PAGINATION_SPACER_RIGHT,
      ...generateRange(pagesCount - (BOUNDARIES - 1), pagesCount),
    ];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = BOUNDARIES + 1 + 2 * NEIGHBOURS;

    return [
      ...generateRange(1, BOUNDARIES),
      PAGINATION_SPACER_LEFT,
      ...generateRange(pagesCount - rightItemCount, pagesCount),
    ];
  }

  return [
    ...generateRange(1, BOUNDARIES),
    PAGINATION_SPACER_LEFT,
    ...generateRange(leftSiblingIndex, rightSiblingIndex),
    PAGINATION_SPACER_RIGHT,
    ...generateRange(pagesCount - BOUNDARIES + 1, pagesCount),
  ];
};

type UsePaginationParams = {
  activePage: HumanPageNumber;
  pagesCount: HumanPageNumber;
  setPage: (page: HumanPageNumber) => void;
};

const paginationController = ({
  activePage,
  pagesCount,
  setPage,
}: UsePaginationParams) => {
  const goToNextPage = () => setPage(activePage + 1);
  const goToPreviousPage = () => setPage(activePage - 1);

  const goToPage = (page: HumanPageNumber) => {
    if (page <= 0) {
      return setPage(1);
    }
    if (page <= pagesCount) {
      setPage(page);
    }
  };

  return {
    goToNextPage,
    goToPage,
    goToPreviousPage,
  };
};

export {
  PAGINATION_SPACER_LEFT,
  PAGINATION_SPACER_RIGHT,
  generatePaginationBlocks,
  isPaginationSpacer,
  paginationController,
};
