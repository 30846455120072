import React from "react";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import {
  TableBody,
  TableCell,
  TableContent,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "./Table/TableComponents";
import type { TableSize } from "./Table/TableContext";
import { TableProvider } from "./Table/TableContext";

type Props = {
  highlightOnHover?: boolean;
  isFixed?: boolean;
  size?: TableSize;
  variant?: "ghost" | "outlined";
};

export type TableProps = Props & SystemProps<"div">;

const StyledTableContainer = styled<"div", Required<Pick<Props, "variant">>>(
  "div",
  {
    base: ({ theme, variant }) => [
      {
        borderRadius: theme.radius.base,
        borderSpacing: "unset",
        overflow: "auto",
        width: "100%",
      },
      variant === "outlined" && {
        border: `1px solid ${theme.colors.neutral["20"]}`,
      },
    ],
  },
);

type Compounds = {
  Body: typeof TableBody;
  Cell: typeof TableCell;
  Content: typeof TableContent;
  Footer: typeof TableFooter;
  Header: typeof TableHeader;
  HeaderCell: typeof TableHeaderCell;
  Row: typeof TableRow;
};

export const Table = polymorphic<"div", Props, Compounds>(
  (
    {
      highlightOnHover,
      isFixed = true,
      size = "md",
      variant = "outlined",
      ...rest
    },
    ref,
  ) => (
    <TableProvider
      value={{
        highlightOnHover,
        isFixed,
        size,
      }}
    >
      <StyledTableContainer variant={variant} {...rest} ref={ref} />
    </TableProvider>
  ),
);

Table.displayName = "Table";
Table.Body = TableBody;
Table.Cell = TableCell;
Table.Content = TableContent;
Table.Footer = TableFooter;
Table.Header = TableHeader;
Table.HeaderCell = TableHeaderCell;
Table.Row = TableRow;
