import type { Currency } from "../pricesUtils/priceUtils";
import { calculateTotalPriceWithCurrency } from "../pricesUtils/priceUtils";

type PriceCategory = {
  prices: {
    amount: number;
    priceCurrency: Currency;
    vatRate?: null | number;
  }[];
};

type GetTotalByCategoriesArgs = {
  priceCategories: PriceCategory[];
  targetCurrency: Currency;
  withVat?: boolean;
};

export const calculateSumOfAllCategoriesPrices = ({
  priceCategories,
  targetCurrency,
  withVat = false,
}: GetTotalByCategoriesArgs) => {
  const allPrices = priceCategories.flatMap((category) => category.prices);

  const pricesWithCurrency = allPrices.map((price) => ({
    currency: price.priceCurrency,
    value: price.amount,
    vatRate: price.vatRate,
  }));

  return calculateTotalPriceWithCurrency({
    exchangeRateArray: [],
    priceArray: pricesWithCurrency,
    targetCurrency,
    withVat,
  });
};
