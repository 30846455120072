// https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Glossary:Country_codes
export const EUROPEAN_UNION_COUNTRIES = [
  "be",
  "el",
  "lt",
  "pt",
  "bg",
  "es",
  "lu",
  "ro",
  "cz",
  "fr",
  "hu",
  "si",
  "dk",
  "hr",
  "mt",
  "sk",
  "de",
  "it",
  "nl",
  "fi",
  "ee",
  "cy",
  "at",
  "se",
  "ie",
  "lv",
  "pl",
];

//https://www.countrycallingcodes.com/iso-country-codes/europe-codes.php
export const EUROPEAN_COUNTRIES = [
  "al",
  "ad",
  "at",
  "by",
  "be",
  "ba",
  "bg",
  "hr",
  "cy",
  "cz",
  "dk",
  "ee",
  "fo",
  "fi",
  "fr",
  "de",
  "gi",
  "gr",
  "hu",
  "is",
  "ie",
  "im",
  "it",
  "rs",
  "lv",
  "li",
  "lt",
  "lu",
  "mk",
  "mt",
  "md",
  "mc",
  "me",
  "nl",
  "no",
  "pl",
  "pt",
  "ro",
  "ru",
  "sm",
  "rs",
  "sk",
  "si",
  "es",
  "se",
  "ch",
  "ua",
  "gb",
  "va",
  "rs",
];

// https://www.countrycallingcodes.com/iso-country-codes/africa-codes.php
export const AFRICAN_COUNTRIES = [
  "dz",
  "ao",
  "sh",
  "bj",
  "bw",
  "bf",
  "bi",
  "cm",
  "cv",
  "cf",
  "td",
  "km",
  "cg",
  "cd",
  "dj",
  "eg",
  "gq",
  "er",
  "sz",
  "et",
  "ga",
  "gm",
  "gh",
  "gn",
  "gw",
  "ci",
  "ke",
  "ls",
  "lr",
  "ly",
  "mg",
  "mw",
  "ml",
  "mr",
  "mu",
  "yt",
  "ma",
  "mz",
  "na",
  "ne",
  "ng",
  "st",
  "re",
  "rw",
  "st",
  "sn",
  "sc",
  "sl",
  "so",
  "za",
  "ss",
  "sh",
  "sd",
  "sz",
  "tz",
  "tg",
  "tn",
  "ug",
  "cd",
  "zm",
  "tz",
  "zw",
];

// https://www.countrycallingcodes.com/iso-country-codes/asia-codes.php
export const ASIAN_COUNTRIES = [
  "af",
  "am",
  "az",
  "bh",
  "bd",
  "bt",
  "bn",
  "kh",
  "cn",
  "cx",
  "cc",
  "io",
  "ge",
  "hk",
  "in",
  "id",
  "ir",
  "iq",
  "il",
  "jp",
  "jo",
  "kz",
  "kw",
  "kg",
  "la",
  "lb",
  "mo",
  "my",
  "mv",
  "mn",
  "mm",
  "np",
  "kp",
  "om",
  "pk",
  "ps",
  "ph",
  "qa",
  "sa",
  "sg",
  "kr",
  "lk",
  "sy",
  "tw",
  "tj",
  "th",
  "tr",
  "tm",
  "ae",
  "uz",
  "vn",
  "ye",
];

export const isAsianCountry = (countryCode: string) =>
  ASIAN_COUNTRIES.some(
    (asianCountry) => countryCode.toLowerCase() === asianCountry,
  );

export const isEuropeanUnionCountry = (countryCode: string) =>
  EUROPEAN_UNION_COUNTRIES.some(
    (europeanUnionCountry) =>
      countryCode.toLowerCase() === europeanUnionCountry,
  );

export const isEuropeanCountry = (countryCode: string) =>
  EUROPEAN_COUNTRIES.some(
    (europeanCountry) => countryCode.toLowerCase() === europeanCountry,
  );
