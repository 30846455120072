import { isArray, merge } from "lodash-es";
import type { Sx } from "../types/system";
import type { Theme } from "../../theme/theme";
import type { CSSObject } from "../types/css";
import { callIfFunction } from "../objects";

export type StyleResolverProps = {
  sx?: Sx;
  theme: Theme;
};

type BaseStyleMapperProps<P = object> = P & Pick<StyleResolverProps, "theme">;

type MaybeCSSObject = CSSObject | false | null | undefined;

export type BaseStyleMapper<P = object> =
  | ((props: BaseStyleMapperProps<P>) => MaybeCSSObject | MaybeCSSObject[])
  | CSSObject;

const extractSx = (theme: Theme) => (sx?: Sx) => {
  const styles = callIfFunction(sx)(theme);

  return isArray(styles) ? merge({}, ...styles) : styles;
};

export const composeSx = (sx: Sx | Sx[] | undefined, theme: Theme) =>
  isArray(sx) ? merge({}, ...sx.map(extractSx(theme))) : extractSx(theme)(sx);

export const composeStyleMapper = <T>(
  base: BaseStyleMapper<T> | undefined,
  props: Omit<StyleResolverProps & T, "sx" | "theme">,
  theme: Theme,
) => {
  const baseStyles = callIfFunction(base)({
    theme,
    ...props,
  } as BaseStyleMapperProps<T>);

  return isArray(baseStyles) ? merge({}, ...baseStyles) : baseStyles;
};
