import React from "react";

type ContextFactoryOptions<IsOptional extends boolean | undefined> = {
  displayName?: string;
  errorMessage?: string;
  isOptional?: IsOptional;
};

type CreateContextReturn<T> = [React.Provider<T>, () => T];

// if `isOptional: true` is passed in options, context type will be Nullable.
export function contextFactory<ContextType extends object>(
  params: ContextFactoryOptions<false>,
): CreateContextReturn<ContextType>;
export function contextFactory<ContextType extends object>(
  params: ContextFactoryOptions<undefined>,
): CreateContextReturn<ContextType | undefined>;
export function contextFactory<ContextType extends object>(
  params: ContextFactoryOptions<true>,
): CreateContextReturn<ContextType | undefined>;
export function contextFactory<ContextType extends object>({
  displayName,
  errorMessage = displayName,
  isOptional,
}: ContextFactoryOptions<
  boolean | undefined
>): CreateContextReturn<ContextType> {
  const Context = React.createContext<ContextType | undefined>(undefined);

  Context.displayName = displayName;

  const useContext = () => {
    const context = React.useContext(Context);

    if (!context && !isOptional) {
      throw new Error(`\`context\` is undefined. ${errorMessage}`);
    }

    return context;
  };

  return [Context.Provider, useContext] as CreateContextReturn<ContextType>;
}
