import type { CSSProperties, SyntheticEvent } from "react";
import React from "react";
import { noop } from "lodash-es";
import type { DocumentProps } from "../../DataDisplay/Document";
import { Document } from "../../DataDisplay/Document";
import type { FlexProps } from "../../Layout/Flex";
import { Flex } from "../../Layout/Flex";
import { Reveal } from "../../Layout/Reveal";
import { deepHasChild } from "../../../utils/children";
import { polymorphic } from "../../../utils/ref";
import type { BoxProps } from "../../Meta/Box";
import { styled } from "../../../utils/system/factory";
import { generateSpacing } from "../../../theme/commons";
import type { IconProps } from "../../Typography/Icon";
import { Icon } from "../../Typography/Icon";
import type { SpacingScale } from "../../../utils/system/system";
import type { ButtonProps } from "../../Action/Button";
import { Button } from "../../Action/Button";
import type { BodyProps } from "../../Typography/Body";
import { Body } from "../../Typography/Body";
import { HStack } from "../../Layout/Stack";

const DropzoneButton = polymorphic<"button", ButtonProps>((props, ref) => (
  <Button ref={ref} {...props} />
));

DropzoneButton.displayName = "DropzoneButton";

const StyledDescription = styled(Body, {
  base: ({ theme }) => ({
    fontWeight: theme.font.weight.medium,
    lineHeight: theme.lineHeight.md,
  }),
});

const DropzoneDescription = polymorphic<typeof Body, BodyProps>(
  (props, ref) => (
    <StyledDescription isSecondary ref={ref} size="sm" {...props} />
  ),
);

DropzoneDescription.displayName = "DropzoneDescription";

const StyledIcon = styled(Icon, {
  base: ({ theme }) => ({ color: theme.colors.neutral.base }),
});

const DropzoneIcon = polymorphic<"svg", Partial<IconProps>>(
  ({ children, name = "file-plus", ...rest }, ref) => (
    <StyledIcon name={name} ref={ref} {...rest} />
  ),
);

DropzoneIcon.displayName = "DropzoneIcon";

const DropzoneTitle = Body;

type _DropzoneGroupProps = {
  spacing?: SpacingScale;
};

const DropzoneGroup = styled<"div", _DropzoneGroupProps>("div", {
  base: ({ spacing = 16, theme }) => generateSpacing(theme)(spacing, "top"),
});

const StyledContent = styled(HStack, {
  base: ({ theme }) => ({
    cursor: "pointer",
    padding: `${theme.spacing[12]} ${theme.spacing[16]}`,
  }),
});

const DropzoneContent = polymorphic<"div", BoxProps>(
  ({ children, className, ...rest }, ref) => (
    <StyledContent
      alignItems="center"
      justifyContent="flex-start"
      ref={ref}
      spacing={12}
      {...rest}
    >
      {children}
    </StyledContent>
  ),
);

DropzoneContent.displayName = "DropzoneContent";

const DropzoneDocuments = styled(Document.Grid, {
  base: {
    border: "none",
    minWidth: 0,
  },
});

DropzoneDocuments.displayName = "DropzoneDocuments";

const StyledDrawer = styled<typeof Flex, { isActive: boolean }>(Flex, {
  base: ({ isActive, theme }) => [
    {
      background: theme.colors.background.light,
      paddingBottom: theme.spacing[8],
      paddingLeft: theme.spacing[8],
      paddingRight: theme.spacing[24],
      paddingTop: theme.spacing[8],
      transitionDuration: theme.transition.duration.fast,
      transitionProperty: "border-top",
      transitionTimingFunction: theme.transition.easing.color,
    },
    isActive && {
      borderTop: `1px dashed ${theme.colors.neutral[10]}`,
    },
  ],
});

const StyledDocument = styled(Document, {
  base: ({ theme }) => ({
    borderRadius: theme.radius.lg,
    flex: "1 1 100%",
    marginRight: theme.spacing[16],
  }),
});

const DropzoneDocument = polymorphic<"div", DocumentProps>((props, ref) => (
  <StyledDocument ref={ref} shouldShowActionsOnHover={false} {...props} />
));

DropzoneDocument.displayName = "DropzoneDocument";

type DropzoneDrawerProps = {
  overflow?: CSSProperties["overflow"];
} & FlexProps;

const DropzoneDrawer = polymorphic<"div", DropzoneDrawerProps>(
  ({ children, onClick = noop, overflow, ...rest }, ref) => {
    const isActive = deepHasChild(children, DropzoneDocument);

    return (
      <Reveal on={isActive} overflow={overflow}>
        <StyledDrawer
          alignItems="center"
          isActive={isActive}
          onClick={(event: SyntheticEvent) => {
            onClick(event);
            event.stopPropagation();
          }}
          ref={ref}
          {...rest}
        >
          {children}
        </StyledDrawer>
      </Reveal>
    );
  },
);

DropzoneDrawer.displayName = "DropzoneDrawer";

export {
  DropzoneButton,
  DropzoneContent,
  DropzoneDescription,
  DropzoneDocument,
  DropzoneDocuments,
  DropzoneDrawer,
  DropzoneGroup,
  DropzoneIcon,
  DropzoneTitle,
};
