import React from "react";
import type { FlexProps } from "../../Layout/Flex";
import { Flex } from "../../Layout/Flex";
import { polymorphic } from "../../../utils/ref";
import type { SystemProps } from "../../../utils/types/system";
import type { SpacingScale } from "../../../utils/system/system";

type Props = {
  spacing?: SpacingScale;
} & FlexProps;

export type IconButtonGroupProps = Props & SystemProps<"div">;

export const IconButtonGroup = polymorphic<"div", Props>(
  ({ spacing = 12, ...rest }, ref) => (
    <Flex gap={spacing} ref={ref} role="group" {...rest} />
  ),
);

IconButtonGroup.displayName = "IconButtonGroup";
