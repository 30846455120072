import { sum } from "lodash-es";
import { convertPrice, type Currency } from "../pricesUtils/priceUtils";

export type PriceForComputeTotalPrice = {
  priceCurrency: Currency;
  priceQuantity: number;
  unitPrice: number;
};

export type ExchangeRate = {
  from: Currency;
  to: Currency;
  value: number;
};

export const computeTotalPrice = (
  prices: PriceForComputeTotalPrice[],
  targetCurrency: Currency,
  exchangeRates: ExchangeRate[],
) => {
  const pricesInTargetCurrency = prices.map((price) =>
    convertPrice({
      baseCurrency: price.priceCurrency,
      exchangeRates,
      finalCurrency: targetCurrency,
      price: price.unitPrice * price.priceQuantity,
    }),
  );

  return sum(pricesInTargetCurrency);
};
