import type { KronosCategory } from "../../sharedTypes";

export const SORTED_CATEGORIES: KronosCategory[] = [
  "departure_truck_freight",
  "departure_fees",
  "freight",
  "arrival_fees",
  "arrival_truck_freight",
  "insurance",
];

export const compareKronosCategories = (
  categoryA: KronosCategory,
  categoryB: KronosCategory,
) => {
  const indexOfCategoryA = SORTED_CATEGORIES.indexOf(categoryA);
  const indexOfCategoryB = SORTED_CATEGORIES.indexOf(categoryB);

  return indexOfCategoryA - indexOfCategoryB;
};

export const sortByKronosCategory = (
  { category: categoryA }: { category: KronosCategory },
  { category: categoryB }: { category: KronosCategory },
) => {
  const indexOfCategoryA = SORTED_CATEGORIES.indexOf(categoryA);
  const indexOfCategoryB = SORTED_CATEGORIES.indexOf(categoryB);

  return indexOfCategoryA - indexOfCategoryB;
};

export const sortKronosCategories = (categories: KronosCategory[]) => {
  return categories.slice().sort(compareKronosCategories);
};
