import { dayjs } from "../ovrDayjs";
import type { FreightMethodOvrUtils } from "../orderUtils/orderFreightMethodSelectors";
import { isDefined } from "../other/isDefined";
import { isNotDefined } from "../other/isNotDefined";
import type { DbPricingSteps } from "../sharedTypes";

export const OPERATIONAL_INCIDENT_STAKEHOLDERS = {
  arrival_customs: { label: "Arrival customs" },
  arrival_logistics: { label: "Arrival logistics" },
  arrival_shipowner: { label: "Arrival shipowner" },
  client: { label: "Client" },
  contacted_shipowner_from_quote: { label: "Quote shipowner" },
  departure_customs: { label: "Departure customs" },
  departure_logistics: { label: "Departure logistics" },
  departure_shipowner: { label: "Departure shipowner" },
  freight_shipowner: { label: "Freight shipowner" },
  on_carriage_shipowner: { label: "On-carriage shipowner" },
  ovrsea: { label: "Ovrsea" },
  pre_carriage_shipowner: { label: "Pre-carriage shipowner" },
  supplier: { label: "Supplier" },
} as const;

export type OperationalIncidentStakeholder =
  keyof typeof OPERATIONAL_INCIDENT_STAKEHOLDERS;

export const PRICING_STEPS_TO_INCIDENT_STAKEHOLDER = {
  arrival_customs: "arrival_customs",
  arrival_fees: "arrival_shipowner",
  arrival_logistics: "arrival_shipowner",
  arrival_truck_freight: "on_carriage_shipowner",
  departure_customs: "departure_customs",
  departure_fees: "departure_shipowner",
  departure_logistics: "departure_shipowner",
  departure_truck_freight: "pre_carriage_shipowner",
  freight: "freight_shipowner",
} as const satisfies Partial<
  Record<DbPricingSteps, OperationalIncidentStakeholder>
>;

export const INCIDENT_STAKEHOLDER_TO_PRICING_STEPS = {
  arrival_customs: "arrival_customs",
  arrival_logistics: "arrival_logistics",
  departure_customs: "departure_customs",
  departure_logistics: "departure_logistics",
  departure_shipowner: "departure_logistics",
  freight_shipowner: "freight",
  on_carriage_shipowner: "arrival_truck_freight",
  pre_carriage_shipowner: "departure_truck_freight",
} as const satisfies Partial<
  Record<OperationalIncidentStakeholder, DbPricingSteps>
>;

export const OPERATIONAL_INCIDENT_TYPES = {
  changed_their_mind: {
    defaultStakeholder: null,
    isStakeholderMandatory: true,
    label: "Changed their mind",
  },
  customs_control: {
    defaultStakeholder: "freight_shipowner",
    isStakeholderMandatory: false,
    label: "🛂 Customs Control",
  },
  delay: {
    defaultStakeholder: null,
    isStakeholderMandatory: true,
    label: "Delay",
  },
  external: {
    defaultStakeholder: null,
    isStakeholderMandatory: false,
    label: "External (weather, strikes...)",
  },
  mistake: {
    defaultStakeholder: null,
    isStakeholderMandatory: true,
    label: "Mistake / Shared wrong info",
  },
  no_equipment_release: {
    defaultStakeholder: "freight_shipowner",
    isStakeholderMandatory: true,
    label: "No equipment release",
  },
  no_space_release: {
    defaultStakeholder: "freight_shipowner",
    isStakeholderMandatory: true,
    label: "No space release",
  },
  no_vessel_available: {
    defaultStakeholder: "freight_shipowner",
    isStakeholderMandatory: true,
    label: "No vessel available",
  },
  no_warehouse_availability: {
    defaultStakeholder: "client",
    isStakeholderMandatory: true,
    label: "No warehouse availability",
  },
  rollover: {
    defaultStakeholder: "freight_shipowner",
    isStakeholderMandatory: true,
    label: "Rollover",
  },
  unresponsive: {
    defaultStakeholder: null,
    isStakeholderMandatory: true,
    label: "🗣 Did not share info on time",
  },
} as const satisfies Record<
  string,
  {
    defaultStakeholder: OperationalIncidentStakeholder | null;
    isStakeholderMandatory: boolean;
    label: string;
  }
>;

export type OperationalIncidentType = keyof typeof OPERATIONAL_INCIDENT_TYPES;
export const OPERATIONAL_INCIDENT_OPTIONS = Object.keys(
  OPERATIONAL_INCIDENT_TYPES,
).map((incident) => ({
  label:
    OPERATIONAL_INCIDENT_TYPES[
      incident as keyof typeof OPERATIONAL_INCIDENT_TYPES
    ].label,
  value: incident,
}));

type TrackingIncidentImpactOnClientMetadata = Readonly<{
  label: string;
}>;

export const TRACKING_INCIDENTS_IMPACTS_ON_CLIENT = {
  cargo_ready_date_compromised: {
    label: "CRD compromised",
  },
  delivery_compromised: {
    label: "Delivery compromised",
  },
  eta_compromised: {
    label: "ETA compromised",
  },
  etd_compromised: {
    label: "ETD compromised",
  },
  pickup_compromised: {
    label: "Pickup compromised",
  },
} as const satisfies Record<string, TrackingIncidentImpactOnClientMetadata>;

export const OPERATIONAL_INCIDENT_IMPACTS_ON_CLIENT = {
  allocation_compromised: {
    label: "Allocation compromised",
  },
  cargo_damaged: {
    label: "Cargo damaged",
  },
  cargo_lost: {
    label: "Cargo lost or stolen",
  },
  quote_answer_compromised: {
    label: "Quote answer compromised",
  },
  ...TRACKING_INCIDENTS_IMPACTS_ON_CLIENT,
} as const satisfies Record<string, TrackingIncidentImpactOnClientMetadata>;

export type OperationalIncidentClientImpact =
  keyof typeof OPERATIONAL_INCIDENT_IMPACTS_ON_CLIENT;

export type TrackingIncidentClientImpact =
  keyof typeof TRACKING_INCIDENTS_IMPACTS_ON_CLIENT;

export const OPERATIONAL_INCIDENT_IMPACTS_ON_CLIENT_OPTIONS = Object.entries(
  OPERATIONAL_INCIDENT_IMPACTS_ON_CLIENT,
).map(([impact, { label }]) => ({
  label,
  value: impact,
}));

type TrackingDateChange = {
  hasChanged: boolean;
  initialDate: null | number | undefined;
  newDate: null | number | undefined;
};

export const computeDiffInDays = (
  trackingDateChange: TrackingDateChange | undefined,
): number => {
  if (isNotDefined(trackingDateChange)) {
    return 0;
  }

  const { initialDate, newDate } = trackingDateChange;

  if (isNotDefined(initialDate) || isNotDefined(newDate)) {
    return 0;
  }

  return dayjs(newDate * 1000).diff(dayjs(initialDate * 1000), "days");
};

export const isIncidentTransitTimeImpactCritical = (
  freightMethod: FreightMethodOvrUtils,
  trackingDateChange: TrackingDateChange,
) => {
  const transitTimeImpact = computeDiffInDays(trackingDateChange);

  switch (freightMethod) {
    case "air":
      return transitTimeImpact > 4;
    case "ocean":
      return transitTimeImpact > 10;

    default:
      return false;
  }
};

type TrackingEventType =
  | "cargo_ready_date"
  | "delivery"
  | "eta"
  | "etd"
  | "pickup";

export { type TrackingEventType as IncidentTrackingEventType };

export type DetermineIfCriticalTrackingIncidentArgs = Partial<
  Record<TrackingEventType, TrackingDateChange>
>;

type IsCriticalTrackingIncidentResult =
  | {
      incidentClientImpact: TrackingIncidentClientImpact | null;
      isTransitTimeImpactCritical: false;
      trackingEventDateAfterIncident: null | number;
      trackingEventImpacted: TrackingEventType | null;
      transitTimeImpactInDays: null | number;
    }
  | {
      incidentClientImpact: TrackingIncidentClientImpact;
      isTransitTimeImpactCritical: true;
      trackingEventDateAfterIncident: null | number;
      trackingEventImpacted: TrackingEventType;
      transitTimeImpactInDays: number;
    };

const ADDITIONAL_INFO_BY_DATE_TYPE = {
  cargo_ready_date: {
    incidentClientImpact: "cargo_ready_date_compromised",
    trackingEventImpacted: "cargo_ready_date",
  },
  delivery: {
    incidentClientImpact: "delivery_compromised",
    trackingEventImpacted: "delivery",
  },
  eta: {
    incidentClientImpact: "eta_compromised",
    trackingEventImpacted: "eta",
  },
  etd: {
    incidentClientImpact: "etd_compromised",
    trackingEventImpacted: "etd",
  },
  pickup: {
    incidentClientImpact: "pickup_compromised",
    trackingEventImpacted: "pickup",
  },
} as const satisfies Record<
  TrackingEventType,
  Pick<
    IsCriticalTrackingIncidentResult,
    "incidentClientImpact" | "trackingEventImpacted"
  >
>;

export const determineDelayAndCriticityOfTrackingIncident =
  (freightMethod: FreightMethodOvrUtils) =>
  ({
    cargo_ready_date,
    delivery,
    eta,
    etd,
    pickup,
  }: DetermineIfCriticalTrackingIncidentArgs): IsCriticalTrackingIncidentResult => {
    if (
      isDefined(cargo_ready_date) &&
      cargo_ready_date.hasChanged &&
      isIncidentTransitTimeImpactCritical(freightMethod, cargo_ready_date)
    ) {
      return {
        isTransitTimeImpactCritical: true,
        trackingEventDateAfterIncident: cargo_ready_date.newDate ?? null,
        transitTimeImpactInDays: computeDiffInDays(cargo_ready_date),
        ...ADDITIONAL_INFO_BY_DATE_TYPE.cargo_ready_date,
      };
    }

    if (
      isDefined(pickup) &&
      pickup.hasChanged &&
      isIncidentTransitTimeImpactCritical(freightMethod, pickup)
    ) {
      return {
        isTransitTimeImpactCritical: true,
        trackingEventDateAfterIncident: pickup.newDate ?? null,
        transitTimeImpactInDays: computeDiffInDays(pickup),
        ...ADDITIONAL_INFO_BY_DATE_TYPE.pickup,
      };
    }

    if (
      isDefined(etd) &&
      etd.hasChanged &&
      isIncidentTransitTimeImpactCritical(freightMethod, etd)
    ) {
      return {
        isTransitTimeImpactCritical: true,
        trackingEventDateAfterIncident: etd.newDate ?? null,
        transitTimeImpactInDays: computeDiffInDays(etd),
        ...ADDITIONAL_INFO_BY_DATE_TYPE.etd,
      };
    }

    if (
      isDefined(eta) &&
      eta.hasChanged &&
      isIncidentTransitTimeImpactCritical(freightMethod, eta)
    ) {
      return {
        isTransitTimeImpactCritical: true,
        trackingEventDateAfterIncident: eta.newDate ?? null,
        transitTimeImpactInDays: computeDiffInDays(eta),
        ...ADDITIONAL_INFO_BY_DATE_TYPE.eta,
      };
    }

    if (
      isDefined(delivery) &&
      delivery.hasChanged &&
      isIncidentTransitTimeImpactCritical(freightMethod, delivery)
    ) {
      return {
        isTransitTimeImpactCritical: true,
        trackingEventDateAfterIncident: delivery.newDate ?? null,
        transitTimeImpactInDays: computeDiffInDays(delivery),
        ...ADDITIONAL_INFO_BY_DATE_TYPE.delivery,
      };
    }

    const transitTimeImpactByPriority = [
      {
        date: "cargo_ready_date",
        hasChanged: isDefined(cargo_ready_date) && cargo_ready_date.hasChanged,
        trackingEventDateAfterIncident: cargo_ready_date?.newDate ?? null,
        transitTimeImpact: computeDiffInDays(cargo_ready_date),
      },
      {
        date: "pickup",
        hasChanged: isDefined(pickup) && pickup.hasChanged,
        trackingEventDateAfterIncident: pickup?.newDate ?? null,
        transitTimeImpact: computeDiffInDays(pickup),
      },
      {
        date: "etd",
        hasChanged: isDefined(etd) && etd.hasChanged,
        trackingEventDateAfterIncident: etd?.newDate ?? null,
        transitTimeImpact: computeDiffInDays(etd),
      },
      {
        date: "eta",
        hasChanged: isDefined(eta) && eta.hasChanged,
        trackingEventDateAfterIncident: eta?.newDate ?? null,
        transitTimeImpact: computeDiffInDays(eta),
      },
      {
        date: "delivery",
        hasChanged: isDefined(delivery) && delivery.hasChanged,
        trackingEventDateAfterIncident: delivery?.newDate ?? null,
        transitTimeImpact: computeDiffInDays(delivery),
      },
    ] as const;

    const transitTimeImpactToConsider = transitTimeImpactByPriority
      .filter(
        ({ hasChanged, transitTimeImpact }) =>
          hasChanged && transitTimeImpact > 0,
      )
      .at(0);

    const delayInformation = {
      incidentClientImpact: transitTimeImpactToConsider?.date
        ? ADDITIONAL_INFO_BY_DATE_TYPE[transitTimeImpactToConsider.date]
            .incidentClientImpact
        : null,
      trackingEventDateAfterIncident:
        transitTimeImpactToConsider?.trackingEventDateAfterIncident ?? null,
      trackingEventImpacted: transitTimeImpactToConsider?.date ?? null,
      transitTimeImpactInDays:
        transitTimeImpactToConsider?.transitTimeImpact ?? null,
    } as const;

    return {
      isTransitTimeImpactCritical: false,
      ...delayInformation,
    };
  };
