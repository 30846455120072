import type { ReactNode } from "react";
import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { polymorphic } from "../../../../utils/ref";
import type { Sx, SystemProps } from "../../../../utils/types/system";
import { styled } from "../../../../utils/system/factory";
import type { Theme } from "../../../../theme/theme";
import { HStack } from "../../../Layout/Stack";
import type { BodyProps } from "../../../Typography/Body";
import { Body } from "../../../Typography/Body";
import type { IconProps } from "../../../Typography/Icon";
import { Icon } from "../../../Typography/Icon";
import { Box } from "../../../Meta/Box";

export type NextTimelineEventStatus =
  | "done"
  | "error"
  | "notConfirmed"
  | "pending";

export type EventProps = {
  date: ReactNode;
  name: ReactNode;
  onEventIconClick?: () => void;
  showStatusIcon?: boolean;
  status?: NextTimelineEventStatus;
};

export type NextTimelineEventProps = EventProps & SystemProps<"div">;

const iconMap = {
  done: "check",
  error: "warning",
  notConfirmed: "question-circle",
  pending: "circle",
} as const;

const colorMapper = ({ colors }: Theme) =>
  ({
    done: {
      icon: colors.alert.success,
      name: colors.alert.success,
    },
    error: {
      icon: colors.alert.danger,
      name: colors.alert.danger,
    },
    notConfirmed: {
      icon: colors.alert.info,
      name: colors.neutral.base,
    },
    pending: {
      icon: colors.neutral[40],
      name: colors.neutral.base,
    },
  }) as const;

const StyledIcon = styled<
  typeof Icon,
  { status: NextTimelineEventStatus } & IconProps
>(Icon, {
  base: ({ onClick, status, theme }) => [
    {
      color: colorMapper(theme)[status].icon,
      height: 16,
      width: 16,
    },
    status === "pending" && {
      height: 8,
      width: 8,
    },
    onClick && {
      cursor: "pointer",
    },
  ],
});

const StyledDateContainer = styled<typeof Body, BodyProps>(Body, {
  base: ({ theme }) => ({
    ...theme.caption.xs,
    color: theme.colors.neutral[50],
    display: "inline",
    fontWeight: theme.font.weight.medium,
  }),
});

const iconContainerSx: Sx = (theme: Theme) => ({
  height: theme.spacing[16],
  width: theme.spacing[16],
});

const nameSx =
  (status: NextTimelineEventStatus): Sx =>
  (theme: Theme) => ({
    color: colorMapper(theme)[status].name,
  });

export const NextTimelineEvent = polymorphic<"div", NextTimelineEventProps>(
  (
    {
      date,
      name,
      onEventIconClick,
      showStatusIcon = true,
      status = "pending",
      ...rest
    },
    ref,
  ) => (
    <HStack ref={ref} {...rest}>
      {showStatusIcon && (
        <Box marginRight={8} sx={iconContainerSx}>
          <StyledIcon
            name={iconMap[status]}
            onClick={onEventIconClick}
            status={status}
          />
        </Box>
      )}
      <Body>
        <Body isInline marginRight={4} size="sm" sx={nameSx(status)}>
          {name}
        </Body>
        {isDefined(date) && (
          <>
            <StyledDateContainer marginRight={4}>•</StyledDateContainer>
            <StyledDateContainer>{date}</StyledDateContainer>
          </>
        )}
      </Body>
    </HStack>
  ),
);

NextTimelineEvent.displayName = "NextTimelineEvent";
