import roundValue from "./roundValue";

type PercentageBetweenValuesOptions = {
  rounded?: boolean;
};

export const percentageBetweenValues = (
  firstValue: number,
  secondValue: number,
  options?: PercentageBetweenValuesOptions,
) => {
  const difference = secondValue - firstValue;
  const percentage = (difference / firstValue) * 100;

  if (options?.rounded) {
    return roundValue(percentage);
  }

  return percentage;
};
