import type { MouseEventHandler, SyntheticEvent } from "react";
import { useCallback } from "react";
import type { SelectOptionType } from "../Shared/types";

type Params = {
  activeIndex: number;
  filteredOptions: SelectOptionType[];
  handleFocusInput: () => void;
  handleResetFilter: (value: string[]) => void;
  isDisabled?: boolean;
  isOpened: boolean;
  isSearchable?: boolean;
  onChange: (value: string[]) => void;
  onSearchChange: (value: string) => void;
  setActiveIndex: (id: number) => void;
  setClosed: () => void;
  setOpened: () => void;
  value?: string[];
};

export const useMultiSelectInteractions = ({
  activeIndex,
  filteredOptions,
  handleFocusInput,
  handleResetFilter,
  isDisabled,
  isOpened,
  isSearchable,
  onChange,
  onSearchChange,
  setActiveIndex,
  setClosed,
  setOpened,
  value = [],
}: Params) => {
  const handleInputBlur = () => {
    if (isSearchable) {
      onSearchChange("");
    }
    setClosed();
  };

  const handleInputChange = (value: string) => {
    onSearchChange(value);
    setActiveIndex(-1);
    setOpened();
  };

  const handleInputClick = (event: SyntheticEvent) => {
    if (isDisabled) {
      return event.stopPropagation();
    }
    if (!isOpened) {
      setTimeout(handleFocusInput, 0);
    }
  };

  const handleSelect = useCallback(
    (selectedValue: string) => {
      const isAlreadySelected = value.some(
        (option) => option === selectedValue,
      );

      if (isAlreadySelected) {
        const nextValue = value.filter((option) => option !== selectedValue);

        onChange(nextValue);
        handleResetFilter(nextValue);
      } else {
        const nextValue = [...value, selectedValue];

        onChange(nextValue);
        handleResetFilter(nextValue);
      }

      onSearchChange("");
      setActiveIndex(-1);
      setTimeout(handleFocusInput, 0);
    },
    [value],
  );

  const handleListClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const activeOption = filteredOptions[activeIndex];

    const isTargetingListbox =
      (event.target as HTMLDivElement).role === "listbox";

    if (isTargetingListbox && activeOption) {
      handleSelect(activeOption.value);
    }
  };

  return {
    handleInputBlur,
    handleInputChange,
    handleInputClick,
    handleListClick,
    handleSelect,
  };
};
