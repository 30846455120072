import type { PropsWithChildren, RefObject } from "react";
import React, { useCallback } from "react";
import ReactFocusLock from "react-focus-lock";
import { focusElement, selectFocusableElements } from "../../utils/focus";

type Props = {
  contentRef?: RefObject<HTMLElement>;
  isDisabled?: boolean;
  returnFocusRef?: RefObject<HTMLElement>;
};

const FocusLock = ({
  children,
  contentRef,
  isDisabled,
  returnFocusRef,
}: PropsWithChildren<Props>) => {
  const onActivation = useCallback(() => {
    if (contentRef?.current) {
      const focusables = selectFocusableElements(contentRef.current);

      if (focusables.length === 0) {
        focusElement(contentRef.current);
      }
    }
  }, [contentRef]);

  const onDeactivation = useCallback(() => {
    returnFocusRef?.current?.focus();
  }, [returnFocusRef]);

  if (!contentRef) {
    return <>{children}</>;
  }

  return (
    <ReactFocusLock
      autoFocus
      crossFrame
      disabled={isDisabled}
      onActivation={onActivation}
      onDeactivation={onDeactivation}
      returnFocus={!returnFocusRef}
    >
      {children}
    </ReactFocusLock>
  );
};

export { FocusLock };
