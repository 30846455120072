import type { CSSProperties } from "react";
import React from "react";
import { useImageLoad } from "../../utils/hooks/useImageLoad";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import { useOvrseaTheme } from "../../theme/useTheme";
import type { SystemProps } from "../../utils/types/system";

type Props = {
  containerStyle?: CSSProperties;
  dominantColor?: string;
  ratio: number;
  src: string;
  style?: CSSProperties;
};

export type ImageProps = Props & SystemProps<"img">;

const placeholderContainerStyle = (
  imageDominantColor: string,
  imageStyle?: CSSProperties,
) => ({
  backgroundColor: imageDominantColor,
  ...imageStyle,
});

const StyledImageContainer = styled("div", {
  base: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
  },
});

const StyledImage = styled("img", {
  base: ({ theme }) => ({
    "&.loaded": {
      opacity: 1,
    },
    left: 0,
    opacity: 0,
    position: "absolute",
    top: 0,
    transitionDuration: theme.transition.duration.slower,
    transitionProperty: "opacity",
    transitionTimingFunction: theme.transition.easing.opacity,
    width: "100%",
  }),
});

const StyledPlaceholder = styled<"div", Pick<Props, "ratio">>("div", {
  base: ({ ratio }) => ({
    paddingBottom: `${ratio}%`,
  }),
});

export const Image = polymorphic<"img", Props>(
  (
    {
      alt,
      children,
      containerStyle,
      dominantColor: dominantColorProp,
      id,
      ratio,
      src,
      style,
      ...rest
    },
    ref,
  ) => {
    const theme = useOvrseaTheme();
    const dominantColor = dominantColorProp || theme.colors.neutral["10"];
    const [imageRef, onImageLoad] = useImageLoad();

    return (
      <StyledImageContainer
        className="image-placeholder"
        id={id}
        style={placeholderContainerStyle(dominantColor, containerStyle)}
        {...rest}
      >
        {children}
        <StyledImage
          alt={alt}
          onLoad={onImageLoad}
          ref={imageRef}
          src={src}
          style={style}
        />

        <StyledPlaceholder
          className="ratio-placeholder"
          ratio={ratio}
          ref={ref}
        />
      </StyledImageContainer>
    );
  },
);

Image.displayName = "Image";
