import React from "react";
import type { SystemProps } from "../../utils/types/system";
import { HeadlessMenu } from "../Meta/Headless";
import { polymorphic } from "../../utils/ref";
import { Box } from "../Meta/Box";
import {
  MenuButton,
  MenuContent,
  MenuDivider,
  MenuItem,
} from "./Menu/MenuComponents";

export type MenuProps = Omit<SystemProps<"div">, "dir">;

type Compounds = {
  Button: typeof MenuButton;
  Content: typeof MenuContent;
  Divider: typeof MenuDivider;
  Item: typeof MenuItem;
};
export const Menu = polymorphic<"div", MenuProps, Compounds>(
  ({ as = HeadlessMenu.Root, children, dir, sx, ...rest }, ref) => (
    <HeadlessMenu.Root modal={false} {...rest}>
      <Box as={as} ref={ref} sx={sx}>
        {children}
      </Box>
    </HeadlessMenu.Root>
  ),
);

Menu.displayName = "Menu";
Menu.Button = MenuButton;
Menu.Content = MenuContent;
Menu.Item = MenuItem;
Menu.Divider = MenuDivider;
