import React from "react";
import { polymorphic } from "../../../utils/ref";
import type { SystemProps } from "../../../utils/types/system";
import { styled } from "../../../utils/system/factory";

type BreadcrumbSeparatorProps = SystemProps<"span">;

const StyledSeparator = styled("span", {
  base: ({ theme }) => ({
    color: theme.colors.neutral["30"],
    marginInline: theme.spacing[8],
  }),
});

const BreadcrumbSeparator = polymorphic<"span", BreadcrumbSeparatorProps>(
  (props, ref) => <StyledSeparator ref={ref} role="presentation" {...props} />,
);

BreadcrumbSeparator.displayName = "BreadcrumbSeparator";

export { BreadcrumbSeparator };
