import type { KeyboardEvent, MouseEvent, RefObject } from "react";
import { useCallback, useRef } from "react";

type UseOverlayOptions = {
  isOpened?: boolean;
  onClose?: () => void;
  returnFocusRef?: RefObject<HTMLElement>;
  shouldCloseOnEscape?: boolean;
  shouldCloseOnOutsideClick?: boolean;
  shouldTrapFocus?: boolean;
};

const useOverlay = ({
  onClose,
  returnFocusRef,
  shouldCloseOnEscape,
  shouldCloseOnOutsideClick,
  shouldTrapFocus,
}: UseOverlayOptions) => {
  const mouseDownTarget = useRef<EventTarget | null>(null);

  const contentRef = useRef<HTMLDivElement>(null);

  const onContentMouseDown = useCallback((event: MouseEvent<HTMLElement>) => {
    mouseDownTarget.current = event.target;
  }, []);

  const onContentKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === "Escape" && shouldCloseOnEscape) {
        onClose?.();
      }
    },
    [shouldCloseOnEscape, onClose],
  );

  const onContentClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      if (mouseDownTarget.current !== event.target) {
        return;
      }

      if (shouldCloseOnOutsideClick) {
        onClose?.();
      }
    },
    [shouldCloseOnOutsideClick, onClose],
  );

  return {
    contentRef,
    onContentClick,
    onContentKeyDown,
    onContentMouseDown,
    returnFocusRef,
    shouldTrapFocus,
  };
};

export { useOverlay };
