import { countTeus } from "../cargaisonUtils/containerUtils";
import { isNotDefined } from "../other/isNotDefined";
import type { ContainerForAutofill, PriceForAutofill } from "./types";

export const applyRuleOfThreeOnQuantityBasedOnContainersNumber =
  <Container extends ContainerForAutofill>({
    newContainers,
    oldContainers,
  }: {
    newContainers: Container[];
    oldContainers: Container[];
  }) =>
  <Price extends { quantity?: null | number }>(price: Price) => {
    if (isNotDefined(price.quantity)) {
      return null;
    }

    if (oldContainers.length === 0) {
      return price.quantity;
    }

    return Math.round(
      (price.quantity * newContainers.length) / oldContainers.length,
    );
  };

const isContainerConcernedByPrice =
  <Price extends PriceForAutofill>(price: Price) =>
  ({ containerType }: { containerType: string }) => {
    const priceContainerType = price.containerType;

    if (!priceContainerType) {
      return true;
    }

    return containerType === priceContainerType;
  };

export const determinePriceQuantityForNewContainers =
  <Container extends ContainerForAutofill>({
    newContainers,
    oldContainers,
  }: {
    newContainers: Container[];
    oldContainers: Container[];
  }) =>
  <Price extends PriceForAutofill>(price: Price): null | number | undefined => {
    const concernedContainers = newContainers.filter(
      isContainerConcernedByPrice(price),
    );

    const totalTeus = countTeus(concernedContainers);
    const numberOfContainers = concernedContainers.length;

    switch (price.quantityUnit) {
      case "teu":
        return totalTeus;
      case "tc":
        return numberOfContainers;
      case "km":
      case "related_rate":
        return applyRuleOfThreeOnQuantityBasedOnContainersNumber({
          newContainers,
          oldContainers,
        })(price);

      default:
        return price.quantity;
    }
  };
