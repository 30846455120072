import type { ReactNode } from "react";
import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import { polymorphic } from "../../../../utils/ref";
import type { SystemProps } from "../../../../utils/types/system";
import { styled } from "../../../../utils/system/factory";
import { VStack } from "../../../Layout/Stack";
import type { BodyProps } from "../../../Typography/Body";
import { Body } from "../../../Typography/Body";
// import type { CaptionProps } from "../../../Typography/Caption";
// import { Caption } from "../../../Typography/Caption";
import { Link } from "../../../Navigation/Link";

export type StepProps = {
  description?: ReactNode;
  href?: string;
  onStepClick?: () => void;
  title: ReactNode;
};

type NextTimelineStepProps = Omit<SystemProps<"div">, "title"> & StepProps;

const StyledTitle = styled<typeof Body, { href?: string } & BodyProps>(Body, {
  base: ({ theme }) => ({
    fontWeight: theme.font.weight.medium,
  }),
});

/* const StyledCaption = styled<typeof Caption, CaptionProps>(Caption, {
  base: ({ theme }) => ({
    color: theme.colors.neutral[50],
    fontWeight: theme.font.weight.medium,
  }),
}); */

export const NextTimelineStep = polymorphic<"div", NextTimelineStepProps>(
  ({ description, href, onStepClick, title, ...rest }, ref) => (
    <VStack ref={ref} {...rest}>
      <StyledTitle
        as={href || onStepClick ? Link : Body}
        href={href}
        onClick={onStepClick}
        size="sm"
      >
        {title}
      </StyledTitle>
      {isDefined(description) && description}
    </VStack>
  ),
);

NextTimelineStep.displayName = "NextTimelineStep";
