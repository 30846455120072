import type { As, FactoryComponent } from "../types/polymorphic";
import type { DOMElements, HTMLOvrseaComponents } from "../types/dom";
import { styledWrapper } from "../../theme/serializer/adapters";
import type { ShouldForwardProp } from "../../theme/serializer/shouldForwardProp";
import type { BaseStyleMapper } from "./compose";

export type OvrseaFactoryOptions<P = object> = {
  base?: BaseStyleMapper<P>;
  shouldForwardProp?: ShouldForwardProp;
};

type OvrseaFactory = {
  <Component extends As, Props = object>(
    component: Component,
    options?: OvrseaFactoryOptions<Props>,
  ): FactoryComponent<Component, Props>;
};

const domElementsFactory = () => {
  const cache = new Map<DOMElements, FactoryComponent<DOMElements>>();

  return new Proxy(styledWrapper, {
    apply(_, __, args: [DOMElements, OvrseaFactoryOptions]) {
      return styledWrapper(...args);
    },
    get(_, element: DOMElements) {
      if (!cache.has(element)) {
        cache.set(element, styledWrapper(element));
      }

      return cache.get(element);
    },
  }) as HTMLOvrseaComponents & OvrseaFactory;
};

const styled = domElementsFactory();

/**
 * `styled` is the base of our component styling system.
 * It is internal and can be used in two ways:

 * 1. With the styled factory
 *    The styled factory generates theme-aware, polymorphic components.
 *    ```tsx
 *    const StyledTabList = styled("div", {
 *      base: ({ theme }) => ({
 *        background: theme.colors.neutral.dark,
 *      }),
 *    });
 *    ```

 * 2. With native HTML tags
 *    All JSX intrinsic elements are available to use directly (div, span, button, caption, label, header, etc.)
 *    e.g.: `<styled.header>...</styled.header>`
 *    Use this if you need to spread system props (as, sx, paddingRight, etc.) and you don't need to map anything from theme
 * */

export { styled };
